import React, { useState } from "react"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { Alert, Spinner } from "reactstrap"
import PhoneInput from "react-phone-input-2"

import { searchPatient } from "../../services/api"
import ViewReportModal from "../../components/modals/ViewReportModal"
import { AllRoutesMap } from "../../routes/RoutesConfig"
import { useToast } from "@chakra-ui/core"
import { phoneNoCountries, getPassport } from "../../utils/common"

const radios = [
  {
    value: "passport",
    label: "Passport",
  },
  {
    value: "phone",
    label: "Contact#",
  },
  {
    value: "registrationId",
    label: "MR#",
  },
]

export default function PatientSearchView() {
  const [loadingPatient, setLoadingPatient] = useState(false)
  const [query, setQuery] = useState("")
  const [filter, setFilter] = useState("passport")
  const [patient, setPatient] = useState([])
  const [selectedRegistration, setSelectedRegistration] = useState(null)
  const [openViewReportModal, setOpenViewReportModal] = useState(false)
  const toast = useToast()

  const toggleViewReportModal = () => {
    setOpenViewReportModal(!openViewReportModal)
  }

  const onKeyUp = event => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  const handleSearch = async () => {
    if (!filter || !query?.trim() || query?.trim() === "92") {
      toast({
        title: "Error",
        description: 'Enter some query in the "Search By"',
        status: "warning",
        duration: 3000,
        isClosable: true,
      })
      return
    }
    try {
      setPatient(null)
      setLoadingPatient(true)

      const res = await searchPatient({
        filter,
        query,
      })
      setPatient(res?.data?.registrations)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingPatient(false)
    }
  }

  const getResult = registration => {
    const status = registration?.reportVerifiedAt
      ? registration?.result
        ? registration.result === "detected"
          ? "Detected"
          : "Not Detected"
        : "-"
      : "-"
    const color = registration?.reportVerifiedAt
      ? registration?.result
        ? registration.result === "detected"
          ? "text-white bg-danger"
          : "text-white bg-success"
        : ""
      : ""
    return { status, color }
  }

  const handleSearchDetails = ({ registrationId }) => {
    setSelectedRegistration({
      registrationId,
    })
    setOpenViewReportModal(true)
  }

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12">
          <h4>Patient COVID-19 Reports</h4>
          <p className="lead mb-2">
            <b>Search by:</b>
          </p>

          <form className="cms-contact-form" onSubmit={e => e.preventDefault()}>
            <div className="row">
              <div className="col-12 mb-2">
                <div className="d-flex">
                  {radios.map(radio => {
                    return (
                      <div key={radio.value}>
                        <input
                          type="radio"
                          disabled={radio?.value === "phone" ? true : false}
                          value={radio.value}
                          checked={radio.value === filter}
                          onChange={e => {
                            setFilter(e.target.value)
                            setQuery("")
                          }}
                        />
                        <span className="ml-1 mr-4 text-dark">{radio.label}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-9 mb-2">
                {filter === "phone" ? (
                  <PhoneInput
                    onlyCountries={phoneNoCountries}
                    country={"pk"}
                    value={query}
                    onChange={e => setQuery(e)}
                    onKeyDown={onKeyUp}
                    placeholder="92 123-4567890"
                  />
                ) : (
                  <input
                    placeholder="Search reports..."
                    type="text"
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    onKeyUp={onKeyUp}
                  />
                )}
              </div>
              <div className="col-12 col-md-3 mb-2">
                <button
                  type="button"
                  className="btn btn-primary h-100 w-100"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={handleSearch}
                >
                  <i className="fas fa-search mr-2"></i>
                  Search
                  {loadingPatient && <Spinner color="default" className="ml-2" size="sm" />}
                </button>
              </div>
            </div>
          </form>

          {!loadingPatient && patient && (
            <>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="card p-4">
                    <h5>
                      <i className="fas fa-address-book mr-2"></i>
                      Patient Details
                    </h5>

                    {patient.length <= 0 && (
                      <Alert color="warning">No patient found corresponding to the search.</Alert>
                    )}

                    {patient.length >= 1 && (
                      <>
                        <div className="table-responsive table-hover">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col" className="text-center">
                                  Download Report
                                </th>
                                <th scope="col" className="text-center">
                                  View Report
                                </th>
                                <th scope="col" className="text-center">
                                  Result
                                </th>
                                <th scope="col">MR #</th>
                                <th scope="col">Name</th>
                                <th scope="col">Passport</th>
                                <th scope="col">Booking Ref/PNR</th>
                                <th scope="col">Registration Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {patient?.map(registration => {
                                return (
                                  <tr key={`patient-${registration?.registrationId}`}>
                                    <td className="text-center">
                                      <Link
                                        className="btn btn-primary"
                                        style={{ cursor: "pointer" }}
                                        target="_blank"
                                        to={`${AllRoutesMap.report}?patientId=${registration?.patient?.patientId}&registrationId=${registration?.registrationId}`}
                                      >
                                        <i className="fas fa-download"></i>
                                      </Link>
                                    </td>
                                    <td className="text-center">
                                      {registration?.reportVerifiedAt ? (
                                        <Link
                                          className="text-link"
                                          to="#"
                                          onClick={() => {
                                            handleSearchDetails({
                                              registrationId: registration?.registrationId,
                                            })
                                          }}
                                        >
                                          <i className="fas fa-eye"></i>
                                        </Link>
                                      ) : (
                                        <span>Pending</span>
                                      )}
                                    </td>
                                    <td className={`text-center ${getResult(registration)?.color}`}>
                                      {getResult(registration)?.status}
                                    </td>
                                    <td>{registration?.registrationId}</td>
                                    <td>{registration?.patient?.fullname}</td>
                                    <td>{getPassport(registration) || "-"}</td>
                                    <td>{registration?.pnr || "-"}</td>
                                    <td>{dayjs(registration?.createdAt).format("DD/MM/YYYY hh:mm A")}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {selectedRegistration && (
          <ViewReportModal
            openModal={openViewReportModal}
            toggle={toggleViewReportModal}
            registrationId={selectedRegistration.registrationId}
          />
        )}
      </div>
    </div>
  )
}
