// --- Authentication --- //
export const AUTH = "/auth"

// --- Patients --- //
export const PATIENTS = "/patients"
export const PATIENTS_FILTERS = `${PATIENTS}/filters`
export const PATIENT_HISTORY = "/patient/history"

// --- Search --- //
export const SEARCH = "/search"

// --- Registrations --- //
export const REGISTRATIONS = "/registrations"

// --- Stats --- //
export const STATS = "/stats"

// --- Data --- //
export const DATA = "/data"

// --- Users --- //
export const USERS = "/users"

// --- Airports --- //
export const AIRPORTS = "/airports"
