import React from "react"
import { Route, Switch } from "react-router-dom"

import PrivateRoute from "./PrivateRoute"
import NotFound from "../pages/NotFound"
import Registration from "../pages/Registration"
import RegistrationTemplate from "../pages/RegistrationTemplate"
import RegistrationSuccess from "../pages/RegistrationSuccess"
import Staff from "../pages/Staff"
import Admin from "../pages/Admin"
import Emirates from "../pages/Emirates"
import Chinese from "../pages/Chinese"
import Turkish from "../pages/Turkish"
import PIA from "../pages/PIA"
import QatarAirways from "../pages/QatarAirways"
import TGDC from "../pages/TGDC"
import TGDCSection1 from "../pages/TGDCSection1"
import TGDCSection2 from "../pages/TGDCSection2"
import TGDCSection3 from "../pages/TGDCSection3"
import TGDCSection4 from "../pages/TGDCSection4"
import TGDCSection5 from "../pages/TGDCSection5"
import SaudiAirways from "../pages/SaudiAirways"
import AirBlue from "../pages/AirBlue"
import EtihadAirways from "../pages/EtihadAirways"
import AirArabia from "../pages/AirArabia"
import FlyDubai from "../pages/FlyDubai"
import Oman from "../pages/Oman"
import HSR from "../pages/HSR"
import ChashmaHydelPowerPlant from "../pages/ChashmaHydelPowerPlant"
import CCDC from "../pages/CCDC"
import DeliJwGlasswareCompany from "../pages/DeliJwGlasswareCompany"
import CDCCAdilOilField from "../pages/CDCCAdilOilField"
import MgMotors from "../pages/MgMotors"
import CGGC from "../pages/CGGC"
import ZTE from "../pages/ZTE"
import Huawei from "../pages/Huawei"
import KPCL from "../pages/KPCL"
import SMEC from "../pages/SMEC"
import SMC from "../pages/SMC"
import MegaProLynks from "../pages/MegaProLykns"
import XinyuanInternationalEngineering from "../pages/XinyuanInternationalEngineering"
import NationalCenterForPhysics from "../pages/NationalCenterForPhysics"
import SereneAir from "../pages/SereneAir"
import ResetPassword from "../pages/ResetPassword"
import Lab from "../pages/Lab"
import BGP from "../pages/BGP"
import SahiwalCoalPower from "../pages/SahiwalCoalPower"
import ChinaFWO from "../pages/ChinaFWO"
import ChinaCov from "../pages/ChinaCov"
import GMAndSONS from "../pages/GMAndSONS"
import HydroChinaInternationalEngineering from "../pages/HydroChinaInternationalEngineering"
import MRBarcode from "../pages/MRBarcode"
import ReportTemplate from "../pages/ReportTemplate"
import Invoice from "../pages/Invoice"
import InvoiceTemplate from "../pages/InvoiceTemplate"
import Consent from "../pages/Consent"
import ConsentGeneralTemplate from "../pages/ConsentGeneralTemplate"
import ConsentPassengerTemplate from "../pages/ConsentPassengerTemplate"
import { AllRoutesMap } from "./RoutesConfig"
import EasyRegistration from "../pages/EasyRegistration"
import ManageUsers from "../pages/ManageUsers"
import Manage from "../pages/Manage"
import ManageAirports from "../pages/ManageAirports"
import PatientRecovery from "../pages/PatientRecovery"
import MultiRegister from "../pages/MultiRegister"
import ManageResults from "../pages/ManageResults"
import ManageRestrictions from "../pages/ManageRestrictions"

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute exact path={AllRoutesMap.easyRegistration} component={EasyRegistration} />
      <PrivateRoute exact path={AllRoutesMap.registration} component={Registration} />
      <PrivateRoute exact path={AllRoutesMap.registrationSuccess} component={RegistrationSuccess} />
      <PrivateRoute exact path={AllRoutesMap.registrationTemplate} component={RegistrationTemplate} />
      <PrivateRoute exact path={AllRoutesMap.staff} component={Staff} />
      <PrivateRoute exact path={AllRoutesMap.admin} component={Admin} />
      <PrivateRoute exact path={AllRoutesMap.manage} component={Manage} />
      <PrivateRoute exact path={AllRoutesMap.manageUsers} component={ManageUsers} />
      <PrivateRoute exact path={AllRoutesMap.manageAirports} component={ManageAirports} />
      <PrivateRoute exact path={AllRoutesMap.emirates} component={Emirates} />
      <PrivateRoute exact path={AllRoutesMap.chinese} component={Chinese} />
      <PrivateRoute exact path={AllRoutesMap.turkish} component={Turkish} />
      <PrivateRoute exact path={AllRoutesMap.pia} component={PIA} />
      <PrivateRoute exact path={AllRoutesMap.qatarAirways} component={QatarAirways} />
      <PrivateRoute exact path={AllRoutesMap.tgdc} component={TGDC} />
      <PrivateRoute exact path={AllRoutesMap.tgdcSec1} component={TGDCSection1} />
      <PrivateRoute exact path={AllRoutesMap.tgdcSec2} component={TGDCSection2} />
      <PrivateRoute exact path={AllRoutesMap.tgdcSec3} component={TGDCSection3} />
      <PrivateRoute exact path={AllRoutesMap.tgdcSec4} component={TGDCSection4} />
      <PrivateRoute exact path={AllRoutesMap.tgdcSec5} component={TGDCSection5} />
      <PrivateRoute exact path={AllRoutesMap.saudiAirways} component={SaudiAirways} />
      <PrivateRoute exact path={AllRoutesMap.airBlue} component={AirBlue} />
      <PrivateRoute exact path={AllRoutesMap.etihadAirways} component={EtihadAirways} />
      <PrivateRoute exact path={AllRoutesMap.airArabia} component={AirArabia} />
      <PrivateRoute exact path={AllRoutesMap.flyDubai} component={FlyDubai} />
      <PrivateRoute exact path={AllRoutesMap.oman} component={Oman} />
      <PrivateRoute exact path={AllRoutesMap.hsr} component={HSR} />
      <PrivateRoute exact path={AllRoutesMap.chashmaHydelPowerPlant} component={ChashmaHydelPowerPlant} />
      <PrivateRoute exact path={AllRoutesMap.ccdc} component={CCDC} />
      <PrivateRoute exact path={AllRoutesMap.deliJwGlasswareCompany} component={DeliJwGlasswareCompany} />
      <PrivateRoute exact path={AllRoutesMap.ccdcAdilOilField} component={CDCCAdilOilField} />
      <PrivateRoute exact path={AllRoutesMap.mgMotors} component={MgMotors} />
      <PrivateRoute exact path={AllRoutesMap.cggc} component={CGGC} />
      <PrivateRoute exact path={AllRoutesMap.zte} component={ZTE} />
      <PrivateRoute exact path={AllRoutesMap.huawei} component={Huawei} />
      <PrivateRoute exact path={AllRoutesMap.kpcl} component={KPCL} />
      <PrivateRoute exact path={AllRoutesMap.smec} component={SMEC} />
      <PrivateRoute exact path={AllRoutesMap.smc} component={SMC} />
      <PrivateRoute exact path={AllRoutesMap.megaProLynks} component={MegaProLynks} />
      <PrivateRoute
        exact
        path={AllRoutesMap.xinyuanInternationalEngineering}
        component={XinyuanInternationalEngineering}
      />
      <PrivateRoute exact path={AllRoutesMap.nationalCenterForPhysics} component={NationalCenterForPhysics} />
      <PrivateRoute exact path={AllRoutesMap.sereneAir} component={SereneAir} />
      <PrivateRoute exact path={AllRoutesMap.bgp} component={BGP} />
      <PrivateRoute exact path={AllRoutesMap.sahiwalCoalPower} component={SahiwalCoalPower} />
      <PrivateRoute exact path={AllRoutesMap.powerChinaFWO} component={ChinaFWO} />
      <PrivateRoute exact path={AllRoutesMap.chinaCov} component={ChinaCov} />
      <PrivateRoute exact path={AllRoutesMap.gmAndSons} component={GMAndSONS} />
      <PrivateRoute
        exact
        path={AllRoutesMap.hydroChinaInternationalEngineering}
        component={HydroChinaInternationalEngineering}
      />
      <PrivateRoute exact path={AllRoutesMap.patientRecovery} component={PatientRecovery} />
      <PrivateRoute exact path={AllRoutesMap.multiRegister} component={MultiRegister} />
      <PrivateRoute exact path={AllRoutesMap.manageResults} component={ManageResults} />
      <PrivateRoute exact path={AllRoutesMap.manageRestrictions} component={ManageRestrictions} />
      <PrivateRoute exact path={AllRoutesMap.resetPassword} component={ResetPassword} />
      <PrivateRoute exact path={AllRoutesMap.lab} component={Lab} />
      <PrivateRoute exact path={AllRoutesMap.barcode} component={MRBarcode} />
      <PrivateRoute exact path={AllRoutesMap.reportTemplate} component={ReportTemplate} />
      <PrivateRoute exact path={AllRoutesMap.invoice} component={Invoice} />
      <PrivateRoute exact path={AllRoutesMap.invoiceTemplate} component={InvoiceTemplate} />
      <PrivateRoute exact path={AllRoutesMap.consent} component={Consent} />
      <PrivateRoute exact path={AllRoutesMap.consentGeneralTemplate} component={ConsentGeneralTemplate} />
      <PrivateRoute exact path={AllRoutesMap.consentPassengerTemplate} component={ConsentPassengerTemplate} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
