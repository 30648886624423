import React from "react"
import { Modal, ModalFooter } from "reactstrap"
import { nanoid } from "nanoid"

import CameraPicture from "../../common/CameraPicture"

export default function PhotoCaptureModal(props) {
  const { openModal, toggle } = props

  const onChange = e => {
    toggle()
    props.onChange(e)
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true} style={{ padding: 0 }}>
      <div className="rear-camera">
        {openModal && (
          <CameraPicture
            path={"attachments"}
            onTakePhoto={onChange}
            pictureId={nanoid()}
            rearCamera={true}
            otherCameraProps={{ isMaxResolution: true, sizeFactor: 0.4 }}
          />
        )}
      </div>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={toggle}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  )
}
