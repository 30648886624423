import { Link, useHistory } from "react-router-dom"
import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import { AllRoutesMap } from "../routes/RoutesConfig"

const Manage = () => {
  const history = useHistory()
  return (
    <>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay" style={{ backgroundColor: "#092031" }}></div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="text-white mb-0">
                    <i className="fas fa-cogs mr-4"></i>Global Clinical Care Manage Portal
                  </h3>
                </div>
                <div className="col-md-4 text-right">
                  <button
                    className="btn btn-secondary"
                    title="Home"
                    onClick={() => {
                      history.push(AllRoutesMap.admin)
                    }}
                  >
                    <i className="fas fa-home mr-2"></i>
                    Home
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-12">
                <div className="card p-4">
                  <div className="row">
                    <div className="col-md-6 text-right">
                      <Link className="btn btn-primary mr-2 w-100" title="Manage Users" to={AllRoutesMap.manageUsers}>
                        <i className="fas fa-users mr-2"></i>
                        Manage Users
                      </Link>
                    </div>
                    <div className="col-md-6 text-right">
                      <Link
                        className="btn btn-primary mr-2 w-100"
                        title="Manage Airports"
                        to={AllRoutesMap.manageAirports}
                      >
                        <i className="fas fa-plane mr-2"></i>
                        Manage Airports
                      </Link>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6 text-right">
                      <Link
                        className="btn btn-primary mr-2 w-100"
                        title="Patient Recovery"
                        to={AllRoutesMap.patientRecovery}
                      >
                        <i className="fas fa-window-restore mr-2"></i>
                        Patient Recovery
                      </Link>
                    </div>
                    <div className="col-md-6 text-right">
                      <Link
                        className="btn btn-primary mr-2 w-100"
                        title="Multi Register"
                        to={AllRoutesMap.multiRegister}
                      >
                        <i className="fas fa-user mr-2"></i>
                        Multi Register
                      </Link>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6 text-right">
                      <Link
                        className="btn btn-primary mr-2 w-100"
                        title="Manage Results"
                        to={AllRoutesMap.manageResults}
                      >
                        <i className="fas fa-sticky-note mr-2"></i>
                        Manage Results
                      </Link>
                    </div>
                    <div className="col-md-6 text-right">
                      <Link
                        className="btn btn-primary mr-2 w-100"
                        title="Manage Restrictions"
                        to={AllRoutesMap.manageRestrictions}
                      >
                        <i className="fas fa-ban mr-2"></i>
                        Manage Restrictions
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Main>
    </>
  )
}

export default Manage
