import queryString from "query-string"
import dayjs from "dayjs"
import { getAirport } from "./airports"
import { getAirline } from "./airlines"
import { rgb } from "pdf-lib"

export const getFromQueryString = key => {
  return queryString.parse(window.location.search)[key]
}

export const amplificationKits = [
  { label: "Daan Gene", value: "daAnGene" },
  { label: "Seegene", value: "seegene" },
  { label: "Bio Perfectus", value: "bioPerfectus" },
]

export const extractionKits = [
  { label: "Maccura", value: "maccura" },
  { label: "Zybio", value: "zybio" },
]

export const getAmplificationKit = value => {
  if (!value) {
    return ""
  }
  return amplificationKits.find(kit => kit?.value === value)?.label || value
}

export const getExtractionKit = value => {
  if (!value) {
    return ""
  }
  return extractionKits.find(kit => kit?.value === value)?.label || value
}

export const getNumberOfPagesForPdfs = patientData => {
  const rowLimitPerPage = 18
  let numberOfPages = 1

  for (let index = 1; index <= patientData.length; index++) {
    if (index % rowLimitPerPage === 0) {
      numberOfPages += 1
    }
  }

  return numberOfPages
}

export const methodTypes = [
  { value: "manual", label: "Manual" },
  { value: "csv", label: "CSV" },
]

export const getMethod = value => {
  if (!value) {
    return ""
  }

  return methodTypes.find(method => method?.value === value)?.label || value
}

export const genderTypes = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
]

export const getGender = value => {
  if (!value) {
    return undefined
  }

  return genderTypes.find(gender => gender?.value === value)?.label || undefined
}

export const capitalize = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getTestResultStatus = (registration, resultKey = "result") => {
  if (!registration) {
    return { status: null, color: { r: 158, g: 158, b: 158 } }
  } else if (registration[resultKey] === "detected" && registration?.reportVerifiedAt) {
    return { status: "Detected", color: { r: 204, g: 12.75, b: 20.4 } }
  } else if (registration[resultKey] === "not-detected" && registration?.reportVerifiedAt) {
    return { status: "Not Detected", color: { r: 0, g: 128, b: 0 } }
  } else if (registration.reportUploadedAt && !registration[resultKey]) {
    return { status: "-", color: { r: 0, g: 0, b: 0 } }
  } else if (registration.sampleTakenAt && !registration?.reportVerifiedAt) {
    return { status: "Pending", color: { r: 255, g: 193, b: 7 } }
  } else {
    return {
      status: "Sample Not Collected",
      color: { r: 158, g: 158, b: 158 },
    }
  }
}

export const getIgmResultRange = (registration, role) => {
  if (!registration) {
    return { status: "N/A" }
  } else if (registration?.antibodyResultRange && registration?.reportVerifiedAt && role !== "chinese") {
    return { status: registration?.antibodyResultRange, color: rgb(0, 0, 0) }
  } else if (registration?.antibodyResultRange && registration?.reportVerifiedAt && role === "chinese") {
    return { status: "N/A", color: rgb(0, 0, 0) }
  } else if (!registration?.antibodyResultRange && registration?.reportVerifiedAt) {
    return { status: "N/A", color: rgb(0, 0, 0) }
  } else {
    const { r, g, b } = normalizeRGB({ r: 255, g: 193, b: 7 })
    return { status: "Pending", color: rgb(r, g, b) }
  }
}

export const getIggResultRange = (registration, role) => {
  if (!registration) {
    return { status: "N/A" }
  } else if (registration?.iggResultRange && registration?.reportVerifiedAt && role !== "chinese") {
    return { status: registration?.iggResultRange, color: rgb(0, 0, 0) }
  } else if (registration?.iggResultRange && registration?.reportVerifiedAt && role === "chinese") {
    return { status: "N/A", color: rgb(0, 0, 0) }
  } else if (!registration?.iggResultRange && registration?.reportVerifiedAt) {
    return { status: "N/A", color: rgb(0, 0, 0) }
  } else {
    const { r, g, b } = normalizeRGB({ r: 255, g: 193, b: 7 })
    return { status: "Pending", color: rgb(r, g, b) }
  }
}

export const getAntibodyResultStatus = registration => {
  if (!registration) {
    return { status: null, color: { r: 158, g: 158, b: 158 } }
  } else if (registration.antibodyResult === "negative") {
    return { status: "Negative", color: { r: 0, g: 0, b: 0 } }
  } else if (registration.antibodyResult === "positive") {
    return { status: "Positive", color: { r: 0, g: 128, b: 0 } }
  } else {
    return { status: "N/A", color: { r: 0, g: 0, b: 0 } }
  }
}

export const normalizeNumber = (value = 0, min = 0, max = 255) => {
  return (value - min) / (max - min)
}

export const normalizeRGB = ({ r, g, b }) => {
  return {
    r: normalizeNumber(r),
    g: normalizeNumber(g),
    b: normalizeNumber(b),
  }
}

export const calculateRisk = (date, time) => {
  if (!date || !time) {
    return null
  }
  const currentDay = dayjs()
  const formattedDate = dayjs(date).format("YYYY/MM/DD")
  const flightDateTime = dayjs(`${formattedDate} ${time}`, "YYYY/MM/DD HH:mm")

  const fourDaysBeforeFlight = dayjs(flightDateTime).add(-4, "day")
  const twoDaysBeforeFlight = dayjs(flightDateTime).add(-2, "day")
  const oneDayBeforeFlight = dayjs(flightDateTime).add(-1, "day")
  const flightDay = dayjs(flightDateTime)

  if (currentDay.isBefore(fourDaysBeforeFlight)) {
    return "low"
  } else if (currentDay.isSameOrAfter(fourDaysBeforeFlight) && currentDay.isBefore(twoDaysBeforeFlight)) {
    return "success"
  } else if (currentDay.isSameOrAfter(twoDaysBeforeFlight) && currentDay.isBefore(oneDayBeforeFlight)) {
    return "warning"
  } else if (currentDay.isSameOrAfter(oneDayBeforeFlight) && currentDay.isBefore(flightDay)) {
    return "danger"
  } else {
    return "high"
  }
}

export const getTestCenter = testCenterCode => {
  const supportEmail = "help@gccdiagnostic.com"
  switch (testCenterCode) {
    case "ISB01":
      return {
        address: "Office 6-7, Khalid Plaza, 38-W, Block A D-Chowk, Jinnah Avenue, G-6/3\nBlue Area, Islamabad",
        phone: "051 2120802",
        email: supportEmail,
      }

    case "ISB02":
      return {
        address:
          "Embassy of the People's Republic of China: \n3rd Ave, Diplomatic Enclave, Islamabad, Islamabad Capital Territory",
        phone: "051 2120802",
        email: supportEmail,
      }

    case "SHWL":
      return {
        address: "Sahiwal Coal Power Plant (Qadir Abad)",
      }
    case "BGP":
      return {
        address: "BGP Company (Fateh Jang Warehouse)",
      }
    case "CHP":
      return {
        address: "Chashma Hydel Power Plant (Mianwali)",
      }
    case "PC":
      return {
        address: "Power China (Tarbela Dam)",
      }
    case "PC-FWO-JV":
      return {
        address: "POWERCHINA-FWO JV",
      }
    case "D-JW-GC":
      return {
        address: "Deli-JW Glassware Company (Faisalabad)",
      }
    case "CDCC":
      return {
        address: "CDCC Company (Adil Oil Field Doltala)",
      }
    case "MG-M":
      return {
        address: "MG Motors (Lahore)",
      }
    case "CGCC":
      return {
        address: "CGCC Company",
      }
    case "ZTE":
      return {
        address: "ZTE Company",
      }
    case "TGDC":
      return {
        address: "TGDC Company",
      }
    default:
      return {
        address: "Office 6-7, Khalid Plaza, 38-W, Block A\nD-Chowk, Jinnah Avenue, G-6/3\nBlue Area, Islamabad",
        phone: "",
        email: supportEmail,
      }
  }
}

/**
 * Obfuscate a plaintext string with a simple rotation algorithm similar to
 * the rot13 cipher.
 * @param  {[type]} key rotation index between 0 and n
 * @param  {Number} n   maximum char that will be affected by the algorithm
 * @return {[type]}     obfuscated string
 */
export const obfuscate = (key, n = 126) => {
  // return String itself if the given parameters are invalid
  if (!(typeof key === "number" && key % 1 === 0) || !(typeof key === "number" && key % 1 === 0)) {
    return this.toString()
  }

  let chars = this.toString().split("")

  for (var i = 0; i < chars.length; i++) {
    const c = chars[i].charCodeAt(0)

    if (c <= n) {
      chars[i] = String.fromCharCode((chars[i].charCodeAt(0) + key) % n)
    }
  }

  return chars.join("")
}

/**
 * De-obfuscate an obfuscated string with the method above.
 * @param  {[type]} key rotation index between 0 and n
 * @param  {Number} n   same number that was used for obfuscation
 * @return {[type]}     plaintext string
 */
export const deobfuscate = (key, n = 126) => {
  // return String itself if the given parameters are invalid
  if (!(typeof key === "number" && key % 1 === 0) || !(typeof key === "number" && key % 1 === 0)) {
    return this.toString()
  }

  return this.toString().obfs(n - key)
}

export const calculateAge = dob => {
  if (!dob) return
  return dayjs().diff(dob, "year")
}

export const embassies = [
  { value: "chinese", label: "China", prefix: "C" },
  { value: "turkish", label: "Turkish", prefix: "T" },
  { value: "qatar", label: "Qatar", prefix: "Q" },
  { value: "uae", label: "United Arab Emirates", prefix: "U" },
]

export const embassyList = ["chinese", "turkish", "qatar", "uae"]

export const getEmbassy = embassyValue => {
  if (!embassyValue) {
    return undefined
  }
  return embassies.find(embassy => embassy.value === embassyValue)?.label || undefined
}

export const paymentTypes = [
  {
    label: "Cash",
    value: "cash",
  },
  {
    label: "Credit",
    value: "credit",
  },
]

export const getPaymentType = value => {
  if (!value) {
    return undefined
  }
  return paymentTypes.find(paymentType => paymentType?.value === value)?.label || undefined
}

export const covidTestCategories = [
  {
    label: "PCR And Antibody",
    value: "pcrAndAntibody",
  },
  {
    label: "Antibody Only",
    value: "antibody",
  },
  {
    label: "PCR Only",
    value: "pcr",
  },
  {
    label: "PCR And IgM Only",
    value: "pcrAndIgM",
  },
  {
    label: "PCR And IgG Only",
    value: "pcrAndIgG",
  },
  {
    label: "IgG Only",
    value: "igg",
  },
  {
    label: "IgM Only",
    value: "igm",
  },
  {
    label: "Rapid PCR Only",
    value: "rapidPcr",
  },
]

export const getCovidTestCategory = value => {
  if (!value) {
    return ""
  }
  return covidTestCategories.find(category => category.value === value)?.label || value
}

export const getTestType = registration => {
  const testType =
    registration?.charges && registration?.iggCharges && registration?.igmCharges
      ? "pcrAndAntibody"
      : registration?.iggCharges && registration?.igmCharges
      ? "antibody"
      : registration?.charges && registration?.iggCharges
      ? "pcrAndIgG"
      : registration?.charges && registration?.igmCharges
      ? "pcrAndIgM"
      : registration?.charges
      ? "pcr"
      : registration?.iggCharges
      ? "igg"
      : registration?.igmCharges
      ? "igm"
      : registration?.rapidPcrCharges
      ? "rapidPcr"
      : ""
  return testType
}

export const getPatientTestCharges = (registration, payment = {}) => {
  //pcr and antibody
  if (registration?.charges && registration?.iggCharges && registration?.igmCharges) {
    return (
      Number(payment?.charges) + Number(payment?.iggCharges) + Number(payment?.igmCharges) ||
      Number(registration?.charges) + Number(registration?.iggCharges) + Number(registration?.igmCharges)
    )

    // igg and igm
  } else if (registration?.iggCharges && registration?.igmCharges) {
    return (
      Number(payment?.iggCharges) + Number(payment?.igmCharges) ||
      Number(registration?.iggCharges) + Number(registration?.igmCharges)
    )

    //pcr and igg
  } else if (registration?.charges && registration?.iggCharges) {
    return (
      Number(payment?.charges) + Number(payment?.iggCharges) ||
      Number(registration?.charges) + Number(registration?.iggCharges)
    )

    //pcr and igm
  } else if (registration?.charges && registration?.igmCharges) {
    return (
      Number(payment?.charges) + Number(payment?.igmCharges) ||
      Number(registration?.charges) + Number(registration?.igmCharges)
    )

    //pcr
  } else if (registration?.charges) {
    return Number(payment?.charges) || Number(registration?.charges)
  }
  //igm
  else if (registration?.igmCharges) {
    return Number(payment?.igmCharges) || Number(registration?.igmCharges)
  }
  //igg
  else if (registration?.iggCharges) {
    return Number(payment?.iggCharges) || Number(registration?.iggCharges)
  } else {
    return 0
  }
}

export const registrationTypes = [
  { value: "general", label: "General" },
  { value: "passenger", label: "Passenger" },
]

export const getRegistrationType = value => {
  if (!value) {
    return undefined
  }
  return registrationTypes.find(registrationType => registrationType?.value === value)?.label || undefined
}

export const panels = [{ label: "China", value: "china" }]

export const getPanel = value => {
  if (!value) {
    return undefined
  }
  return panels?.find(panel => panel.value === value)?.label || undefined
}

export const specimenType = [
  { label: "Nasopharyngeal Swab", value: "Nasopharyngeal Swab" },
  { label: "Oropharyngeal Swab", value: "Oropharyngeal Swab" },
]

export const getSpecimenType = value => {
  if (!value) {
    return undefined
  }
  return specimenType?.find(specimen => specimen.value === value)?.label || undefined
}

export const references = [
  { value: "BGP (Pakistan) International", label: "BGP (Pakistan) International" },
  { value: "CCDC", label: "CCDC" },
  { value: "CCDC Adil Oil Field", label: "CCDC Company (Adil Oil Field Doltala)" },
  { value: "CGGC", label: "CGGC" },
  { value: "Chashma Hydel Power Plant", label: "Chashma Hydel Power Plant (Mianwali)" },
  { value: "chinaCov", label: "China COV" },
  { value: "Deli-JW Glassware Company", label: "Deli-JW Glassware Company (Faisalabad)" },
  { value: "Gerry's Turkish Airlines", label: "Gerry's - Turkish Airlines" },
  { value: "Gerry's Virgin Atlantic", label: "Gerry's - Virgin Atlantic" },
  { value: "gmAndSons", label: "GM & SONS" },
  { value: "Huawei", label: "Huawei" },
  { value: "HSR", label: "HSR" },
  { value: "hydroChinaInternationalEngineering", label: "Hydrochina Inernational Engineering Co.,LTD" },
  { value: "kpcl", label: "Karot Power Company" },
  { value: "MG Motors", label: "MG Motors (Lahore)" },
  { value: "megaProLynks", label: "MegaPro Lynks Company" },
  { value: "nationalCenterForPhysics", label: "National Center For Physics (Pakistan)" },
  { value: "Power China - FWO JV", label: "Power China - FWO JV" },
  { value: "Sahiwal Coal Power Plant", label: "Sahiwal Coal Power Plant" },
  { value: "smc", label: "Xinrong Construction Engineering (SMC-Private) Ltd" },
  { value: "smec", label: "SMEC International Pty. Ltd" },
  { value: "xinyuanInternationalEngineering", label: "Xinyuan International Engineering Company (Pvt) Ltd" },
  { value: "TGDC", label: "Yangtze Three Gorges Technology & Economy Development Co. Ltd. (Pakistan) - Main" },
  { value: "tgdcSec1", label: "Yangtze Three Gorges Technology & Economy Development Co. Ltd. (Pakistan) - Section 1" },
  { value: "tgdcSec2", label: "Yangtze Three Gorges Technology & Economy Development Co. Ltd. (Pakistan) - Section 2" },
  { value: "tgdcSec3", label: "Yangtze Three Gorges Technology & Economy Development Co. Ltd. (Pakistan) - Section 3" },
  { value: "tgdcSec4", label: "Yangtze Three Gorges Technology & Economy Development Co. Ltd. (Pakistan) - Section 4" },
  { value: "tgdcSec5", label: "Yangtze Three Gorges Technology & Economy Development Co. Ltd. (Pakistan) - Section 5" },
  { value: "ZTE", label: "ZTE Company" },
]

export const referencesList = [
  "BGP (Pakistan) International",
  "CCDC",
  "CCDC Adil Oil Field",
  "CGGC",
  "Chashma Hydel Power Plant",
  "chinaCov",
  "Deli-JW Glassware Company",
  "Gerry's Turkish Airlines",
  "Gerry's Virgin Atlantic",
  "gmAndSons",
  "Huawei",
  "HSR",
  "hydroChinaInternationalEngineering",
  "kpcl",
  "MG Motors",
  "megaProLynks",
  "nationalCenterForPhysics",
  "Power China - FWO JV",
  "Sahiwal Coal Power Plant",
  "smc",
  "smec",
  "xinyuanInternationalEngineering",
  "TGDC",
  "tgdcSec1",
  "tgdcSec2",
  "tgdcSec3",
  "tgdcSec4",
  "tgdcSec5",
  "ZTE",
]

export const getReference = referenceValue => {
  if (!referenceValue) {
    return undefined
  }
  return references.find(reference => reference.value === referenceValue)?.label || referenceValue
}

export const getRequestStatus = value => {
  const defaultStatus = { label: "-", type: "" }
  if (!value) {
    return defaultStatus
  }
  const StatusMap = {
    completed: { label: "Done", type: "success" },
    ongoing: { label: "Pending", type: "warning" },
    error: { label: "Error", type: "danger" },
  }
  return StatusMap[value] || defaultStatus
}

export const phoneNoCountries = ["pk", "cn"]

export const getTNoPrefix = embassyValue => {
  if (!embassyValue) return ""
  return embassies.find(embassy => embassy.value === embassyValue)?.prefix || ""
}

export const getTNo = registration => {
  if (!registration.tNo) return undefined
  return `${getTNoPrefix(registration.embassy)}${registration.tNo}`
}

export const getTotalCharges = data => {
  const { rapidPcrCharges, charges, igmCharges, iggCharges, travelCharges, discount = 0 } = data
  let totalCharges = 0
  if (rapidPcrCharges) {
    totalCharges += Number(rapidPcrCharges)
  } else {
    if (charges) {
      totalCharges += Number(charges)
    }
    if (igmCharges) {
      totalCharges += Number(igmCharges)
    }
    if (iggCharges) {
      totalCharges += Number(iggCharges)
    }
  }

  if (travelCharges) {
    totalCharges += Number(travelCharges)
  }

  totalCharges -= discount ? Number(discount) : 0

  return totalCharges
}

export const getFormattedCnic = value => {
  if (!value) return ""
  return value.slice(0, 5) + "-" + value.slice(5, 12) + "-" + value.slice(12)
}

export const getCovidResult = data => {
  if (data.result === "not-detected") {
    return "NEGATIVE"
  } else if (!data.result || data.result === "undefined") {
    return "-"
  } else {
    return "POSITIVE"
  }
}

export const getPassport = registration => {
  return registration?.patient?.passport || registration?.passport
}

export const getSelectedRegistrationIds = selectedReportIds => {
  return selectedReportIds?.map(({ registrationId }) => registrationId)
}

export const getFormattedCSVData = data => {
  return data.map((registration, idx) => {
    return {
      sNo: idx + 1,
      mrNo: registration.registrationId || "",
      tNo: getTNo(registration) || "-",
      labNo: registration.labId || "-",
      testType: getTestType(registration) || "-",
      embassy: getEmbassy(registration.embassy) || "-",
      registrationType: registration.embassy ? "Embassy" : capitalize(registration.registrationType) || "-",
      patientName: registration.patient?.fullname || "-",
      cnic: getFormattedCnic(registration.patient?.cnic) || "-",
      passport: getPassport(registration) || "-",
      phone: registration.patient?.phone || "-",
      airline: getAirline(registration.airline) || "-",
      flightNo: registration.flightNo || "-",
      departureAirport: registration.departureAirport || "-",
      destinationAirport: getAirport(registration.destinationAirport) || "-",
      travelCharges: registration?.travelCharges || "-",
      netBill: getTotalCharges(registration) || "-",
      result: registration.reportVerifiedAt
        ? registration.result === "detected"
          ? "Detected"
          : "Not Detected"
        : "Pending",
      antibodyResultRange: registration.antibodyResultRange || "-",
      iggResultRange: registration.iggResultRange || "-",
      sampleTakenAt: registration.sampleTakenAt ? dayjs(registration.sampleTakenAt).format("YYYY/MM/DD HH:mm") : "-",
      reportVerifiedAt: registration.reportVerifiedAt
        ? dayjs(registration.reportVerifiedAt).format("YYYY/MM/DD HH:mm")
        : "-",
      pnr: registration.pnr || "-",
    }
  })
}
