import React from "react"
import { Link } from "react-router-dom"
import { AllRoutesMap } from "../../../routes/RoutesConfig"

export default function Footer() {
  return (
    <footer className="text-center py-4 mt-auto bg-theme-secondary text-white">
      <div className="inner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-6 text-left">
              <ul className="mb-0">
                <li className="inline-block mr-5">
                  <Link to={AllRoutesMap.bookingInformation} className="ml-2">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6 text-right">
              <p className="mb-0">
                © 2020 - All rights reserved <a href="/">Global Clinical Care Diagnostic centre</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
