import { PDFDocument, StandardFonts, rgb, grayscale } from "pdf-lib"
import {
  getFormattedCnic,
  getPassport,
  getPatientTestCharges,
  getReference,
  getTestType,
  getCovidTestCategory,
} from "../../utils/common"
import { openPDFNewTab, getNumberOfPagesForPdfs, NumInWords, createTable } from "../../utils/pdfs"
import { getReportStats } from "../../services/api"
import dayjs from "dayjs"

export default async function createQuickInvoicePDF(result) {
  const { registrations, date, payment, isDownload } = result

  const numberOfPages = getNumberOfPagesForPdfs(registrations)
  const pdfDoc = await PDFDocument.create()

  const pages = Array(numberOfPages).fill(pdfDoc)

  //Embed Helvetica Font
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
  const courierFont = await pdfDoc.embedFont(StandardFonts.Courier)

  //Data Preprocessing
  const preparedData = dataPreparation({ registrations, payment })

  //Get Invoice Number
  const key = `GCC-${dayjs(new Date()).format("DDMMYYYY")}`
  const res = await getReportStats(new Date(), new Date(), key)

  const config = {
    pages,
    helveticaFont,
    helveticaBoldFont,
    courierFont,
    pdfDoc,
    preparedData,
    registrations,
    date,
    payment,
    invoiceCounter: res?.data?.invoiceCounter,
  }

  await renderInvoiceHeader(config)
  await renderInvoiceBody(config)
  renderInvoiceFooter(config)

  const pdfBytes = await pdfDoc.save()

  if (isDownload) {
    const sheetName = `invoice.pdf`
    // Trigger the browser to download the PDF document
    window.download(pdfBytes, sheetName, "application/pdf")
  } else {
    openPDFNewTab(pdfBytes, true)
  }
}

//Data prep
const dataPreparation = params => {
  const { registrations, payment } = params
  const initialObj = { noOfPersons: 0, travelCharges: 0, unitTestPrice: 0, totalAmount: 0 }

  let data = []
  let recs = {}
  let sum = { travelCharges: 0, totalAmount: 0 }

  registrations.forEach(reg => {
    const date = dayjs(reg.createdAt).format("DD-MM-YYYY")

    if (!(date in recs)) {
      recs[date] = {}
    }

    let { category, travelCharges, unitTestPrice } = getParamsFromEachReg(reg, payment)

    if (!(category in recs[date])) {
      recs[date][category] = { ...initialObj }
    }
    if (category in recs[date]) {
      recs[date][category].noOfPersons++
      recs[date][category].travelCharges = recs[date][category].travelCharges + travelCharges
      recs[date][category].unitTestPrice = unitTestPrice
    }
  })

  //Calculating total amount
  Object.keys(recs).forEach(date => {
    Object.keys(recs[date]).forEach(category => {
      recs[date][category].totalAmount = recs[date][category].noOfPersons * recs[date][category].unitTestPrice
      data.push({
        testDate: date,
        testCategory: getCovidTestCategory(category),
        numberOfPersons: recs[date][category].noOfPersons,
        unitTestPrice: recs[date][category].unitTestPrice,
        travelCharges: Number(payment?.travelCharges) || recs[date][category].travelCharges,
        totalAmount: recs[date][category].totalAmount,
      })
    })
  })

  data.map(data => {
    sum.totalAmount += data.totalAmount
    sum.travelCharges += data.travelCharges
    return null
  })

  return { data, sum }
}

const getParamsFromEachReg = (reg, payment) => {
  const category = getTestType(reg)
  const travelCharges = reg?.travelCharges || 0

  const unitTestPrice =
    category === "pcrAndAntibody"
      ? Number(payment?.charges) + Number(payment?.iggCharges) + Number(payment?.igmCharges) ||
        Number(reg?.charges) + Number(reg?.iggCharges) + Number(reg?.igmCharges)
      : category === "antibody"
      ? Number(payment?.iggCharges) + Number(payment?.igmCharges) || Number(reg?.iggCharges) + Number(reg?.igmCharges)
      : category === "pcrAndIgG"
      ? Number(payment?.charges) + Number(payment?.iggCharges) || Number(reg?.charges) + Number(reg?.iggCharges)
      : category === "pcrAndIgM"
      ? Number(payment?.charges) + Number(payment?.igmCharges) || Number(reg?.charges) + Number(reg?.igmCharges)
      : category === "pcr"
      ? Number(payment?.charges) || reg?.charges
      : category === "igg"
      ? Number(payment?.iggCharges) || reg?.iggCharges
      : category === "igm"
      ? Number(payment?.igmCharges) || reg?.igmCharges
      : category === "rapidPcr"
      ? Number(payment?.rapidPcrCharges) || reg?.rapidPcrCharges
      : getPatientTestCharges(reg) || 0

  return { category, travelCharges, unitTestPrice }
}

//Render Invoice Header
const renderInvoiceHeader = async config => {
  const { pages, helveticaBoldFont, helveticaFont, invoiceCounter } = config
  const xPosition = 28
  const yPosition = 750
  const pngImageBytes = await fetch("assets/images/logo-dark.png").then(res => res.arrayBuffer())
  //Embed png image bytes and jpeg image bytes
  const pngImage = await config.pdfDoc.embedPng(pngImageBytes)
  // Get the width/height of the PNG image scaled down to 50% of its original size
  const pngDims = pngImage.scaleToFit(130, 130)

  pages.forEach((page, idx) => {
    page = page.insertPage(idx)
    //Global Clincal Care LOGO
    page.drawImage(pngImage, {
      x: xPosition,
      y: yPosition,
      width: pngDims.width,
      height: pngDims.height,
    })

    //Render NTN No.
    page.drawText("NTN NO.", {
      x: 420,
      y: 810,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText("1005975-0", {
      x: 460,
      y: 810,
      size: 9,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    //Render Invoice No.
    page.drawText("INVOICE NO.", {
      x: 420,
      y: 800,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    //Render Invoice No.
    page.drawText(`GCC${dayjs(new Date()).format("DDMMYYYY")}-${invoiceCounter}`, {
      x: 480,
      y: 800,
      size: 9,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
  })
}

//Render Invoice Body
const renderInvoiceBody = async config => {
  const { pdfDoc, helveticaBoldFont, helveticaFont, registrations, date, preparedData, payment } = config
  const reference = getReference(registrations[0]?.reference)
  const { sum, data } = preparedData

  let activePage = 0 //First page
  let page = pdfDoc.getPage(activePage)

  const xPosition = 37
  let yPosition = 730
  const lineSpacing = 24
  const paddingBottom = 8

  //Render Company Name
  page.drawText("Company Name: ", {
    x: xPosition,
    y: yPosition,
    size: 11,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })
  page.drawText(getReference(reference) || "-", {
    x: xPosition + 90,
    y: yPosition,
    size: 11,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  //Render Invoice Date
  const invoiceDate = `${dayjs(date?.startDate).format("DD MMMM YYYY")} to ${dayjs(date?.endDate).format(
    "DD MMMM YYYY"
  )}`
  page.drawText("Invoice Date: ", {
    x: xPosition,
    y: yPosition - 16,
    size: 11,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })
  page.drawText(invoiceDate || "-", {
    x: xPosition + 72,
    y: yPosition - 16,
    size: 11,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  //Render Invoice Subject
  page.drawText("Subject: ", {
    x: xPosition,
    y: yPosition - 50,
    size: 11,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })
  //Subject Text
  page.drawText(`Test Receipt for Covid-19 Testing`, {
    x: xPosition + 48,
    y: yPosition - 50,
    size: 11,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  yPosition -= 85

  //Render Salutation And Greetings
  page.drawText("Dear Sir,", {
    x: xPosition,
    y: yPosition,
    size: 11,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })

  yPosition -= 20

  page.drawText("Please find the invoice details amounting to rupees ", {
    x: xPosition,
    y: yPosition,
    size: 11,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })
  page.drawText(`${sum?.totalAmount + sum?.travelCharges} PKR. `, {
    x: xPosition + 255,
    y: yPosition,
    size: 11,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })

  yPosition -= 20

  page.drawText(
    `( ${sum?.totalAmount + sum?.travelCharges} = ${NumInWords(sum?.totalAmount + sum?.travelCharges)} Only)`,
    {
      x: xPosition,
      y: yPosition,
      size: 11,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    }
  )

  yPosition -= 52

  //Render Patient Details
  const header = [
    {
      srNo: "Sr No.",
      testDate: "Test Date",
      testCategory: "Test Category",
      numberOfPersons: "No. of Persons",
      unitTestPrice: "Unit Test Price",
      travelCharges: "Travel Charges",
      totalAmount: "Total Amount",
    },
  ]

  //Create table header
  createTable(1, Object.keys(header[header?.length - 1]).length, true, activePage, config)
  //Placing header values on top of table
  header.map(label => {
    //Render Serial No.
    page.drawText(`${label.srNo}`, {
      x: xPosition + xPosition * 0.6,
      y: yPosition + 9,
      size: 10,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${label.testDate}`, {
      x: xPosition + xPosition * 2.4,
      y: yPosition + 9,
      size: 10,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${label.testCategory}`, {
      x: xPosition + xPosition * 4.25,
      y: yPosition + 9,
      size: 10,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${label.numberOfPersons}`, {
      x: xPosition + xPosition * 6.25,
      y: yPosition + 9,
      size: 10,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${label.unitTestPrice}`, {
      x: xPosition + xPosition * 8.3,
      y: yPosition + 9,
      size: 10,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${label.travelCharges}`, {
      x: xPosition + xPosition * 10.33,
      y: yPosition + 9,
      size: 10,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${label.totalAmount}`, {
      x: xPosition + xPosition * 12.43,
      y: yPosition + 9,
      size: 10,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    return null
  })

  yPosition -= lineSpacing

  //Create table body
  createTable(data.length + 2, Object.keys(header[header?.length - 1]).length, false, activePage, config)
  //Placing body values on top of table
  let rowCounter = 0
  data.map((patient, idx) => {
    //if ((rowCounter + 1) % 21 === 0 && activePage < 1) {
    if (yPosition < 97) {
      activePage += 1
      page = pdfDoc.getPage(activePage)
      yPosition = 720
      rowCounter = 0
    }
    //else if ((rowCounter + 1) % 27 === 0 && activePage >= 1) {
    else if (yPosition < 97 && activePage >= 1) {
      activePage += 1
      page = pdfDoc.getPage(activePage)
      yPosition = 720
      rowCounter = 0
    }

    page.drawText(`${idx + 1}`, {
      x: xPosition + xPosition * 0.9,
      y: yPosition + 9,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${patient?.testDate || "-"}`, {
      x: xPosition + xPosition * 2.4,
      y: yPosition + 9,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${patient?.testCategory || "-"}`, {
      x: xPosition + xPosition * 4.15,
      y: yPosition + 9,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${patient?.numberOfPersons || "-"}`, {
      x: xPosition + xPosition * 6.25,
      y: yPosition + 9,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${patient?.unitTestPrice || "-"}`, {
      x: xPosition + xPosition * 8.3,
      y: yPosition + 9,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${patient?.travelCharges || "-"}`, {
      x: xPosition + xPosition * 10.33,
      y: yPosition + 9,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${patient?.totalAmount || "-"}`, {
      x: xPosition + xPosition * 12.43,
      y: yPosition + 9,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    yPosition -= 24

    if (rowCounter + 1 === data.length && yPosition < 100) {
      activePage++
      page = pdfDoc.getPage(activePage)
      yPosition = 720
    }

    rowCounter++
    return null
  })

  // Render Column Lines For Total Travel Charges And Total Amount
  page.drawLine({
    start: { x: xPosition + xPosition * 2.05, y: yPosition },
    end: { x: xPosition + xPosition * 2.05, y: yPosition + lineSpacing },
    thickness: 1,
    borderColor: grayscale(0.5),
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })
  page.drawLine({
    start: { x: xPosition + xPosition * 10.27, y: yPosition },
    end: { x: xPosition + xPosition * 10.27, y: yPosition + lineSpacing },
    thickness: 1,
    borderColor: grayscale(0.5),
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })
  page.drawLine({
    start: { x: xPosition * 13.33, y: yPosition },
    end: { x: xPosition * 13.33, y: yPosition + lineSpacing },
    thickness: 1,
    borderColor: grayscale(0.5),
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })

  //Rendering values
  page.drawText("TOTAL CHARGES", {
    x: xPosition + xPosition * 4.8,
    y: yPosition + paddingBottom,
    size: 10,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })
  page.drawText(`${sum?.travelCharges || "-"}`, {
    x: xPosition + xPosition * 10.33,
    y: yPosition + paddingBottom,
    size: 10,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })
  page.drawText(`${sum?.totalAmount || "-"}`, {
    x: xPosition + xPosition * 12.4,
    y: yPosition + paddingBottom,
    size: 10,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })

  yPosition -= lineSpacing

  //Render Column Lines For Total Travel Charges And Total Amount
  page.drawLine({
    start: { x: xPosition + xPosition * 2.05, y: yPosition },
    end: { x: xPosition + xPosition * 2.05, y: yPosition + lineSpacing },
    thickness: 1,
    borderColor: grayscale(0.5),
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })
  page.drawLine({
    start: { x: xPosition + xPosition * 10.27, y: yPosition },
    end: { x: xPosition + xPosition * 10.27, y: yPosition + lineSpacing },
    thickness: 1,
    borderColor: grayscale(0.5),
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })

  //Rendering values
  page.drawText("NET TOTAL (IN PKR)", {
    x: xPosition + xPosition * 4.6,
    y: yPosition + paddingBottom,
    size: 10,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })
  page.drawText(`${sum?.travelCharges + sum?.totalAmount || "-"}`, {
    x: xPosition + xPosition * 11.6,
    y: yPosition + paddingBottom,
    size: 10,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })

  if (yPosition <= 180) {
    yPosition = 730 - lineSpacing * 5.35
    activePage++
    page = pdfDoc.getPage(activePage)
  } else {
    yPosition -= lineSpacing * 5.35
  }

  const pngImageBytes = await fetch("assets/images/accountants.png").then(res => res.arrayBuffer())
  //Embed png image bytes and jpeg image bytes
  const pngImage = await config.pdfDoc.embedPng(pngImageBytes)
  // Get the width/height of the PNG image scaled down to 50% of its original size
  const pngDims = pngImage.scaleToFit(500, 500)

  page.drawImage(pngImage, {
    x: xPosition,
    y: yPosition,
    width: pngDims.width,
    height: pngDims.height,
  })

  yPosition += lineSpacing * 2.35

  page.drawText(`Date: ${dayjs(new Date()).format("DD-MM-YYYY")}`, {
    x: 445,
    y: yPosition,
    size: 11,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
    opacity: 0.75,
  })

  //Render Invoiced Patient List
  activePage++
  page = pdfDoc.getPage(activePage)
  yPosition = 730

  //Render Heading
  page.drawText("Employee Details", {
    x: xPosition + 200,
    y: yPosition,
    size: 14,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })

  // //Render Heading
  // page.drawText(`(${invoiceDate})`, {
  //   x: xPosition + 130,
  //   y: yPosition - 10,
  //   size: 14,
  //   font: helveticaBoldFont,
  //   color: rgb(0, 0, 0),
  // })

  yPosition -= lineSpacing * 2

  //Create Table
  createTable(1, 0, true, activePage, config, yPosition)
  //Define header
  const patientInvoiceListHeader = [
    {
      srNo: "Sr No.",
      patientName: "Patient Name",
      passport: "Passport",
      testDate: "Test Date",
      cnic: "CNIC",
      testCategory: "Test Category",
      testCharges: "Test Charges",
    },
  ]

  patientInvoiceListHeader.map(label => {
    page.drawText(`${label.srNo}`, {
      x: xPosition + xPosition * 0.2,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${label.patientName}`, {
      x: xPosition + xPosition * 1.3,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${label.passport}`, {
      x: xPosition + xPosition * 5.7,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${label.testDate}`, {
      x: xPosition + xPosition * 7.2,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${label.cnic}`, {
      x: xPosition + xPosition * 8.7,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${label.testCategory}`, {
      x: xPosition + xPosition * 10.65,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${label.testCharges}`, {
      x: xPosition + xPosition * 12.8,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })

    return null
  })

  yPosition -= lineSpacing

  //Render values on top of drawn table
  registrations.map((registration, idx) => {
    if ((idx + 1) % 25 === 0) {
      activePage++
      page = pdfDoc.getPage(activePage)
      yPosition = 720

      //Create Table
      createTable(1, 0, true, activePage, config, yPosition)

      patientInvoiceListHeader.map(label => {
        page.drawText(`${label.srNo || "-"}`, {
          x: xPosition + xPosition * 0.2,
          y: yPosition + paddingBottom,
          size: 8,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        })
        page.drawText(`${label.patientName || "-"}`, {
          x: xPosition + xPosition * 1.3,
          y: yPosition + paddingBottom,
          size: 8,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        })
        page.drawText(`${label.passport || "-"}`, {
          x: xPosition + xPosition * 5.7,
          y: yPosition + paddingBottom,
          size: 8,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        })
        page.drawText(`${label.testDate}`, {
          x: xPosition + xPosition * 7.2,
          y: yPosition + paddingBottom,
          size: 8,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        })
        page.drawText(`${label.cnic || "-"}`, {
          x: xPosition + xPosition * 8.7,
          y: yPosition + paddingBottom,
          size: 8,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        })
        page.drawText(`${label.testCategory || "-"}`, {
          x: xPosition + xPosition * 10.65,
          y: yPosition + paddingBottom,
          size: 8,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        })
        page.drawText(`${label.testCharges || "-"}`, {
          x: xPosition + xPosition * 12.8,
          y: yPosition + paddingBottom,
          size: 8,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        })
        return null
      })

      yPosition -= lineSpacing
    }

    page.drawText(`${idx + 1 || "-"}`, {
      x: xPosition + xPosition * 0.2,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${registration?.patient?.fullname || "-"}`, {
      x: xPosition + xPosition * 1.3,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${getPassport(registration) || "-"}`, {
      x: xPosition + xPosition * 5.7,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${dayjs(registration?.sampleTakenAt).format("DD-MM-YYYY") || "-"}`, {
      x: xPosition + xPosition * 7.2,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${getFormattedCnic(registration?.patient?.cnic) || "-"}`, {
      x: xPosition + xPosition * 8.7,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${getCovidTestCategory(getTestType(registration)) || "-"}`, {
      x: xPosition + xPosition * 10.66,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    page.drawText(`${getPatientTestCharges(registration, payment) || "-"}`, {
      x: xPosition + xPosition * 12.85,
      y: yPosition + paddingBottom,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    if (idx !== registrations.length - 1) {
      page.drawLine({
        start: { x: xPosition, y: yPosition + 2 },
        end: { x: 567, y: yPosition + 2 },
        thickness: 1,
        borderColor: grayscale(0.5),
        color: rgb(0.5, 0.5, 0.5),
        opacity: 0.5,
      })
    }

    yPosition -= lineSpacing
    return null
  })

  //Create Table
  createTable(1, 0, true, activePage, config, yPosition)

  //Render Total Amount
  page.drawText("NET TOTAL (IN PKR)", {
    x: xPosition + xPosition * 4.6,
    y: yPosition + paddingBottom,
    size: 9,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })
  page.drawText(`${sum?.totalAmount + "/-" || "-"}`, {
    x: xPosition + xPosition * 12.85,
    y: yPosition + paddingBottom,
    size: 9,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })
}

//Render Invoice Footer
const renderInvoiceFooter = async config => {
  const { pdfDoc, helveticaBoldFont } = config
  const xPosition = 37
  const yPosition = 650

  const pages = pdfDoc.getPages()

  pages.forEach((page, idx) => {
    //Render Line Separator
    page.drawLine({
      start: { x: xPosition + 80, y: yPosition - 600 },
      end: { x: xPosition + 430, y: yPosition - 600 },
      thickness: 1,
      color: rgb(0.3, 0.3, 0.3),
    })
    //Render Test Center
    page.drawText("Office # 6-7, Khalid Plaza, 38-W, Block A D-Chowk, Jinnah Avenue, G-6/3 Blue Area, Islamabad", {
      x: xPosition + 12,
      y: yPosition - 615,
      size: 11,
      font: helveticaBoldFont,
      color: rgb(0.3, 0.3, 0.3),
    })
    //Render Telephone No.
    page.drawText("Tel: (+92 51) 2120802-3   | ", {
      x: xPosition + 12,
      y: yPosition - 630,
      size: 11,
      font: helveticaBoldFont,
      color: rgb(0.3, 0.3, 0.3),
    })
    //Render Email Info
    page.drawText("Email: info@globalclinicalcare.com   | ", {
      x: xPosition + 152,
      y: yPosition - 630,
      size: 11,
      font: helveticaBoldFont,
      color: rgb(0.3, 0.3, 0.3),
    })
    //Render Website URL
    page.drawText("www.globalclinicalcare.com", {
      x: xPosition + 358,
      y: yPosition - 630,
      size: 11,
      font: helveticaBoldFont,
      color: rgb(0.3, 0.3, 0.3),
    })
  })
}
