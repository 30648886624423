export const handleMultiCheckboxSelection = params => {
  const { name, checked, reportIds } = params

  if (name === "selectAll") {
    const updatedReportIds = reportIds?.map(({ registrationId }) => ({ registrationId, isChecked: checked }))

    return {
      updatedReportIds,
      isAllChecked: checked,
      openMultiSelectionToolbar: checked,
    }
  } else {
    const updatedReportIds = reportIds?.map(registration => {
      return name === registration?.registrationId ? { ...registration, isChecked: checked } : { ...registration }
    })

    const isAllChecked = !updatedReportIds?.some(({ isChecked }) => !isChecked)
    const isAnyChecked = checked || updatedReportIds?.some(({ isChecked }) => isChecked)

    return {
      updatedReportIds,
      isAllChecked: isAllChecked,
      openMultiSelectionToolbar: isAnyChecked,
    }
  }
}
