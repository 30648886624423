import { Alert, Spinner, useToast } from "@chakra-ui/core"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import AddAirportModal from "../components/modals/AddAirportModal"
import ConfirmationModal from "../components/modals/ConfirmationModal"
import { AllRoutesMap } from "../routes/RoutesConfig"
import { deleteAirport } from "../services/api"
import { useAirports } from "../services/swrHooks"

const ManageAirports = () => {
  const history = useHistory()
  const toast = useToast()

  const { airports, isLoading, mutateAirports } = useAirports()

  const [openAddAirportModal, setOpenAddAirportModal] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState({ isOpen: false, id: null, name: "" })

  const toggleAddAirportModal = () => {
    setOpenAddAirportModal(!openAddAirportModal)
  }

  const toggleConfirmationModal = airport => {
    const data = { isOpen: !confirmationModal.isOpen, id: airport?._id || null, name: airport?.label || "" }
    setConfirmationModal(data)
  }

  const updateAirport = (airport, isDeleted = false) => {
    if (!airport) {
      return
    }
    let updatedAirports = airports
    if (!isDeleted) {
      updatedAirports.push(airport)
      updatedAirports.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
    } else {
      updatedAirports = airports.filter(({ _id }) => _id !== airport._id)
    }

    mutateAirports(updatedAirports)
  }

  const setDeleteLoading = id => {
    const tempAirports = [...airports]
    const updatedAirports = tempAirports.map(airport => {
      if (airport._id === id) {
        return { ...airport, isDeleting: true }
      }
      return airport
    })
    mutateAirports(updatedAirports)
  }

  const removeAirport = async ({ id }) => {
    try {
      setDeleteLoading(id)
      const res = await deleteAirport(id)
      if (res?.data?.airport) {
        updateAirport(res.data.airport, true)
      }
      toast({
        title: "Airport deleted successfully.",
        description: "Airport deleted successfully.",
        status: "success",
        duration: 4000,
      })
    } catch (err) {
      const error = err.response?.data?.errors?.message || "There was an error while deleting airport."
      toast({
        title: "Error occurred while deleting airport.",
        description: error,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    }
  }

  return (
    <>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay" style={{ backgroundColor: "#092031" }}></div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="text-white mb-0">
                    <i className="fas fa-plane mr-4"></i>Global Clinical Care Manage Airports Portal
                  </h3>
                </div>
                <div className="col-md-4 text-right">
                  <button
                    className="btn btn-secondary"
                    title="Back"
                    onClick={() => {
                      history.push(AllRoutesMap.manage)
                    }}
                  >
                    <i className="fas fa-arrow-left mr-2"></i>
                    Back
                  </button>
                  <button
                    className="btn btn-primary ml-2"
                    title="Home"
                    onClick={() => {
                      history.push(AllRoutesMap.admin)
                    }}
                  >
                    <i className="fas fa-home mr-2"></i>
                    Home
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-12">
                <div className="card p-4">
                  <div className="row mb-4">
                    <div className="col">
                      <button className="btn btn-primary float-right" title="Home" onClick={toggleAddAirportModal}>
                        <i className="fas fa-plus mr-2"></i>
                        Add Airport
                      </button>
                    </div>
                  </div>
                  {!isLoading && !airports?.length && (
                    <Alert color="warning" className="mb-0">
                      No results found.
                    </Alert>
                  )}

                  {airports?.length > 0 && (
                    <div className="row c-table">
                      <div className="col-12">
                        <div className="table-responsive table-border-top no-vertical-scroll">
                          <table className="table table-hover text-nowrap">
                            <thead className="table-header-bg">
                              <tr>
                                <th scope="col" style={{ width: "5%" }}>
                                  Sr. No
                                </th>
                                <th scope="col" style={{ width: "10%" }}>
                                  Actions
                                </th>
                                <th scope="col">Name</th>
                              </tr>
                            </thead>

                            <tbody>
                              {airports.map((airport, idx) => (
                                <tr key={`AirportsTable-${idx}`}>
                                  <td>{idx + 1}</td>
                                  <td>
                                    <button
                                      className="btn-action bg-danger border-0 mr-2"
                                      onClick={() => {
                                        toggleConfirmationModal(airport)
                                      }}
                                      disabled={airport.isDeleting}
                                      title="Delete Airport"
                                    >
                                      {airport.isDeleting ? (
                                        <Spinner color="#fff" size="sm" />
                                      ) : (
                                        <i className="fas fa-trash" />
                                      )}
                                    </button>
                                  </td>
                                  <td>{airport.label}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-12 text-center">{isLoading && <Spinner color="primary" />}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {openAddAirportModal && (
          <AddAirportModal
            openModal={openAddAirportModal}
            toggle={toggleAddAirportModal}
            updateAirport={updateAirport}
          />
        )}
        {confirmationModal.isOpen && (
          <ConfirmationModal
            openModal={confirmationModal.isOpen}
            toggle={toggleConfirmationModal}
            callback={removeAirport}
            callbackData={confirmationModal}
            headerText="Airport Deletion Confirmation"
            bodyText={`Do you really want to delete <b>${confirmationModal.name}</b>?`}
            headerClass="bg-danger"
            headerTextClass="text-white"
            okayBtnClass="btn-danger"
          />
        )}
      </Main>
    </>
  )
}

export default ManageAirports
