import axios from "axios"

import * as apiURLs from "./apiURLs"
import environment from "../config/environment"
import { overmind } from "../overmind"
import dayjs from "dayjs"

const baseConfig = {
  baseURL: `${environment.API_URL}`,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
}

// Basic configuration for HTTP calls
const api = axios.create(baseConfig)

api.interceptors.request.use(config => {
  if (localStorage.token) {
    config.headers.common["x-auth-token"] = localStorage.token
  }
  return config
})

/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
 logout the user if the token has expired
**/

api.interceptors.response.use(
  res => res,
  err => {
    if (err.message.includes(401)) {
      overmind.actions.auth.logout()
    }
    return Promise.reject(err)
  }
)

// --- Authentication --- //
export async function loginUser(credentials) {
  const { email, password } = credentials
  return api.post(apiURLs.AUTH, { email, password })
}

export async function updateUser({ email, permissions, password }) {
  return api.put(`${apiURLs.AUTH}/update-user`, {
    email,
    permissions,
    password: password,
  })
}

export async function getCurrentUser() {
  return api.get(apiURLs.AUTH)
}

export async function logoutUser() {
  return api.post(`${apiURLs.AUTH}/logout`)
}

// --- Multi Register Requests --- //
export async function getMultiRegisterRequestsWithFilters(params) {
  const { limit, offset, filters } = params
  let queryURL = `${apiURLs.PATIENTS}/multi-register/requests?limit=${limit}&offset=${offset}`
  for (let key in filters) {
    queryURL += `&${key}=${filters[key]}`
  }
  return api.get(queryURL)
}

// --- Multi Result Upload Requests --- //
export async function getMultiResultUploadRequestsWithFilters(params) {
  const { limit, offset, filters } = params
  let queryURL = `${apiURLs.REGISTRATIONS}/multi-result-upload/requests?limit=${limit}&offset=${offset}`
  for (let key in filters) {
    queryURL += `&${key}=${filters[key]}`
  }
  return api.get(queryURL)
}

// --- Patients --- //
export async function getPatients(params) {
  const { limit, offset, filters } = params
  let queryURL = `${apiURLs.PATIENTS}?limit=${limit}&offset=${offset}`
  for (let key in filters) {
    queryURL += `&${key}=${filters[key]}`
  }
  return api.get(queryURL)
}

export async function getPatientsWithFilters(params) {
  const { limit, offset, filters, isRecover } = params
  const recovery = isRecover ? "&isRecover=true" : ""
  let queryURL = `${apiURLs.PATIENTS_FILTERS}?limit=${limit}&offset=${offset}${recovery}`
  for (let key in filters) {
    queryURL += `&${key}=${filters[key]}`
  }
  return api.get(queryURL)
}

export async function createPatientAndRegistration(data) {
  return api.post(`${apiURLs.PATIENTS}`, data)
}

export async function editPatientAndRegistration(registrationId, data) {
  return api.put(`${apiURLs.PATIENTS}/${registrationId}`, data)
}

export async function updateSampleDateAndTime(registrationId, data) {
  return api.put(`${apiURLs.PATIENTS}/${registrationId}/sample-time`, data)
}

export async function updateMultipleSampleDateAndTime(data) {
  return api.post(`${apiURLs.PATIENTS}/multiple-sample-time`, data)
}

export async function generateMultipeRegistrationsManually(params) {
  const { data } = params

  return api.post(`${apiURLs.PATIENTS}/multi-register`, data)
}

export async function generateMultipeRegistrationsViaCSV(params) {
  const { data } = params
  return api.post(`${apiURLs.PATIENTS}/multi-register/csv`, data)
}

// --- Search --- //
export async function searchPatient(params) {
  const { filter, query } = params
  return api.get(`${apiURLs.SEARCH}?filter=${filter}&query=${query}`)
}

export async function restrictSearch(data) {
  return api.post(`${apiURLs.SEARCH}/restriction`, data)
}

export async function getRestrictedSearchDetails() {
  return api.get(`${apiURLs.SEARCH}/restriction`)
}

// --- Registrations --- //
export async function getRegistrationDetails(registrationId) {
  return api.get(`${apiURLs.REGISTRATIONS}/${registrationId}`)
}

export async function getRegistrationDetailsByUniqueIdentifier(data) {
  return api.get(`${apiURLs.REGISTRATIONS}/data/autofill`, {
    params: data,
  })
}

export async function uploadMultipeResultsViaCSV(params) {
  const { data } = params
  return api.put(`${apiURLs.REGISTRATIONS}/result/csv`, data)
}

// --- PATIENT HISTORY --- //
export async function getRegistrationDetailsByPassportAndCnic(data) {
  return api.get(`${apiURLs.PATIENT_HISTORY}/`, {
    params: data,
  })
}

export async function getRegistrationDetailsByLabId(labId, { createdAt }) {
  return api.get(`${apiURLs.REGISTRATIONS}/lab/${labId}?createdAt=${createdAt}`)
}

export async function receiveSample(registrationId) {
  return api.put(`${apiURLs.REGISTRATIONS}/${registrationId}/sample-received`)
}

export async function uploadReportResult(registrationId, data) {
  return api.put(`${apiURLs.REGISTRATIONS}/${registrationId}/result`, data)
}

export async function getRegistrationDetailsByRegistrationIds(data) {
  return api.post(`${apiURLs.REGISTRATIONS}/download-multiple-reports`, data)
}

export async function verifyReport(registrationId, data) {
  return api.put(`${apiURLs.REGISTRATIONS}/${registrationId}/verify`, data)
}

export async function verifyMultipleReport(data) {
  return api.put(`${apiURLs.REGISTRATIONS}/multi-verify`, data)
}

// --- Hard Delete @desc User deleted permenantly --- //
export async function deletePatient(id) {
  return api.delete(`${apiURLs.REGISTRATIONS}/${id}`)
}

export async function multipleHardDelete(data) {
  return api.post(`${apiURLs.REGISTRATIONS}/multiple-hard-delete`, data)
}

// --- Soft Delete @desc User not deleted permenantly --- //
export async function softDeletePatient(id) {
  return api.put(`${apiURLs.REGISTRATIONS}/${id}/soft-delete`)
}

// --- Recover Patient --- //
export async function recoverPatient(id) {
  return api.put(`${apiURLs.REGISTRATIONS}/${id}/recover-patient`)
}

export async function recoverMultiplePatient(data) {
  return api.put(`${apiURLs.REGISTRATIONS}/recover-multiple-patient`, data)
}

export async function multipleSoftDelete(data) {
  return api.put(`${apiURLs.REGISTRATIONS}/multiple-soft-delete`, data)
}

export async function sendMultipleReport(data) {
  return api.post(`${apiURLs.REGISTRATIONS}/send-multiple-reports`, data)
}

export async function sendReport(registrationId) {
  return api.post(`${apiURLs.REGISTRATIONS}/${registrationId}/send-report`)
}

export async function downloadReport(registrationId) {
  if (!localStorage.token) {
    return api.put(`${apiURLs.REGISTRATIONS}/${registrationId}/download-report`)
  }
}

// --- Airports --- //
export async function addAirport(data) {
  return api.post(apiURLs.AIRPORTS, data)
}
export async function deleteAirport(id) {
  return api.delete(`${apiURLs.AIRPORTS}/${id}`)
}

// --- Report Stats --- //
export async function getReportStats(startDate, endDate, key = `GCC-${dayjs(new Date()).format("DDMMYYYY")}`) {
  return api.get(`${apiURLs.STATS}?startDate=${startDate}&endDate=${endDate}&key=${key}`)
}

// --- CSV Data --- //
export async function getCSVData(data) {
  const { startDate, endDate, client, testType } = data
  return api.get(`${apiURLs.DATA}?startAt=${startDate}&endAt=${endDate}&client=${client}&testType=${testType}`)
}

// --- Users --- //
export async function getUsers() {
  return api.get(apiURLs.USERS)
}
export async function addUser(data) {
  return api.post(apiURLs.USERS, data)
}
export async function deleteUser(id) {
  return api.delete(`${apiURLs.USERS}/${id}`)
}

export default api
