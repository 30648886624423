import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useToast } from "@chakra-ui/core"
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap"

import ViewReportLayout from "../report/view-report-layout"
import { getRegistrationDetails } from "../../services/api"
import { AllRoutesMap } from "../../routes/RoutesConfig"
import { useState as useOvermindState } from "../../overmind"

export default function ReportModal(props) {
  const { openModal, toggle, registrationId } = props
  const { user } = useOvermindState().auth
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const toast = useToast()

  useEffect(() => {
    if (openModal) {
      fetchPatientDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  const fetchPatientDetails = async () => {
    try {
      setLoading(true)
      const res = await getRegistrationDetails(registrationId)
      const result = res.data

      setData(result)
    } catch (error) {
      toast({
        title: "There was an error generating report.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const queryParam = user.role === "chinese" ? `&embassy=${user.role}` : ""

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true} size="xl">
      <ModalHeader toggle={toggle}>
        {loading ? (
          <span>Loading...</span>
        ) : data?.reportUploadedAt ? (
          <>
            <Link
              className="btn btn-primary"
              style={{ cursor: "pointer" }}
              target="_blank"
              to={`${AllRoutesMap.report}?patientId=${data.patient?.patientId}&registrationId=${data?.registrationId}${queryParam}&download=true`}
            >
              <i className="fas fa-download space-right"></i>
              Download Report
            </Link>
            <Link
              className="btn btn-secondary ml-2"
              style={{ cursor: "pointer" }}
              target="_blank"
              to={`${AllRoutesMap.report}?patientId=${data.patient?.patientId}&registrationId=${data?.registrationId}${queryParam}`}
            >
              <i className="fas fa-print space-right"></i>
              Print
            </Link>
          </>
        ) : (
          <span>Report Not Availavle</span>
        )}
      </ModalHeader>

      <ModalBody>
        {loading && (
          <div className="container py-5">
            <div className="row">
              <div className="col-12 text-center">
                <Spinner color="primary" />
              </div>
            </div>
          </div>
        )}
        {!loading && data && <ViewReportLayout data={data} />}
      </ModalBody>
    </Modal>
  )
}
