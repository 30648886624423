import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

export default function ConfirmationModal(props) {
  const {
    openModal,
    toggle,
    callback,
    callbackData = null,
    headerText = "",
    bodyText = "",
    headerClass = "",
    headerTextClass = "",
    okayBtnClass = "btn-primary",
  } = props

  const onSubmit = () => {
    toggle()
    if (callback) {
      callback(callbackData)
    }
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true} backdrop="static">
      <ModalHeader className={headerClass}>
        <div>
          <h5 className={headerTextClass}>{headerText}</h5>
        </div>
      </ModalHeader>
      <ModalBody>
        <p className="text-dark" dangerouslySetInnerHTML={{ __html: bodyText }} />
        <ModalFooter className="pb-0 px-0">
          <button onClick={toggle} className="btn btn-secondary">
            Cancel
          </button>
          <button onClick={onSubmit} className={`btn ${okayBtnClass}`}>
            Yes
          </button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}
