import { renderHeader } from "../utils/pdfs"

const renderPatientTypeInformation = () => {
  return (
    <table
      style={{
        width: "100%",
        padding: "0.5rem 0",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          {/* <td align="left" width="30%">
            <div className="d-flex align-items-center">
              <h6
                className="mb-0"
                style={{
                  fontWeight: "bold",
                }}
              >
                Type
              </h6>
              <div className="d-flex align-items-center ml-3">
                <img
                  src="assets/images/checkbox.png"
                  style={{
                    maxHeight: "20px",
                    marginRight: "0.5rem",
                  }}
                  alt="Checkbox"
                />
                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  Regular
                </h6>
              </div>
              <div className="d-flex align-items-center ml-5">
                <img
                  src="assets/images/checkbox.png"
                  style={{
                    maxHeight: "20px",
                    marginRight: "0.5rem",
                  }}
                  alt="Checkbox"
                />

                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  Passenger
                </h6>
              </div>
            </div>
          </td> */}
          <td align="left" width="85%">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center ml-3">
                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  <span> &nbsp;</span>
                </h6>
              </div>
            </div>
          </td>
          <td align="left" width="15%">
            <div className="d-flex align-items-center">
              <h6
                className="mb-0"
                style={{
                  fontWeight: "bold",
                }}
              >
                T#
              </h6>
              <div className="d-flex align-items-center ml-3">
                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  <span> &nbsp;</span>
                </h6>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const passengerInformationRow = (title, text) => {
  return (
    <tr>
      <td style={{ padding: "5px 10px" }}>
        <p
          style={{
            margin: 0,
            color: "black",
            fontWeight: "600",
          }}
        >
          {title}
        </p>
      </td>
      <td style={{ padding: "5px 10px" }} width="75%">
        <p
          style={{
            margin: 0,
            color: "black",
            fontWeight: "400",
          }}
        >
          {text}
        </p>
      </td>
    </tr>
  )
}

const renderPatientInformation = () => {
  return (
    <>
      <h5> Personal Details</h5>
      <table
        style={{
          width: "100%",
          padding: "0.5rem 0",
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          {passengerInformationRow("Test Type", "")}
          {passengerInformationRow("MR#", "")}
          {passengerInformationRow("Name", "")}
          {passengerInformationRow("Date Of Birth", "")}
          {passengerInformationRow("Contact Number", "")}
          {passengerInformationRow("CNIC", "")}
          {passengerInformationRow("Email", "")}
          {passengerInformationRow("Gender", "")}
          {passengerInformationRow("Address", "")}
          {passengerInformationRow("City", "")}
          <tr>
            <td style={{ padding: "5px 10px", verticalAlign: "top" }}>
              <p
                style={{
                  margin: 0,
                  color: "black",
                  fontWeight: "600",
                }}
              >
                Sample Collection Location
              </p>
            </td>
            <td style={{ padding: "5px 10px" }} width="75%">
              <p
                style={{
                  margin: 0,
                  color: "black",
                  fontWeight: "400",
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: 0,
                  color: "black",
                  fontWeight: "400",
                }}
              >
                &nbsp;
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

const renderFlightInformation = () => {
  return (
    <>
      <h5> Flight Details</h5>
      <table
        style={{
          width: "100%",
          padding: "0.5rem 0",
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          {passengerInformationRow("Passport Number", "")}
          {passengerInformationRow("Airline", "")}
          {passengerInformationRow("Flight Number", "")}
          {passengerInformationRow("Booking Ref/PNR", "")}
          {passengerInformationRow("Flight Date", "")}
          {passengerInformationRow("Departure Airport", "")}
          {passengerInformationRow("Arrival Airport", "")}
        </tbody>
      </table>
    </>
  )
}

const RegistrationTemplate = () => {
  return (
    <div
      style={{
        maxWidth: "1000px",
        margin: "auto",
        backgroundColor: "white",
        minHeight: "100vh",
        paddingBottom: "2rem",
      }}
    >
      {renderHeader("Patient Registration Form")}
      {renderPatientTypeInformation()}
      {renderPatientInformation()}
      {renderFlightInformation()}

      <button className="no-print text-white" onClick={() => window.print()}>
        PRINT
      </button>
    </div>
  )
}

export default RegistrationTemplate
