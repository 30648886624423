import React from "react"
import ReactDOM from "react-dom"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import { Provider } from "overmind-react"
import "react-phone-input-2/lib/bootstrap.css"

import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { overmind } from "./overmind"
import "./styles/index.scss"

dayjs.extend(customParseFormat)
dayjs.extend(quarterOfYear)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

ReactDOM.render(
  <Provider value={overmind}>
    <App />
  </Provider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
