import React, { useEffect, useState } from "react"
import { Spinner } from "reactstrap"
import { Route, Redirect } from "react-router-dom"
import { useState as useOvermindState, useOvermind } from "../overmind"
import { AllRoutesMap, canRouteOn } from "./RoutesConfig"

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { actions } = useOvermind()
  const [isLoadingUser, setIsLoadingUser] = useState(true)

  const checkForToken = async () => {
    // check for token in LS
    if (localStorage.token) {
      try {
        await actions.auth.loadUser({ token: localStorage.token })
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoadingUser(false)
      }
    } else {
      setIsLoadingUser(false)
    }
  }

  useEffect(() => {
    checkForToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const auth = useOvermindState().auth
  const path = rest?.path
  const role = auth.user?.role
  const canRoute = canRouteOn(role, path)
  return (
    <Route
      {...rest}
      render={props =>
        isLoadingUser || (auth.isLoading && auth.isAuthenticated) === null ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <Spinner color="primary" className="m-2" size="md" />
          </div>
        ) : auth.token && canRoute ? (
          <Component {...props} />
        ) : auth.token ? (
          <Redirect to={AllRoutesMap.notFound} />
        ) : (
          <Redirect to={AllRoutesMap.login} />
        )
      }
    />
  )
}

export default PrivateRoute
