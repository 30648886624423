import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap"
import React, { Fragment, useState } from "react"
import { useForm } from "react-hook-form"
import { references } from "../../utils/common"
import { Checkbox, useToast } from "@chakra-ui/core"
import DatePicker from "../../components/common/DatePicker"
import dayjs from "dayjs"
import { getPatientsWithFilters } from "../../services/api"
import createQuickInvoicePDF from "../invoice/create-quick-invoice"

const getConvertedDateWithStartOfDay = date => {
  if (date) {
    return dayjs(date).startOf("day").toISOString()
  }
  return undefined
}

const getConvertedDateWithEndOfDay = date => {
  if (date) {
    return dayjs(date).endOf("day").toISOString()
  }
  return undefined
}

const getFilterDateRange = dateRangeData => {
  let dateRange = {}
  const startDate = getConvertedDateWithStartOfDay(dateRangeData.startDate)
  const endDate = getConvertedDateWithEndOfDay(dateRangeData.endDate)
  if (startDate) {
    dateRange["startDate"] = startDate
  }
  if (endDate) {
    dateRange["endDate"] = endDate
  }
  return dateRange
}

export default function GenerateInvoiceModal(props) {
  const { openModal, toggle } = props
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [isCharges, setIsCharges] = useState(true)
  const [isIgmCharges, setIsIgmCharges] = useState(true)
  const [isIggCharges, setIsIggCharges] = useState(true)
  const [isTravelCharges, setIsTravelCharges] = useState(true)
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  })
  const [filters] = useState({ ...getFilterDateRange(dateRange) })
  const [updatedFilters, setUpdatedFilters] = useState({})

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    defaultValues: {
      charges: 0,
      igmCharges: 0,
      iggCharges: 0,
      travelCharges: 0,
    },
  })

  const onChangeDateRange = (date, key) => {
    const updatedDateRange = { ...dateRange, [key]: date }
    setDateRange(updatedDateRange)
    const originalFilters = { ...filters }
    delete originalFilters.startDate
    delete originalFilters.endDate

    setUpdatedFilters({
      ...originalFilters,
      ...getFilterDateRange(updatedDateRange),
    })
  }

  const clearIcon = <i className="fas fa-times" />

  const onSubmit = async formData => {
    try {
      setLoading(true)
      const { reference } = formData
      const data = {
        reference,
        isInvoice: true,
        ...updatedFilters,
      }
      const payment = {
        charges: formData?.charges,
        iggCharges: formData?.iggCharges,
        igmCharges: formData?.igmCharges,
        travelCharges: formData?.travelCharges,
      }

      const date = { ...updatedFilters }
      const res = await getPatientsWithFilters({
        limit: 0,
        offset: 0,
        filters: data,
      })
      await createQuickInvoicePDF({ registrations: res?.data?.registrations?.data, payment, date })
      toast({
        title: "Invoice Generated successfully.",
        description: "Invoice Generated successfully.",
        status: "success",
        duration: 5000,
      })
    } catch (error) {
      toast({
        title: "There was an error while generating invoice.",
        description: error?.response?.data?.errors?.message || error?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Modal isOpen={openModal} toggle={toggle} centered={true} size="xl">
        <ModalHeader toggle={toggle}>Generate Portal Invoice</ModalHeader>
        <ModalBody>
          <div className="container">
            <form className="cms-contact-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <h6>Reference</h6>
                  </div>
                  <div>
                    <select name="reference" ref={register}>
                      <option value="">Select Reference</option>
                      {references.map(({ value, label }, idx) => (
                        <option key={`reference-${label}-${idx + 1}`} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="text-bold">Start Date</label>
                    <div>
                      <DatePicker
                        placeholder="Select Date"
                        id="start-date-picker"
                        onChange={date => onChangeDateRange(date, "startDate")}
                        value={dateRange.startDate}
                        className="w-100"
                        clearIcon={clearIcon}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="text-bold">End Date</label>
                    <div>
                      <DatePicker
                        placeholder="Select Date"
                        id="end-date-picker"
                        onChange={date => onChangeDateRange(date, "endDate")}
                        value={dateRange.endDate}
                        className="w-100"
                        clearIcon={clearIcon}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      <Checkbox
                        size="sm"
                        colorScheme="green"
                        className="c-checkbox"
                        isChecked={isCharges}
                        onChange={() => setIsCharges(!isCharges)}
                      >
                        PCR Charges
                      </Checkbox>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Charges"
                      name="charges"
                      ref={register}
                      disabled={!isCharges}
                    />
                    <span className="text-danger">{errors.charges?.message}</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      <Checkbox
                        size="sm"
                        colorScheme="green"
                        className="c-checkbox"
                        isChecked={isIgmCharges}
                        onChange={() => setIsIgmCharges(!isIgmCharges)}
                      >
                        IgM Charges
                      </Checkbox>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Charges"
                      name="igmCharges"
                      ref={register}
                      disabled={!isIgmCharges}
                    />
                    <span className="text-danger">{errors.igmCharges?.message}</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      <Checkbox
                        size="sm"
                        colorScheme="green"
                        className="c-checkbox"
                        isChecked={isIggCharges}
                        onChange={() => setIsIggCharges(!isIggCharges)}
                      >
                        IgG Charges
                      </Checkbox>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Charges"
                      name="iggCharges"
                      ref={register}
                      disabled={!isIggCharges}
                    />
                    <span className="text-danger">{errors.iggCharges?.message}</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      <Checkbox
                        size="sm"
                        colorScheme="green"
                        className="c-checkbox"
                        isChecked={isTravelCharges}
                        onChange={() => setIsTravelCharges(!isTravelCharges)}
                      >
                        Travelling Charges
                      </Checkbox>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Travelling Charges"
                      name="travelCharges"
                      ref={register}
                      disabled={!isTravelCharges}
                    />
                    <span className="text-danger">{errors.travelCharges?.message}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-right">
                  <button className="btn btn-primary" title="Generate" disabled={loading}>
                    Generate {loading && <Spinner color="default" className="ml-2" size="sm" />}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}
