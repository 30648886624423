import useSWR from "swr"
import * as apiURLs from "../apiURLs"

const useAirports = () => {
  const url = apiURLs.AIRPORTS

  const { data, error, revalidate, isValidating, mutate } = useSWR(url)

  return {
    airports: data || [],
    isLoading: !error && !data,
    error,
    getAirports: revalidate,
    isValidating,
    mutateAirports: mutate,
  }
}

export default useAirports
