import BarcodeReader from "react-barcode-reader"
import React, { Fragment, useState, useEffect } from "react"
import Pagination from "react-js-pagination"
import { Alert, Spinner, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from "reactstrap"
import { Link } from "react-router-dom"
import dayjs from "dayjs"

import { AllRoutesMap } from "../routes/RoutesConfig"
import { getPatientsWithFilters } from "../services/api"
import { getCovidTestCategory, getPassport, getTestType } from "../utils/common"

import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import Footer from "../components/layouts/footer/Footer"

import DatePicker from "../components/common/DatePicker"

import LabSampleModal from "../components/modals/LabSampleModal"
import LabManualModal from "../components/modals/LabManualModal"
import ViewPatientHistoyModal from "../components/modals/ViewPatientHistoryModal"
import ExportCSVDataModal from "../components/modals/ExportCSVDataModal"
import Badge from "../components/common/Badge"

import { useDebounce } from "../hooks/UseDebounce"

const getConvertedDateWithStartOfDay = date => {
  if (date) {
    return dayjs(date).startOf("day").toISOString()
  }
  return null
}

const getConvertedDateWithEndOfDay = date => {
  if (date) {
    return dayjs(date).endOf("day").toISOString()
  }
  return null
}

const getFilterDateRange = dateRangeData => {
  let dateRange = {}
  const startDate = getConvertedDateWithStartOfDay(dateRangeData.startDate)
  const endDate = getConvertedDateWithEndOfDay(dateRangeData.endDate)
  if (startDate) {
    dateRange["startDate"] = startDate
  }
  if (endDate) {
    dateRange["endDate"] = endDate
  }
  return dateRange
}

const Lab = () => {
  const [openLabReportSampleModal, setOpenLabReportSamplemodal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openScanBarcodeInstructionAlert, setOpenScanBarcodeInstructionAlert] = useState(false)
  const [openManualModal, setOpenManualModal] = useState(false)
  const [openCSVModal, setOpenCSVModal] = useState(false)
  const [openPatientHistoryModal, setOpenPatientHistoryModal] = useState(false)
  const [openResultDropdown, setOpenResultDropdown] = useState(false)
  const [barcodeData, setBarcodeData] = useState(null)
  const [loadintDetails, setLoadingDetails] = useState(false)
  const [registrations, setRegistrations] = useState([])
  const [passport, setPassport] = useState("")
  const [cnic, setCnic] = useState("")
  const [filter] = useState({})
  const [limit] = useState(10)
  const [offset, setOffset] = useState(false)
  const [targetPage, setTargetPage] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  })
  const [filters, setFilters] = useState({ ...getFilterDateRange(dateRange) })
  const debouncedValue = useDebounce(targetPage)
  const count = Math.ceil(registrations.total / limit)

  const toggleReportModal = () => setOpenLabReportSamplemodal(!openLabReportSampleModal)
  const toggleManualModal = () => setOpenManualModal(!openManualModal)
  const toggleOpenCSVModal = () => setOpenCSVModal(!openCSVModal)
  const toggleOpenResultDropdown = () => setOpenResultDropdown(!openResultDropdown)
  const toggleOpenPatientHistoryModal = () => setOpenPatientHistoryModal(!openPatientHistoryModal)

  const handleBarcodeScan = data => {
    setBarcodeData(data)
    setOpenLabReportSamplemodal(true)
  }

  useEffect(() => {
    initSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (debouncedValue && debouncedValue !== activePage && !loading) {
      goToPage(Number(debouncedValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  const initSearch = async (
    updatedOffset = offset,
    pageNo = activePage,
    filters = { ...getFilterDateRange(dateRange) }
  ) => {
    if (updatedOffset >= 0) {
      try {
        setLoading(true)
        const res = await getPatientsWithFilters({
          limit,
          offset: updatedOffset,
          filters: filters || {},
        })

        if (res.data.registrations) {
          setRegistrations(res.data.registrations)
          setOffset(updatedOffset)
          setActivePage(pageNo)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const getResultVerification = registration => {
    const status = registration?.reportVerifiedAt ? "Verified" : "Pending"

    const color = registration?.reportVerifiedAt ? "success" : "warning"
    return { status, color }
  }

  const getResult = registration => {
    const status = registration?.rapidPcrResult
      ? "Uploaded"
      : registration?.result
      ? "Uploaded"
      : registration?.antibodyResultRange
      ? "Uploaded"
      : registration?.antibodyResult
      ? "Uploaded"
      : registration?.iggResultRange
      ? "Uploaded"
      : registration?.iggResult
      ? "Uploaded"
      : "Pending"

    const color = registration?.rapidPcrResult
      ? "success"
      : registration?.result
      ? "success"
      : registration?.antibodyResultRange
      ? "success"
      : registration?.igmResultRange
      ? "success"
      : registration?.iggResultRange
      ? "success"
      : registration?.iggResult
      ? "success"
      : "warning"
    return { status, color }
  }

  const goToPage = pageNo => {
    const updatedOffset = (pageNo - 1) * limit
    const filters = {
      ...getFilterDateRange(dateRange),
      ...filter,
    }
    initSearch(updatedOffset, pageNo, filters)
    setTargetPage(pageNo)
  }

  const onClearDateRange = () => {
    const updatedDateRange = { startDate: null, endDate: null }
    setDateRange(updatedDateRange)
    const filters = {
      ...getFilterDateRange(updatedDateRange),
      ...filter,
    }
    initSearch(0, 1, filters)
  }

  const onChangeDateRange = (date, key) => {
    const updatedDateRange = { ...dateRange, [key]: date }
    setDateRange(updatedDateRange)
    const filters = {
      ...getFilterDateRange(updatedDateRange),
      ...filter,
    }
    setFilters(filters)
    initSearch(0, 1, filters)
  }

  const handleJumpToPage = e => {
    const re = /^[0-9\b]+$/
    const page = e.target.value
    if (page === "" || (re.test(page) && Number(page) <= count)) {
      setTargetPage(page)
    }
  }

  const viewPatientHistory = params => {
    setPassport(params?.passport)
    setCnic(params?.cnic)
    toggleOpenPatientHistoryModal()
  }

  const clearIcon = <i className="fas fa-times" />

  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay"></div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <h2 className="text-white mb-0">
                    <i className="fas fa-user-circle mr-4"></i>
                    Global Clinical Care Lab Portal
                  </h2>
                </div>
                <div className="col-12 col-lg-6 text-left text-lg-right ml-auto">
                  <button className="btn btn-white mr-2" onClick={() => setOpenScanBarcodeInstructionAlert(true)}>
                    <i className="fas fa-barcode mr-2"></i>
                    Scan
                  </button>

                  <button className="btn btn-secondary mr-2" title="Export CSV" onClick={toggleOpenCSVModal}>
                    <i className="fas fa-download mr-2"></i>
                    Export CSV
                  </button>

                  <ButtonDropdown isOpen={openResultDropdown} toggle={toggleOpenResultDropdown}>
                    <DropdownToggle split color="primary" className="btn btn-secondary" />
                    <DropdownMenu right>
                      <DropdownItem id="upload-manually" style={{ whiteSpace: "nowrap" }} onClick={toggleManualModal}>
                        <i className="fas fa-hand-lizard-o mr-2"></i>
                        Manual
                      </DropdownItem>
                      <DropdownItem id="upload-via-csv">
                        <Link to={AllRoutesMap.manageResults} style={{ whiteSpace: "nowrap" }}>
                          <i className="fas fa-sticky-note mr-2"></i>
                          Import CSV
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid pt-5">
            <div className="row">
              <div className="col-12">
                <h4 className="mb-2">Follow instructions below </h4>

                <p className="lead mb-2">
                  Please use barcode scanner to mark received sample or upload results by scanning the barcode again.
                </p>

                <p>
                  <i className="fas fa-5x fa-barcode"></i>
                  <i className="fas fa-5x fa-barcode"></i>
                  <i className="fas fa-5x fa-barcode"></i>
                </p>

                {openScanBarcodeInstructionAlert && <Alert>Please scan the barcode</Alert>}

                <BarcodeReader
                  onError={e => console.error(e)}
                  onScan={value => {
                    handleBarcodeScan({ value })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="card p-4">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group mr-2">
                    <label className="text-bold">Start Date</label>
                    <div className="d-flex">
                      <div className="cms-input date-wrap w-100">
                        <DatePicker
                          placeholder="Select Date"
                          id="start-date-picker"
                          onChange={date => onChangeDateRange(date, "startDate")}
                          value={dateRange.startDate}
                          className="w-100"
                          clearIcon={clearIcon}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="text-bold">End Date</label>
                    <div className="d-flex">
                      <div className="cms-input date-wrap w-100">
                        <DatePicker
                          placeholder="Select Date"
                          id="end-date-picker"
                          onChange={date => onChangeDateRange(date, "endDate")}
                          value={dateRange.endDate}
                          className="w-100"
                          clearIcon={clearIcon}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <button onClick={onClearDateRange} className="btn btn-secondary btn-clear w-100">
                    Clear
                  </button>
                </div>

                <div className="form-group c-spinner text-center ml-2">{loading && <Spinner color="primary" />}</div>
              </div>
              {!loading && registrations?.data?.length === 0 && (
                <Alert color="warning" className="mb-0">
                  No results found.
                </Alert>
              )}
              {registrations?.data?.length > 0 && (
                <div className="row c-table">
                  <div className="col-12">
                    <div className="table-responsive table-hover">
                      <table className="table">
                        <thead className="table-header-bg">
                          <tr>
                            <th scope="col">Sr#</th>
                            <th scope="col">Actions</th>
                            <th scope="col">MR#</th>
                            <th scope="col">T No.</th>
                            <th scope="col">Test Type</th>
                            <th scope="col">Lab Id</th>
                            <th scope="col">Name</th>
                            <th scope="col">Passport#</th>
                            <th scope="col">CNIC</th>
                            <th scope="col">Sample Date/Time</th>
                            <th scope="col" className="text-center">
                              Result Status
                            </th>
                            <th scope="col" className="text-center">
                              Result Verified
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {registrations.data.map((registration, idx) => {
                            return (
                              <tr key={registration.registrationId}>
                                <td>{idx + 1 + offset}</td>
                                <td>
                                  <button
                                    className="btn-action bg-dark border-0 ml-1"
                                    onClick={() => {
                                      const params = {
                                        passport: getPassport(registration),
                                        cnic: registration?.patient?.cnic,
                                      }
                                      viewPatientHistory(params)
                                    }}
                                  >
                                    <i className="fas fa-hourglass-half" title="View Patient's History"></i>
                                  </button>
                                </td>
                                <td>{registration?.registrationId}</td>
                                <td>{registration?.tNo}</td>
                                <td>{getCovidTestCategory(getTestType(registration))}</td>
                                <td>{registration.labId}</td>
                                <td>{registration.patient?.fullname}</td>
                                <td>{getPassport(registration) || "-"}</td>
                                <td>{registration.patient?.cnic || "-"}</td>

                                <td>{dayjs(registration.createdAt).format("DD/MM/YYYY hh:mm A")}</td>
                                <td className="text-center">
                                  <Badge
                                    label={getResult(registration)?.status}
                                    type={getResult(registration)?.color}
                                  />
                                </td>
                                <td className="text-center">
                                  <Badge
                                    label={getResultVerification(registration).status}
                                    type={getResultVerification(registration).color}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={limit}
                          totalItemsCount={registrations.total}
                          pageRangeDisplayed={5}
                          onChange={goToPage}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                      <div className="col-md-4 d-flex">
                        <span className="text-dark pt-2">Jump to Page</span>
                        <input
                          type="number"
                          disabled={loading}
                          value={targetPage}
                          className="jump-to-page ml-2"
                          placeholder="1"
                          onChange={handleJumpToPage}
                        />
                      </div>
                      <div className="col-md-4 pt-2">
                        <span className="pull-right text-dark">
                          Showing:{" "}
                          {`${offset + 1} to ${Math.min(limit + offset, registrations.total)} of ${
                            registrations.total
                          } records`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {openPatientHistoryModal && (
            <ViewPatientHistoyModal
              openModal={openPatientHistoryModal}
              toggle={toggleOpenPatientHistoryModal}
              passport={passport}
              cnic={cnic}
            />
          )}

          {barcodeData && (
            <LabSampleModal
              openModal={openLabReportSampleModal}
              toggle={toggleReportModal}
              data={barcodeData}
              setLoadingDetails={setLoadingDetails}
              updateData={() => initSearch(offset, activePage, filters)}
            />
          )}

          <LabManualModal
            openModal={openManualModal}
            toggle={toggleManualModal}
            onBarcodeScan={handleBarcodeScan}
            loadingDetails={loadintDetails}
          />

          <ExportCSVDataModal openModal={openCSVModal} toggle={toggleOpenCSVModal} isLab />

          {/* To push footer a bit low */}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </section>
      </Main>
      <Footer />
    </Fragment>
  )
}

export default Lab
