import React, { useState, useEffect } from "react"
import dayjs from "dayjs"
import { useToast } from "@chakra-ui/core"
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"

import {
  getRegistrationDetails,
  getRegistrationDetailsByLabId,
  receiveSample,
  uploadReportResult,
} from "../../services/api"
import ConfirmationModal from "./ConfirmationModal"
import { amplificationKits, extractionKits, getTestType, getCovidTestCategory } from "../../utils/common"
import { useState as useOvermindState } from "../../overmind"

export default function LabSampleModal(props) {
  const {
    openModal,
    toggle,
    data: { value: searchId, createdAt },
    setLoadingDetails,
    updateData,
  } = props

  const { user, permissions } = useOvermindState().auth
  const [loading, setLoading] = useState(false)
  const [upLoadingResult, setUpLoadingResult] = useState(false)
  const [patientName, setPatientName] = useState("")
  const [receivedTime, setReceivedTime] = useState("")
  const [registrationTime, setRegistrationTime] = useState("")
  const [rapidPcrResult, setRapidPcrResult] = useState("")
  const [rapidPcrSpecimen, setRapidPcrSpecimen] = useState("")
  const [testResult, setTestResult] = useState("")
  const [antibodyResult, setAntibodyResult] = useState("")
  const [antibodyResultRange, setAntibodyResultRange] = useState("")
  const [iggResult, setIggResult] = useState("")
  const [iggResultRange, setIggResultRange] = useState("")
  const [error, setError] = useState(null)
  const [registration, setRegistration] = useState(null)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [amplificationKit, setAmplificationKit] = useState("")
  const [extractionKit, setExtractionKit] = useState("")
  const [rdRpGene, setRdRpGene] = useState("")
  const [orf1AbGene, setOrf1AbGene] = useState("")
  const [nGene, setNGene] = useState("")
  const [eGene, setEGene] = useState("")
  const [targetGenes, setTargetGenes] = useState(["ORF-1ab", "N-Gene"])
  const [batchNo, setBatchNo] = useState("")
  const [batchDate, setBatchDate] = useState("")
  const [instrument, setInstrument] = useState("")

  const toast = useToast()

  const resetModalState = () => {
    setRegistration(null)
    setPatientName("")
    setReceivedTime("")
    setRegistrationTime("")
    setRapidPcrResult("")
    setRapidPcrSpecimen("")
    setTestResult("")
    setAntibodyResult("")
    setAntibodyResultRange("")
    setIggResult("")
    setIggResultRange("")
    setAmplificationKit("bioPerfectus")
    setExtractionKit("maccura")
    setTargetGenes(["ORF-1ab", "N-Gene"])
    setRdRpGene("")
    setOrf1AbGene("")
    setEGene("")
    setNGene("")
    setBatchDate(`${dayjs().format("DD-MM-YYYY")}`)
    setInstrument("CFX96 Touch Real-Time PCR Detection System - Bio-Rad, USA")
  }

  useEffect(() => {
    if (openModal) {
      loadRegistrationInformation()
      resetModalState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  const toggleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal)
  }

  const onSubmit = e => {
    e.preventDefault()
    toggleConfirmationModal()
  }

  const loadRegistrationInformation = async () => {
    setError(null)
    try {
      setLoading(true)
      setLoadingDetails(true)
      const res = createdAt
        ? await getRegistrationDetailsByLabId(searchId, {
            createdAt: createdAt.toISOString(),
          })
        : await getRegistrationDetails(searchId)
      const result = res.data

      const isResultUploadable =
        user?.role === "admin" || (!result?.reportVerifiedAt && permissions.includes("uploadResult"))
      const isResultEditable =
        user?.role === "admin" || (result?.reportVerifiedAt && permissions.includes("editResult"))

      if (isResultUploadable || isResultEditable) {
        setRegistration(result)
        if (result) {
          if (result.patient?.fullname) {
            setPatientName(result.patient?.fullname)
          }

          if (result.rapidPcrResult) {
            setRapidPcrResult(result.rapidPcrResult)
          }

          if (result.rapidPcrSpecimen) {
            setRapidPcrSpecimen(result.rapidPcrSpecimen)
          }

          if (result.result) {
            setTestResult(result.result)
          }

          if (result.antibodyResult) {
            setAntibodyResult(result.antibodyResult)
          }

          if (result.antibodyResultRange) {
            setAntibodyResultRange(result.antibodyResultRange)
          }

          if (result.iggResult) {
            setIggResult(result.iggResult)
          }

          if (result.iggResultRange) {
            setIggResultRange(result.iggResultRange)
          }

          if (result.createdAt) {
            setRegistrationTime(dayjs(result.createdAt).format("DD/MM/YYYY hh:mm A"))
          }

          if (result.amplificationKit) {
            setAmplificationKit(result.amplificationKit)
          }

          if (result.extractionKit) {
            setExtractionKit(result.extractionKit)
          }

          if (result.targetGenes) {
            setTargetGenes(result.targetGenes)
          }

          if (result.rdRpGene) {
            setRdRpGene(result.rdRpGene)
          }

          if (result.orf1AbGene) {
            setOrf1AbGene(result.orf1AbGene)
          }

          if (result.nGene) {
            setNGene(result.nGene)
          }

          if (result.eGene) {
            setEGene(result.eGene)
          }

          if (result.batchNo) {
            setBatchNo(result.batchNo)
          }

          if (result.batchDate) {
            setBatchDate(result.batchDate)
          }

          if (result.instrument) {
            setInstrument(result.instrument)
          }

          if (result?.sampleReceivedAt) {
            setReceivedTime(dayjs(result.sampleReceivedAt).format("DD/MM/YYYY hh:mm A"))
          } else {
            toggle()
            initSampleReceived(result)
          }
        }
      } else {
        toggle()
        toast({
          title: "Access Denied!",
          description: "User not authorized.",
          status: "warning",
          duration: 5000,
        })
      }
    } catch (error) {
      toggle()
      toast({
        title: "Doesn't Exist",
        description: "Lab serial no. doesn't exist on provided Registration Date",
        status: "error",
        duration: 2500,
      })
      setError("Lab serial no. doesn't exist.")
    } finally {
      setLoading(false)
      setLoadingDetails(false)
    }
  }

  const initSampleReceived = async registrationData => {
    try {
      setLoading(true)
      setLoadingDetails(true)
      const res = await receiveSample(registrationData.registrationId)
      const updatedRegistration = res.data

      if (updatedRegistration) {
        toast({
          title: "Sample Received",
          description: "Sample received has been successfully updated.",
          status: "success",
          duration: 3000,
        })
      }

      if (updatedRegistration?.sampleReceivedAt) {
        setReceivedTime(dayjs(updatedRegistration.sampleReceivedAt).format("DD/MM/YYYY hh:mm A"))
      }

      if (updatedRegistration?.createdAt) {
        setRegistrationTime(dayjs(updatedRegistration.createdAt).format("DD/MM/YYYY hh:mm A"))
      }
    } catch (error) {
      toast({
        title: "There was an error receiving sample.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
      setLoadingDetails(false)
    }
  }

  const handleUploadResult = async () => {
    try {
      setUpLoadingResult(true)

      const res = await uploadReportResult(registration?.registrationId, {
        rapidPcrResult: rapidPcrResult || undefined,
        rapidPcrSpecimen: rapidPcrSpecimen || undefined,
        result: testResult || undefined,
        antibodyResult: antibodyResult || undefined,
        antibodyResultRange: antibodyResultRange?.trim() || undefined,
        iggResult: iggResult || undefined,
        iggResultRange: iggResultRange?.trim() || undefined,
        amplificationKit: amplificationKit || undefined,
        extractionKit: extractionKit || undefined,
        targetGenes: targetGenes || undefined,
        orf1AbGene: orf1AbGene || undefined,
        rdRpGene: rdRpGene || undefined,
        nGene: nGene || undefined,
        eGene: eGene || undefined,
        batchNo: batchNo?.toUpperCase() || undefined,
        batchDate: batchDate || undefined,
        instrument: instrument || undefined,
      })

      const updatedRegistration = res?.data

      if (updatedRegistration) {
        toast({
          title: "Report Uploaded",
          description: "Report has been successfully uploaded.",
          status: "success",
          duration: 3000,
        })
      }

      if (updatedRegistration?.sampleReceivedAt) {
        setReceivedTime(dayjs(updatedRegistration.sampleReceivedAt).format("DD/MM/YYYY hh:mm A"))
      }

      if (updatedRegistration?.createdAt) {
        setRegistrationTime(dayjs(updatedRegistration.createdAt).format("DD/MM/YYYY hh:mm A"))
      }

      updateData()

      toggle()
    } catch (error) {
      console.log(error)
      toast({
        title: "There was an error receiving sample.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setUpLoadingResult(false)
    }
  }

  const handleOnChange = e => {
    const { name } = e.target
    if (name === "batchNumber") {
      setBatchNo(e.target.value)
    }
    if (name === "batchDate") {
      setBatchDate(e.target.value)
    }
    if (name === "instrument") {
      setInstrument(e.target.value)
    }
  }

  return !loading ? (
    <>
      <Modal isOpen={openModal} toggle={toggle} centered={true} size="xl">
        <ModalHeader toggle={toggle}>Lab Result Upload</ModalHeader>
        <ModalBody>
          {error ? (
            <Alert color="warning border-0 mb-0">{error}</Alert>
          ) : (
            <form className="cms-contact-form" onSubmit={onSubmit}>
              <div className="row">
                <div className="col-12">
                  <div>
                    <h4 className="text-bold text-align-center">
                      Test Type: {getCovidTestCategory(getTestType(registration))}
                    </h4>
                  </div>
                  <div>
                    <label className="text-bold">Barcode #: &nbsp;</label>
                    <span className="text-dark font-15">{registration?.labId || ""}</span>
                  </div>

                  <div>
                    <label>Patient Name: &nbsp;</label>
                    <span className="text-dark font-15">{patientName}</span>
                  </div>

                  <div>
                    <label>Registered Date: &nbsp;</label>
                    <span className="text-dark font-15">{registrationTime}</span>
                  </div>

                  <div>
                    <label>Received Date: &nbsp;</label>
                    <span className="text-dark font-15">{receivedTime}</span>
                  </div>

                  <div>
                    <label>T No: &nbsp;</label>
                    <span className="text-dark font-15">{registration?.tNo || ""}</span>
                  </div>

                  <div className="d-flex flex-column mb-3 mt-2">
                    {registration?.rapidPcrCharges && (
                      <>
                        <div className="h-card mb-3">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label>Test</label>
                                <div>
                                  <span className="text-dark font-15">Rapid PCR</span>
                                </div>
                              </div>
                            </div>

                            {receivedTime && (
                              <>
                                <div className="col-lg-4">
                                  <div className="form-group">
                                    <label>Specimen</label>
                                    <select
                                      value={rapidPcrSpecimen}
                                      onChange={e => setRapidPcrSpecimen(e.target.value)}
                                    >
                                      <option value="" selected>
                                        Select Option
                                      </option>
                                      <option value="Nasopharyngeal Swab">Nasopharyngeal Swab</option>
                                      <option value="Oropharyngeal Swab">Oropharyngeal Swab</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-4">
                                  <div className="form-group">
                                    <label>Result</label>
                                    <select value={rapidPcrResult} onChange={e => setRapidPcrResult(e.target.value)}>
                                      <option value="" selected>
                                        Select Option
                                      </option>
                                      <option value="detected">Covid 19 RNA Detected</option>
                                      <option value="not-detected">Covid 19 RNA Not Detected</option>
                                    </select>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {!registration?.rapidPcrCharges && (
                      <>
                        {registration?.charges && (
                          <div>
                            <div className="h-card mb-3">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="form-group">
                                    <label>Test</label>
                                    <div>
                                      <span className="text-dark font-15">Real Time RT-PCR</span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-4">
                                  <div className="form-group">
                                    <label>Specimen</label>
                                    <div>
                                      <span className="text-dark font-15">Nasopharyngeal Swab</span>
                                    </div>
                                  </div>
                                </div>
                                {receivedTime && (
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Result</label>
                                      <select value={testResult} onChange={e => setTestResult(e.target.value)}>
                                        <option value="" selected>
                                          Select Option
                                        </option>
                                        <option value="detected">Covid 19 RNA Detected</option>
                                        <option value="not-detected">Covid 19 RNA Not Detected</option>
                                      </select>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="h-card mb-3">
                              <div className="row">
                                <div className="col-lg-3">
                                  <div className="form-group">
                                    <label>Kit Category</label>
                                    <div>
                                      <span className="text-dark font-15">Amplification Kit</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="form-group">
                                    <label>Testing Kit</label>
                                    <select
                                      value={amplificationKit}
                                      onChange={e => {
                                        setAmplificationKit(e.target.value)
                                        if (e.target.value === "seegene" || e.target.value === "daAngene") {
                                          setTargetGenes(["RdRP-Gene", "N-Gene", "E-Gene"])
                                        } else if (e.target.value === "bioPerfectus") {
                                          setTargetGenes(["ORF-1ab", "N-Gene"])
                                        } else {
                                          setTargetGenes([""])
                                        }
                                      }}
                                    >
                                      <option value="">Select Option</option>
                                      {amplificationKits.map((kit, idx) => {
                                        return (
                                          <option value={kit.value} key={`Amplification-Kit-${kit.label}-${idx}`}>
                                            {kit.label}
                                          </option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                </div>
                                {amplificationKit === "seegene" ? (
                                  <>
                                    <div className="col-lg-2">
                                      <div className="form-group">
                                        <label>RdRP-Gene</label>
                                        <input
                                          type="text"
                                          value={rdRpGene}
                                          onChange={e => setRdRpGene(e.target.value)}
                                          placeholder="Enter value here"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-2">
                                      <div className="form-group">
                                        <label>N-Gene</label>
                                        <input
                                          type="text"
                                          value={nGene}
                                          onChange={e => setNGene(e.target.value)}
                                          placeholder="Enter value here"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-2">
                                      <div className="form-group">
                                        <label>E-Gene</label>
                                        <input
                                          type="text"
                                          value={eGene}
                                          onChange={e => setEGene(e.target.value)}
                                          placeholder="Enter value here"
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : amplificationKit === "daAnGene" ? (
                                  <>
                                    <div className="col-lg-2">
                                      <div className="form-group">
                                        <label>ORF-1ab</label>
                                        <input
                                          type="text"
                                          value={orf1AbGene}
                                          onChange={e => setOrf1AbGene(e.target.value)}
                                          placeholder="Enter value here"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-2">
                                      <div className="form-group">
                                        <label>N-Gene</label>
                                        <input
                                          type="text"
                                          value={nGene}
                                          onChange={e => setNGene(e.target.value)}
                                          placeholder="Enter value here"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-2">
                                      <div className="form-group">
                                        <label>E-Gene</label>
                                        <input
                                          type="text"
                                          value={eGene}
                                          onChange={e => setEGene(e.target.value)}
                                          placeholder="Enter value here"
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : amplificationKit === "bioPerfectus" ? (
                                  <>
                                    <div className="col-lg-3">
                                      <div className="form-group">
                                        <label>ORF-1ab</label>
                                        <input
                                          type="text"
                                          value={orf1AbGene}
                                          onChange={e => setOrf1AbGene(e.target.value)}
                                          placeholder="Enter value here"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="form-group">
                                        <label>N-Gene</label>
                                        <input
                                          type="text"
                                          value={nGene}
                                          onChange={e => setNGene(e.target.value)}
                                          placeholder="Enter value here"
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>

                              <div className="row">
                                <div className="col-lg-3">
                                  <div className="form-group">
                                    <span className="text-dark font-15">Extraction Kit</span>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="form-group">
                                    <select
                                      value={extractionKit}
                                      onChange={e => {
                                        setExtractionKit(e.target.value)
                                      }}
                                    >
                                      <option value="">Select Option</option>
                                      {extractionKits.map((kit, idx) => {
                                        return (
                                          <option value={kit.value} key={`Extraction-Kit-${kit.label}-${idx}`}>
                                            {kit.label}
                                          </option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="form-group"></div>
                                </div>
                              </div>
                            </div>

                            <div className="h-card mb-3">
                              <div className="row">
                                <div className="col-lg-2">
                                  <div className="form-group">
                                    <label>Batch Number</label>
                                    <input
                                      type="text"
                                      name="batchNumber"
                                      className="text-uppercase"
                                      value={batchNo}
                                      onChange={handleOnChange}
                                      placeholder="Batch Number"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="form-group">
                                    <label>Batch Date</label>
                                    <input
                                      type="text"
                                      name="batchDate"
                                      value={batchDate}
                                      onChange={handleOnChange}
                                      placeholder="Batch Date"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label>Instrument</label>
                                    <select value={instrument} onChange={handleOnChange}>
                                      <option value="CFX96 Touch Real-Time PCR Detection System - Bio-Rad, USA">
                                        CFX96 Touch Real-Time PCR Detection System - Bio-Rad, USA
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {registration?.igmCharges && (
                          <div className="h-card mb-3">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>Test</label>
                                  <div>
                                    <span className="text-dark font-15">COVID-19 IgM Antibodies</span>
                                  </div>
                                </div>
                              </div>

                              {receivedTime && (
                                <>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Result</label>
                                      <select value={antibodyResult} onChange={e => setAntibodyResult(e.target.value)}>
                                        <option value="" selected>
                                          Select Option
                                        </option>
                                        <option value="positive">Positive</option>
                                        <option value="negative">Negative</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Range</label>
                                      <input
                                        type="text"
                                        value={antibodyResultRange}
                                        onChange={e => setAntibodyResultRange(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}

                        {registration?.iggCharges && (
                          <div className="h-card mb-3">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>Test</label>
                                  <div>
                                    <span className="text-dark font-15">COVID-19 IgG Antibodies</span>
                                  </div>
                                </div>
                              </div>
                              {receivedTime && (
                                <>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Result</label>
                                      <select value={iggResult} onChange={e => setIggResult(e.target.value)}>
                                        <option value="" selected>
                                          Select Option
                                        </option>
                                        <option value="positive">Positive</option>
                                        <option value="negative">Negative</option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Range</label>
                                      <input
                                        type="text"
                                        value={iggResultRange}
                                        onChange={e => setIggResultRange(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <ModalFooter className="pb-0 px-0">
                <button type="submit" className="btn btn-secondary" disabled={loading || upLoadingResult}>
                  <i className="fas fa-arrow-circle-right space-right"></i>
                  Upload Result
                  {upLoadingResult && <Spinner color="default" className="ml-2" size="sm" />}
                </button>
              </ModalFooter>
            </form>
          )}
        </ModalBody>
      </Modal>
      {openConfirmationModal && (
        <ConfirmationModal
          openModal={openConfirmationModal}
          toggle={toggleConfirmationModal}
          callback={handleUploadResult}
          headerText="Result Upload Confirmation"
          bodyText="Do you really want to upload the result?"
        />
      )}
    </>
  ) : null
}
