import { PDFDocument, StandardFonts } from "pdf-lib"

import { openPDFNewTab, renderBarcode, renderProfileImage } from "../../utils/pdfs"

import * as createInvoice from "../invoice/create-invoice"
import * as createConsent from "../consent/create-passenger-consent"
import * as createRegistration from "../pdf-reports/create-registration"

export default async function createAllFormsGeneralPDF(result) {
  // This should be a Uint8Array or ArrayBuffer
  // This data can be obtained in a number of different ways
  // If your running in a Node environment, you could use fs.readFile()
  // In the browser, you could make a fetch() call and use res.arrayBuffer()
  const url = `assets/pdf-templates/combined-forms/passenger-combined-template.pdf`

  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes)

  // Embed the Helvetica font
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

  // Get the first page of the document
  const pages = pdfDoc.getPages()

  const consentConfig = {
    page: pages[0],
    helveticaFont,
    helveticaBoldFont,
    pdfDoc,
  }
  await createConsentPage(result, consentConfig)

  const invoiceConfig = {
    page: pages[1],
    helveticaFont,
    helveticaBoldFont,
    pdfDoc,
  }
  await createInvoicePage(result, invoiceConfig)

  const registrationConfig = {
    page: pages[2],
    helveticaFont,
    helveticaBoldFont,
    pdfDoc,
  }
  await createRegistrationPage(result, registrationConfig)

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save()

  // Open PDF in new tab
  openPDFNewTab(pdfBytes)
}

const createConsentPage = async (result, config) => {
  await renderProfileImage(result, config)
  await createConsent.renderEmbassyChecks(result, config)
  createConsent.renderPassengerInformation(result, config)
  await renderBarcode(result, config)
}

const createRegistrationPage = async (result, config) => {
  await renderProfileImage(result, config)
  await createRegistration.renderEmbassyChecks(result, config)
  createRegistration.renderPassengerInformation(result, config)
  createRegistration.renderFlightInformation(result, config)
  await renderBarcode(result, config)
}

const createInvoicePage = async (result, config) => {
  await renderProfileImage(result, config)
  await createInvoice.renderEmbassyChecks(result, config)
  createInvoice.renderHeader(result, config)
  createInvoice.renderBillingnformation(result, config)
  createInvoice.renderTestCenterInformation(result, config)
  await renderBarcode(result, config)
}
