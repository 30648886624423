export const AllRoutesMap = {
  landing: "/",
  bookingInformation: "/booking-information",
  login: "/login",
  patient: "/patient",
  report: "/report",
  notFound: "/not-found",

  easyRegistration: "/easy-registration",
  registration: "/registration",
  registrationSuccess: "/registration-success",
  registrationTemplate: "/registration-template",
  admin: "/admin",
  manage: "/manage",
  manageUsers: "/manage-users",
  manageAirports: "/manage-airports",
  staff: "/staff",
  emirates: "/emirates",
  chinese: "/chinese",
  turkish: "/gerrys",
  pia: "/piac",
  qatarAirways: "/qatar-airways",
  tgdc: "/tgdc",
  tgdcSec1: "/tgdc-sec-1",
  tgdcSec2: "/tgdc-sec-2",
  tgdcSec3: "/tgdc-sec-3",
  tgdcSec4: "/tgdc-sec-4",
  tgdcSec5: "/tgdc-sec-5",
  saudiAirways: "/saudi-airways",
  airBlue: "/airblue",
  etihadAirways: "/etihad-airways",
  airArabia: "/air-arabia",
  flyDubai: "/fly-dubai",
  oman: "/oman",
  hsr: "/hsr",
  chashmaHydelPowerPlant: "/chashma-hydel-power-plant",
  ccdc: "/ccdc",
  deliJwGlasswareCompany: "/deli-jw-glassware",
  ccdcAdilOilField: "/ccdc-adil-oil-field",
  mgMotors: "/mg-motors",
  zte: "/zte",
  cggc: "/cggc",
  huawei: "/huawei",
  kpcl: "/kpcl",
  smec: "/smec",
  smc: "/smc",
  megaProLynks: "/mega-pro-lynks",
  xinyuanInternationalEngineering: "/xinyuan-international-engineering",
  nationalCenterForPhysics: "/national-center-for-physics",
  sereneAir: "/sereneair",
  bgp: "/bgp",
  sahiwalCoalPower: "/sahiwal-coal-power-plant",
  powerChinaFWO: "/power-china-fwo-jv",
  chinaCov: "/china-cov",
  gmAndSons: "/gm-and-sons",
  hydroChinaInternationalEngineering: "/hydrochina-international-engineering",
  patientRecovery: "/patient-recovery",
  multiRegister: "/multi-register",
  lab: "/lab",
  manageResults: "/manage-results",
  manageRestrictions: "/manage-restrictions",
  barcode: "/barcode",
  reportTemplate: "/report-template",
  invoice: "/invoice",
  invoiceTemplate: "/invoice-template",
  consent: "/consent",
  consentGeneralTemplate: "/consent-general-template",
  consentPassengerTemplate: "/consent-passenger-template",
  resetPassword: "/reset-password",
}

export const HomeRoutes = {
  admin: AllRoutesMap.admin,
  staff: AllRoutesMap.staff,
  emirates: AllRoutesMap.emirates,
  lab: AllRoutesMap.lab,
  manageResults: AllRoutesMap.manageResults,
  chinese: AllRoutesMap.chinese,
  turkish: AllRoutesMap.turkish,
  pia: AllRoutesMap.pia,
  qatarAirways: AllRoutesMap.qatarAirways,
  tgdc: AllRoutesMap.tgdc,
  tgdcSec1: AllRoutesMap.tgdcSec1,
  tgdcSec2: AllRoutesMap.tgdcSec2,
  tgdcSec3: AllRoutesMap.tgdcSec3,
  tgdcSec4: AllRoutesMap.tgdcSec4,
  tgdcSec5: AllRoutesMap.tgdcSec5,
  saudiAirways: AllRoutesMap.saudiAirways,
  airBlue: AllRoutesMap.airBlue,
  etihadAirways: AllRoutesMap.etihadAirways,
  airArabia: AllRoutesMap.airArabia,
  flyDubai: AllRoutesMap.flyDubai,
  oman: AllRoutesMap.oman,
  hsr: AllRoutesMap.hsr,
  chashmaHydelPowerPlant: AllRoutesMap.chashmaHydelPowerPlant,
  ccdc: AllRoutesMap.ccdc,
  deliJwGlasswareCompany: AllRoutesMap.deliJwGlasswareCompany,
  ccdcAdilOilField: AllRoutesMap.ccdcAdilOilField,
  mgMotors: AllRoutesMap.mgMotors,
  cggc: AllRoutesMap.cggc,
  zte: AllRoutesMap.zte,
  huawei: AllRoutesMap.huawei,
  kpcl: AllRoutesMap.kpcl,
  smec: AllRoutesMap.smec,
  smc: AllRoutesMap.smc,
  megaProLynks: AllRoutesMap.megaProLynks,
  xinyuanInternationalEngineering: AllRoutesMap.xinyuanInternationalEngineering,
  nationalCenterForPhysics: AllRoutesMap.nationalCenterForPhysics,
  bgp: AllRoutesMap.bgp,
  sahiwalCoalPower: AllRoutesMap.sahiwalCoalPower,
  powerChinaFWO: AllRoutesMap.powerChinaFWO,
  chinaCov: AllRoutesMap.chinaCov,
  gmAndSons: AllRoutesMap.gmAndSons,
  hydroChinaInternationalEngineering: AllRoutesMap.hydroChinaInternationalEngineering,
  sereneAir: AllRoutesMap.sereneAir,
}

const AllPrivateRoutes = [
  AllRoutesMap.admin,
  AllRoutesMap.resetPassword,
  AllRoutesMap.manage,
  AllRoutesMap.manageUsers,
  AllRoutesMap.manageAirports,
  AllRoutesMap.manageResults,
  AllRoutesMap.manageRestrictions,
  AllRoutesMap.multiRegister,
  AllRoutesMap.patientRecovery,
  AllRoutesMap.easyRegistration,
  AllRoutesMap.registration,
  AllRoutesMap.registrationSuccess,
  AllRoutesMap.registrationTemplate,
  AllRoutesMap.staff,
  AllRoutesMap.emirates,
  AllRoutesMap.chinese,
  AllRoutesMap.turkish,
  AllRoutesMap.pia,
  AllRoutesMap.qatarAirways,
  AllRoutesMap.tgdc,
  AllRoutesMap.tgdcSec1,
  AllRoutesMap.tgdcSec2,
  AllRoutesMap.tgdcSec3,
  AllRoutesMap.tgdcSec4,
  AllRoutesMap.tgdcSec5,
  AllRoutesMap.saudiAirways,
  AllRoutesMap.airBlue,
  AllRoutesMap.etihadAirways,
  AllRoutesMap.airArabia,
  AllRoutesMap.flyDubai,
  AllRoutesMap.oman,
  AllRoutesMap.hsr,
  AllRoutesMap.chashmaHydelPowerPlant,
  AllRoutesMap.ccdc,
  AllRoutesMap.deliJwGlasswareCompany,
  AllRoutesMap.ccdcAdilOilField,
  AllRoutesMap.mgMotors,
  AllRoutesMap.cggc,
  AllRoutesMap.zte,
  AllRoutesMap.huawei,
  AllRoutesMap.kpcl,
  AllRoutesMap.smec,
  AllRoutesMap.smc,
  AllRoutesMap.megaProLynks,
  AllRoutesMap.xinyuanInternationalEngineering,
  AllRoutesMap.nationalCenterForPhysics,
  AllRoutesMap.sereneAir,
  AllRoutesMap.bgp,
  AllRoutesMap.sahiwalCoalPower,
  AllRoutesMap.powerChinaFWO,
  AllRoutesMap.chinaCov,
  AllRoutesMap.gmAndSons,
  AllRoutesMap.hydroChinaInternationalEngineering,
  AllRoutesMap.lab,
  AllRoutesMap.barcode,
  AllRoutesMap.reportTemplate,
  AllRoutesMap.invoice,
  AllRoutesMap.invoiceTemplate,
  AllRoutesMap.consent,
  AllRoutesMap.consentGeneralTemplate,
  AllRoutesMap.consentPassengerTemplate,
]

const StaffRoutes = [
  AllRoutesMap.staff,
  AllRoutesMap.easyRegistration,
  AllRoutesMap.registration,
  AllRoutesMap.multiRegister,
  AllRoutesMap.manageResults,
  AllRoutesMap.barcode,
  AllRoutesMap.registrationSuccess,
  AllRoutesMap.registrationTemplate,
  AllRoutesMap.reportTemplate,
  AllRoutesMap.invoice,
  AllRoutesMap.invoiceTemplate,
  AllRoutesMap.consent,
  AllRoutesMap.consentGeneralTemplate,
  AllRoutesMap.consentPassengerTemplate,
]

const LabRoutes = [AllRoutesMap.lab, AllRoutesMap.manageResults]

const EmiratesRoutes = [AllRoutesMap.emirates]

const ChineseRoutes = [AllRoutesMap.chinese, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const TurkishRoutes = [AllRoutesMap.turkish, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const PIARoutes = [AllRoutesMap.pia, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const QatarAirwaysRoutes = [AllRoutesMap.qatarAirways, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const TGDCRoutes = [AllRoutesMap.tgdc, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const TGDCSec1Routes = [AllRoutesMap.tgdcSec1, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]
const TGDCSec2Routes = [AllRoutesMap.tgdcSec2, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]
const TGDCSec3Routes = [AllRoutesMap.tgdcSec3, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]
const TGDCSec4Routes = [AllRoutesMap.tgdcSec4, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]
const TGDCSec5Routes = [AllRoutesMap.tgdcSec5, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const SaudiAirwaysRoutes = [AllRoutesMap.saudiAirways, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const AirBlueRoutes = [AllRoutesMap.airBlue, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const EtihadAirwaysRoutes = [AllRoutesMap.etihadAirways, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const AirArabiaRoutes = [AllRoutesMap.airArabia, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const FlyDubaiRoutes = [AllRoutesMap.flyDubai, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const OmanRoutes = [AllRoutesMap.oman, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const HSRRoutes = [AllRoutesMap.hsr, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const ChashmaHydelPowerPlantRoutes = [
  AllRoutesMap.chashmaHydelPowerPlant,
  AllRoutesMap.reportTemplate,
  AllRoutesMap.registrationTemplate,
]

const CCDCRoutes = [AllRoutesMap.ccdc, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const DeliJwGlasswareCompanyRoutes = [
  AllRoutesMap.deliJwGlasswareCompany,
  AllRoutesMap.reportTemplate,
  AllRoutesMap.registrationTemplate,
]

const CCDCAdilOilFieldRoutes = [
  AllRoutesMap.ccdcAdilOilField,
  AllRoutesMap.reportTemplate,
  AllRoutesMap.registrationTemplate,
]

const MgMotorsRoutes = [AllRoutesMap.mgMotors, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const CGGCRoutes = [AllRoutesMap.cggc, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const ZTERoutes = [AllRoutesMap.zte, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const HuaweiRoutes = [AllRoutesMap.huawei, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const KPCLRoutes = [AllRoutesMap.kpcl, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const SMECRoutes = [AllRoutesMap.smec, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const SMCRoutes = [AllRoutesMap.smc, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const MegaProLynksRoutes = [AllRoutesMap.megaProLynks, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const XinyuanInternationalEngineeringRoutes = [
  AllRoutesMap.xinyuanInternationalEngineering,
  AllRoutesMap.reportTemplate,
  AllRoutesMap.registrationTemplate,
]

const NationalCenterForPhysicsRoutes = [
  AllRoutesMap.nationalCenterForPhysics,
  AllRoutesMap.reportTemplate,
  AllRoutesMap.registrationTemplate,
]

const GMAndSONSRoutes = [AllRoutesMap.gmAndSons, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const HydroChinaInternationalEngineeringRoutes = [
  AllRoutesMap.hydroChinaInternationalEngineering,
  AllRoutesMap.reportTemplate,
  AllRoutesMap.registrationTemplate,
]

const SereneAirRoutes = [AllRoutesMap.sereneAir, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const BGPRoutes = [AllRoutesMap.bgp, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const SahiwalCoalPowerRoutes = [
  AllRoutesMap.sahiwalCoalPower,
  AllRoutesMap.reportTemplate,
  AllRoutesMap.registrationTemplate,
]

const PowerChinaFWORoutes = [AllRoutesMap.powerChinaFWO, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

const ChinaCovRoutes = [AllRoutesMap.chinaCov, AllRoutesMap.reportTemplate, AllRoutesMap.registrationTemplate]

export const rolesRoutesMapping = {
  admin: AllPrivateRoutes,
  staff: StaffRoutes,
  lab: LabRoutes,
  emirates: EmiratesRoutes,
  chinese: ChineseRoutes,
  turkish: TurkishRoutes,
  pia: PIARoutes,
  qatarAirways: QatarAirwaysRoutes,
  tgdc: TGDCRoutes,
  tgdcSec1: TGDCSec1Routes,
  tgdcSec2: TGDCSec2Routes,
  tgdcSec3: TGDCSec3Routes,
  tgdcSec4: TGDCSec4Routes,
  tgdcSec5: TGDCSec5Routes,
  saudiAirways: SaudiAirwaysRoutes,
  airBlue: AirBlueRoutes,
  etihadAirways: EtihadAirwaysRoutes,
  airArabia: AirArabiaRoutes,
  flyDubai: FlyDubaiRoutes,
  oman: OmanRoutes,
  hsr: HSRRoutes,
  chashmaHydelPowerPlant: ChashmaHydelPowerPlantRoutes,
  ccdc: CCDCRoutes,
  deliJwGlasswareCompany: DeliJwGlasswareCompanyRoutes,
  ccdcAdilOilField: CCDCAdilOilFieldRoutes,
  mgMotors: MgMotorsRoutes,
  cggc: CGGCRoutes,
  zte: ZTERoutes,
  huawei: HuaweiRoutes,
  kpcl: KPCLRoutes,
  smec: SMECRoutes,
  smc: SMCRoutes,
  megaProLynks: MegaProLynksRoutes,
  xinyuanInternationalEngineering: XinyuanInternationalEngineeringRoutes,
  nationalCenterForPhysics: NationalCenterForPhysicsRoutes,
  sereneAir: SereneAirRoutes,
  bgp: BGPRoutes,
  sahiwalCoalPower: SahiwalCoalPowerRoutes,
  powerChinaFWO: PowerChinaFWORoutes,
  chinaCov: ChinaCovRoutes,
  gmAndSons: GMAndSONSRoutes,
  hydroChinaInternationalEngineering: HydroChinaInternationalEngineeringRoutes,
}

export const getRedirectRoute = role => {
  return HomeRoutes[role] || AllRoutesMap.landing
}

export const canRouteOn = (role, route) => {
  const routes = rolesRoutesMapping[role]
  return routes?.includes(route)
}
