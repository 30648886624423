import React, {
  Fragment,
  useState,
  // useEffect
} from "react"
import { useHistory } from "react-router-dom"
import {
  // Alert,
  // Spinner,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap"
// import Pagination from "react-js-pagination"
// import InputMask from "react-input-mask"
// import dayjs from "dayjs"

import { AllRoutesMap } from "../routes/RoutesConfig"

import {
  MultiPatientRegistrationManuallyModal,
  MultiPatientRegistrationViaCSVModal,
} from "../components/modals/multi-registration"
import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
// import DatePicker from "../components/common/DatePicker"

import { useState as useOvermindState } from "../overmind"
// import { getMultiRegisterRequestsWithFilters } from "../services/api"
// import {
//   getEmbassy,
//   getMethod,
//   getPaymentType,
//   getReference,
//   getRegistrationType,
//   getTestType,
//   getRequestStatus,
//   getCovidTestCategory,
// } from "../utils/common"
// import { useDebounce } from "../hooks/UseDebounce"
// import Badge from "../components/common/Badge"

// const getConvertedDateWithStartOfDay = date => {
//   if (date) {
//     return dayjs(date).startOf("day").toISOString()
//   }
//   return undefined
// }

// const getFilterDateRange = dateRangeData => {
//   let dateRange = {}
//   const startDate = getConvertedDateWithStartOfDay(dateRangeData.startDate)
//   if (startDate) {
//     dateRange["startDate"] = startDate
//   }
//   return dateRange
// }

const MultiRegister = () => {
  const { user } = useOvermindState().auth
  const history = useHistory()
  // const [loading, setLoading] = useState(false)
  // const [query, setQuery] = useState("")
  // const [records, setRecords] = useState([])
  // const [filter, setFilter] = useState("email")
  // const [targetPage, setTargetPage] = useState("")
  // const [limit] = useState(10)
  // const [offset, setOffset] = useState(0)
  // const [dateRange, setDateRange] = useState({
  //   startDate: new Date(),
  // })
  // const [filters, setFilters] = useState({ startDate: dayjs(new Date()).startOf("day").toISOString() })
  // const [activePage, setActivePage] = useState(1)
  const [openMultiPatientRegistrationModal, setOpenMultiPatientRegistrationModal] = useState(false)
  const [openMultiPatientRegistrationViaCSVModal, setOpenMultiPatientRegistrationViaCSVModal] = useState(false)
  const [multiRegisterDropdownOpen, setMultiRegisterDropdownOpen] = useState(false)
  // const debouncedValue = useDebounce(targetPage)
  // const count = Math.ceil(records?.total / limit)

  // useEffect(() => {
  //   if (debouncedValue && debouncedValue !== activePage && !loading) {
  //     goToPage(Number(debouncedValue))
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [debouncedValue])

  // useEffect(() => {
  //   initSearch(0, 1, filters)

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // const onKeyUp = event => {
  //   if (event.key === "Enter") {
  //     handleSearch()
  //   }
  // }

  // const initSearch = async (updatedOffset = offset, pageNo = activePage, apiFilters) => {
  //   const updatedFilters = { ...apiFilters }
  //   if (apiFilters) {
  //     if (query) {
  //       if (!(filter in updatedFilters)) {
  //         updatedFilters[filter] = query
  //       }
  //     } else {
  //       delete updatedFilters[filter]
  //     }
  //   }
  //   setFilters(updatedFilters)
  //   if (updatedOffset >= 0) {
  //     try {
  //       setLoading(true)

  //       const res = await getMultiRegisterRequestsWithFilters({
  //         limit,
  //         offset: updatedOffset,
  //         filters: apiFilters ? updatedFilters : {},
  //       })

  //       if (res?.data?.records) {
  //         setRecords(res?.data?.records)
  //         setOffset(updatedOffset)
  //         setActivePage(pageNo)
  //       }
  //     } catch (error) {
  //       console.error(error)
  //     } finally {
  //       setLoading(false)
  //     }
  //   }
  // }

  // const onChangeDateRange = (date, key) => {
  //   const updatedDateRange = { ...dateRange, [key]: date }
  //   setDateRange(updatedDateRange)
  //   const originalFilters = { ...filters }
  //   delete originalFilters.startDate

  //   const updatedFilters = {
  //     ...originalFilters,
  //     ...getFilterDateRange(updatedDateRange),
  //   }
  //   initSearch(0, 1, updatedFilters)
  // }

  const toggleOpenMultiPatientRegistration = () => {
    setOpenMultiPatientRegistrationModal(!openMultiPatientRegistrationModal)
  }

  const toggleOpenMultiPatientRegistrationViaCSVModal = () => {
    setOpenMultiPatientRegistrationViaCSVModal(!openMultiPatientRegistrationViaCSVModal)
  }

  const toggleMultiRegisterDropdown = () => {
    setMultiRegisterDropdownOpen(!multiRegisterDropdownOpen)
  }

  // const goToPage = pageNo => {
  //   const updatedOffset = (pageNo - 1) * limit
  //   initSearch(updatedOffset, pageNo, filters)
  //   setTargetPage(pageNo)
  // }

  // const handleSearch = () => {
  //   if (query) {
  //     const updatedFilters = {
  //       [filter]: query,
  //       startDate: filters?.startDate,
  //     }
  //     initSearch(0, 1, updatedFilters)
  //   } else {
  //     const updatedFilters = { ...filters }
  //     delete updatedFilters[filter]
  //     initSearch(0, 1, updatedFilters)
  //   }
  // }

  // const handleJumpToPage = e => {
  //   const re = /^[0-9\b]+$/
  //   const page = e.target.value
  //   if (page === "" || (re.test(page) && Number(page) <= count)) {
  //     setTargetPage(page)
  //   }
  // }

  const isAdmin = user?.role === "admin" ? true : false
  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline pb-5 ">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay" style={{ backgroundColor: "#092031" }}></div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="text-white mb-0">
                    <i className="fas fa-user mr-4"></i>Global Clinical Care Multiple Register Portal
                  </h3>
                </div>
                <div className="col-md-4 text-right">
                  <button
                    className="btn btn-secondary"
                    title={isAdmin ? "Manage" : "Home"}
                    onClick={() => {
                      const route = isAdmin ? AllRoutesMap.manage : AllRoutesMap.staff
                      history.push(route)
                    }}
                  >
                    <i className={`fas fa-${isAdmin ? "cogs" : "home"} mr-2`}></i>
                    {isAdmin ? "Manage" : "Home"}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <form className="cms-contact-form" onSubmit={e => e.preventDefault()}>
            <div className="container-fluid mt-5">
              <div className="row">
                <div className="col-12">
                  <h4>Search Records</h4>
                  <p className="lead mb-2">
                    <b>Filter by:</b>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 mt-2">
                  <select
                    name="filter"
                    className="mr-4"
                    value={filter}
                    onChange={e => {
                      const updatedFilters = { ...filters }
                      delete updatedFilters[filter]
                      setFilters(updatedFilters)
                      setFilter(e.target.value)
                    }}
                  >
                    <option value="email">Email</option>
                  </select>
                </div>
                <div className="col-md-5 mt-2">
                  <InputMask
                    type="text"
                    name="query"
                    placeholder="Search records"
                    onChange={e => {
                      setQuery(e.target.value)
                    }}
                    onKeyUp={onKeyUp}
                  />
                </div>

                <div className="col-md-1 mt-2">
                  <button type="submit" title="Search" className="btn btn-primary w-100 h-60" onClick={handleSearch}>
                    {loading ? <Spinner color="default" size="sm" /> : <i className="fas fa-search mr-2"></i>}
                  </button>
                </div>
              </div>
            </div>
          </form> */}

          <div className="container-fluid mt-5">
            <div className="card p-4">
              <div className="row mb-4 text-right">
                {/* <div className="col-md-3">
                  <div className="cms-contact-form mr-2">
                    <label className="text-bold">Select Date</label>
                    <div className="d-flex">
                      <div className="cms-input date-wrap w-100">
                        <DatePicker
                          placeholder="Select Date"
                          id="start-date-picker"
                          onChange={date => onChangeDateRange(date, "startDate")}
                          value={dateRange.startDate}
                          className="w-100"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-md-8"></div> */}

                <div className="col-md-12 mt-4">
                  <div className="form-group">
                    <ButtonDropdown
                      className=""
                      isOpen={multiRegisterDropdownOpen}
                      toggle={toggleMultiRegisterDropdown}
                    >
                      <DropdownToggle split color="primary" className="ml-0" />
                      <DropdownMenu right>
                        <DropdownItem
                          id="multi-register-manual"
                          style={{ whiteSpace: "nowrap" }}
                          onClick={toggleOpenMultiPatientRegistration}
                        >
                          <i className="fas fa-hand-lizard-o mr-2"></i>
                          Manual
                        </DropdownItem>
                        <DropdownItem
                          id="multi-register-modal"
                          style={{ whiteSpace: "nowrap" }}
                          onClick={toggleOpenMultiPatientRegistrationViaCSVModal}
                        >
                          <i className="fas fa-sticky-note mr-2"></i>
                          Import CSV
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                </div>
              </div>

              {/* {!loading && records?.data?.length <= 0 && (
                <Alert color="warning">No data found corresponding to this search.</Alert>
              )} */}
              {/* {!loading && records?.data?.length > 0 && (
                <>
                  <div className="row c-table">
                    <div className="col-12">
                      <div className="table-responsive table-border-top no-vertical-scroll">
                        <table className="table table-hover text-nowrap">
                          <thead className="table-header-bg">
                            <tr>
                              <th>Sr No.</th>
                              <th className="text-center">Status</th>
                              <th>Method</th>
                              <th>Category</th>
                              <th>Code</th>
                              <th>From</th>
                              <th>To</th>
                              <th>Registration Type</th>
                              <th>Payment Type</th>
                              <th>Embassy</th>
                              <th>Reference</th>
                            </tr>
                          </thead>
                          {loading && records?.data?.length <= 0 && (
                            <Alert color="warning">No requests found corresponding to this search.</Alert>
                          )}
                          <tbody>
                            {!loading && records?.data?.length > 0 && (
                              <>
                                {records.data?.map((record, idx) => {
                                  const { type, label } = getRequestStatus(record?.status)
                                  return (
                                    <tr key={`request-${idx}`}>
                                      <td key={`serial-no`}>{idx + 1}</td>
                                      <td className="text-center" key={`request-${record?._id}-status-${idx}`}>
                                        <Badge type={type} label={label} />
                                      </td>
                                      <td key={`request-method-${idx}`}>{getMethod(record?.method) || "-"}</td>
                                      <td key={`test-category-${idx}`}>
                                        {getCovidTestCategory(getTestType(record)) || "-"}
                                      </td>
                                      <td key={`code-${idx}`}>{record?.tNo || "-"}</td>
                                      <td key={`from-${idx}`}>{record?.from || "-"}</td>
                                      <td key={`to-${idx}`}>{record?.to || "-"}</td>
                                      <td key={`registration-type-${idx}`}>
                                        {getRegistrationType(record?.registrationType)}
                                      </td>
                                      <td key={`payment-type-${idx}`}>{getPaymentType(record?.paymentType)}</td>
                                      <td key={`embassy-${idx}`}>{getEmbassy(record?.embassy) || "-"}</td>
                                      <td key={`reference-${idx}`}>{getReference(record?.reference)}</td>
                                    </tr>
                                  )
                                })}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={limit}
                          totalItemsCount={records?.total}
                          pageRangeDisplayed={5}
                          onChange={goToPage}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                      <div className="col-md-4 d-flex">
                        <span className="text-dark pt-2">Jump to Page</span>
                        <input
                          type="number"
                          disabled={loading}
                          value={targetPage}
                          className="jump-to-page ml-2"
                          placeholder="1"
                          onChange={handleJumpToPage}
                        />
                      </div>
                      <div className="col-md-4 pt-2">
                        <span className="pull-right text-dark">
                          Showing:{" "}
                          {`${offset + 1} to ${Math.min(limit + offset, records?.total)} of ${records?.total} records`}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">{loading && <Spinner color="primary" />}</div>
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-12 text-center">{loading && <Spinner color="primary" />}</div>
              </div> */}
            </div>
          </div>
        </section>

        {openMultiPatientRegistrationModal && (
          <MultiPatientRegistrationManuallyModal
            openModal={openMultiPatientRegistrationModal}
            toggle={toggleOpenMultiPatientRegistration}
            updateData={() => {
              // initSearch(offset, activePage, filters)
            }}
          />
        )}

        {openMultiPatientRegistrationViaCSVModal && (
          <MultiPatientRegistrationViaCSVModal
            openModal={openMultiPatientRegistrationViaCSVModal}
            toggle={toggleOpenMultiPatientRegistrationViaCSVModal}
            updateData={() => {
              // initSearch(offset, activePage, filters)
            }}
          />
        )}
      </Main>
    </Fragment>
  )
}

export default MultiRegister
