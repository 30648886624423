import { renderHeader } from "../utils/pdfs"

const renderPatientTypeInformation = () => {
  return (
    <table
      style={{
        width: "100%",
        padding: "0.5rem 0rem 0rem 0rem",
        marginBottom: "0.5rem",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          <td align="left" width="85%">
            {/* <div className="d-flex align-items-center">
              <h6
                className="mb-0"
                style={{
                  fontWeight: "bold",
                }}
              >
                Type
              </h6>
              <div className="d-flex align-items-center ml-3">
                <img
                  src="assets/images/checkbox.png"
                  style={{
                    maxHeight: "20px",
                    marginRight: "0.5rem",
                  }}
                  alt="Checkbox"
                />
                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  Regular
                </h6>
              </div>
              <div className="d-flex align-items-center ml-5">
                <img
                  src="assets/images/checkbox.png"
                  style={{
                    maxHeight: "20px",
                    marginRight: "0.5rem",
                  }}
                  alt="Checkbox"
                />

                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  Passenger
                </h6>
              </div>
            </div> */}
          </td>
          <td align="left" width="15%">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center ml-3">
                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  <span> &nbsp;</span>
                </h6>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const passengerInformationRow = (title, text) => {
  return (
    <tr>
      <td style={{ padding: "5px 10px" }}>
        <p
          style={{
            margin: 0,
            color: "black",
            fontWeight: "600",
          }}
        >
          {title}
        </p>
      </td>
      <td style={{ padding: "5px 10px" }} width="75%">
        <p
          style={{
            margin: 0,
            color: "black",
            fontWeight: "400",
          }}
        >
          {text}
        </p>
      </td>
    </tr>
  )
}

const renderSubHeader = () => {
  return (
    <>
      <table
        style={{
          width: "100%",
          padding: "0.5rem 0",
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          {passengerInformationRow("Test Type", "")}
          {passengerInformationRow("MR#", "")}
          {passengerInformationRow("Name", "")}
          {passengerInformationRow("CNIC", "")}
          {passengerInformationRow("Passport", "")}
          {passengerInformationRow("Date Of Birth", "")}
          {passengerInformationRow("Passenger Email", "")}
          {passengerInformationRow("Passenger Contact Number", "")}
          {passengerInformationRow("Address", "")}
          {passengerInformationRow("City", "")}
          {passengerInformationRow("Flight Number", "")}
          {passengerInformationRow("Flight Date", "")}
          {passengerInformationRow("Booking Reference", "")}
          {passengerInformationRow("Destination", "")}
          {passengerInformationRow("Airline", "")}
          <tr>
            <td style={{ padding: "5px 10px", verticalAlign: "top" }}>
              <p
                style={{
                  margin: 0,
                  color: "black",
                  fontWeight: "600",
                }}
              >
                Sample Collection Location
              </p>
            </td>
            <td style={{ padding: "5px 10px" }} width="75%">
              <p
                style={{
                  margin: 0,
                  color: "black",
                  fontWeight: "400",
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: 0,
                  color: "black",
                  fontWeight: "400",
                }}
              >
                &nbsp;
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

const renderDisclaimer = () => (
  <>
    <h5>CONSENT</h5>
    <p className="text-dark mb-1">
      I have read this form fully and understand the contents of it. I understand that filling in and signing this form
      gives you permission to share my covid 19 report with the local health authorities and to the Airline.
    </p>
    <ol>
      <li>
        <p className="text-dark mb-1">
          Please consult with your airline/travel agent for the window in which sample can be given to Global Clinical
          Care Diagnostic Center. Global Clinical Care Diagnostic Center will not be responsible if airline is not
          acccepting the report due to the expiry of time window.
        </p>
      </li>
      <li>
        <p className="text-dark mb-1">
          Airlines will have different policy for testing of children under age 12. You are advised to consult with you
          airline/travel agent for clarity on this.
        </p>
      </li>
      <li>
        <p className="text-dark mb-1">
          Global Clinical Care Diagnostic Center or Airline shall not be liable for the outcome of the test.
        </p>
      </li>
      <li>
        <p className="text-dark mb-1">
          Global Clinical Care Diagnostic Center or Airline shall not be liable for any damages, claims or other
          consequences including expenses arising out of any loss, temporary misplacement, delay or damage to the test
          results once handed over to the passenger.
        </p>
      </li>
      <li>
        <p className="text-dark mb-1">
          While the said tests are minimally invasive and designed to be conducted with minimum discomfort to the
          patient, all medical procedures bear some risk.
        </p>
      </li>
      <li>
        <p className="text-dark mb-1">All amounts paid shall be non-refundable.</p>
      </li>
    </ol>
  </>
)

const renderFooter = () => {
  return (
    <>
      <table
        style={{
          width: "100%",
          padding: "0.5rem 0",
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          <tr>
            <td style={{ padding: "5px 10px" }} width="50%">
              <p
                style={{
                  margin: 0,
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Your Signature:
              </p>
            </td>
            <td style={{ padding: "5px 10px" }} width="50%">
              <p
                style={{
                  margin: 0,
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Date:
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

const ConsentPassengerTemplate = () => {
  return (
    <div
      style={{
        maxWidth: "1000px",
        margin: "auto",
        backgroundColor: "white",
        minHeight: "100vh",
        paddingBottom: "2rem",
      }}
    >
      {renderHeader("Patient Consent Form")}
      {renderPatientTypeInformation()}
      {renderSubHeader()}
      {renderDisclaimer()}
      {renderFooter()}

      <button className="no-print text-white" onClick={() => window.print()}>
        PRINT
      </button>
    </div>
  )
}

export default ConsentPassengerTemplate
