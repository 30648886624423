import { Alert, Spinner, useToast } from "@chakra-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import AddUserModal from "../components/modals/AddUserModal"
import ChangeUserModal from "../components/modals/ChangeUserModal"
import ConfirmationModal from "../components/modals/ConfirmationModal"
import { AllRoutesMap } from "../routes/RoutesConfig"
import { deleteUser, getUsers } from "../services/api"
import { getRole } from "../utils/roles"

const ManageUsers = () => {
  const history = useHistory()
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [openAddUserModal, setOpenAddUserModal] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState({ isOpen: false, id: null, email: "" })
  const [userDataModal, setUserDataModal] = useState({ isOpen: false, role: "", email: "" })

  const searchUsers = async () => {
    try {
      setLoading(true)
      const res = await getUsers()
      if (res.data.users) {
        setUsers(res.data.users)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const toggleAddUserModal = () => {
    setOpenAddUserModal(!openAddUserModal)
  }

  const toggleConfirmationModal = user => {
    const data = { isOpen: !confirmationModal.isOpen, id: user?._id || null, email: user?.email || "" }
    setConfirmationModal(data)
  }

  const toggleChangeUserModal = user => {
    const data = {
      isOpen: !userDataModal.isOpen,
      role: user?.role || "",
      email: user?.email || "",
      permissions: user?.permissions,
    }
    setUserDataModal(data)
  }

  const updateUsers = (user, isDeleted = false) => {
    if (!user) {
      return
    }
    let updatedUsers = [...users]
    if (!isDeleted) {
      updatedUsers.push(user)
      updatedUsers.sort((a, b) => (a.role > b.role ? 1 : b.role > a.role ? -1 : 0))
    } else {
      updatedUsers = updatedUsers.filter(({ email }) => email !== user.email)
    }
    setUsers(updatedUsers)
  }

  const setDeleteLoading = (id, value) => {
    const tempUsers = [...users]
    const updateUsers = tempUsers.map(user => {
      if (user._id === id) {
        return { ...user, isDeleting: value }
      }
      return user
    })
    setUsers(updateUsers)
  }

  const removeUser = async ({ id }) => {
    try {
      setDeleteLoading(id, true)
      const res = await deleteUser(id)
      if (res?.data?.user) {
        updateUsers(res.data.user, true)
      }
      toast({
        title: "User deleted successfully.",
        description: "User deleted successfully.",
        status: "success",
        duration: 4000,
      })
    } catch (err) {
      const error = err.response?.data?.errors?.message || "There was an error while deleting user."
      toast({
        title: "Error occurred while deleting user.",
        description: error,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
      setDeleteLoading(id, false)
    }
  }

  useEffect(() => {
    searchUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay" style={{ backgroundColor: "#092031" }}></div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="text-white mb-0">
                    <i className="fas fa-user-circle mr-4"></i>Global Clinical Care Manage Users Portal
                  </h3>
                </div>
                <div className="col-md-4 text-right">
                  <button
                    className="btn btn-secondary"
                    title="Back"
                    onClick={() => {
                      history.push(AllRoutesMap.manage)
                    }}
                  >
                    <i className="fas fa-arrow-left mr-2"></i>
                    Back
                  </button>
                  <button
                    className="btn btn-primary ml-2"
                    title="Home"
                    onClick={() => {
                      history.push(AllRoutesMap.admin)
                    }}
                  >
                    <i className="fas fa-home mr-2"></i>
                    Home
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-12">
                <div className="card p-4">
                  <div className="row mb-4">
                    <div className="col">
                      <button className="btn btn-primary float-right" title="Home" onClick={toggleAddUserModal}>
                        <i className="fas fa-plus mr-2"></i>
                        Add User
                      </button>
                    </div>
                  </div>
                  {!loading && !users?.length && (
                    <Alert color="warning" className="mb-0">
                      No results found.
                    </Alert>
                  )}

                  {users?.length > 0 && (
                    <div className="row c-table">
                      <div className="col-12">
                        <div className="table-responsive table-border-top no-vertical-scroll">
                          <table className="table table-hover text-nowrap">
                            <thead className="table-header-bg">
                              <tr>
                                <th scope="col" style={{ width: "5%" }}>
                                  Sr. No
                                </th>
                                <th scope="col" style={{ width: "10%" }}>
                                  Actions
                                </th>
                                <th scope="col">Username/Email</th>
                                <th scope="col">Role</th>
                              </tr>
                            </thead>

                            <tbody>
                              {users.map((user, idx) => (
                                <tr key={`UsersTable-${idx}`}>
                                  <td>{idx + 1}</td>
                                  <td>
                                    {user.role !== "admin" && (
                                      <button
                                        className="btn-action bg-danger border-0 mr-2"
                                        onClick={() => {
                                          toggleConfirmationModal(user)
                                        }}
                                        disabled={user.isDeleting}
                                        title="Delete User"
                                      >
                                        {user.isDeleting ? (
                                          <Spinner color="#fff" size="sm" />
                                        ) : (
                                          <i className="fas fa-trash" />
                                        )}
                                      </button>
                                    )}
                                    <button
                                      className="btn-action bg-primary border-0"
                                      onClick={() => {
                                        toggleChangeUserModal(user)
                                      }}
                                      title="Change Password"
                                    >
                                      <i className="fas fa-pencil" />
                                    </button>
                                  </td>
                                  <td>{user.email}</td>
                                  <td className="text-capitalize">{getRole(user.role)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-12 text-center">{loading && <Spinner color="primary" />}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {openAddUserModal && (
          <AddUserModal openModal={openAddUserModal} toggle={toggleAddUserModal} updateUsers={updateUsers} />
        )}
        {confirmationModal.isOpen && (
          <ConfirmationModal
            openModal={confirmationModal.isOpen}
            toggle={toggleConfirmationModal}
            callback={removeUser}
            callbackData={confirmationModal}
            headerText="User Deletion Confirmation"
            bodyText={`Do you really want to delete <b>${confirmationModal.email}</b>?`}
            headerClass="bg-danger"
            headerTextClass="text-white"
            okayBtnClass="btn-danger"
          />
        )}
        {userDataModal.isOpen && (
          <ChangeUserModal
            openModal={userDataModal.isOpen}
            toggle={toggleChangeUserModal}
            data={userDataModal}
            searchUsers={searchUsers}
          />
        )}
      </Main>
    </>
  )
}

export default ManageUsers
