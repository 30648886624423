import { PDFDocument, StandardFonts, rgb } from "pdf-lib"
import dayjs from "dayjs"

import { getEmbassy, getPanel, getReference, getTestCenter, getTotalCharges } from "../../utils/common"
import { renderBarcode, openPDFNewTab, renderProfileImage } from "../../utils/pdfs"

export default async function createInvoicePDF(props) {
  const { isDownload = true, isZip = false, ...rest } = props
  const result = rest

  // This should be a Uint8Array or ArrayBuffer
  // This data can be obtained in a number of different ways
  // If your running in a Node environment, you could use fs.readFile()
  // In the browser, you could make a fetch() call and use res.arrayBuffer()
  const url = `assets/pdf-templates/invoice/invoice-template.pdf`

  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes)

  // Embed the Helvetica font
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold) // for bold font

  // Get the first page of the document
  const pages = pdfDoc.getPages()
  const page = pages[0]

  const config = {
    page,
    helveticaFont,
    helveticaBoldFont,
    pdfDoc,
  }

  await renderProfileImage(result, config)
  await renderEmbassyChecks(result, config)
  renderHeader(result, config)
  renderBillingnformation(result, config)
  renderTestCenterInformation(result, config)
  await renderBarcode(result, config)

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save()

  if (isDownload && isZip) {
    return pdfBytes
  } else if (isDownload && !isZip) {
    const invoiceName = `invoice-${result?.patient?.fullname?.toLowerCase() || "patient"}-${
      result.registration.registrationId
    }.pdf`
    // Trigger the browser to download the PDF document
    window.download(pdfBytes, invoiceName, "application/pdf")
  } else {
    //Open PDF to new TAB
    openPDFNewTab(pdfBytes)
  }
}

export const renderHeader = (data, config) => {
  const { page, helveticaFont } = config
  const { patient, registration } = data
  const yPosition = 665

  // Render Fullname
  page.drawText(patient?.fullname || "-", {
    x: 40,
    y: yPosition,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render Passport
  page.drawText(patient?.passport || registration?.passport || "-", {
    x: 162, // 198
    y: yPosition,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render CNIC
  page.drawText(patient?.cnic || "-", {
    x: 242, // 198
    y: yPosition,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render Panel Information
  if (registration?.panel) {
    page.drawText(`${getPanel(registration?.panel)} Covid-19 GCC`, {
      x: 322, // 278
      y: yPosition,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
  } else {
    /* Might require 2nd default option in the future */
    page.drawText("-" || "Covid-19 for Islamabad (Regular)", {
      x: 322, // 278
      y: yPosition,
      size: 8,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
  }

  const sampleDate = dayjs(registration.sampleTakenAt).format("DD/MM/YYYY") //hh:mm A
  const sampleTime = dayjs(registration.sampleTakenAt).format("hh:mm A")

  // Render Sample Date & Time
  page.drawText(`${sampleDate} ${sampleTime}`, {
    x: 472,
    y: yPosition + 14,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render MR No.
  page.drawText(registration?.registrationId, {
    x: 442,
    y: yPosition + 2,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })
}

export const renderEmbassyChecks = async (data, config) => {
  const { page, helveticaBoldFont } = config
  const { registration } = data

  const pngImageBytes = await fetch("assets/images/checkbox-check.png").then(res => res.arrayBuffer())
  // Embed the JPG image bytes and PNG image bytes
  const pngImage = await config.pdfDoc.embedPng(pngImageBytes)
  // Get the width/height of the PNG image scaled down to 50% of its original size
  const pngDims = pngImage.scaleToFit(15, 15)
  const yPosition = 716

  if (registration?.registrationType) {
    if (registration?.registrationType === "passenger") {
      page.drawImage(pngImage, {
        x: 134,
        y: yPosition,
        width: pngDims.width,
        height: pngDims.height,
      })
    } else {
      page.drawImage(pngImage, {
        x: 65,
        y: yPosition,
        width: pngDims.width,
        height: pngDims.height,
      })
    }
  }

  const embassyRefX = 220
  const embassyRefXConstant = registration?.embassy ? 40 : 17
  const embassyRefValueX = embassyRefX + embassyRefXConstant

  page.drawText(registration?.embassy ? "Embassy: " : "Ref: ", {
    x: embassyRefX,
    y: yPosition + 3,
    size: 8,
    font: helveticaBoldFont,
  })

  page.drawText(getEmbassy(registration.embassy) || getReference(registration?.reference) || "-", {
    x: embassyRefValueX,
    y: yPosition + 3,
    size: 8,
  })
}

export const renderBillingnformation = (data, config) => {
  const { page, helveticaFont } = config
  const { registration } = data

  const totalCharges = getTotalCharges(registration)
  const isDiscountedPrice = registration.discount ? "*" : ""
  const xBillConstant = 325
  const yPositionConstant = 14
  const fontSize = 8
  let srNo = 1
  let yPosition = 545

  if (registration.rapidPcrCharges) {
    page.drawText(`${srNo}`, {
      x: 40,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    page.drawText("Rapid PCR", {
      x: 102,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    page.drawText(`${registration.rapidPcrCharges || "-"}`, {
      x: xBillConstant,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    yPosition -= yPositionConstant
    srNo += 1
  }

  if (registration.charges) {
    page.drawText(`${srNo}`, {
      x: 40,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    page.drawText("Real Time RT-PCR", {
      x: 102,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    page.drawText(`${registration.charges || "-"}`, {
      x: xBillConstant,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    yPosition -= yPositionConstant
    srNo += 1
  }

  if (registration.igmCharges) {
    page.drawText(`${srNo}`, {
      x: 40,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    page.drawText("COVID 19 Antibodies (IgM)", {
      x: 102,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    page.drawText(`${registration.igmCharges || "-"}`, {
      x: xBillConstant,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    yPosition -= yPositionConstant
    srNo += 1
  }

  if (registration.iggCharges) {
    page.drawText(`${srNo}`, {
      x: 40,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    page.drawText("COVID 19 Antibodies (IgG)", {
      x: 102,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    page.drawText(`${registration.iggCharges || "-"}`, {
      x: xBillConstant,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    yPosition -= yPositionConstant
    srNo += 1
  }

  if (registration.travelCharges) {
    page.drawText(`${srNo}`, {
      x: 40,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    page.drawText("Travel Charges", {
      x: 102,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    page.drawText(`${registration.travelCharges || "-"}`, {
      x: xBillConstant,
      y: yPosition,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    srNo += 1
  }

  page.drawText(`${totalCharges}${isDiscountedPrice}`, {
    x: xBillConstant,
    y: 463,
    size: fontSize,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })
}

export const renderTestCenterInformation = (data, config) => {
  const { page, helveticaFont } = config
  const { registration } = data

  const yPosition = 330
  const testCenter = getTestCenter(registration.testCenter)

  let address

  if (testCenter?.address) {
    address = testCenter.address.split("\n").join(" ")
  }

  // Render Address
  page.drawText(address || "-", {
    x: 40,
    y: yPosition,
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })
}
