import * as yup from "yup"
import dayjs from "dayjs"
import PhoneInput from "react-phone-input-2"
import InputMask from "react-input-mask"
import Select from "react-select"
import clx from "classnames"
import React, { Fragment, useEffect } from "react"
import { Link } from "react-router-dom"
import { nanoid } from "nanoid"
import { useHistory } from "react-router-dom"
import { Spinner, Nav, NavItem, NavLink } from "reactstrap"

import { Checkbox, useToast } from "@chakra-ui/core"
import { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"

import * as apiURLs from "../services/apiURLs"
import api, { getRegistrationDetailsByUniqueIdentifier } from "../services/api"
import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import Footer from "../components/layouts/footer/Footer"
import RequiredFieldMark from "../components/common/RequiredFieldMark"
import DatePicker from "../components/common/DatePicker"
import FileUploader from "../components/common/FileUploader"
import CameraPicture from "../components/common/CameraPicture"
import CheckTestWindowModal from "../components/modals/CheckTestWindowModal"
import ConfirmationModal from "../components/modals/ConfirmationModal"
// import { airports } from "../utils/airports"
import { airlines, defaultAirline } from "../utils/airlines"
import { AllRoutesMap } from "../routes/RoutesConfig"
import { useAirports } from "../services/swrHooks"
import { useDebounce } from "../hooks/UseDebounce"

import {
  embassies,
  getPassport,
  getTestCenter,
  getTNoPrefix,
  phoneNoCountries,
  references,
  registrationTypes,
} from "../utils/common"

//const EMAIL_REGEX = /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/

const schema = yup.object().shape({
  tNo: yup.string(),
  //fullname: yup.string().required("Full name is a required field."),
  profilePictureUrlFile: yup.string(),
  profilePictureUrlCam: yup.string(),
  //gender: yup.string().required("Gender is required field."),
  //dob: yup.string(),
  //phone: yup.string(),
  //.required("Phone number is a required field."),
  //email: yup.string(),
  //.required("Email is a required field.")
  //.max(320, "Email must not be longer than 320 characters")
  //.matches(EMAIL_REGEX, "Email is Invalid")
  //.email("Email is Invalid"),
  //cnic: yup.string(),
  //country: yup.string().required(),
  //city: yup.string().required(),
  //testCenter: yup.string().required(),
  //address: yup.string(),
  panel: yup.string(),
  paymentType: yup.string().required("Payment Type is a required field."),
  registrationFormUrl: yup.string(),
  otherPhotoUrl: yup.string(),
  totalCharges: yup
    .number()
    .typeError("Net bill must be a number")
    .required("Net bill is a required field.")
    .positive("Net bill must be a positive number.")
    .integer("Decimal not allowed for Net bill")
    .min(0, "Net bill must be greater than 0."),
  rapidPcrCharges: yup.number().typeError("Rapid PCR Charges must be a number"),
  charges: yup.number().typeError("PCR Charges must be a number"),
  igmCharges: yup.number().typeError("IgM Charges must be a number"),
  iggCharges: yup.number().typeError("IgG Charges must be a number"),
  travelCharges: yup.number().typeError("Travel Charges must be a number"),
  discount: yup
    .number()
    .typeError("Discount must be a number")
    .positive("Discount must be a positive number.")
    .integer("Decimal not allowed for discount")
    .min(0, "Mininum discount is 0."),
  discountComments: yup.string().when("discount", {
    is: discount => discount > 0,
    then: yup.string().required("Comments field is required"),
  }),
  //reference: yup.string(),
})

const customStyles = {
  control: provided => {
    const background = "#f1f5fe"
    const border = "1px solid #f1f5fe"
    return { ...provided, background, border }
  },
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "#07152a",
  }),
}

export default function Registration() {
  const { airports, isLoading } = useAirports()

  const toast = useToast()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState("regular")
  const [loading, setLoading] = useState(false)
  const [autoFillLoading, setAutoFillLoading] = useState(false)
  const [isCheckTestWindowOpen, setIsCheckTestWindowOpen] = useState(false)
  const [registrationType, setRegistrationType] = useState("passenger")
  const [embassy, setEmbassy] = useState(undefined)
  const [tNoPrefix, setTNoPrefix] = useState("C")
  const [isRapidPcrCharges, setIsRapidPcrCharges] = useState(false)
  const [isCharges, setIsCharges] = useState(true)
  const [isIgmCharges, setIsIgmCharges] = useState(true)
  const [isIggCharges, setIsIggCharges] = useState(true)
  const [isTravelCharges, setIsTravelCharges] = useState(true)
  const [registrationData, setRegistrationData] = useState([])
  const [fullname, setFullname] = useState("")
  const [cnic, setCNIC] = useState("")
  const [dob, setDOB] = useState("")
  const [phone, setPhone] = useState("")
  const [gender, setGender] = useState("")
  const [passport, setPassport] = useState("")
  const [email, setEmail] = useState("")
  const [country, setCountry] = useState("Pakistan")
  const [city, setCity] = useState("Islamabad")
  const [address, setAddress] = useState("")
  const [testCenter, setTestCenter] = useState("ISB01")
  const [reference, setReference] = useState("")
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const debouncedCNICValue = useDebounce(cnic)
  const debouncedPassportValue = useDebounce(passport?.toUpperCase())

  const autoCompleteRegistrationsData = async registration => {
    setPassport(getPassport(registration) || "")
    setCNIC(registration?.patient?.cnic || "")
    setRegistrationType(registration?.registrationType)
    setEmbassy(registration?.embassy)
    setFullname(registration?.patient?.fullname)
    setDOB(registration?.patient?.dob)
    setGender(registration?.patient?.gender)
    setPhone(registration?.patient?.phone)
    setEmail(registration?.patient?.email)
    setCountry(registration?.patient?.country)
    setCity(registration?.patient?.city)
    setAddress(registration?.patient?.address)
    setTestCenter(registration?.testCenter)
    setReference(registration?.reference)
  }

  const { register, handleSubmit, control, errors, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      country: "Pakistan",
      city: "Islamabad",
      testCenter: "ISB01",
      totalCharges: 11500,
      rapidPcrCharges: 6500,
      charges: 6500,
      igmCharges: 3000,
      iggCharges: 2000,
      travelCharges: 0,
      discount: 0,
      paymentType: "",
      panel: "",
    },
  })
  const { rapidPcrCharges, charges, igmCharges, iggCharges, travelCharges, discount } = watch([
    "rapidPcrCharges",
    "charges",
    "igmCharges",
    "iggCharges",
    "travelCharges",
    "discount",
  ])

  const toggleConfirmationModal = () => setOpenConfirmationModal(!openConfirmationModal)

  const profilePictureUniqueID = nanoid()

  const setTotalCharges = () => {
    let total = 0
    if (isRapidPcrCharges) {
      total += Number(rapidPcrCharges)
    } else {
      if (isCharges) {
        total += Number(charges)
      }
      if (isIgmCharges) {
        total += Number(igmCharges)
      }
      if (isIggCharges) {
        total += Number(iggCharges)
      }
    }
    if (isTravelCharges) {
      total += Number(travelCharges)
    }
    total -= Number(discount)
    setValue("totalCharges", total, { shouldValidate: true })
  }

  useEffect(() => {
    const CNIC_REGEX = /\d{5}-\d{7}-\d{1}$/
    const PASSPORT_REGEX = /[A-Z]{2}\d{7}$/

    if (
      (debouncedCNICValue && CNIC_REGEX.test(debouncedCNICValue) && !debouncedPassportValue) ||
      (debouncedPassportValue && PASSPORT_REGEX.test(debouncedPassportValue) && !debouncedCNICValue)
    ) {
      async function fetchRegistrationsData() {
        try {
          const data = { cnic: debouncedCNICValue, passport: debouncedPassportValue }

          setAutoFillLoading(true)
          const res = await getRegistrationDetailsByUniqueIdentifier(data)
          const registration = res?.data?.registrations[0]

          if (registration) {
            setRegistrationData(registration)
            toggleConfirmationModal()
          }
        } catch (error) {
          console.log(error)
          toast({
            title: "There was an error while fetching data",
            description: "There was an error while fetching data",
            status: "error",
            duration: 3000,
            isClosable: true,
          })
        } finally {
          setAutoFillLoading(false)
        }
      }
      fetchRegistrationsData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCNICValue || debouncedPassportValue])

  useEffect(() => {
    setTotalCharges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rapidPcrCharges, charges, igmCharges, iggCharges, travelCharges, discount])

  useEffect(() => {
    if (isCharges || isIgmCharges || isIggCharges) {
      setIsRapidPcrCharges(false)
    }
    setTotalCharges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCharges, isIgmCharges, isIggCharges])

  useEffect(() => {
    if (isRapidPcrCharges) {
      setIsCharges(false)
      setIsIgmCharges(false)
      setIsIggCharges(false)
    }
    setTotalCharges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRapidPcrCharges])

  useEffect(() => {
    setTNoPrefix(getTNoPrefix(embassy))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embassy])

  const toggleCheckTestWindowModal = () => setIsCheckTestWindowOpen(!isCheckTestWindowOpen)

  const onSubmit = async formData => {
    const { tNo, flightDetails, profilePictureUrlCam, profilePictureUrlFile, ...otherFormData } = formData
    const profilePictureUrl = profilePictureUrlCam || profilePictureUrlFile
    const data = {
      ...flightDetails,
      ...otherFormData,
      profilePictureUrl,
      registrationType: registrationType,
      tNo: tNo?.toUpperCase(),
      passport: passport?.toUpperCase(),
      fullname: fullname?.toUpperCase(),
      cnic,
      phone,
      dob,
      gender,
      email,
      country,
      city,
      address,
      testCenter,
      reference,
      embassy,
    }

    try {
      setLoading(true)
      const res = await api.post(apiURLs.PATIENTS, {
        ...data,
        flightDate: data.flightDate ? dayjs(data.flightDate).format("DD-MM-YYYY") : null,
        destinationAirport: data.destinationAirport?.value,
        rapidPcrCharges: isRapidPcrCharges ? data.rapidPcrCharges : undefined,
        charges: isCharges ? data.charges : undefined,
        igmCharges: isIgmCharges ? data.igmCharges : undefined,
        iggCharges: isIggCharges ? data.iggCharges : undefined,
        travelCharges: isTravelCharges ? data.travelCharges : undefined,
      })

      history.push(
        `${AllRoutesMap.registrationSuccess}?patientId=${res?.data?.patient.patientId}&registrationId=${res?.data?.registration.registrationId}&labId=${res?.data?.registration.labId}`
      )
      toast({
        title: "Registration successfully.",
        description: "Message has been sent to your number with details.",
        status: "success",
        duration: 10000,
      })
    } catch (error) {
      toast({
        title: "There was an error while registration.",
        description: error?.response?.data?.errors?.message || error?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <h2 className="text-white mb-0">
                    <i className="fas fa-address-card mr-4"></i>
                    Registration Form
                  </h2>
                </div>
                <div className="col-12 col-lg-4 text-left text-lg-right ml-auto">
                  <Link to={AllRoutesMap.staff} className="btn btn-white mr-2">
                    <i className="fas fa-home space-right"></i>
                    Home
                  </Link>
                  <button className="btn btn-secondary" title="Check Test Window" onClick={toggleCheckTestWindowModal}>
                    <i className="fas fa-calculator mr-2"></i>
                    Check Test Window
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5">
            <form className="cms-contact-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={clx({ active: activeTab === "regular" })}
                        onClick={() => {
                          reset()
                          setRegistrationType("passenger")
                          setEmbassy(undefined)
                          setActiveTab("regular")
                        }}
                      >
                        Regular
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={clx({ active: activeTab === "embassy" })}
                        onClick={() => {
                          reset()
                          setRegistrationType("passenger")
                          setEmbassy("chinese")
                          setActiveTab("embassy")
                        }}
                      >
                        Embassy
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <div className="col mt-4">
                  <div className="row">
                    <div className="col-lg-3 py-3 d-flex flex-column align-items-center">
                      <h5>Profile Photo</h5>
                      <Controller
                        control={control}
                        name="profilePictureUrlCam"
                        render={({ onChange }) => (
                          <CameraPicture onTakePhoto={onChange} pictureId={profilePictureUniqueID} />
                        )}
                      />
                      <div className="text-danger text-center">{errors.profilePictureUrlCam?.message}</div>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      {embassy && (
                        <div className="mb-4">
                          <div>
                            <h5>Embassy</h5>
                          </div>
                          <div>
                            <select
                              name="embassy"
                              value={embassy}
                              onChange={e => {
                                setEmbassy(e.target.value)
                              }}
                            >
                              <option value="">Select Embassy</option>
                              {embassies.map(({ value, label }, idx) => (
                                <option key={`Embassy-${label}-${idx}`} value={value}>
                                  {label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-3">
                      <div>
                        <h5>Patient Type</h5>
                      </div>
                      <div className="mb-3">
                        <select
                          name="registrationType"
                          value={registrationType}
                          onChange={e => {
                            setRegistrationType(e.target.value)
                          }}
                        >
                          {registrationTypes.map(({ value, label }, idx) => (
                            <option key={`registrationTypes-${label}-${idx}`} value={value}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div>
                        <h5>T Number</h5>
                      </div>
                      <div>
                        {embassy ? (
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text input-prepend" id="basic-addon1">
                                {tNoPrefix}
                              </span>
                            </div>
                            <input
                              type="text"
                              placeholder="Enter T number"
                              name="tNo"
                              ref={register}
                              className="form-control c-input text-uppercase"
                              aria-describedby="basic-addon1"
                              style={{ paddingLeft: 3 }}
                            />
                          </div>
                        ) : (
                          <input
                            type="text"
                            placeholder="Enter T number"
                            name="tNo"
                            className="text-uppercase"
                            ref={register}
                          />
                        )}
                        <span className="text-danger">{errors.tNo?.message}</span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div>
                        <h5>Panel</h5>
                      </div>
                      <div className="mb-3">
                        <select name="panel" ref={register}>
                          <option value="">Choose Panel</option>
                          <option value="china">China</option>
                        </select>
                        <span className="text-danger">{errors.panel?.message}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        <h5>
                          <i className="fas fa-address-book mr-2"></i>
                          Personal Details
                        </h5>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-bold">
                              Full Name <RequiredFieldMark />
                            </label>
                            <input
                              type="text"
                              value={fullname}
                              placeholder="Enter your name"
                              name="fullname"
                              className="text-uppercase"
                              onChange={e => setFullname(e.target.value)}
                            />
                            {/* <span className="text-danger">{errors.fullname?.message}</span> */}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="text-bold">CNIC</label>
                            <div className="d-flex">
                              <InputMask
                                mask="99999-9999999-9"
                                maskChar="_"
                                type="text"
                                onChange={e => {
                                  setCNIC(e.target.value)
                                }}
                                value={cnic}
                                disabled={autoFillLoading}
                              />
                              {autoFillLoading && <Spinner className="mt-4" color="default" size="sm" />}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Passport No.</label>
                            <div className="d-flex">
                              <input
                                className="text-uppercase"
                                type="text"
                                placeholder="Enter Passport here"
                                name="passport"
                                value={passport}
                                onChange={e => {
                                  setPassport(e.target.value)
                                }}
                                disabled={autoFillLoading}
                              />
                              {autoFillLoading && <Spinner className="mt-4" color="default" size="sm" />}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-bold">
                              Gender <RequiredFieldMark />
                            </label>
                            <select name="gender" value={gender} onChange={e => setGender(e.target.value)}>
                              <option value="">Select Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                            {/* <span className="text-danger">{errors.gender?.message}</span> */}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Date of Birth</label>
                            <input
                              type="date"
                              placeholder="Your date of birth"
                              name="dob"
                              value={dob}
                              onChange={e => {
                                setDOB(e.target.value)
                              }}
                              // ref={register}
                            />
                            {/* <span className="text-danger">{errors.dob?.message}</span> */}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-bold">
                              Cell No. <RequiredFieldMark />
                            </label>
                            <PhoneInput
                              onlyCountries={phoneNoCountries}
                              country={"pk"}
                              onChange={phone => setPhone(phone)}
                              value={phone}
                            />
                            {/* <span className="text-danger">{errors.phone?.message}</span> */}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Email <RequiredFieldMark />
                            </label>
                            <input
                              type="email"
                              placeholder="Your email abc@example.com"
                              name="email"
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                              // ref={register}
                            />
                            {/* <span className="text-danger">{errors.email?.message}</span> */}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-bold">
                              Country <RequiredFieldMark />
                            </label>
                            <select name="country" value={country} onChange={e => setCountry(e.target.value)}>
                              <option value="Pakistan">Pakistan</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-bold">
                              City <RequiredFieldMark />
                            </label>
                            <select name="city" value={city} onChange={e => setCity(e.target.value)}>
                              <option value="Islamabad">Islamabad</option>
                              <option value="" disabled>
                                Punjab Cities
                              </option>
                              <option value="Ahmed Nager Chatha">Ahmed Nager Chatha</option>
                              <option value="Ahmadpur East">Ahmadpur East</option>
                              <option value="Ali Khan Abad">Ali Khan Abad</option>
                              <option value="Alipur">Alipur</option>
                              <option value="Arifwala">Arifwala</option>
                              <option value="Attock">Attock</option>
                              <option value="Bhera">Bhera</option>
                              <option value="Bhalwal">Bhalwal</option>
                              <option value="Bahawalnagar">Bahawalnagar</option>
                              <option value="Bahawalpur">Bahawalpur</option>
                              <option value="Bhakkar">Bhakkar</option>
                              <option value="Burewala">Burewala</option>
                              <option value="Chillianwala">Chillianwala</option>
                              <option value="Chakwal">Chakwal</option>
                              <option value="Chichawatni">Chichawatni</option>
                              <option value="Chiniot">Chiniot</option>
                              <option value="Chishtian">Chishtian</option>
                              <option value="Daska">Daska</option>
                              <option value="Darya Khan">Darya Khan</option>
                              <option value="Dera Ghazi Khan">Dera Ghazi Khan</option>
                              <option value="Dhaular">Dhaular</option>
                              <option value="Dina">Dina</option>
                              <option value="Dinga">Dinga</option>
                              <option value="Dipalpur">Dipalpur</option>
                              <option value="Faisalabad">Faisalabad</option>
                              <option value="Ferozewala">Ferozewala</option>
                              <option value="Fateh Jhang">Fateh Jang</option>
                              <option value="Ghakhar Mandi">Ghakhar Mandi</option>
                              <option value="Gojra">Gojra</option>
                              <option value="Gujranwala">Gujranwala</option>
                              <option value="Gujrat">Gujrat</option>
                              <option value="Gujar Khan">Gujar Khan</option>
                              <option value="Hafizabad">Hafizabad</option>
                              <option value="Haroonabad">Haroonabad</option>
                              <option value="Hasilpur">Hasilpur</option>
                              <option value="Haveli Lakha">Haveli Lakha</option>
                              <option value="Jatoi">Jatoi</option>
                              <option value="Jalalpur">Jalalpur</option>
                              <option value="Jattan">Jattan</option>
                              <option value="Jampur">Jampur</option>
                              <option value="Jaranwala">Jaranwala</option>
                              <option value="Jhang">Jhang</option>
                              <option value="Jhelum">Jhelum</option>
                              <option value="Kalabagh">Kalabagh</option>
                              <option value="Karor Lal Esan">Karor Lal Esan</option>
                              <option value="Kasur">Kasur</option>
                              <option value="Kamalia">Kamalia</option>
                              <option value="Kamoke">Kamoke</option>
                              <option value="Khanewal">Khanewal</option>
                              <option value="Khanpur">Khanpur</option>
                              <option value="Kharian">Kharian</option>
                              <option value="Khushab">Khushab</option>
                              <option value="Kot Addu">Kot Addu</option>
                              <option value="Jauharabad">Jauharabad</option>
                              <option value="Lahore">Lahore</option>
                              <option value="Lalamusa">Lalamusa</option>
                              <option value="Layyah">Layyah</option>
                              <option value="Liaquat Pur">Liaquat Pur</option>
                              <option value="Lodhran">Lodhran</option>
                              <option value="Malakwal">Malakwal</option>
                              <option value="Mamoori">Mamoori</option>
                              <option value="Mailsi">Mailsi</option>
                              <option value="Mandi Bahauddin">Mandi Bahauddin</option>
                              <option value="Mian Channu">Mian Channu</option>
                              <option value="Mianwali">Mianwali</option>
                              <option value="Multan">Multan</option>
                              <option value="Murree">Murree</option>
                              <option value="Muridke">Muridke</option>
                              <option value="Mianwali Bangla">Mianwali Bangla</option>
                              <option value="Muzaffargarh">Muzaffargarh</option>
                              <option value="Narowal">Narowal</option>
                              <option value="Nankana Sahib">Nankana Sahib</option>
                              <option value="Okara">Okara</option>
                              <option value="Renala Khurd">Renala Khurd</option>
                              <option value="Pakpattan">Pakpattan</option>
                              <option value="Pattoki">Pattoki</option>
                              <option value="Pir Mahal">Pir Mahal</option>
                              <option value="Qaimpur">Qaimpur</option>
                              <option value="Qila Didar Singh">Qila Didar Singh</option>
                              <option value="Rabwah">Rabwah</option>
                              <option value="Raiwind">Raiwind</option>
                              <option value="Rajanpur">Rajanpur</option>
                              <option value="Rahim Yar Khan">Rahim Yar Khan</option>
                              <option value="Rawalpindi">Rawalpindi</option>
                              <option value="Sadiqabad">Sadiqabad</option>
                              <option value="Safdarabad">Safdarabad</option>
                              <option value="Sahiwal">Sahiwal</option>
                              <option value="Sangla Hill">Sangla Hill</option>
                              <option value="Sarai Alamgir">Sarai Alamgir</option>
                              <option value="Sargodha">Sargodha</option>
                              <option value="Shakargarh">Shakargarh</option>
                              <option value="Sheikhupura">Sheikhupura</option>
                              <option value="Sialkot">Sialkot</option>
                              <option value="Sohawa">Sohawa</option>
                              <option value="Soianwala">Soianwala</option>
                              <option value="Siranwali">Siranwali</option>
                              <option value="Talagang">Talagang</option>
                              <option value="Taxila">Taxila</option>
                              <option value="Toba Tek Singh">Toba Tek Singh</option>
                              <option value="Vehari">Vehari</option>
                              <option value="Wah Cantonment">Wah Cantonment</option>
                              <option value="Wazirabad">Wazirabad</option>
                              <option value="" disabled>
                                Sindh Cities
                              </option>
                              <option value="Badin">Badin</option>
                              <option value="Bhirkan">Bhirkan</option>
                              <option value="Rajo Khanani">Rajo Khanani</option>
                              <option value="Chak">Chak</option>
                              <option value="Dadu">Dadu</option>
                              <option value="Digri">Digri</option>
                              <option value="Diplo">Diplo</option>
                              <option value="Dokri">Dokri</option>
                              <option value="Ghotki">Ghotki</option>
                              <option value="Haala">Haala</option>
                              <option value="Hyderabad">Hyderabad</option>
                              <option value="Islamkot">Islamkot</option>
                              <option value="Jacobabad">Jacobabad</option>
                              <option value="Jamshoro">Jamshoro</option>
                              <option value="Jungshahi">Jungshahi</option>
                              <option value="Kandhkot">Kandhkot</option>
                              <option value="Kandiaro">Kandiaro</option>
                              <option value="Karachi">Karachi</option>
                              <option value="Kashmore">Kashmore</option>
                              <option value="Keti Bandar">Keti Bandar</option>
                              <option value="Khairpur">Khairpur</option>
                              <option value="Kotri">Kotri</option>
                              <option value="Larkana">Larkana</option>
                              <option value="Matiari">Matiari</option>
                              <option value="Mehar">Mehar</option>
                              <option value="Mirpur Khas">Mirpur Khas</option>
                              <option value="Mithani">Mithani</option>
                              <option value="Mithi">Mithi</option>
                              <option value="Mehrabpur">Mehrabpur</option>
                              <option value="Moro">Moro</option>
                              <option value="Nagarparkar">Nagarparkar</option>
                              <option value="Naudero">Naudero</option>
                              <option value="Naushahro Feroze">Naushahro Feroze</option>
                              <option value="Naushara">Naushara</option>
                              <option value="Nawabshah">Nawabshah</option>
                              <option value="Nazimabad">Nazimabad</option>
                              <option value="Qambar">Qambar</option>
                              <option value="Qasimabad">Qasimabad</option>
                              <option value="Ranipur">Ranipur</option>
                              <option value="Ratodero">Ratodero</option>
                              <option value="Rohri">Rohri</option>
                              <option value="Sakrand">Sakrand</option>
                              <option value="Sanghar">Sanghar</option>
                              <option value="Shahbandar">Shahbandar</option>
                              <option value="Shahdadkot">Shahdadkot</option>
                              <option value="Shahdadpur">Shahdadpur</option>
                              <option value="Shahpur Chakar">Shahpur Chakar</option>
                              <option value="Shikarpaur">Shikarpaur</option>
                              <option value="Sukkur">Sukkur</option>
                              <option value="Tangwani">Tangwani</option>
                              <option value="Tando Adam Khan">Tando Adam Khan</option>
                              <option value="Tando Allahyar">Tando Allahyar</option>
                              <option value="Tando Muhammad Khan">Tando Muhammad Khan</option>
                              <option value="Thatta">Thatta</option>
                              <option value="Umerkot">Umerkot</option>
                              <option value="Warah">Warah</option>
                              <option value="" disabled>
                                Khyber Cities
                              </option>
                              <option value="Abbottabad">Abbottabad</option>
                              <option value="Adezai">Adezai</option>
                              <option value="Alpuri">Alpuri</option>
                              <option value="Akora Khattak">Akora Khattak</option>
                              <option value="Ayubia">Ayubia</option>
                              <option value="Banda Daud Shah">Banda Daud Shah</option>
                              <option value="Bannu">Bannu</option>
                              <option value="Batkhela">Batkhela</option>
                              <option value="Battagram">Battagram</option>
                              <option value="Birote">Birote</option>
                              <option value="Chakdara">Chakdara</option>
                              <option value="Charsadda">Charsadda</option>
                              <option value="Chitral">Chitral</option>
                              <option value="Daggar">Daggar</option>
                              <option value="Dargai">Dargai</option>
                              <option value="Darya Khan">Darya Khan</option>
                              <option value="Dera Ismail Khan">Dera Ismail Khan</option>
                              <option value="Doaba">Doaba</option>
                              <option value="Dir">Dir</option>
                              <option value="Drosh">Drosh</option>
                              <option value="Hangu">Hangu</option>
                              <option value="Haripur">Haripur</option>
                              <option value="Karak">Karak</option>
                              <option value="Kohat">Kohat</option>
                              <option value="Kulachi">Kulachi</option>
                              <option value="Lakki Marwat">Lakki Marwat</option>
                              <option value="Latamber">Latamber</option>
                              <option value="Madyan">Madyan</option>
                              <option value="Mansehra">Mansehra</option>
                              <option value="Mardan">Mardan</option>
                              <option value="Mastuj">Mastuj</option>
                              <option value="Mingora">Mingora</option>
                              <option value="Nowshera">Nowshera</option>
                              <option value="Paharpur">Paharpur</option>
                              <option value="Pabbi">Pabbi</option>
                              <option value="Peshawar">Peshawar</option>
                              <option value="Saidu Sharif">Saidu Sharif</option>
                              <option value="Shorkot">Shorkot</option>
                              <option value="Shewa Adda">Shewa Adda</option>
                              <option value="Swabi">Swabi</option>
                              <option value="Swat">Swat</option>
                              <option value="Tangi">Tangi</option>
                              <option value="Tank">Tank</option>
                              <option value="Thall">Thall</option>
                              <option value="Timergara">Timergara</option>
                              <option value="Tordher">Tordher</option>
                              <option value="" disabled>
                                Balochistan Cities
                              </option>
                              <option value="Awaran">Awaran</option>
                              <option value="Barkhan">Barkhan</option>
                              <option value="Chagai">Chagai</option>
                              <option value="Dera Bugti">Dera Bugti</option>
                              <option value="Gwadar">Gwadar</option>
                              <option value="Harnai">Harnai</option>
                              <option value="Jafarabad">Jafarabad</option>
                              <option value="Jhal Magsi">Jhal Magsi</option>
                              <option value="Kacchi">Kacchi</option>
                              <option value="Kalat">Kalat</option>
                              <option value="Kech">Kech</option>
                              <option value="Kharan">Kharan</option>
                              <option value="Khuzdar">Khuzdar</option>
                              <option value="Killa Abdullah">Killa Abdullah</option>
                              <option value="Killa Saifullah">Killa Saifullah</option>
                              <option value="Kohlu">Kohlu</option>
                              <option value="Lasbela">Lasbela</option>
                              <option value="Lehri">Lehri</option>
                              <option value="Loralai">Loralai</option>
                              <option value="Mastung">Mastung</option>
                              <option value="Musakhel">Musakhel</option>
                              <option value="Nasirabad">Nasirabad</option>
                              <option value="Nushki">Nushki</option>
                              <option value="Panjgur">Panjgur</option>
                              <option value="Pishin Valley">Pishin Valley</option>
                              <option value="Quetta">Quetta</option>
                              <option value="Sherani">Sherani</option>
                              <option value="Sibi">Sibi</option>
                              <option value="Sohbatpur">Sohbatpur</option>
                              <option value="Washuk">Washuk</option>
                              <option value="Zhob">Zhob</option>
                              <option value="Ziarat">Ziarat</option>
                            </select>
                            <span className="text-danger">{errors.city?.message}</span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="text-bold">Addresss</label>
                            <input
                              type="text"
                              placeholder="Enter your street address"
                              name="address"
                              value={address}
                              onChange={e => setAddress(e.target.value)}
                            />
                            <span className="text-danger">{errors.address?.message}</span>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-12">
                          <h5>
                            <i className="fas fa-map-marker mr-2"></i>
                            Choose Sample Collection Location
                          </h5>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="text-bold">
                              Global Clinical Care Diagnostic Center Sample Collection Point <RequiredFieldMark />
                            </label>

                            <select name="testCenter" value={testCenter} onChange={e => setTestCenter(e.target.value)}>
                              <option value="ISB01">{getTestCenter("ISB01").address}</option>
                              <option value="SHWL">{getTestCenter("SHWL").address}</option>
                              <option value="BGP">{getTestCenter("BGP").address}</option>
                              <option value="CHP">{getTestCenter("CHP").address}</option>
                              <option value="PC">{getTestCenter("PC").address}</option>
                              <option value="PC-FWO-JV">{getTestCenter("PC-FWO-JV").address}</option>
                              <option value="D-JW-GC">{getTestCenter("D-JW-GC").address}</option>
                              <option value="CDCC">{getTestCenter("CDCC").address}</option>
                              <option value="MG-M">{getTestCenter("MG-M").address}</option>
                              <option value="CGCC">{getTestCenter("CGCC").address}</option>
                              <option value="ZTE">{getTestCenter("ZTE").address}</option>
                              <option value="TGDC">{getTestCenter("TGDC").address}</option>
                            </select>
                            <span className="text-danger">{errors.register?.message}</span>
                          </div>
                        </div>
                      </div>

                      {registrationType === "passenger" && (
                        <>
                          <div className="row mt-4">
                            <div className="col-12">
                              <h5>
                                <i className="fas fa-plane mr-2"></i>
                                Flight Details
                              </h5>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="text-bold">Airline</label>
                                <select defaultValue={defaultAirline.value} name="flightDetails.airline" ref={register}>
                                  {airlines.map(({ value, label }, idx) => (
                                    <option key={`airline-${label}-${idx}`} value={value}>
                                      {label}
                                    </option>
                                  ))}
                                </select>
                                <span className="text-danger">{errors.flightDetails?.airline?.message}</span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Flight No.</label>
                                <input
                                  type="text"
                                  placeholder="Enter your flight number"
                                  name="flightDetails.flightNo"
                                  ref={register}
                                />
                                <span className="text-danger">{errors.flightDetails?.flightNo?.message}</span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Booking Ref./PNR</label>
                                <input
                                  type="text"
                                  placeholder="Enter your ticket/PNRA number"
                                  name="flightDetails.pnr"
                                  ref={register}
                                />
                                <span className="text-danger">{errors.flightDetails?.pnr?.message}</span>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="text-bold">Flight Date & Time</label>
                                <div className="d-flex">
                                  <div className="cms-input date-wrap w-50">
                                    <Controller
                                      control={control}
                                      name="flightDetails.flightDate"
                                      render={({ onChange, value }) => (
                                        <DatePicker
                                          className="w-100"
                                          placeholder="Select Date"
                                          id="date-picker"
                                          onChange={onChange}
                                          value={value}
                                          minDate={new Date()}
                                        />
                                      )}
                                    />
                                    <span className="text-danger">{errors.flightDetails?.flightDate?.message}</span>
                                  </div>
                                  <div className="w-50 ml-2">
                                    <input
                                      type="time"
                                      placeholder="Select time"
                                      name="flightDetails.flightTime"
                                      ref={register}
                                    />
                                    <span className="text-danger">{errors.flightDetails?.flightTime?.message}</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="text-bold">Departure Airport</label>

                                <select
                                  defaultValue="Islamabad International Airport"
                                  name="flightDetails.departureAirport"
                                  ref={register}
                                >
                                  <option value="Islamabad International Airport">
                                    Islamabad International Airport
                                  </option>
                                  <option value="Karachi Jinnah International Airport">
                                    Karachi Jinnah International Airport
                                  </option>
                                  <option value="Lahore Allama Iqbal International Airport">
                                    Lahore Allama Iqbal International Airport
                                  </option>
                                  <option value="Dera Ghazi Khan International Airport">
                                    Dera Ghazi Khan International Airport
                                  </option>
                                  <option value="Faisalabad International Airport">
                                    Faisalabad International Airport
                                  </option>
                                  <option value="Gawadar International Airport">Gawadar International Airport</option>
                                  <option value="Multan International Airport">Multan International Airport</option>
                                  <option value="PAF Base Minhas, Kamra Terminal (Main Tarmac)">
                                    PAF Base Minhas, Kamra Terminal (Main Tarmac)
                                  </option>
                                  <option value="Peshawar Bacha Khan International Airport">
                                    Peshawar Bacha Khan International Airport
                                  </option>
                                  <option value="Quetta International Airport">Quetta International Airport</option>
                                  <option value="Sheikh Zayed International Airport">
                                    Sheikh Zayed International Airport
                                  </option>

                                  <option value="Sialkot International Airport">Sialkot International Airport</option>

                                  <option value="Turbat International Airport">Turbat International Airport</option>
                                  <option value="Bahawalpur Airport">Bahawalpur Airport</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="text-bold">Destination Airport</label>

                                <Controller
                                  control={control}
                                  name="flightDetails.destinationAirport"
                                  render={({ onChange, value }) => (
                                    <Select
                                      label="Select destination airport"
                                      options={airports}
                                      onChange={onChange}
                                      value={value}
                                      styles={customStyles}
                                      className="c-select"
                                      isLoading={isLoading}
                                    />
                                  )}
                                />

                                <span className="text-danger">{errors.flightDetails?.destinationAirport?.message}</span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="row mt-4">
                        <div className="col-12">
                          <h5>
                            <i className="fas fa-money mr-2"></i>
                            Payment
                          </h5>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              <Checkbox
                                size="sm"
                                colorScheme="green"
                                className="c-checkbox"
                                isChecked={isRapidPcrCharges}
                                onChange={() => setIsRapidPcrCharges(!isRapidPcrCharges)}
                              >
                                Rapid PCR Charges
                              </Checkbox>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Charges"
                              name="rapidPcrCharges"
                              ref={register}
                              disabled={!isRapidPcrCharges}
                            />
                            <span className="text-danger">{errors.rapidPcrCharges?.message}</span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              <Checkbox
                                size="sm"
                                colorScheme="green"
                                className="c-checkbox"
                                isChecked={isCharges}
                                onChange={() => setIsCharges(!isCharges)}
                              >
                                PCR Charges
                              </Checkbox>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Charges"
                              name="charges"
                              ref={register}
                              disabled={!isCharges}
                            />
                            <span className="text-danger">{errors.charges?.message}</span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              <Checkbox
                                size="sm"
                                colorScheme="green"
                                className="c-checkbox"
                                isChecked={isIgmCharges}
                                onChange={() => setIsIgmCharges(!isIgmCharges)}
                              >
                                IgM Charges
                              </Checkbox>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Charges"
                              name="igmCharges"
                              ref={register}
                              disabled={!isIgmCharges}
                            />
                            <span className="text-danger">{errors.igmCharges?.message}</span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              <Checkbox
                                size="sm"
                                colorScheme="green"
                                className="c-checkbox"
                                isChecked={isIggCharges}
                                onChange={() => setIsIggCharges(!isIggCharges)}
                              >
                                IgG Charges
                              </Checkbox>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Charges"
                              name="iggCharges"
                              ref={register}
                              disabled={!isIggCharges}
                            />
                            <span className="text-danger">{errors.iggCharges?.message}</span>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              <Checkbox
                                size="sm"
                                colorScheme="green"
                                className="c-checkbox"
                                isChecked={isTravelCharges}
                                onChange={() => setIsTravelCharges(!isTravelCharges)}
                              >
                                Travelling Charges
                              </Checkbox>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Travelling Charges"
                              name="travelCharges"
                              ref={register}
                              disabled={!isTravelCharges}
                            />
                            <span className="text-danger">{errors.travelCharges?.message}</span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Payment Type <RequiredFieldMark />
                            </label>
                            <select name="paymentType" className="mt-1" ref={register}>
                              <option value="">Select Payment Type</option>
                              <option value="cash">Cash</option>
                              <option value="credit">Credit</option>
                            </select>

                            <span className="text-danger">{errors.paymentType?.message}</span>
                          </div>
                        </div>

                        <div className="col-md-3 mt-1">
                          <div className="form-group">
                            <label>Discount</label>
                            <input type="number" placeholder="Enter Charges" name="discount" ref={register} />
                            <span className="text-danger">{errors.discount?.message}</span>
                          </div>
                        </div>
                        <div className="col-md-3 mt-1">
                          <div className="form-group">
                            <label>Net Bill Amount</label>
                            <input
                              type="number"
                              placeholder="Total Charges"
                              name="totalCharges"
                              ref={register}
                              readOnly
                              className="readOnly"
                            />
                            <span className="text-danger">{errors.totalCharges?.message}</span>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <h5>Comments</h5>
                            <input type="text" placeholder="Comments" name="discountComments" ref={register} />
                            <span className="text-danger">{errors.discountComments?.message}</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-4">
                          <div>
                            <h5>Reference</h5>
                          </div>
                          <div>
                            <select
                              name="reference"
                              value={reference}
                              onChange={e => setReference(e.target.value)}
                              // ref={register}
                            >
                              <option value="">Select Reference</option>
                              {references.map(({ value, label }, idx) => (
                                <option key={`reference-${label}-${idx}`} value={value}>
                                  {label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-12">
                          <h5>
                            <i className="fas fa-files-o mr-2"></i>
                            Attachments
                          </h5>

                          <div className="card p-4">
                            <ul className="requirements-list mb-0">
                              <li className="d-flex justify-content-between border-bottom my-3">
                                <div className="d-flex">
                                  <i className="fas fa-paperclip mr-2"></i>
                                  <span className="list-text">Upload copy of registration form</span>
                                </div>

                                <span>
                                  <Controller
                                    control={control}
                                    name="registrationFormUrl"
                                    render={({ onChange }) => <FileUploader onChange={onChange} />}
                                  />

                                  <span className="text-danger">{errors.registrationFormUrl?.message}</span>
                                </span>
                              </li>
                              <li className="d-flex justify-content-between border-bottom my-3">
                                <div className="d-flex">
                                  <i className="fas fa-paperclip mr-2"></i>
                                  <span className="list-text">Upload copy of airline ticket</span>
                                </div>

                                <span>
                                  <Controller
                                    control={control}
                                    name="flightDetails.airlineTicketUrl"
                                    render={({ onChange }) => <FileUploader onChange={onChange} />}
                                  />

                                  <span className="text-danger">{errors.flightDetails?.airlineTicketUrl?.message}</span>
                                </span>
                              </li>

                              <li className="d-flex justify-content-between border-bottom my-3">
                                <div className="d-flex">
                                  <i className="fas fa-paperclip mr-2"></i>
                                  <span className="list-text">Upload copy of passport</span>
                                </div>

                                <span>
                                  <Controller
                                    control={control}
                                    name="flightDetails.passportUrl"
                                    render={({ onChange }) => <FileUploader onChange={onChange} />}
                                  />

                                  <span className="text-danger">{errors.flightDetails?.passportUrl?.message}</span>
                                </span>
                              </li>
                              <li className="d-flex justify-content-between border-bottom my-3">
                                <div className="d-flex">
                                  <i className="fas fa-paperclip mr-2"></i>
                                  <span className="list-text">Upload patient photo</span>
                                </div>

                                <span>
                                  <Controller
                                    control={control}
                                    name="profilePictureUrlFile"
                                    render={({ onChange }) => <FileUploader onChange={onChange} />}
                                  />

                                  <span className="text-danger">{errors?.profilePictureUrlFile?.message}</span>
                                </span>
                              </li>

                              <li className="d-flex justify-content-between border-bottom my-3">
                                <div className="d-flex">
                                  <i className="fas fa-paperclip mr-2"></i>
                                  <span className="list-text">Upload CNIC (For Pakistani Nationals)</span>
                                </div>

                                <span>
                                  <Controller
                                    control={control}
                                    name="otherPhotoUrl"
                                    render={({ onChange }) => <FileUploader onChange={onChange} />}
                                  />

                                  <span className="text-danger">{errors.otherPhotoUrl?.message}</span>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 pt-2">
                <div className="col-12">
                  <button
                    type={"submit"}
                    className="btn btn-secondary btn-fullwidth"
                    style={{ whiteSpace: "nowrap" }}
                    disabled={loading}
                  >
                    <i className="fas fa-arrow-circle-right mr-2"></i>
                    Submit Registration
                    {loading && <Spinner color="default" className="ml-2" size="sm" />}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
        <CheckTestWindowModal openModal={isCheckTestWindowOpen} toggle={toggleCheckTestWindowModal} />
        {openConfirmationModal && (
          <ConfirmationModal
            openModal={openConfirmationModal}
            toggle={toggleConfirmationModal}
            callbackData={registrationData}
            callback={autoCompleteRegistrationsData}
            headerText={"Patient Info Found"}
            bodyText={"Do you like to use patient's previous information?"}
          />
        )}
      </Main>
      <Footer />
    </Fragment>
  )
}
