import React, { useState, useEffect } from "react"
import { useToast } from "@chakra-ui/core"
import { Alert, Spinner } from "reactstrap"

import createReportPDF from "../components/report/create-report"
import { getFromQueryString } from "../utils/common"
import { downloadReport, getRegistrationDetails } from "../services/api"

export default function Report() {
  const toast = useToast()

  const patientId = getFromQueryString("patientId")
  const registrationId = getFromQueryString("registrationId")
  const isDownload = getFromQueryString("download")
  const role = getFromQueryString("embassy")

  const [loading, setLoading] = useState(true)

  const fetchPatientDetails = async () => {
    try {
      downloadReport(registrationId)
      const res = await getRegistrationDetails(registrationId)
      const result = res.data

      if (result) {
        const { patient, ...rest } = result
        const registration = rest
        await createReportPDF({ patient, registration, isDownload, role })
      }

      if (!result) {
        toast({
          title: "Whooops",
          description: "Probably you got the wrong address.",
          status: "warning",
          duration: 10000,
        })
      }
    } catch (error) {
      toast({
        title: "There was an error generating report.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const loader = () => (
    <div className="container py-5">
      <div className="row">
        <div className="col-12 text-center">
          <Spinner color="primary" />
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    if (patientId && registrationId) {
      fetchPatientDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, registrationId])

  if (loading && patientId && registrationId) {
    return loader()
  }

  if (!loading && patientId && registrationId) {
    return (
      <Alert>
        Report generated successfully for Lab #: {patientId} & MR #: {registrationId}
      </Alert>
    )
  }

  if (!loading && (!patientId || !registrationId)) {
    return <Alert>Lab# or MR# seems to be missing</Alert>
  }

  return <div></div>
}
