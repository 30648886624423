import classNames from "classnames"
import React from "react"
import "./Badge.scss"

const Badge = ({ type, label }) => {
  return (
    <div
      className={classNames("badge", {
        badgeSuccess: type === "success",
        badgeWarning: type === "warning",
        badgeDanger: type === "danger",
      })}
    >
      {label}
    </div>
  )
}

export default Badge
