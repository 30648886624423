import { Fragment } from "react"

import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import Footer from "../components/layouts/footer/Footer"
import { getTestCenter } from "../utils/common"

export default function BookingInformation() {
  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="text-white mb-0">
                    <i className="fas fa-phone fa-rotate-90 mr-4"></i> Book an Appointment
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="container airline-information">
            <div className="row">
              <div className="col-12">
                <p className="text-secondary lead pt-5">
                  Book an appointment by visiting or contacting the Sample Collection Point of your convenience given
                  below:
                </p>

                <div className="row">
                  <div className="col-12">
                    <h6 className="mb-0 title">Islamabad</h6>
                    <p className="mb-0">Global Clinical Care Diagnostic Center Sample Collection Points</p>
                    <div className="table-responsive pt-1">
                      <table className="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "300px" }}>
                              Address
                            </th>
                            <th scope="col" style={{ width: "50px" }}>
                              Location
                            </th>
                            <th scope="col" style={{ width: "120px" }}>
                              Phone No.
                            </th>
                            <th scope="col" style={{ width: "100px" }}>
                              Timing
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td className="whitespace-pre">{getTestCenter("ISB01").address}</td>
                            <td>
                              <a
                                href="https://www.google.com/maps/place/Khalid+Plaza/@33.726106,73.0857103,17z/data=!3m1!4b1!4m5!3m4!1s0x38dfc07ff5d1b54f:0xb5d9af2cee162005!8m2!3d33.726106!4d73.087899"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fa fa-map-marker fa-2x"></i>
                              </a>
                            </td>
                            <td>
                              <a href="tel:0512120802">051 2120802</a>
                              <br />
                              <a href="tel:0512120803">051 2120803</a>
                              <br />
                            </td>

                            <td>10am to 8pm</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-12">
                    <h5>
                      <u>Instructions for Airline Passengers</u>
                    </h5>
                    <ol className="requirements-list">
                      <li>
                        <span className="align-items-start d-flex list-icon">
                          <span className="list-text">
                            Please consult with your airline / travel agent to determine your time window in which
                            sample can be given to Global Clinical Care Diagnostic Center.
                          </span>
                        </span>
                      </li>
                      <li>
                        <span className="align-items-start d-flex list-icon">
                          <span className="list-text">
                            Global Clinical Care Diagnostic Center will be not be responsible if airline is not
                            accepting the report due to the expiry of time window.
                          </span>
                        </span>
                      </li>
                      <li>
                        <span className="align-items-start d-flex list-icon">
                          <span className="list-text">
                            Reporting time for Covid RT-PCR is upto 48hrs. Please observe this time limit to get your
                            reports in hand before your departure.
                          </span>
                        </span>
                      </li>
                    </ol>

                    <h6>
                      <u>Please bring the following documents at the time of test:</u>
                    </h6>
                    <ol className="requirements-list">
                      <li>
                        <span className="align-items-start d-flex list-icon">
                          <span className="list-text">Valid Passport & photocopy</span>
                        </span>
                      </li>

                      <li>
                        <span className="align-items-start d-flex list-icon">
                          <span className="list-text">Valid CNIC & photocopy</span>
                        </span>
                      </li>

                      <li>
                        <span className="align-items-start d-flex list-icon">
                          <span className="list-text">Airline Ticket</span>
                        </span>
                      </li>
                    </ol>

                    <h5 className="text-right">ہدایات برائے ایئر لائن مسافر</h5>

                    <ol
                      className="requirements-list"
                      style={{
                        listStyleType: "arabic-indic",
                        direction: "rtl",
                        textAlign: "right",
                      }}
                    >
                      <li>
                        <span className="align-items-start d-flex list-icon">
                          <span className="list-text">
                            برائے مہربانی، Global Clinical Care ڈائگناسٹک سنٹر کو سیمپل دینے کی ٹائم ونڈو جاننے کے لیے
                            اپنی ایئرلائن یا ٹریول ایجنٹ سے مشورہ کریں۔
                          </span>
                        </span>
                      </li>
                      <li>
                        <span className="align-items-start d-flex list-icon">
                          <span className="list-text">
                            اگر ایئرلائن ٹائم ونڈو کی ایکسپائری کی بنیاد پر آپ کی رپورٹ مسترد کر دیتی ہے تو Global
                            Clinical Care ڈائگناسٹک سنٹر اس کا ذمہ دار نہ ہو گا۔
                          </span>
                        </span>
                      </li>
                      <li>
                        <span className="align-items-start d-flex list-icon">
                          <span className="list-text">
                            کوویڈ آر ٹی-پی سی آر کے رزلٹ میں 48 گھنٹے تک کا ٹائم لگ سکتا ہے۔ براہ کرم اپنی روانگی سے قبل
                            اپنی رپورٹیں حاصل کرنے کے لئے یہ مد نظر رکھیں۔
                          </span>
                        </span>
                      </li>
                    </ol>
                    <h5 className="text-right">:براہ کرم ٹیسٹ کے وقت درج ذیل دستاویزات لائیں</h5>
                    <ol
                      className="requirements-list"
                      style={{
                        listStyleType: "arabic-indic",
                        direction: "rtl",
                        textAlign: "right",
                      }}
                    >
                      <li>
                        <span className="align-items-start d-flex list-icon">
                          <span className="list-text">درست پاسپورٹ اور فوٹو کاپی</span>
                        </span>
                      </li>
                      <li>
                        <span className="align-items-start d-flex list-icon">
                          <span className="list-text">درست CNIC اور فوٹو کاپی</span>
                        </span>
                      </li>
                      <li>
                        <span className="align-items-start d-flex list-icon">
                          <span className="list-text">ایئر لائن ٹکٹ</span>
                        </span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Main>
      <Footer />
    </Fragment>
  )
}
