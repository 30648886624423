import React, { Fragment, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Alert, Spinner } from "reactstrap"
import { Checkbox } from "@chakra-ui/core"
import Pagination from "react-js-pagination"

import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import Footer from "../components/layouts/footer/Footer"
import ViewReportModal from "../components/modals/ViewReportModal"
import { getPatientsWithFilters } from "../services/api"
import dayjs from "dayjs"
import DatePicker from "../components/common/DatePicker"
import PatientsFiltersBar from "../components/common/PatientsFiltersBar"
import MultiSelection from "../components/common/MultiSelection"
import FullScreenLoader from "../components/loaders/FullScreenLoader"
import { calculateAge, getPassport } from "../utils/common"
import { handleMultiCheckboxSelection } from "../utils/mutiple-checkbox-selection/common"
import ExportCSVDataModal from "../components/modals/ExportCSVDataModal"

const TGDC = () => {
  const [loading, setLoading] = useState(false)
  const [multiLoading, setMultiLoading] = useState(false)
  const [registrations, setRegistrations] = useState([])
  const [limit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [filter, setFilter] = useState({})

  const [selectedReport, setSelectedReport] = useState(null)
  const [openViewReportModal, setOpenViewReportModal] = useState(false)
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  })
  const [openCSVModal, setOpenCSVModal] = useState(false)
  const [reportIds, setReportIds] = useState([])
  const [isAllReportIdSelected, setIsAllReportIdSelected] = useState(false)
  const [openMultiSelectionToolbar, setOpenMultiSelectionToolbar] = useState(false)

  useEffect(() => {
    initSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getConvertedDateWithStartOfDay = date => {
    if (date) {
      return dayjs(date).startOf("day").toISOString()
    }
    return null
  }

  const getConvertedDateWithEndOfDay = date => {
    if (date) {
      return dayjs(date).endOf("day").toISOString()
    }
    return null
  }

  const getFilterDateRange = dateRangeData => {
    let dateRange = {}
    const startDate = getConvertedDateWithStartOfDay(dateRangeData.startDate)
    const endDate = getConvertedDateWithEndOfDay(dateRangeData.endDate)
    if (startDate) {
      dateRange["startDate"] = startDate
    }
    if (endDate) {
      dateRange["endDate"] = endDate
    }
    return dateRange
  }

  const initSearch = async (
    updatedOffset = offset,
    pageNo = activePage,
    filters = { ...getFilterDateRange(dateRange) }
  ) => {
    if (updatedOffset >= 0) {
      try {
        setLoading(true)
        const res = await getPatientsWithFilters({
          limit,
          offset: updatedOffset,
          filters: { ...filters, reference: "TGDC" },
        })

        const result = res?.data?.registrations
        if (res.data.registrations) {
          setRegistrations(result)
          const reportIds = getRegistrationIds(result?.data)
          setReportIds(reportIds)
          setOffset(updatedOffset)
          setActivePage(pageNo)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const getRegistrationIds = registrations => {
    return registrations.map(({ registrationId }) => ({ registrationId }))
  }

  const getResult = registration => {
    const status = registration?.reportVerifiedAt
      ? registration?.result
        ? registration.result === "detected"
          ? "Detected"
          : "Not Detected"
        : "-"
      : "-"
    const color = registration?.reportVerifiedAt
      ? registration?.result
        ? registration.result === "detected"
          ? "text-white bg-danger"
          : "text-white bg-success"
        : ""
      : ""
    return { status, color }
  }

  const goToPage = pageNo => {
    const updatedOffset = (pageNo - 1) * limit
    const filters = {
      ...getFilterDateRange(dateRange),
      ...filter,
    }
    initSearch(updatedOffset, pageNo, filters)
    reset()
  }

  const toggleViewReportModal = () => {
    setOpenViewReportModal(!openViewReportModal)
  }

  const toggleMultiSelectionToolbar = () => {
    setOpenMultiSelectionToolbar(!openMultiSelectionToolbar)
  }

  const toggleOpenCSVModal = () => {
    setOpenCSVModal(!openCSVModal)
  }

  const handleSearchDetails = ({ registrationId }) => {
    setSelectedReport({
      registrationId,
    })
    setOpenViewReportModal(true)
  }

  const onClearDateRange = () => {
    const updatedDateRange = { startDate: null, endDate: null }
    setDateRange(updatedDateRange)
    const filters = {
      ...getFilterDateRange(updatedDateRange),
      ...filter,
    }
    initSearch(0, 1, filters)
  }

  const onChangeDateRange = (date, key) => {
    const updatedDateRange = { ...dateRange, [key]: date }
    setDateRange(updatedDateRange)
    const filters = {
      ...getFilterDateRange(updatedDateRange),
      ...filter,
    }
    initSearch(0, 1, filters)
  }

  const onFilterApply = (filter, query) => {
    let filters = {
      ...getFilterDateRange(dateRange),
    }

    if (query) {
      filters[filter] = query
      setFilter({ [filter]: query })
    } else {
      setFilter({})
    }

    initSearch(0, 1, filters)
  }

  const handleOnChange = e => {
    const { name, checked } = e.target

    const { updatedReportIds, isAllChecked, openMultiSelectionToolbar } = handleMultiCheckboxSelection({
      name,
      checked,
      reportIds,
    })

    setReportIds(updatedReportIds)
    setIsAllReportIdSelected(isAllChecked)
    setOpenMultiSelectionToolbar(openMultiSelectionToolbar)
  }

  const reset = () => {
    const updatedReportIds = reportIds?.map(registration => ({ ...registration, isChecked: false }))
    setReportIds(updatedReportIds)
    setIsAllReportIdSelected(false)
  }

  const clearIcon = <i className="fas fa-times" />
  const client = "TGDC"

  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay-bgp"></div>
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex">
                  <h3 className="mb-0 ml-3 align-self-center" style={{ fontSize: "1.6em" }}>
                    Yangtze Three Gorge Technology And Economy Development Co. Ltd (Main)
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <PatientsFiltersBar loading={loading} onFilterApply={onFilterApply} />

          <div className="container-fluid mt-5">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="card p-4">
                      <div className="row cms-contact-form">
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group mr-2">
                                <label className="text-bold">Start Date</label>
                                <div className="d-flex">
                                  <div className="cms-input date-wrap w-100">
                                    <DatePicker
                                      placeholder="Select Date"
                                      id="start-date-picker"
                                      onChange={date => onChangeDateRange(date, "startDate")}
                                      value={dateRange.startDate}
                                      className="w-100"
                                      clearIcon={clearIcon}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="text-bold">End Date</label>
                                <div className="d-flex">
                                  <div className="cms-input date-wrap w-100">
                                    <DatePicker
                                      placeholder="Select Date"
                                      id="end-date-picker"
                                      onChange={date => onChangeDateRange(date, "endDate")}
                                      value={dateRange.endDate}
                                      className="w-100"
                                      clearIcon={clearIcon}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <button onClick={onClearDateRange} className="btn btn-secondary btn-clear w-100">
                                Clear
                              </button>
                            </div>

                            <div className="form-group c-spinner text-center ml-2">
                              {loading && <Spinner color="primary" />}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 mb-3">
                          <button onClick={toggleOpenCSVModal} className="btn btn-secondary btn-export-csv w-100">
                            <i className="fas fa-download mr-2"></i>
                            Export CSV
                          </button>
                        </div>
                      </div>
                      {!loading && registrations?.data?.length === 0 && (
                        <Alert color="warning" className="mb-0">
                          No results found.
                        </Alert>
                      )}

                      {openMultiSelectionToolbar && (
                        <MultiSelection
                          toggle={toggleMultiSelectionToolbar}
                          selectedReportIds={reportIds.filter(reportId => reportId?.isChecked === true)}
                          reset={reset}
                          isMultiLoading={multiLoading}
                          setIsMultiLoading={setMultiLoading}
                          updateData={() => initSearch(offset, activePage, { ...getFilterDateRange(dateRange) })}
                        />
                      )}

                      {registrations?.data?.length > 0 && (
                        <div className="row c-table">
                          <div className="col-12">
                            <div className="table-responsive table-hover">
                              <table className="table">
                                <thead className="table-header-bg">
                                  <tr>
                                    <th scope="col">
                                      <label>
                                        <Checkbox
                                          size="sm"
                                          colorScheme="gray"
                                          className="c-checkbox mt-2"
                                          name="selectAll"
                                          isChecked={isAllReportIdSelected}
                                          onChange={handleOnChange}
                                        ></Checkbox>
                                      </label>
                                    </th>
                                    <th scope="col">Sr#</th>
                                    <th scope="col">MR#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Age</th>
                                    <th scope="col">Passport#</th>
                                    <th scope="col">CNIC</th>
                                    <th scope="col">Contact#</th>
                                    <th scope="col">Flight#</th>
                                    <th scope="col">Sample Date/Time</th>
                                    <th scope="col" className="text-center">
                                      Result
                                    </th>
                                    <th scope="col">Type</th>
                                    <th scope="col">View Report</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {registrations.data.map((registration, idx) => (
                                    <tr key={registration.registrationId}>
                                      <td>
                                        <label>
                                          <Checkbox
                                            size="sm"
                                            colorScheme="gray"
                                            className="c-checkbox mt-2"
                                            name={registration?.registrationId}
                                            isChecked={reportIds[idx]?.isChecked}
                                            key={`checkbox-${registration?.registrationId}-${idx}`}
                                            onChange={handleOnChange}
                                          ></Checkbox>
                                        </label>
                                      </td>
                                      <td>{idx + 1 + offset}</td>
                                      <td>{registration.registrationId}</td>
                                      <td>{registration.patient?.fullname}</td>
                                      <td>{calculateAge(registration.patient?.dob)}</td>
                                      <td>{getPassport(registration) || "-"}</td>
                                      <td>{registration.patient?.cnic || "-"}</td>
                                      <td>{registration.patient?.phone || "-"}</td>
                                      <td>{registration?.flightNo || "-"}</td>

                                      <td>{dayjs(registration.createdAt).format("DD/MM/YYYY hh:mm A")}</td>
                                      <td className={`text-center ${getResult(registration).color}`}>
                                        {getResult(registration).status}
                                      </td>
                                      <td className="text-capitalize">{registration.registrationType}</td>
                                      <td className="text-center">
                                        {registration.reportVerifiedAt ? (
                                          <Link
                                            className="text-link"
                                            to="#"
                                            onClick={() => {
                                              handleSearchDetails({
                                                registrationId: registration?.registrationId,
                                              })
                                            }}
                                          >
                                            <i className="fas fa-eye"></i>
                                          </Link>
                                        ) : (
                                          <span>Pending</span>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <Pagination
                                  activePage={activePage}
                                  itemsCountPerPage={limit}
                                  totalItemsCount={registrations.total}
                                  pageRangeDisplayed={5}
                                  onChange={goToPage}
                                  itemClass="page-item"
                                  linkClass="page-link"
                                />
                              </div>
                              <div className="col-md-6 pt-2">
                                <span className="pull-right text-dark">
                                  Showing:{" "}
                                  {`${offset + 1} to ${Math.min(limit + offset, registrations.total)} of ${
                                    registrations.total
                                  } records`}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {multiLoading && <FullScreenLoader />}
                    </div>
                  </div>
                </div>

                {selectedReport && (
                  <ViewReportModal
                    openModal={openViewReportModal}
                    toggle={toggleViewReportModal}
                    registrationId={selectedReport.registrationId}
                    patientId={selectedReport.patientId}
                  />
                )}

                {openCSVModal && (
                  <ExportCSVDataModal openModal={openCSVModal} toggle={toggleOpenCSVModal} client={client} />
                )}

                {/* To push footer a bit low */}
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </section>
      </Main>
      <Footer />
    </Fragment>
  )
}

export default TGDC
