import * as yup from "yup"
import dayjs from "dayjs"
import { useState, useEffect } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import { Modal, ModalHeader, ModalBody, Alert, Spinner, Progress } from "reactstrap"

import RequiredFieldMark from "../common/RequiredFieldMark"
import DatePicker from "../common/DatePicker"
import { calculateRisk } from "../../utils/common"
import "./CheckTestWindowModal.css"

const schema = yup.object().shape({
  flightDate: yup.date().required("Flight date is required"),
  flightTime: yup.string().required("Flight time is required"),
})

export default function CheckTestWindowModal(props) {
  const { openModal, toggle } = props
  const [flightPriority, setFlightPriority] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const { register, handleSubmit, control, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      flightDate: new Date(),
      flightTime: dayjs().format("HH:mm"),
    },
  })

  useEffect(() => {
    if (openModal) {
      setFlightPriority(null)
      setIsLoading(false)
      reset({
        flightDate: new Date(),
        flightTime: dayjs().format("HH:mm"),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  const onSubmit = data => {
    setIsLoading(true)
    const { flightDate, flightTime } = data
    setFlightPriority(calculateRisk(flightDate, flightTime))
    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true} size="lg">
      <ModalHeader toggle={toggle}>Check Test Window</ModalHeader>
      <ModalBody>
        <form className="cms-contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-9 pr-0">
              <div className="form-group">
                <label className="text-bold">
                  Flight Date &amp; Time <RequiredFieldMark />
                </label>
                <div className="d-flex">
                  <div className="cms-input date-wrap w-100">
                    <Controller
                      control={control}
                      name="flightDate"
                      render={({ onChange, value }) => (
                        <DatePicker
                          placeholder="Select Date"
                          id="date-picker"
                          onChange={onChange}
                          value={value}
                          minDate={new Date()}
                          className="w-100"
                        />
                      )}
                    />
                    <span className="text-danger">{errors.flightDate?.message}</span>
                  </div>
                  <div className="w-50 ml-2">
                    <input type="time" placeholder="Select time" name="flightTime" ref={register} />
                    <span className="text-danger">{errors.flightTime?.message}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <button
                type="submit"
                className="btn btn-secondary w-100"
                disabled={isLoading}
                style={{ height: "60px", marginTop: "30px" }}
              >
                Calculate
                {isLoading && <Spinner color="default" className="ml-2" size="sm" />}
              </button>
            </div>
          </div>

          {flightPriority && (
            <div>
              <div>
                <Progress multi>
                  <Progress bar style={{ backgroundColor: "#E5E5E5" }} value="10" />
                  <Progress bar color="success" value="20" />
                  <Progress bar color="success" value="20" />
                  <Progress bar color="warning" value="20" />
                  <Progress bar color="danger" value="20" />
                  <Progress bar style={{ backgroundColor: "#E5E5E5" }} value="10" />
                </Progress>

                <i className={`fas fa-arrow-up test-window-indicator fa-2x fa-bounce text-body ${flightPriority}`}></i>
              </div>

              {flightPriority === "low" && <Alert color="danger">You are too early for the test.</Alert>}

              {flightPriority === "high" && <Alert color="danger">You are too late for the test</Alert>}
            </div>
          )}
        </form>
      </ModalBody>
    </Modal>
  )
}
