import React, { Fragment, useEffect } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { ThemeProvider } from "@chakra-ui/core"
import { SWRConfig } from "swr"

import Landing from "./pages/Landing"
import Routes from "./routes/Routes"
import BookingInformation from "./pages/BookingInformation"
import { useOvermind } from "./overmind"
import Login from "./pages/Login"
import Patient from "./pages/Patient"
import Report from "./pages/Report"
import { AllRoutesMap } from "./routes/RoutesConfig"
import SWRGlobalConfig from "./services/swrHooks"

function App() {
  const { actions } = useOvermind()

  useEffect(() => {
    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) {
        actions.auth.logout()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SWRConfig value={SWRGlobalConfig}>
      <ThemeProvider>
        <Router>
          <Fragment>
            <Switch>
              <Route exact path={AllRoutesMap.landing} component={Landing} />
              <Route exact path={AllRoutesMap.bookingInformation} component={BookingInformation} />
              <Route exact path={AllRoutesMap.login} component={Login} />
              <Route exact path={AllRoutesMap.patient} component={Patient} />
              <Route exact path={AllRoutesMap.report} component={Report} />

              <Route component={Routes} />
            </Switch>
          </Fragment>
        </Router>
      </ThemeProvider>
    </SWRConfig>
  )
}

export default App
