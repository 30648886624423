import React, { Fragment, useState, useEffect, useRef } from "react"
import dayjs from "dayjs"
import InputMask from "react-input-mask"
import Pagination from "react-js-pagination"

import "./Staff.css"

import { Link, useHistory } from "react-router-dom"
import { Alert, Spinner, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown, Button } from "reactstrap"
import { Checkbox, useToast } from "@chakra-ui/core"

import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import Footer from "../components/layouts/footer/Footer"
import ViewReportModal from "../components/modals/ViewReportModal"
import CheckTestWindowModal from "../components/modals/CheckTestWindowModal"
import EditRegistrationModal from "../components/modals/EditRegistrationModal"
import ViewPatientHistoyModal from "../components/modals/ViewPatientHistoryModal"
import GenerateInvoiceModal from "../components/modals/GenerateInvoiceModal"
import ReportVerificationModal from "../components/modals/ReportVerificationModal"
import createRegistrationPDF from "../components/pdf-reports/create-registration"
import createInvoicePDF from "../components/invoice/create-invoice"
import createGeneralConsentPDF from "../components/consent/create-general-consent"
import createPassengerConsentPDF from "../components/consent/create-passenger-consent"
import createAllFormsGeneralPDF from "../components/combined-forms/create-combined-forms-general"
import createAllFormsPassengerPDF from "../components/combined-forms/create-combined-forms-passenger"
import { getPatientsWithFilters, searchPatient } from "../services/api"
import { AllRoutesMap } from "../routes/RoutesConfig"
import { getRegistrationDetails } from "../services/api"
import DatePicker from "../components/common/DatePicker"
import Select from "react-select"
import {
  embassies,
  getEmbassy,
  getReference,
  getPassport,
  getTNoPrefix,
  registrationTypes,
  getPanel,
} from "../utils/common"
import { useState as useOvermindState } from "../overmind"
import { useDebounce } from "../hooks/UseDebounce"
import MultiSelection from "../components/common/MultiSelection"
import FullScreenLoader from "../components/loaders/FullScreenLoader"
import { handleMultiCheckboxSelection } from "../utils/mutiple-checkbox-selection/common"

const customStyles = {
  control: provided => {
    const background = "#f1f5fe"
    const border = "1px solid #f1f5fe"
    return { ...provided, background, border }
  },
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "#07152a",
  }),
}

const clearIcon = <i className="fas fa-times" />

const scrollTo = ref => window.scrollTo({ behavior: "smooth", top: ref?.current?.offsetTop })

const getConvertedDateWithStartOfDay = date => {
  if (date) {
    return dayjs(date).startOf("day").toISOString()
  }
  return undefined
}

const getConvertedDateWithEndOfDay = date => {
  if (date) {
    return dayjs(date).endOf("day").toISOString()
  }
  return undefined
}

const getFilterDateRange = dateRangeData => {
  let dateRange = {}
  const startDate = getConvertedDateWithStartOfDay(dateRangeData.startDate)
  const endDate = getConvertedDateWithEndOfDay(dateRangeData.endDate)
  if (startDate) {
    dateRange["startDate"] = startDate
  }
  if (endDate) {
    dateRange["endDate"] = endDate
  }
  return dateRange
}

const Staff = () => {
  const { permissions, user } = useOvermindState().auth

  const [loading, setLoading] = useState(false)
  const [multiLoading, setMultiLoading] = useState(false)
  const [registrations, setRegistrations] = useState([])
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const searchRef = useRef(null)
  const history = useHistory()

  const [loadingPatient, setLoadingPatient] = useState(false)
  const [query, setQuery] = useState("")
  const [filter, setFilter] = useState("passport")
  const [patient, setPatient] = useState(null)
  const [selectedRegistration, setSelectedRegistration] = useState(null)
  const [selectedReport, setSelectedReport] = useState(null)
  const [openViewReportModal, setOpenViewReportModal] = useState(false)
  const [selectedRegistrationData, setSelectedRegistrationData] = useState(null)
  const [openEditRegistrationModal, setOpenEditRegistrationModal] = useState(false)
  const [openGenerateInvoiceModal, setOpenGenerateInvoiceModal] = useState(false)
  const [isCheckTestWindowOpen, setIsCheckTestWindowOpen] = useState(false)
  const [isPatientDetails, setIsPatientDetails] = useState(false)
  const [loadingPrint, setLoadingPrint] = useState(false)
  const [printDropdownOpen, setPrintDropdownOpen] = useState(false)
  const [multiRegisterDropdownOpen, setMultiRegisterDropdownOpen] = useState(false)

  const [reportIds, setReportIds] = useState([])
  const [isAllReportIdSelected, setIsAllReportIdSelected] = useState(false)
  const [openMultiSelectionToolbar, setOpenMultiSelectionToolbar] = useState(false)
  const [openReportModal, setOpenReportModal] = useState(false)

  const [openPatientHistoryModal, setOpenPatientHistoryModal] = useState(false)
  const [passport, setPassport] = useState(null)
  const [cnic, setCnic] = useState(null)
  const [targetPage, setTargetPage] = useState("")
  const [sortOrderType, setSortOrderType] = useState("")
  const [clickCounter, setClickCounter] = useState(0)

  const isEditable = user.role === "admin" || permissions.includes("editRegistration")
  const isVerifiable = permissions.includes("verifyResult")
  const isPermittedToInvoice = permissions.includes("bulkInvoicing")

  const toast = useToast()
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  })
  const [filters, setFilters] = useState({ ...getFilterDateRange(dateRange) })
  const debouncedTargetPageValue = useDebounce(targetPage)
  const debouncedLimitRecordValue = useDebounce(limit)
  const count = Math.ceil(registrations.total / limit)

  useEffect(() => {
    if (debouncedTargetPageValue && debouncedTargetPageValue !== activePage && !loading) {
      goToPage(Number(debouncedTargetPageValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTargetPageValue])

  useEffect(() => {
    if (debouncedLimitRecordValue && !loading) {
      initSearch(0, 1, filters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLimitRecordValue])

  useEffect(() => {
    initSearch(0, 1, filters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onKeyUp = event => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  const toggleCheckTestWindowModal = () => setIsCheckTestWindowOpen(!isCheckTestWindowOpen)

  const initSearch = async (updatedOffset = offset, pageNo = activePage, apiFilters) => {
    const updatedFilters = { ...apiFilters }
    if (apiFilters) {
      if (query) {
        if (!(filter in updatedFilters)) {
          updatedFilters[filter] = query
        }
      } else {
        delete updatedFilters[filter]
      }
    }
    setFilters(updatedFilters)
    if (updatedOffset >= 0) {
      try {
        setLoading(true)

        const res = await getPatientsWithFilters({
          limit,
          offset: updatedOffset,
          filters: apiFilters ? updatedFilters : {},
        })

        const result = res?.data?.registrations
        if (res.data.registrations) {
          setRegistrations(result)
          const reportIds = getRegistrationIds(result?.data)
          setReportIds(reportIds)
          setOffset(updatedOffset)
          setActivePage(pageNo)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const getRegistrationIds = registrations => {
    return registrations.map(({ registrationId }) => ({ registrationId }))
  }

  const getReportInformation = registration => {
    if (registration.reportVerifiedAt) {
      return "Report Uploaded"
    } else if (registration.sampleReceivedAt) {
      return "Lab Sample Received"
    } else {
      return "Sample Taken"
    }
  }

  const goToPage = pageNo => {
    const updatedOffset = (pageNo - 1) * limit
    initSearch(updatedOffset, pageNo, filters)
    setTargetPage(pageNo)
    reset()
  }

  const toggleViewReportModal = () => setOpenViewReportModal(!openViewReportModal)
  const toggleMultiSelectionToolbar = () => setOpenMultiSelectionToolbar(!openMultiSelectionToolbar)
  const toggleEditRegistrationModal = () => setOpenEditRegistrationModal(!openEditRegistrationModal)
  const toggleOpenPatientHistoryModal = () => setOpenPatientHistoryModal(!openPatientHistoryModal)
  const toggleOpenGenerateInvoiceModal = () => setOpenGenerateInvoiceModal(!openGenerateInvoiceModal)

  const handleSearchDetails = ({ registrationId }) => {
    setSelectedReport({
      registrationId,
    })
    setOpenViewReportModal(true)
  }

  const getSelectedRegistration = registrationId => {
    const selectedRegistrationData = registrations.data.find(registration => {
      return registration.registrationId === registrationId
    })
    setSelectedRegistrationData(selectedRegistrationData)
    toggleEditRegistrationModal()
  }

  const getInfo = async registrationId => {
    try {
      setPatient(null)
      setLoadingPatient(true)
      scrollTo(searchRef)
      const res = await searchPatient({
        filter: "registrationId",
        query: registrationId,
      })
      setPatient(res?.data?.registrations)
      if (res?.data?.registrations?.length > 0) {
        setSelectedRegistration(res?.data?.registrations[0])
      }
      showPatientDetails()
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingPatient(false)
    }
  }

  const handleSearch = () => {
    if (query) {
      const updatedFilters = {
        [filter]: query,
      }
      initSearch(0, 1, updatedFilters)
    } else {
      const updatedFilters = { ...filters }
      delete updatedFilters[filter]
      initSearch(0, 1, updatedFilters)
    }
  }

  const updateRegistrations = ({ patientData, registrationData }) => {
    const updatedRegistrations = registrations.data.map(registration => {
      if (registration.registrationId === registrationData.registrationId) {
        const updatedPatient = {
          patient: { ...registration.patient, ...patientData },
        }
        const updatedRegistration = {
          ...registrationData,
          ...updatedPatient,
        }
        return updatedRegistration
      }
      return registration
    })
    setRegistrations({ ...registrations, data: updatedRegistrations })

    if (selectedRegistration?.registrationId === registrationData.registrationId) {
      setSelectedRegistration({
        ...registrationData,
      })
    }
  }

  const hidePatientDetails = () => {
    setIsPatientDetails(false)
  }

  const showPatientDetails = () => {
    setIsPatientDetails(true)
  }

  const toggleReportModal = () => setOpenReportModal(!openReportModal)
  const togglePrintDropdown = () => setPrintDropdownOpen(prevState => !prevState)
  const toggleMultiRegisterDropdown = () => setMultiRegisterDropdownOpen(prevState => !prevState)

  const handlePrintConsent = async () => {
    try {
      setLoadingPrint(true)
      const res = await getRegistrationDetails(selectedRegistration.registrationId)
      const data = res.data

      if (data) {
        const { patient, ...rest } = data
        const registration = rest
        await downloadConsentForm({ patient, registration })
      }
    } catch (error) {
      toast({
        title: "There was an error generating consent form.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setLoadingPrint(false)
    }
  }

  const downloadConsentForm = async ({ patient, registration }) => {
    try {
      if (registration.registrationType === "general") {
        await createGeneralConsentPDF({ patient, registration })
      } else if (registration.registrationType === "passenger") {
        await createPassengerConsentPDF({ patient, registration })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handlePrintAllForms = async () => {
    try {
      setLoadingPrint(true)
      const res = await getRegistrationDetails(selectedRegistration.registrationId)
      const data = res.data

      if (data) {
        const { patient, ...rest } = data
        const registration = rest
        await createAllFormsPDF({ patient, registration })
      }
    } catch (error) {
      toast({
        title: "There was an error generating all forms.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setLoadingPrint(false)
    }
  }

  const createAllFormsPDF = async ({ patient, registration }) => {
    try {
      if (registration.registrationType === "general") {
        await createAllFormsGeneralPDF({ patient, registration })
      } else if (registration.registrationType === "passenger") {
        await createAllFormsPassengerPDF({ patient, registration })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handlePrintRegistration = async () => {
    try {
      setLoadingPrint(true)
      const res = await getRegistrationDetails(selectedRegistration.registrationId)
      const data = res.data

      if (data) {
        const { patient, ...rest } = data
        const registration = rest
        await createRegistrationPDF({ patient, registration })
      }
    } catch (error) {
      toast({
        title: "There was an error generating registration form.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setLoadingPrint(false)
    }
  }

  const handlePrintInvoice = async () => {
    try {
      setLoadingPrint(true)
      const res = await getRegistrationDetails(selectedRegistration.registrationId)
      const data = res.data

      if (data) {
        const { patient, ...rest } = data
        const registration = rest
        await createInvoicePDF({ patient, registration })
      }
    } catch (error) {
      toast({
        title: "There was an error generating invoice form.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setLoadingPrint(false)
    }
  }

  const onChangeDateRange = (date, key) => {
    const updatedDateRange = { ...dateRange, [key]: date }
    setDateRange(updatedDateRange)
    const originalFilters = { ...filters }
    delete originalFilters.startDate
    delete originalFilters.endDate

    const updatedFilters = {
      ...originalFilters,
      ...getFilterDateRange(updatedDateRange),
    }

    initSearch(0, 1, updatedFilters)
  }

  const onChangeType = selected => {
    if (selected) {
      const updatedFilters = {
        ...filters,
        registrationType: selected.value,
      }
      setFilters(updatedFilters)
      initSearch(0, 1, updatedFilters)
    } else {
      const { registrationType, ...restFilters } = { ...filters }
      setFilters(restFilters)
      initSearch(0, 1, restFilters)
    }
  }

  const onChangeEmbassy = selected => {
    if (selected) {
      const updatedFilters = {
        ...filters,
        embassy: selected.value,
      }
      setFilters(updatedFilters)
      initSearch(0, 1, updatedFilters)
    } else {
      const { embassy, ...restFilters } = { ...filters }
      setFilters(restFilters)
      initSearch(0, 1, restFilters)
    }
  }

  const onChangeSortType = selected => {
    if (selected) {
      const updatedFilters = {
        ...filters,
        sort: selected?.value,
        sortBy: "tNo",
      }
      setFilters(updatedFilters)
      initSearch(0, 1, updatedFilters)
    } else {
      const { sortBy, sort, ...restFilters } = { ...filters }
      setFilters(restFilters)
      initSearch(0, 1, restFilters)
    }
  }

  const onClearFilters = () => {
    setDateRange({
      startDate: null,
      endDate: null,
    })
    setQuery("")
    initSearch(0, 1)
  }

  const viewPatientHistory = params => {
    setPassport(params?.passport)
    setCnic(params?.cnic)
    toggleOpenPatientHistoryModal()
  }

  const handleJumpToPage = e => {
    const re = /^[0-9\b]+$/
    const page = e.target.value
    if (page === "" || (re.test(page) && Number(page) <= count)) {
      setTargetPage(page)
    }
  }

  const handleLimitRecords = e => {
    const re = /^[0-9\b]+$/

    const recordsLimit = Number(e.target.value)
    const total = registrations?.total

    if (recordsLimit === "" || (re.test(recordsLimit) && recordsLimit <= total)) {
      setLimit(recordsLimit)
    }
  }

  const handleOnChange = e => {
    const { name, checked } = e.target

    const { updatedReportIds, isAllChecked, openMultiSelectionToolbar } = handleMultiCheckboxSelection({
      name,
      checked,
      reportIds,
    })

    setReportIds(updatedReportIds)
    setIsAllReportIdSelected(isAllChecked)
    setOpenMultiSelectionToolbar(openMultiSelectionToolbar)
  }

  const updateUser = updatedData => {
    if (patient) {
      const updatedRegistrations = patient.registrations.map(registration => {
        if (registration.registrationId === selectedRegistration.registrationId) {
          const updatedRegistration = {
            ...updatedData,
          }
          setSelectedRegistration(updatedRegistration)
          return updatedRegistration
        }
        return registration
      })

      const updatedUser = {
        ...patient,
        registrations: updatedRegistrations,
      }

      setPatient(updatedUser)
    }

    const registrationsData = registrations?.data.map(registration => {
      if (registration.registrationId === updatedData.registrationId) {
        const updatedRegistration = {
          ...updatedData,
          patient: registration.patient,
        }
        return updatedRegistration
      }
      return registration
    })
    setRegistrations({ ...registrations, data: registrationsData })
  }

  const reset = () => {
    const updatedReportIds = reportIds?.map(registration => ({ ...registration, isChecked: false }))
    setReportIds(updatedReportIds)
    setIsAllReportIdSelected(false)
  }

  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay"></div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <h2 className="text-white mb-0">
                    <i className="fas fa-user-circle mr-4"></i> Staff Portal
                  </h2>
                </div>
                <div className="col-12 col-lg-6 text-left text-lg-right ml-auto">
                  <Link to={AllRoutesMap.registration} className="btn btn-white mr-2" style={{ whiteSpace: "nowrap" }}>
                    <i className="fas fa-plus mr-2"></i>
                    Register Patient
                  </Link>

                  <button
                    className="btn btn-secondary mr-2"
                    title="Check Test Window"
                    onClick={toggleCheckTestWindowModal}
                    ref={searchRef}
                  >
                    <i className="fas fa-calculator"></i>
                  </button>

                  <ButtonDropdown isOpen={multiRegisterDropdownOpen} toggle={toggleMultiRegisterDropdown}>
                    <DropdownToggle split color="primary" className="btn-white" />
                    <DropdownMenu right>
                      <DropdownItem id="easy-register">
                        <Link to={AllRoutesMap.easyRegistration} style={{ whiteSpace: "nowrap" }}>
                          <i className="fas fa-plus mr-2"></i>
                          Easy Register
                        </Link>
                      </DropdownItem>
                      <DropdownItem
                        id="multi-register"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => history.push(AllRoutesMap?.multiRegister)}
                      >
                        <i className="fas fa-user mr-2"></i>
                        Multi Register
                      </DropdownItem>
                      {isPermittedToInvoice && (
                        <DropdownItem
                          id="bulk-invoice"
                          style={{ whiteSpace: "nowrap" }}
                          onClick={toggleOpenGenerateInvoiceModal}
                        >
                          <i className="fas fa-user mr-2"></i>
                          Generate Invoice
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-5">
            {loadingPatient && (
              <div className="row mt-4 mb-4">
                <div className="col-12">
                  <div className="card p-4">
                    <div className="d-flex justify-content-between">
                      <h5>
                        <i className="fas fa-address-book mr-2"></i>
                        Patient Details
                        <Spinner color="default" className="ml-2 mb-1" size="sm" />
                      </h5>
                      <div className="cursor-pointer" onClick={hidePatientDetails}>
                        <i className="fas fa-times text-dark" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isPatientDetails && !loadingPatient && patient && (
              <>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="card p-4">
                      <div className="d-flex justify-content-between">
                        <h5>
                          <i className="fas fa-address-book mr-2"></i>
                          Patient Details
                        </h5>
                        <div className="cursor-pointer" onClick={hidePatientDetails}>
                          <i className="fas fa-times text-dark" />
                        </div>
                      </div>
                      {patient?.length <= 0 && (
                        <Alert color="warning">No patient found corresponding to this search.</Alert>
                      )}

                      {patient?.length >= 1 && (
                        <>
                          <div className="row">
                            <div className="col-lg-4">
                              <dl>
                                <dt>Full Name</dt>
                                <dd>{selectedRegistration?.patient?.fullname || "-"}</dd>

                                <dt>Gender</dt>
                                <dd>{selectedRegistration?.patient?.gender || "-"}</dd>

                                <dt>Age</dt>
                                <dd>{selectedRegistration?.patient?.age || "-"}</dd>

                                <dt>Lab No.</dt>
                                <dd>{selectedRegistration?.patient?.patientId || "-"}</dd>

                                <dt>Sample Date/Time</dt>
                                <dd>
                                  {selectedRegistration?.createdAt
                                    ? dayjs(selectedRegistration.createdAt).format("DD/MM/YYYY hh:mm A")
                                    : "-"}
                                </dd>
                              </dl>
                            </div>
                            <div className="col-lg-4">
                              <dl>
                                <dt>Cell No.</dt>
                                <dd>{selectedRegistration?.patient?.phone || "-"}</dd>

                                <dt>Email</dt>
                                <dd>{selectedRegistration?.patient?.email || "-"}</dd>

                                <dt>CNIC</dt>
                                <dd>{selectedRegistration?.patient?.cnic || "-"}</dd>

                                <dt>Passport No.</dt>
                                <dd>{getPassport(selectedRegistration) || "-"}</dd>

                                <dt>Report Date/Time</dt>
                                <dd>
                                  {selectedRegistration?.reportVerifiedAt
                                    ? dayjs(selectedRegistration.reportVerifiedAt).format("DD/MM/YYYY hh:mm A")
                                    : "-"}
                                </dd>
                              </dl>
                            </div>
                            <div className="col-lg-4">
                              <dl>
                                <dt>Address</dt>
                                <dd>{selectedRegistration?.patient?.address || "-"}</dd>

                                <dt>City</dt>
                                <dd>{selectedRegistration?.patient?.city || "-"}</dd>

                                <dt>Country</dt>
                                <dd>{selectedRegistration?.patient?.country || "-"}</dd>

                                <dt>T Number</dt>
                                <dd>
                                  {selectedRegistration?.tNo
                                    ? `${getTNoPrefix(selectedRegistration.embassy)}${selectedRegistration?.tNo}`
                                    : "-"}
                                </dd>

                                <dt>Panel</dt>
                                <dd>
                                  {selectedRegistration?.panel
                                    ? `${getPanel(selectedRegistration?.panel)} Covid-19 GCC`
                                    : "-" || "Covid-19 For Islamabad (Regular)"}
                                </dd>
                              </dl>
                            </div>
                          </div>

                          {selectedRegistration.registrationType === "passenger" && (
                            <div className="col-12 mt-5">
                              <h5>
                                <i className="fas fa-plane mr-2"></i>
                                Flight Details
                              </h5>
                              <div className="table-responsive table-hover">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th scope="col">MR #</th>
                                      <th scope="col">Airline</th>
                                      <th scope="col">Flight No.</th>
                                      <th scope="col">Date</th>
                                      <th scope="col">Booking Ref/PNR</th>
                                      <th scope="col" className="text-center">
                                        Airport
                                      </th>
                                      <th scope="col">Report</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {patient.map(registration => (
                                      <tr key={registration.registrationId}>
                                        <td>
                                          <input
                                            type="radio"
                                            name="registration"
                                            checked={
                                              selectedRegistration?.registrationId === registration.registrationId
                                            }
                                            value={registration.registrationId}
                                            onChange={() => setSelectedRegistration(registration)}
                                          />
                                        </td>
                                        <td>{registration.registrationId}</td>
                                        <td className="text-capitalize">{registration.airline || "-"}</td>
                                        <td>{registration.flightNo || "-"}</td>
                                        <td>
                                          {registration.flightDate
                                            ? dayjs(registration.flightDate, "DD-MM-YYYY").format("DD/MM/YYYY")
                                            : "-"}
                                        </td>
                                        <td>{registration.pnr || "-"}</td>
                                        <td>{registration.departureAirport || "-"}</td>
                                        <td className="text-center">
                                          {registration.reportVerifiedAt ? (
                                            <Link
                                              className="text-link cursor-pointer"
                                              to="#"
                                              onClick={() => {
                                                handleSearchDetails({
                                                  registrationId: registration?.registrationId,
                                                })
                                              }}
                                            >
                                              <i className="fas fa-eye"></i>
                                            </Link>
                                          ) : (
                                            <span>Pending</span>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {isPatientDetails && patient?.length >= 1 && patient && selectedRegistration && (
              <>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-end">
                      <Link
                        to={`${AllRoutesMap.barcode}?labId=${selectedRegistration?.labId}&registrationId=${selectedRegistration?.registrationId}`}
                        className="btn btn-secondary mr-2"
                        target="_blank"
                      >
                        Print MR#
                      </Link>
                      <ButtonDropdown isOpen={printDropdownOpen} toggle={togglePrintDropdown}>
                        <Button id="caret" color="primary" onClick={() => handlePrintAllForms()}>
                          Print All Forms
                          {loadingPrint && <Spinner color="default" className="ml-2" size="sm" />}
                        </Button>
                        <DropdownToggle split color="primary" className="ml-0" />
                        <DropdownMenu right>
                          <DropdownItem onClick={() => handlePrintConsent()}>Download Consent Form</DropdownItem>
                          <DropdownItem onClick={() => handlePrintRegistration()}>
                            Download Registration Form
                          </DropdownItem>
                          <DropdownItem onClick={() => handlePrintInvoice()}>Download Invoice</DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                  </div>
                </div>
                <hr />
              </>
            )}

            <div className="row">
              <div className="col-12">
                <h4>Patient Records</h4>
                <p className="lead mb-2">
                  <b>Search by:</b>
                </p>

                <form className="cms-contact-form" onSubmit={e => e.preventDefault()}>
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <div className="form-group d-flex">
                        <select
                          name="filter"
                          value={filter}
                          onChange={e => {
                            const updatedFilters = { ...filters }
                            delete updatedFilters[filter]
                            setFilters(updatedFilters)
                            setFilter(e.target.value)
                          }}
                          className="mr-4"
                        >
                          <option value="fullname">Name</option>
                          <option value="registrationId">MR No.</option>
                          <option value="cnic">CNIC</option>
                          <option value="passport">Passport</option>
                        </select>
                        <InputMask
                          placeholder="Search reports..."
                          type="search"
                          value={query}
                          onChange={e => setQuery(e.target.value)}
                          onKeyUp={onKeyUp}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-2">
                      <button
                        type="button"
                        className="btn btn-primary w-100 h-60"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => handleSearch()}
                      >
                        <i className="fas fa-search mr-2"></i>
                        Search
                        {loading && <Spinner color="default" className="ml-2" size="sm" />}
                      </button>
                    </div>
                    <div className="col-12 col-md-2">
                      <button
                        type="button"
                        className="btn btn-secondary w-100 h-60"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={onClearFilters}
                      >
                        <i className="fas fa-search mr-2"></i>
                        Clear
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="card p-4">
                      <div className="row cms-contact-form">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="text-bold">Start Date</label>
                            <div>
                              <DatePicker
                                placeholder="Select Date"
                                id="start-date-picker"
                                onChange={date => onChangeDateRange(date, "startDate")}
                                value={dateRange.startDate}
                                className="w-100"
                                clearIcon={clearIcon}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="text-bold">End Date</label>
                            <div>
                              <DatePicker
                                placeholder="Select Date"
                                id="end-date-picker"
                                onChange={date => onChangeDateRange(date, "endDate")}
                                value={dateRange.endDate}
                                className="w-100"
                                clearIcon={clearIcon}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="text-bold">Type</label>
                            <Select
                              label="Select Type"
                              isLoading={loading}
                              isDisabled={loading}
                              isClearable
                              options={registrationTypes}
                              onChange={onChangeType}
                              value={
                                registrationTypes.find(
                                  registrationType => registrationType.value === filters?.registrationType
                                ) || null
                              }
                              styles={customStyles}
                              className="c-select w-100"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="text-bold">Embassy</label>
                            <Select
                              label="Select Embassy"
                              isLoading={loading}
                              isDisabled={loading}
                              isClearable
                              options={embassies}
                              onChange={onChangeEmbassy}
                              value={embassies.find(embassy => embassy.value === filters?.embassy) || null}
                              styles={customStyles}
                              className="c-select"
                            />
                          </div>
                        </div>
                      </div>

                      {openMultiSelectionToolbar && (
                        <MultiSelection
                          toggle={toggleMultiSelectionToolbar}
                          selectedReportIds={reportIds.filter(reportId => reportId?.isChecked === true)}
                          reset={reset}
                          isMultiLoading={multiLoading}
                          setIsMultiLoading={setMultiLoading}
                          updateData={() => initSearch(offset, activePage, filters)}
                        />
                      )}

                      {!loading && registrations?.data?.length === 0 && (
                        <Alert color="warning" className="mb-0">
                          No results found.
                        </Alert>
                      )}

                      {registrations?.data?.length > 0 && (
                        <div className="row c-table">
                          <div className="col-12">
                            <div className="table-responsive table-border-top no-vertical-scroll">
                              <table className="table table-hover text-nowrap">
                                <thead className="table-header-bg">
                                  <tr>
                                    <th scope="col">
                                      <label>
                                        <Checkbox
                                          size="sm"
                                          colorScheme="gray"
                                          className="c-checkbox mt-2"
                                          name="selectAll"
                                          isChecked={!!isAllReportIdSelected}
                                          onChange={handleOnChange}
                                        ></Checkbox>
                                      </label>
                                    </th>
                                    <th scope="col">Sr. No</th>
                                    <th scope="col">Actions</th>
                                    <th
                                      scope="col"
                                      onClick={e => {
                                        if (clickCounter <= 0) {
                                          setSortOrderType("asc")
                                          setClickCounter(clickCounter + 1)
                                          onChangeSortType({ value: "asc" })
                                        } else if (clickCounter === 1) {
                                          setSortOrderType("desc")
                                          setClickCounter(clickCounter + 1)
                                          onChangeSortType({ value: "desc" })
                                        } else {
                                          setSortOrderType("")
                                          setClickCounter(0)
                                          onChangeSortType(null)
                                        }
                                      }}
                                    >
                                      T#
                                      {sortOrderType === "" && <i className="fas fa-sort cursor-pointer ml-2"></i>}
                                      {sortOrderType === "asc" && (
                                        <i className="fas fa-sort-up cursor-pointer ml-2"></i>
                                      )}
                                      {sortOrderType === "desc" && (
                                        <i className="fas fa-sort-down cursor-pointer ml-2"></i>
                                      )}
                                    </th>
                                    <th scope="col">MR#</th>
                                    <th scope="col">Fullname</th>
                                    <th scope="col">Passport#</th>
                                    <th scope="col">Contact#</th>
                                    <th scope="col">CNIC</th>
                                    <th scope="col">Sample Date/Time</th>
                                    <th scope="col">Report Date/Time</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Embassy</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Record Upated By</th>
                                    <th scope="col" className="text-center">
                                      Status
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {registrations.data.map((registration, idx) => (
                                    <tr key={registration.registrationId}>
                                      <td>
                                        <label>
                                          <Checkbox
                                            size="sm"
                                            colorScheme="gray"
                                            className="c-checkbox mt-2"
                                            name={registration?.registrationId}
                                            isChecked={!!reportIds[idx]?.isChecked}
                                            key={`checkbox-${registration?.registrationId}-${idx}`}
                                            onChange={handleOnChange}
                                          ></Checkbox>
                                        </label>
                                      </td>
                                      <td>{idx + 1 + offset}</td>
                                      <td>
                                        {isEditable && (
                                          <button
                                            onClick={() => {
                                              getSelectedRegistration(registration?.registrationId)
                                            }}
                                            className="btn-action bg-success border-0 mr-2"
                                            disabled={openMultiSelectionToolbar}
                                          >
                                            <i className="fas fa-pencil-square-o" title="Edit"></i>
                                          </button>
                                        )}

                                        <button
                                          onClick={() => {
                                            getInfo(registration?.registrationId)
                                          }}
                                          className="btn-action bg-primary border-0 mr-2"
                                          disabled={openMultiSelectionToolbar}
                                        >
                                          <i className="fas fa-info" title="Details"></i>
                                        </button>
                                        {registration?.reportVerifiedAt && (
                                          <button
                                            onClick={() => {
                                              handleSearchDetails({
                                                registrationId: registration?.registrationId,
                                              })
                                            }}
                                            className="btn-action bg-warning border-0 mr-2"
                                            disabled={openMultiSelectionToolbar}
                                          >
                                            <i className="fas fa-eye" title="View Report"></i>
                                          </button>
                                        )}

                                        {isVerifiable && (
                                          <button
                                            to="#"
                                            onClick={() => {
                                              hidePatientDetails()
                                              setSelectedRegistration(registration)
                                              toggleReportModal()
                                            }}
                                            className="btn-action bg-success border-0 mr-1"
                                            disabled={openMultiSelectionToolbar}
                                          >
                                            <i className="fas fa-check" title="Verify Report"></i>
                                          </button>
                                        )}

                                        <button
                                          onClick={() => {
                                            const params = {
                                              passport: getPassport(registration),
                                              cnic: registration?.patient?.cnic,
                                            }
                                            viewPatientHistory(params)
                                          }}
                                          className="btn-action bg-secondary border-0"
                                          disabled={openMultiSelectionToolbar}
                                        >
                                          <i className="fas fa-hourglass-half" title="View Patient's History"></i>
                                        </button>
                                      </td>
                                      <td>{registration?.tNo || "-"}</td>
                                      <td>{registration?.registrationId}</td>
                                      <td>{registration?.patient?.fullname || "-"}</td>
                                      <td>{getPassport(registration) || "-"}</td>
                                      <td>{registration?.patient?.phone || "-"}</td>
                                      <td>{registration?.patient?.cnic || "-"}</td>
                                      <td>{dayjs(registration?.createdAt).format("DD/MM/YYYY hh:mm A")}</td>
                                      <td>
                                        {registration?.reportVerifiedAt
                                          ? dayjs(registration?.reportVerifiedAt).format("DD/MM/YYYY hh:mm A")
                                          : "-"}
                                      </td>
                                      <td className="text-capitalize">{registration?.registrationType}</td>
                                      <td className="text-capitalize">{getEmbassy(registration?.embassy) || "-"}</td>
                                      <td className="text-capitalize">
                                        {getReference(registration?.reference) || "-"}
                                      </td>
                                      <td>{registration?.recordUpdatedBy || "-"}</td>
                                      <td>{getReportInformation(registration)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                            <div className="row mt-2">
                              <div className="col-md-3">
                                <Pagination
                                  activePage={activePage}
                                  itemsCountPerPage={limit}
                                  totalItemsCount={registrations.total}
                                  pageRangeDisplayed={5}
                                  onChange={goToPage}
                                  itemClass="page-item"
                                  linkClass="page-link"
                                />
                              </div>
                              <div className="col-md-3 d-flex">
                                <span className="text-dark pt-2">Jump to Page</span>
                                <input
                                  type="number"
                                  disabled={loading}
                                  value={targetPage}
                                  className="jump-to-page ml-2"
                                  placeholder="1"
                                  onChange={handleJumpToPage}
                                />
                              </div>
                              <div className="col-md-3 d-flex">
                                <span className="text-dark pt-2">Limit records</span>
                                <input
                                  type="number"
                                  disabled={loading}
                                  value={limit}
                                  className="jump-to-page ml-2"
                                  onChange={handleLimitRecords}
                                />
                              </div>
                              <div className="col-md-3 pt-2">
                                <span className="pull-right text-dark">
                                  Showing:{" "}
                                  {`${offset + 1} to ${Math.min(limit + offset, registrations.total)} of ${
                                    registrations.total
                                  } records`}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-12 text-center">{loading && <Spinner color="primary" />}</div>
                      </div>

                      {multiLoading && <FullScreenLoader />}
                    </div>
                  </div>
                </div>

                {openPatientHistoryModal && (
                  <ViewPatientHistoyModal
                    openModal={openPatientHistoryModal}
                    toggle={toggleOpenPatientHistoryModal}
                    passport={passport}
                    cnic={cnic}
                  />
                )}

                {selectedReport && (
                  <ViewReportModal
                    openModal={openViewReportModal}
                    toggle={toggleViewReportModal}
                    registrationId={selectedReport.registrationId}
                    patientId={selectedReport.patientId}
                  />
                )}
                {selectedRegistrationData && (
                  <EditRegistrationModal
                    openModal={openEditRegistrationModal}
                    toggle={toggleEditRegistrationModal}
                    data={selectedRegistrationData}
                    updateRegistrations={updateRegistrations}
                  />
                )}
                {/* To push footer a bit low */}
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </section>
        <CheckTestWindowModal openModal={isCheckTestWindowOpen} toggle={toggleCheckTestWindowModal} />
        {selectedRegistration && (
          <ReportVerificationModal
            openModal={openReportModal}
            toggle={toggleReportModal}
            registration={selectedRegistration}
            updateUser={updateUser}
          />
        )}

        {openGenerateInvoiceModal && (
          <GenerateInvoiceModal
            openModal={openGenerateInvoiceModal}
            toggle={toggleOpenGenerateInvoiceModal}
            isAdmin={true}
          />
        )}
      </Main>
      <Footer />
    </Fragment>
  )
}

export default Staff
