import React, { useState } from "react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"

import RequiredFieldMark from "../common/RequiredFieldMark"
import { roles } from "../../utils/roles"
import { useToast } from "@chakra-ui/core"
import { addUser } from "../../services/api"

const schema = yup.object().shape({
  role: yup.string().required("Role is required"),
  email: yup.string().required("Email/Username is required"),
  userPassword: yup.string().required("Password is Required"),
})

export default function AddUserModal(props) {
  const { openModal, toggle, updateUsers } = props
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      role: "",
      email: "",
      userPassword: "",
    },
    mode: "onChange",
  })

  const onSubmit = async data => {
    const apiData = {
      ...data,
      password: data.userPassword,
    }

    try {
      setLoading(true)

      const res = await addUser(apiData)
      toggle()
      updateUsers(res?.data?.user)
      toast({
        title: "Added user successfully.",
        description: "Added user successfully.",
        status: "success",
        duration: 4000,
      })
    } catch (err) {
      const error = err.response?.data?.errors?.message || "There was an error while adding user."
      toast({
        title: "Error occurred while adding user.",
        description: error,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Add User</ModalHeader>
      <ModalBody>
        <form className="cms-contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="text-bold">
                  Email/Username <RequiredFieldMark />
                </label>
                <input type="text" placeholder="Enter Email/Username" name="email" ref={register} autoComplete="off" />
                <span className="text-danger">{errors.email?.message}</span>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="text-bold">
                  Role <RequiredFieldMark />
                </label>
                <select name="role" ref={register}>
                  <option value="">Select Role</option>
                  {roles.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
                <span className="text-danger">{errors.role?.message}</span>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="text-bold">
                  Password <RequiredFieldMark />
                </label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  name="userPassword"
                  ref={register}
                  autoComplete="new-password"
                />
                <span className="text-danger">{errors.userPassword?.message}</span>
              </div>
            </div>
          </div>

          <ModalFooter className="pb-0 px-0">
            <button type="submit" className="btn btn-secondary" disabled={loading}>
              Add User
              {loading && <Spinner color="default" className="ml-2" size="sm" />}
            </button>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  )
}
