import React, { useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { Checkbox, useToast } from "@chakra-ui/core"
import Select from "react-select"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"
import { generateMultipeRegistrationsManually } from "../../../services/api"
import { getTestCenter, registrationTypes, embassies, getTNoPrefix, references } from "../../../utils/common"
import RequiredFieldMark from "../../common/RequiredFieldMark"
import DatePicker from "../../common/DatePicker"
import { airlines, defaultAirline } from "../../../utils/airlines"
import { useAirports } from "../../../services/swrHooks"
import dayjs from "dayjs"

const schema = yup.object().shape({
  code: yup.string(),
  from: yup
    .number()
    .required("Must not be empty")
    .typeError("Must be a number")
    .integer("Must be a postive number")
    .positive("Must be a positive number")
    .min(0, "Must be greater than 0"),
  to: yup
    .number()
    .required("Must not be empty")
    .typeError("Must be a number")
    .integer("Must be a postive number")
    .positive("Must be a positive number")
    .max(1001, "Must be less than 1000"),
  reference: yup.string(),
  testCenter: yup.string().required(),
  rapidPcrCharges: yup.number().typeError("Rapid PCR Charges must be a number"),
  charges: yup.number().typeError("PCR Charges must be a number"),
  igmCharges: yup.number().typeError("IgM Charges must be a number"),
  iggCharges: yup.number().typeError("IgG Charges must be a number"),
})

const customStyles = {
  control: provided => {
    const background = "#f1f5fe"
    const border = "1px solid #f1f5fe"
    return { ...provided, background, border }
  },
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "#07152a",
  }),
}

export default function MultiPatientRegistrationModal(props) {
  const { openModal, toggle, updateData } = props
  const { airports, isLoading } = useAirports()
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [registrationType, setRegistrationType] = useState("general")
  const [embassy, setEmbassy] = useState("")
  const [tNoPrefix, setTNoPrefix] = useState("")
  const [isRapidPcrCharges, setIsRapidPcrCharges] = useState(true)
  const [isCharges, setIsCharges] = useState(false)
  const [isIggCharges, setIsIggCharges] = useState(false)
  const [isIgmCharges, setIsIgmCharges] = useState(false)
  const [isTravelCharges, setIsTravelCharges] = useState(false)

  const { register, handleSubmit, control, errors, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      code: "",
      from: "",
      to: "",
      paymentType: "cash",
      travelCharges: 0,
    },
  })

  const { charges, iggCharges, igmCharges, travelCharges } = watch([
    "charges",
    "iggCharges",
    "igmCharges",
    "travelCharges",
  ])

  useEffect(() => {
    setTNoPrefix(getTNoPrefix(embassy))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embassy])

  useEffect(() => {
    if (isCharges || isIggCharges || isIgmCharges) {
      setIsRapidPcrCharges(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCharges, isIggCharges, isIgmCharges])

  useEffect(() => {
    if (isRapidPcrCharges) {
      setIsCharges(false)
      setIsIggCharges(false)
      setIsIgmCharges(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRapidPcrCharges])

  const onSubmit = async formData => {
    const { flightDetails, ...otherFormData } = formData

    const adjustedFlightDetails = {
      airline: flightDetails?.airline || null,
      departureAirport: flightDetails?.departureAirport || null,
      destinationAirport: flightDetails?.destinationAirport?.value || null,
      flightDate: flightDetails?.flightDate ? dayjs(flightDetails?.flightDate).format("DD-MM-YYYY") : null,
      flightNo: flightDetails?.flightNo || null,
      flightTime: flightDetails?.flightTime || null,
      pnr: flightDetails?.pnr || null,
    }

    const code = !formData?.code && formData?.embassy ? tNoPrefix : `${formData?.code}`.toUpperCase() || ""

    const data = {
      embassy: embassy || "",
      charges: charges,
      iggCharges: iggCharges,
      igmCharges: igmCharges,
      travelCharges: travelCharges || 0,
      tNoPrefix: tNoPrefix || "",
      code,
      ...otherFormData,
      ...adjustedFlightDetails,
    }

    try {
      setLoading(true)

      await generateMultipeRegistrationsManually({ data })

      toggle()
      updateData()

      toast({
        title: "Registration process initiated successfully",
        description: "Registration process initiated successfully",
        status: "success",
        duration: 4000,
      })
    } catch (error) {
      toast({
        title: "Error While Generating Entries.",
        description: error?.response?.data?.errors?.message || error?.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true} size="xl">
      <ModalHeader toggle={toggle}>Generate Multiple Registrations - Manually</ModalHeader>
      <form className="cms-contact-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <div className="container mb-4">
            <div className="row">
              <div className="col-md-4">
                <div className="mb-2">
                  <div>
                    <label>Embassy</label>
                  </div>
                  <div>
                    <select
                      name="embassy"
                      value={embassy}
                      ref={register}
                      onChange={e => {
                        setEmbassy(e.target.value)
                      }}
                    >
                      <option value="">Select Embassy</option>
                      {embassies.map(({ value, label }, idx) => (
                        <option key={`Embassy-${label}-${idx}`} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="cms-contact-form">
                  <label className="text-bold mb-1">Enter TNo.</label>
                  {embassy ? (
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text input-prepend" id="basic-addon1">
                          {tNoPrefix}
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter T number"
                        name="code"
                        ref={register}
                        className="form-control c-input text-uppercase"
                        aria-describedby="basic-addon1"
                        style={{ paddingLeft: 3 }}
                      />
                    </div>
                  ) : (
                    <input
                      type="text"
                      placeholder="Enter T number"
                      name="code"
                      ref={register}
                      className="text-uppercase"
                    />
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div>
                  <label>Patient Type</label>
                </div>
                <div>
                  <select
                    name="registrationType"
                    value={registrationType}
                    onChange={e => {
                      setRegistrationType(e.target.value)
                    }}
                    ref={register}
                  >
                    {registrationTypes.map(({ value, label }, idx) => (
                      <option key={`registration-type-${label}-${idx}`} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="row  mb-2">
              <div className="col-md-4">
                <div className="cms-contact-form">
                  <label className="text-bold mb-2">
                    Enter Range <RequiredFieldMark />{" "}
                  </label>
                  <div className="d-flex">
                    <input type="number" name="from" placeholder="1" ref={register} />
                    <label className="text-bold px-4 mt-3">-</label>
                    <input type="number" name="to" placeholder="1000" ref={register} />
                  </div>
                  <label className="text-danger">{errors?.from?.message || errors?.to?.message}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Payment Type</label>
                  <select name="paymentType" ref={register}>
                    <option value="cash">Cash</option>
                    <option value="credit">Credit</option>
                  </select>
                </div>
              </div>
              <div className="col-md-5">
                <div>
                  <label>Reference</label>
                </div>
                <div>
                  <select name="reference" ref={register}>
                    <option value="">Select Reference</option>
                    {references.map(({ value, label }, idx) => (
                      <option key={`Reference-${label}-${idx}`} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label className="text-bold">
                    Global Clinical Care Diagnostic Center Sample Collection Point <RequiredFieldMark />
                  </label>

                  <select defaultValue=" D-975, D block Satellite Town Rawalpindi" name="testCenter" ref={register}>
                    <option value="ISB01">{getTestCenter("ISB01").address}</option>
                    <option value="SHWL">{getTestCenter("SHWL").address}</option>
                    <option value="BGP">{getTestCenter("BGP").address}</option>
                    <option value="CHP">{getTestCenter("CHP").address}</option>
                    <option value="PC">{getTestCenter("PC").address}</option>
                    <option value="PC-FWO-JV">{getTestCenter("PC-FWO-JV").address}</option>
                    <option value="D-JW-GC">{getTestCenter("D-JW-GC").address}</option>
                    <option value="CDCC">{getTestCenter("CDCC").address}</option>
                    <option value="MG-M">{getTestCenter("MG-M").address}</option>
                    <option value="CGCC">{getTestCenter("CGCC").address}</option>
                    <option value="ZTE">{getTestCenter("ZTE").address}</option>
                    <option value="TGDC">{getTestCenter("TGDC").address}</option>
                  </select>
                  <span className="text-danger">{errors.register?.message}</span>
                </div>
              </div>
            </div>

            {registrationType === "passenger" && (
              <>
                <div className="row mt-4">
                  <div className="col-12">
                    <h5>
                      <i className="fas fa-plane mr-2"></i>
                      Flight Details
                    </h5>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="text-bold">Airline</label>
                      <select defaultValue={defaultAirline.value} name="flightDetails.airline" ref={register}>
                        {airlines.map(({ value, label }, idx) => (
                          <option key={`${label}-${idx}`} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.flightDetails?.airline?.message}</span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Flight No.</label>
                      <input
                        type="text"
                        placeholder="Enter your flight number"
                        name="flightDetails.flightNo"
                        ref={register}
                      />
                      <span className="text-danger">{errors.flightDetails?.flightNo?.message}</span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Booking Ref./PNR</label>
                      <input
                        type="text"
                        placeholder="Enter your ticket/PNRA number"
                        name="flightDetails.pnr"
                        ref={register}
                      />
                      <span className="text-danger">{errors.flightDetails?.pnr?.message}</span>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="text-bold">Flight Date & Time</label>
                      <div className="d-flex">
                        <div className="cms-input date-wrap w-50">
                          <Controller
                            control={control}
                            name="flightDetails.flightDate"
                            defaultValue={""}
                            render={({ onChange, value }) => (
                              <DatePicker
                                className="w-100"
                                placeholder="Select Date"
                                id="date-picker"
                                onChange={onChange}
                                value={value}
                                minDate={new Date()}
                              />
                            )}
                          />
                          <span className="text-danger">{errors.flightDetails?.flightDate?.message}</span>
                        </div>
                        <div className="w-50 ml-2">
                          <input type="time" placeholder="Select time" name="flightDetails.flightTime" ref={register} />
                          <span className="text-danger">{errors.flightDetails?.flightTime?.message}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="text-bold">Departure Airport</label>

                      <select
                        defaultValue="Islamabad International Airport"
                        name="flightDetails.departureAirport"
                        ref={register}
                      >
                        <option value="Islamabad International Airport">Islamabad International Airport</option>
                        <option value="Karachi Jinnah International Airport">
                          Karachi Jinnah International Airport
                        </option>
                        <option value="Lahore Allama Iqbal International Airport">
                          Lahore Allama Iqbal International Airport
                        </option>
                        <option value="Dera Ghazi Khan International Airport">
                          Dera Ghazi Khan International Airport
                        </option>
                        <option value="Faisalabad International Airport">Faisalabad International Airport</option>
                        <option value="Gawadar International Airport">Gawadar International Airport</option>
                        <option value="Multan International Airport">Multan International Airport</option>
                        <option value="Peshawar Bacha Khan International Airport">
                          Peshawar Bacha Khan International Airport
                        </option>
                        <option value="Quetta International Airport">Quetta International Airport</option>
                        <option value="Sheikh Zayed International Airport">Sheikh Zayed International Airport</option>

                        <option value="Sialkot International Airport">Sialkot International Airport</option>

                        <option value="Turbat International Airport">Turbat International Airport</option>
                        <option value="Bahawalpur Airport">Bahawalpur Airport</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="text-bold">Destination Airport</label>

                      <Controller
                        control={control}
                        name="flightDetails.destinationAirport"
                        render={({ onChange, value }) => (
                          <Select
                            label="Select destination airport"
                            options={airports}
                            onChange={onChange}
                            value={value}
                            styles={customStyles}
                            className="c-select"
                            isLoading={isLoading}
                          />
                        )}
                      />

                      <span className="text-danger">{errors.flightDetails?.destinationAirport?.message}</span>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="row mt-4">
              <div className="col-12">
                <h5>
                  <i className="fas fa-money mr-2"></i>
                  Payment
                </h5>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-3">
                <label>
                  <Checkbox
                    size="sm"
                    colorScheme="green"
                    className="c-checkbox"
                    isChecked={isRapidPcrCharges}
                    onChange={() => setIsRapidPcrCharges(!isRapidPcrCharges)}
                  >
                    Rapid PCR Charges
                  </Checkbox>
                </label>
                <input
                  type="number"
                  placeholder="Enter Charges"
                  name="rapidPcrCharges"
                  ref={register}
                  disabled={!isRapidPcrCharges}
                />
                <span className="text-danger">{errors.rapidPcrCharges?.message}</span>
              </div>
              <div className="col-md-3">
                <label>
                  <Checkbox
                    size="sm"
                    colorScheme="green"
                    className="c-checkbox"
                    isChecked={isCharges}
                    onChange={() => setIsCharges(!isCharges)}
                  >
                    PCR Charges
                  </Checkbox>
                </label>
                <input type="number" placeholder="Enter Charges" name="charges" ref={register} disabled={!isCharges} />
                <span className="text-danger">{errors.charges?.message}</span>
              </div>
              <div className="col-md-3">
                <label>
                  <Checkbox
                    size="sm"
                    colorScheme="green"
                    className="c-checkbox"
                    isChecked={isIgmCharges}
                    onChange={() => setIsIgmCharges(!isIgmCharges)}
                  >
                    IgM Charges
                  </Checkbox>
                </label>
                <input
                  type="number"
                  placeholder="Enter Charges"
                  name="igmCharges"
                  ref={register}
                  disabled={!isIgmCharges}
                />
                <span className="text-danger">{errors.igmCharges?.message}</span>
              </div>
              <div className="col-md-3">
                <label>
                  <Checkbox
                    size="sm"
                    colorScheme="green"
                    className="c-checkbox"
                    isChecked={isIggCharges}
                    onChange={() => setIsIggCharges(!isIggCharges)}
                  >
                    IgG Charges
                  </Checkbox>
                </label>
                <input
                  type="number"
                  placeholder="Enter Charges"
                  name="iggCharges"
                  ref={register}
                  disabled={!isIggCharges}
                />
                <span className="text-danger">{errors.iggCharges?.message}</span>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-3">
                <div className="form-group">
                  <label>
                    <Checkbox
                      size="sm"
                      colorScheme="green"
                      className="c-checkbox"
                      isChecked={isTravelCharges}
                      onChange={() => setIsTravelCharges(!isTravelCharges)}
                    >
                      Travelling Charges
                    </Checkbox>
                  </label>
                  <input
                    type="number"
                    placeholder="Enter Charges"
                    name="travelCharges"
                    ref={register}
                    disabled={!isTravelCharges}
                  />
                  <span className="text-danger">{errors.travelCharges?.message}</span>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-secondary" disabled={loading}>
            Generate
            {loading && <Spinner color="default" className="ml-2" size="sm" />}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
