export const airlines = [
  {
    label: "Select Airlines",
    value: "",
  },
  {
    label: "Emirates",
    value: "emirates",
  },
  {
    label: "Air China",
    value: "air-china",
  },
  {
    label: "China Southern",
    value: "china-southern",
  },
  {
    label: "Fly Dubai",
    value: "Fly Dubai",
  },
  {
    label: "Air Arabia",
    value: "Air Arabia",
  },
  {
    label: "Airblue",
    value: "airblue",
  },
  {
    label: "Hainan Airline",
    value: "Hainan Airline",
  },
  {
    label: "Malindo Air",
    value: "Malindo Air",
  },
  {
    label: "Thai Airline",
    value: "Thai Airline",
  },
  {
    label: "PIA",
    value: "PIA",
  },
  {
    label: "Pakistan Air Force",
    value: "PAF",
  },
  {
    label: "Serene Air",
    value: "Serene Air",
  },
  {
    label: "Turkish",
    value: "Turkish",
  },
  {
    label: "Qatar",
    value: "Qatar",
  },
  {
    label: "Qatar Airways",
    value: "Qatar Airways",
  },
  {
    label: "Etihad",
    value: "Etihad",
  },
  {
    label: "Virgin Atlantic",
    value: "Virgin Atlantic",
  },
  {
    label: "Saudi Airways",
    value: "saudi-airways",
  },
  {
    label: "Saudi Arabian Airlines",
    value: "saudi-arabian-airlines",
  },
  {
    label: "SriLankan Airlines",
    value: "srilankan-airlines",
  },
  {
    label: "Etihad Airways",
    value: "etihad-airways",
  },
  {
    label: "Oman Air",
    value: "Oman",
  },
]

export const defaultAirline = airlines[0]

export const getAirline = airlineValue => {
  if (!airlineValue) {
    return undefined
  }
  return airlines.find(airline => airline.value === airlineValue)?.label || airlineValue
}

export const landingPageAirlines = [
  {
    label: "Emirates",
    value: "emirates",
    src: "/assets/images/airlines/emirates.png",
  },
  {
    label: "Air China",
    value: "air-china",
    src: "/assets/images/airlines/air-china.png",
  },
  {
    label: "China Southern",
    value: "china-southern",
    src: "/assets/images/airlines/china-southern.png",
  },
  {
    label: "Fly Dubai",
    value: "Fly Dubai",
    src: "/assets/images/airlines/fly_dubai.png",
  },
  {
    label: "Air Arabia",
    value: "Air Arabia",
    src: "/assets/images/airlines/air_arabia.png",
  },
  {
    label: "Turkish",
    value: "Turkish",
    src: "/assets/images/airlines/turkish.png",
  },
  {
    label: "Qatar",
    value: "Qatar",
    src: "/assets/images/airlines/qatar.png",
  },
  {
    label: "Etihad",
    value: "Etihad",
    src: "/assets/images/airlines/etihad.png",
  },
  {
    label: "Virgin Atlantic",
    value: "Virgin Atlantic",
    src: "/assets/images/airlines/virgin.jpeg",
  },
  {
    label: "PIA",
    value: "PIA",
    src: "/assets/images/airlines/pia.png",
  },
]
