import Camera, { FACING_MODES } from "react-html5-camera-photo"
import React, { useState } from "react"
import { Button, Spinner } from "reactstrap"
import "react-html5-camera-photo/build/css/index.css"

import ImagePreview from "../../components/common/ImagePreview"
import { storage } from "../../config/firebaseClient"

export default function CameraPicture(props) {
  const path = props.path || "profile-pictures"
  const [uploading, setUploading] = useState(false)
  const [dataUri, setDataUri] = useState("")
  const [facingMode, setFacingMode] = useState(false)
  const showRotateCameraButton = props.showRotateCameraButton || false

  const handleTakePhotoAnimationDone = dataUri => {
    setDataUri(dataUri)
    handleChange(dataUri)
  }

  const resetURL = () => {
    setDataUri("")
    props.onTakePhoto("")
  }

  const toggleMode = () => {
    setFacingMode(!facingMode)
  }

  const handleChange = dataUri => {
    if (!dataUri) {
      return
    }

    const extension = dataUri.includes(".png") ? "png" : "jpg"
    const uploadTask = storage
      .ref(`/${path}/${props.pictureId}.${extension}`)
      .putString(dataUri.substring(dataUri.indexOf(",") + 1), "base64", {
        contentType: `image/${extension}`,
      })

    setUploading(true)
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      snapShot => {
        //takes a snap shot of the process as it is happening
        console.info(snapShot)
      },
      err => {
        //catches the errors
        console.log(err)
        setUploading(false)
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref(path)
          .child(`${props.pictureId}.${extension}`)
          .getDownloadURL()
          .then(fireBaseUrl => {
            props.onTakePhoto(fireBaseUrl)
            setUploading(false)
          })
          .catch(error => {
            setUploading(false)
          })
      }
    )
  }

  return (
    <div className="d-flex align-items-center flex-column">
      {showRotateCameraButton && (
        <Button className="mb-2" style={{ width: "200px" }} onClick={toggleMode}>
          <i className="fas fa-undo mr-2" />
          Rotate
        </Button>
      )}
      {dataUri ? (
        <ImagePreview dataUri={dataUri} isFullscreen={false} />
      ) : (
        <Camera
          onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
          isFullscreen={props.rearCamera || false}
          imageType={"jpg"}
          idealFacingMode={props.rearCamera ? FACING_MODES.ENVIRONMENT : FACING_MODES.USER}
          isImageMirror={!props.rearCamera}
          {...props.otherCameraProps}
        />
      )}
      <div className="mt-2">
        {dataUri && (
          <Button style={{ width: "200px" }} onClick={resetURL} disabled={uploading}>
            Retake {uploading && <Spinner size="sm" className="mr-2" />}
          </Button>
        )}
      </div>
    </div>
  )
}
