import dayjs from "dayjs"
import { PDFDocument, StandardFonts, rgb } from "pdf-lib"
import { getAirline } from "../../utils/airlines"

import { getAirport } from "../../utils/airports"
import {
  capitalize,
  getCovidTestCategory,
  getEmbassy,
  getReference,
  getTestCenter,
  getTestType,
  getTNoPrefix,
} from "../../utils/common"
import { renderBarcode, renderProfileImage } from "../../utils/pdfs"

const getPDFTemplate = ({ registration }) => {
  if (registration?.registrationType === "passenger") {
    return `assets/pdf-templates/passenger/registration-passenger-template.pdf`
  } else {
    return `assets/pdf-templates/general/registration-general-template.pdf`
  }
}

export default async function createRegistrationPDF(result) {
  const url = getPDFTemplate(result)
  // This should be a Uint8Array or ArrayBuffer
  // This data can be obtained in a number of different ways
  // If your running in a Node environment, you could use fs.readFile()
  // In the browser, you could make a fetch() call and use res.arrayBuffer()

  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes)

  // Embed the Helvetica font
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

  // Get the first page of the document
  const pages = pdfDoc.getPages()
  const page = pages[0]

  const config = {
    page,
    helveticaFont,
    helveticaBoldFont,
    pdfDoc,
  }

  await renderProfileImage(result, config)
  await renderEmbassyChecks(result, config)
  renderPassengerInformation(result, config)
  if (result?.registration?.registrationType === "passenger") renderFlightInformation(result, config)
  await renderBarcode(result, config)

  // Serialize the PDFDocument to bytes= (a Uint8Array)
  const pdfBytes = await pdfDoc.save()

  const registrationName = `registration-${result.patient?.fullname || "patient"}-${
    result.registration.registrationId
  }.pdf`
  // Trigger the browser to download the PDF document
  window.download(pdfBytes, registrationName, "application/pdf")
}

const getTNo = registration => {
  if (!registration.tNo) {
    return "-"
  }
  if (registration.embassy) {
    return `${getTNoPrefix(registration.embassy)}${registration.tNo}`
  } else {
    return registration.tNo
  }
}

export const renderEmbassyChecks = async (data, config) => {
  const { page, helveticaBoldFont } = config
  const { registration } = data

  // const pngImageBytes = await fetch("assets/images/checkbox-check.png").then(res => res.arrayBuffer())
  // Embed the JPG image bytes and PNG image bytes
  // const pngImage = await config.pdfDoc.embedPng(pngImageBytes)
  // Get the width/height of the PNG image scaled down to 50% of its original size
  // const pngDims = pngImage.scaleToFit(15, 15)
  const yPosition = 728

  // if (registration?.registrationType) {
  //   if (registration.registrationType === "passenger") {
  //     page.drawImage(pngImage, {
  //       x: 134,
  //       y: yPosition,
  //       width: pngDims.width,
  //       height: pngDims.height,
  //     })
  //   } else {
  //     page.drawImage(pngImage, {
  //       x: 65,
  //       y: yPosition,
  //       width: pngDims.width,
  //       height: pngDims.height,
  //     })
  //   }
  // }

  const embassyRefX = 40 //194
  const embassyRefXConstant = registration?.embassy ? 46 : 20
  const embassyRefValueX = embassyRefX + embassyRefXConstant

  page.drawText(registration?.embassy ? "Embassy: " : "Ref: ", {
    x: embassyRefX,
    y: yPosition + 3,
    size: 9,
    font: helveticaBoldFont,
  })

  page.drawText(getEmbassy(registration.embassy) || getReference(registration?.reference) || "-", {
    x: embassyRefValueX,
    y: yPosition + 3,
    size: 9,
  })

  page.drawText(getTNo(registration), {
    x: 505,
    y: yPosition + 3,
    size: 9,
  })
}

export const renderPassengerInformation = (data, config) => {
  const { page, helveticaFont } = config
  const { patient, registration } = data
  const xPosition = 170
  const yPosition = 690
  const delta = 22

  // Patient Test Type
  page.drawText(getCovidTestCategory(getTestType(registration)), {
    x: xPosition,
    y: yPosition - delta,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // MR
  page.drawText(registration?.registrationId, {
    x: xPosition,
    y: yPosition - delta * 2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Fullname
  page.drawText(patient?.fullname || "-", {
    x: xPosition,
    y: yPosition - delta * 3,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // DOB
  page.drawText(patient?.dob ? dayjs(patient.dob, "YYYY-MM-DD").format("DD/MM/YYYY") : "-", {
    x: xPosition,
    y: yPosition - delta * 4,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Phone
  page.drawText(patient?.phone || "-", {
    x: xPosition,
    y: yPosition - delta * 5,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // CNIC
  page.drawText(patient?.cnic || "-", {
    x: xPosition,
    y: yPosition - delta * 6.1,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Email
  page.drawText(patient?.email || "-", {
    x: xPosition,
    y: yPosition - delta * 7.2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Gender
  page.drawText(patient?.gender || "-", {
    x: xPosition,
    y: yPosition - delta * 8.2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Address
  page.drawText(patient?.address || "-", {
    x: xPosition,
    y: yPosition - delta * 9.2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // City
  page.drawText(patient?.city || "-", {
    x: xPosition,
    y: yPosition - delta * 10.25,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Test Center Address / Sample Collection Location
  const testCenter = getTestCenter(registration?.testCenter)
  page.drawText(testCenter?.address || "-", {
    x: xPosition,
    y: yPosition - delta * 11.15,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })
}

export const renderFlightInformation = (data, config) => {
  const { page, helveticaFont } = config
  const { registration } = data
  const xPosition = 170
  const yPosition = 380

  const delta = 22

  // Passport
  page.drawText(registration?.passport || "-", {
    x: xPosition,
    y: yPosition - delta * 1,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Airline
  page.drawText(capitalize(getAirline(registration?.airline) || "-"), {
    x: xPosition,
    y: yPosition - delta * 2,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Flight No.
  page.drawText(registration?.flightNo || "-", {
    x: xPosition,
    y: yPosition - delta * 3,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Booking Ref. / PNR / Ticket No
  page.drawText(registration?.pnr || "-", {
    x: xPosition,
    y: yPosition - delta * 4,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Flight Date.
  page.drawText(registration?.flightDate ? dayjs(registration.flightDate, "DD-MM-YYYY").format("DD/MM/YYYY") : "-", {
    x: xPosition,
    y: yPosition - delta * 5.1,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Departure
  page.drawText(registration?.departureAirport || "-", {
    x: xPosition,
    y: yPosition - delta * 6.15,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Destination
  page.drawText(capitalize(getAirport(registration?.destinationAirport) || "-"), {
    x: xPosition,
    y: yPosition - delta * 7.15,
    size: 9,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })
}
