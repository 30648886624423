import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useToast } from "@chakra-ui/core"
import FileSaver from "file-saver"
import Papa from "papaparse"
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"

import { generateMultipeRegistrationsViaCSV } from "../../../services/api"
import {
  getEmbassy,
  getGender,
  getPanel,
  getPaymentType,
  getReference,
  getRegistrationType,
} from "../../../utils/common"
import Badge from "../../common/Badge"

import "./MultiRegistrationViaCSVModal.scss"
import dayjs from "dayjs"

export default function MultiPatientRegistrationModal(props) {
  const { openModal, toggle, updateData } = props
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [csvData, setCSVData] = useState([])
  const [isCSVImported, setIsCSVImported] = useState(false)
  const [isHeadersValidated, setIsHeadersValidated] = useState(false)
  const [isCSVDataValidated, setIsCSVDataValidated] = useState(false)
  const [defectiveHeaders, setDefectiveHeaders] = useState([])
  const [defectiveRows, setDefectiveRows] = useState([])

  const { handleSubmit, register } = useForm({
    mode: "onSubmit",
    defaultValues: {
      uploadCSV: null,
    },
  })

  let csvRegistrationHeaders = [
    "tNo",
    "registrationType",
    "fullname",
    "gender",
    "email",
    "dob",
    "phone",
    "cnic",
    "passport",
    "paymentType",
    "panel",
    "reference",
    "embassy",
    "rapidPcrCharges",
    "charges",
    "iggCharges",
    "igmCharges",
    "travelCharges",
    "airline",
    "flightNo",
    "pnr",
    "flightDate",
    "flightTime",
    "departureAirport",
    "destinationAirport",
  ]

  const onSubmit = async () => {
    const params = { data: { registrations: csvData } }
    try {
      setIsLoading(true)
      await generateMultipeRegistrationsViaCSV(params)
      toggle()
      updateData()
      toast({
        title: "Registration process initiated successfully",
        description: "Registration process initiated successfully",
        status: "success",
        duration: 4000,
      })
    } catch (error) {
      toast({
        title: "Error While Generating Entries.",
        description: error?.response?.data?.errors?.message || error?.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true} size="lg">
      <ModalHeader toggle={toggle}>Generate Multiple Registrations - Import CSV</ModalHeader>
      <form className="cms-contact-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <div className="container">
            <div className="cms-contact-form">
              <div className="row">
                <div className="col-lg-6">
                  <button
                    type="button"
                    className="btn btn-outline w-100"
                    onClick={() => {
                      const url =
                        "https://firebasestorage.googleapis.com/v0/b/gcc-diagnostic.appspot.com/o/map-guides%2Fmap_guide.xlsx?alt=media&token=69f7b137-6033-4ce1-bfb7-dcf363f162ff"
                      FileSaver.saveAs(url)
                    }}
                  >
                    Download Guide
                  </button>
                </div>
                <div className="col-lg-6">
                  <button
                    type="button"
                    className="btn btn-outline w-100"
                    onClick={() => {
                      const url =
                        "https://firebasestorage.googleapis.com/v0/b/gcc-diagnostic.appspot.com/o/csv-samples%2Fsample_sheet_registrations.csv?alt=media&token=f774cd8a-7aec-4713-a849-ce99885ff951"
                      FileSaver.saveAs(url)
                    }}
                  >
                    Download Sample
                  </button>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 card p-3">
                  <h6>Choose File</h6>
                  <div>
                    <input
                      type="file"
                      name="uploadCSV"
                      ref={register}
                      onChange={e => {
                        const [file] = e.target.files
                        if (file) {
                          setIsCSVImported(true)

                          // Validate CSV
                          Papa.parse(file, {
                            complete: function (results) {
                              results?.data?.pop() // Removing last empty element

                              const headers = results?.data.shift()
                              const tempCSVData = results?.data

                              let tempDefectiveHeader = []

                              // Validate headers
                              headers.forEach((header, idx) => {
                                const isPresent = csvRegistrationHeaders.includes(header)
                                if (!isPresent) tempDefectiveHeader.push({ header, colNo: idx + 1 })
                              })

                              const isCSVHeadersValidated = tempDefectiveHeader?.length === 0 ? true : false

                              if (isCSVHeadersValidated) setIsHeadersValidated(true)
                              setDefectiveHeaders(tempDefectiveHeader)

                              /*
                              index - value
                              0-tNo
                              1-fullname
                              2-registrationType
                              3-dob
                              4-gender
                              5-phone
                              6-cnic
                              7-email
                              8-passport
                              9-paymentType
                              10-panel
                              11-reference
                              12-embassy
                              13-rapidPcrCharges
                              14-charges
                              15-igmCharges
                              16-iggCharges
                              17-travelCharges
                              18-airline
                              19-flightNo
                              20-pnr
                              21-flightDate
                              22-flightTime
                              23-departureAirport
                              24-destinationAirport
                              */
                              if (isCSVHeadersValidated) {
                                let tempDefectiveRow = []
                                let csvJsonData = []

                                tempCSVData?.forEach((data, idx) => {
                                  const tNo = data[0]
                                  const fullname = data[1]
                                  const registrationType = data[2]
                                  const dob = data[3]
                                  const gender = data[4]
                                  const phone = data[5]
                                  const cnic = data[6]
                                  const email = data[7]
                                  const passport = data[8]
                                  const paymentType = data[9]
                                  const panel = data[10]
                                  const reference = data[11]
                                  const embassy = data[12]
                                  const rapidPcrCharges = data[13]
                                  const charges = data[14]
                                  const igmCharges = data[15]
                                  const iggCharges = data[16]
                                  const travelCharges = data[17]
                                  const airline = data[18]
                                  const flightNo = data[19]
                                  const pnr = data[20]
                                  const flightDate = data[21]
                                  const flightTime = data[22]
                                  const departureAirport = data[23]
                                  const destinationAirport = data[24]

                                  csvJsonData.push({
                                    tNo,
                                    fullname,
                                    registrationType,
                                    dob,
                                    gender,
                                    phone,
                                    cnic,
                                    email,
                                    passport,
                                    paymentType,
                                    panel,
                                    reference,
                                    embassy,
                                    rapidPcrCharges,
                                    charges,
                                    igmCharges,
                                    iggCharges,
                                    travelCharges,
                                    airline,
                                    flightNo,
                                    pnr,
                                    flightDate,
                                    flightTime,
                                    departureAirport,
                                    destinationAirport,
                                  })
                                })

                                const lineNo = 2
                                csvJsonData?.forEach((data, idx) => {
                                  if (data?.fullname === "") {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "fullname should not be empty",
                                    })
                                  }

                                  if (
                                    data?.registrationType === "" ||
                                    getRegistrationType(data?.registrationType) === undefined
                                  ) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "registrationType should be either passenger or general",
                                    })
                                  }

                                  if (data?.dob !== "" && data?.dob !== `${dayjs(data?.dob).format("YYYY-MM-DD")}`) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "dob should not be in format (YYYY-MM-DD) i.e 1992-12-23",
                                    })
                                  }

                                  if (data?.gender === "" || getGender(data?.gender) === undefined) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "gender should be either male or female",
                                    })
                                  }

                                  const phonePattern = new RegExp(/^[0-9\b]+$/)
                                  if (data?.phone !== "" && !phonePattern.test(data?.phone)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid phone number",
                                    })
                                  }

                                  const cnicPattern = new RegExp(/^(\d{13})?$/)
                                  if (!cnicPattern.test(data?.cnic)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "CNIC should be a 13 digit number e.g (1111111111111)",
                                    })
                                  }

                                  var emailPattern = new RegExp(
                                    /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
                                  )
                                  if (data?.email !== "" && !emailPattern.test(data?.email)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid email",
                                    })
                                  }

                                  if (getPaymentType(data?.paymentType) === undefined) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "paymentType can be either cash, credit or left blank",
                                    })
                                  }

                                  if (data?.panel !== "" && getPanel(data?.panel) === undefined) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "panel should be china",
                                    })
                                  }

                                  if (data?.reference !== "" && getReference(data?.reference) === undefined) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid reference",
                                    })
                                  }

                                  if (data?.embassy !== "" && getEmbassy(data?.embassy) === undefined) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid embassy",
                                    })
                                  }

                                  const intPattern = new RegExp(/^\d+$/)
                                  if (data?.rapidPcrCharges !== "" && !intPattern.test(data?.rapidPcrCharges)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "rapidPcrCharges can only be a non-negative number",
                                    })
                                  }

                                  if (data?.charges !== "" && !intPattern.test(data?.charges)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "charges can only be a non-negative number",
                                    })
                                  }

                                  if (data?.igmCharges !== "" && !intPattern.test(data?.igmCharges)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "igmCharges can only be a non-negative number",
                                    })
                                  }

                                  if (data?.iggCharges !== "" && !intPattern.test(data?.iggCharges)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "iggCharges can only be a non-negative number",
                                    })
                                  }

                                  if (data?.travelCharges !== "" && !intPattern.test(data?.travelCharges)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "travelCharges can only be a non-negative number",
                                    })
                                  }

                                  // No flight details for general are permitted
                                  if (data?.registrationType === "general") {
                                    if (data?.airline && data?.airline !== "") {
                                      tempDefectiveRow.push({
                                        lineNo: lineNo + idx,
                                        error: "airline should not be in general patient category",
                                      })
                                    }

                                    if (data?.flightNo && data?.flightNo !== "") {
                                      tempDefectiveRow.push({
                                        lineNo: lineNo + idx,
                                        error: "flightNo should not be in general patient category",
                                      })
                                    }

                                    if (data?.pnr && data?.pnr !== "") {
                                      tempDefectiveRow.push({
                                        lineNo: lineNo + idx,
                                        error: "flightDate should not be in general patient category",
                                      })
                                    }

                                    if (data?.flightDate && data?.flightDate !== "") {
                                      tempDefectiveRow.push({
                                        lineNo: lineNo + idx,
                                        error: "flightDate should not be in general patient category",
                                      })
                                    }

                                    if (data?.flightTime && data?.flightTime !== "") {
                                      tempDefectiveRow.push({
                                        lineNo: lineNo + idx,
                                        error: "flightTime should not be in general patient category",
                                      })
                                    }

                                    if (data?.departureAirport && data?.departureAirport !== "") {
                                      tempDefectiveRow.push({
                                        lineNo: lineNo + idx,
                                        error: "departureAirport should not be in general patient category",
                                      })
                                    }

                                    if (data?.destinationAirport && data?.destinationAirport !== "") {
                                      tempDefectiveRow.push({
                                        lineNo: lineNo + idx,
                                        error: "destinationAirport should not be in general patient category",
                                      })
                                    }
                                  }

                                  if (data?.registrationType === "passenger") {
                                    //airline
                                    //flightNo
                                    //pnr
                                    //flightDate
                                    if (
                                      data?.flightDate !== "" &&
                                      data?.flightDate !== `${dayjs(data?.flightDate).format("YYYY-MM-DD")}`
                                    ) {
                                      tempDefectiveRow.push({
                                        lineNo: lineNo + idx,
                                        error: "flightDate should be in format (YYYY-MM-DD) i.e 1992-12-31",
                                      })
                                    }

                                    //flightTime
                                    const FLIGHT_TIME_REGEX = /^([01][0-9]|2[0-4]):([0-5][0-9])$/
                                    if (!FLIGHT_TIME_REGEX.test(data?.flightTime)) {
                                      tempDefectiveRow.push({
                                        lineNo: lineNo + idx,
                                        error: "flightTime should be in 24hrs format (HH:MM) i.e 13:45, 21:05 & 01:25",
                                      })
                                    }
                                    //departureAirport
                                    //destinationAirport
                                  }
                                })

                                const isCSVRowsValidated = tempDefectiveRow?.length === 0 ? true : false

                                if (isCSVRowsValidated) {
                                  setIsCSVDataValidated(isCSVRowsValidated)
                                  setCSVData(csvJsonData)
                                }

                                setDefectiveRows(tempDefectiveRow)
                              }
                            },
                          })
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3">
                  {isCSVImported && <Badge type={isHeadersValidated ? "success" : "danger"} label={"CSV Headers"} />}
                </div>
                <div className="col-3">
                  {isCSVImported && <Badge type={isCSVDataValidated ? "success" : "danger"} label={"CSV Data"} />}
                </div>
              </div>
              {defectiveHeaders?.length > 0 && (
                <>
                  <span className="text-danger font-xsm text-bold mt-2">Error</span>

                  {defectiveHeaders?.map((data, idx) => {
                    return (
                      <div
                        className="w-100 mt-1"
                        style={{ backgroundColor: "#feeceb", borderRadius: "4px" }}
                        key={`error-${idx}`}
                      >
                        <span className="font-xsm text-danger ml-1" key={`defective-headers-${idx}`}>
                          col#{data?.colNo} - title: {data?.header}{" "}
                        </span>
                      </div>
                    )
                  })}
                </>
              )}

              {defectiveRows?.length > 0 && (
                <>
                  <span className="text-danger font-xsm text-bold mt-2">Error</span>

                  {defectiveRows?.map((data, idx) => {
                    return (
                      <div
                        className="w-100 mt-1"
                        style={{ backgroundColor: "#feeceb", borderRadius: "4px" }}
                        key={`error-${idx}`}
                      >
                        <span className="font-xsm text-danger ml-1" key={`defective-rows-${idx}`}>
                          line#{data?.lineNo} - {data?.error}{" "}
                        </span>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="submit"
            className="btn btn-secondary col-lg-2"
            disabled={isLoading || !isHeadersValidated || !isCSVDataValidated}
          >
            Generate
            {isLoading && <Spinner color="default" className="ml-2" size="sm" />}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
