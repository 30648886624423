import React from "react"
import { Link } from "react-router-dom"

import { useOvermind } from "../../../overmind"
import { AllRoutesMap } from "../../../routes/RoutesConfig"

export default function Header(props) {
  const { actions, state } = useOvermind()

  return (
    <header id="masthead" className="site-header">
      <div id="site-header-wrap" className="header-layout1 fixed-height is-sticky">
        <div id="site-header" className="site-header-main">
          <div className="container-fluid">
            <div className="row">
              <div className="site-branding">
                <Link to={AllRoutesMap.landing} className="logo-light" title="Global Clinical Care Diagnostic">
                  <img src="assets/images/logo-dark.png" alt="Global Clinical Care Diagnostic" />{" "}
                </Link>
                <Link to={AllRoutesMap.landing} className="logo-dark" title="Global Clinical Care Diagnostic">
                  <img src="assets/images/logo-dark.png" alt="Global Clinical Care Diagnostic" />
                </Link>
                <Link to={AllRoutesMap.landing} className="logo-mobile" title="Global Clinical Care Diagnostic">
                  <img src="assets/images/logo-dark.png" alt="Global Clinical Care Diagnostic" />
                </Link>
              </div>
              <div className="ml-auto">
                <div className="site-header-item site-header-button">
                  {props.showDownloadForm && (
                    <Link
                      to="assets/downloads/registration-form.pdf"
                      target="_blank"
                      className="btn btn-danger mr-2"
                      title="Download Registration Form"
                    >
                      <i className="fas fa-download space-right"></i> Download Form
                    </Link>
                  )}
                  {state.auth.isAuthenticated && (
                    <button className="btn btn-outline-white btn-logout" title="Logout" onClick={actions.auth.logout}>
                      <i className="fas fa-sign-out space-right"></i> LOGOUT
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
