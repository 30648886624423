import React, { Fragment, useState } from "react"
import { useForm } from "react-hook-form"
import { useToast } from "@chakra-ui/core"
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"

import { updateSampleDateAndTime } from "../../services/api"
import dayjs from "dayjs"

export default function EditSampleAndReportTimeModal(props) {
  const { openModal, toggle, updateData, registration } = props
  const { registrationId, sampleTakenAt, reportVerifiedAt } = registration

  const sampleTakenDate = dayjs(sampleTakenAt).format("YYYY-MM-DD")
  const sampleTakenTime = dayjs(sampleTakenAt).format("HH:mm")
  const reportVerficationDate = dayjs(reportVerifiedAt).format("YYYY-MM-DD")
  const reportVerficationTime = dayjs(reportVerifiedAt).format("HH:mm")

  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    defaultValues: {
      sampleTakenDate: sampleTakenDate,
      sampleTakenTime: sampleTakenTime,
      reportVerficationDate: reportVerficationDate,
      reportVerficationTime: reportVerficationTime,
    },
  })

  const onSubmit = async formData => {
    const data = { registrationId, ...formData }
    try {
      setIsLoading(true)

      await updateSampleDateAndTime(registrationId, data)

      updateData()
      toggle()

      toast({
        title: "Sample date & time updated successfully",
        description: "Sample date & time updated successfully",
        status: "success",
        duration: 5000,
      })
    } catch (error) {
      console.log(error)
      toast({
        title: "There was error while updating sample date & time",
        description: "There was error while updating sample date & time",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Fragment>
      <Modal isOpen={openModal} toggle={toggle} centered={true} size="md">
        <ModalHeader toggle={toggle}>Edit Date & Time</ModalHeader>
        <form className="cms-contact-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="sampleTakenAt">Choose Sample Date</label>

                    <input type="date" name="sampleTakenDate" ref={register} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label for="sampleTakenAt">Choose Sample Time</label>

                    <input type="time" name="sampleTakenTime" ref={register} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="reportVerficationDate">Choose Report Date</label>

                    <input type="date" name="reportVerficationDate" ref={register} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label for="reportVerficationTime">Choose Report Time</label>

                    <input type="time" name="reportVerficationTime" ref={register} />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="justify-content-between">
              <div></div>
              <div>
                <button type="submit" className="btn btn-primary" name="submit">
                  <i className="fas fa-pencil mr-2"></i>
                  Update {isLoading && <Spinner color="default" className="ml-2" size="sm" />}
                </button>
              </div>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  )
}
