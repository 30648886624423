import React, { useState } from "react"
import * as yup from "yup"
import { Spinner, useToast } from "@chakra-ui/core"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import RequiredFieldMark from "../common/RequiredFieldMark"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { useState as useOvermindState } from "../../overmind"

const schema = yup.object().shape({
  downloadOption: yup.string().required("Download option is required."),
  fileType: yup.string().required("File type is required."),
})

export default function MultiOptionalDownloadModal(props) {
  const { openModal, toggle, multiDownload, toggleMultiSelectionToolbar } = props

  const { user } = useOvermindState().auth

  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    defaultValues: {
      downloadOption: "",
      fileType: "",
    },
  })

  const onSubmit = formData => {
    try {
      setIsLoading(true)
      multiDownload(formData)
      toast({
        title: "Downloading initiated successfully.",
        description: "Downloading initiated successfully.",
        status: "success",
        duration: 3000,
      })
      toggle()
      toggleMultiSelectionToolbar()
    } catch (error) {
      console.log(error)
      toast({
        title: "There was an error while downloading file(s).",
        description: "There was an error while downloading file(s).",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const isAdmin = user.role === "admin" ? true : false
  const isStaff = user.role === "staff" ? true : false

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Multi Optional Download Manual</ModalHeader>
      <form className="cms-contact-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-12">
                <div>
                  <h6>
                    Download Options <RequiredFieldMark />
                  </h6>
                </div>
                <div>
                  <select name="downloadOption" ref={register}>
                    <option value="">Select Option</option>
                    <option value="report">Report</option>
                    {(isAdmin || isStaff) && <option value="invoice">Invoice</option>}
                  </select>
                  <span className="text-danger">{errors.downloadOption?.message}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <h6>
                    File Type <RequiredFieldMark />
                  </h6>
                </div>
                <div>
                  <select name="fileType" ref={register}>
                    <option value="">Select File Type</option>
                    <option value="zip">Zip</option>
                    <option value="unzip">Unzip</option>
                  </select>
                  <span className="text-danger">{errors.fileType?.message}</span>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-secondary">
            Download {isLoading && <Spinner color="default" className="ml-2" size="sm" />}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
