export const renderHeader = () => {
  return (
    <table
      style={{
        width: "100%",
        margin: "auto",
        padding: "1rem 0",
        borderCollapse: "collapse",
        borderSpacing: "0",
        border: "0px solid",
      }}
    >
      <tbody>
        <tr>
          <td align="center" style={{ border: "none", paddingLeft: 0, paddingRight: 0 }}>
            <div className="d-flex align-items-center justify-content-between">
              <img
                src="assets/images/logo-dark.png"
                style={{
                  maxHeight: "120px",
                }}
                alt="Emirates Logo"
              />

              <div style={{ height: "5rem" }}>
                <h4
                  style={{
                    width: "100%",
                    margin: "0rem auto",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  Patient Invoice
                </h4>
              </div>
              <img
                src="assets/images/blank.png"
                style={{
                  maxHeight: "120px",
                }}
                alt="Blank Space For Profile DP"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const renderPatientTypeInformation = () => {
  return (
    <table
      className="mt-4"
      style={{
        width: "100%",
        padding: "0.5rem 0",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          <td align="left" width="35%">
            <div className="d-flex align-items-center">
              <h6
                className="mb-0"
                style={{
                  fontWeight: "bold",
                }}
              >
                Type
              </h6>
              <div className="d-flex align-items-center ml-3">
                <img
                  src="assets/images/checkbox.png"
                  style={{
                    maxHeight: "20px",
                    marginRight: "0.5rem",
                  }}
                  alt="Checkbox"
                />
                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  Regular
                </h6>
              </div>
              <div className="d-flex align-items-center ml-5">
                <img
                  src="assets/images/checkbox.png"
                  style={{
                    maxHeight: "20px",
                    marginRight: "0.5rem",
                  }}
                  alt="Checkbox"
                />

                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  Passenger
                </h6>
              </div>
            </div>
          </td>
          <td align="left" width="65%">
            <div className="d-flex align-items-center">
              <h6
                className="mb-0"
                style={{
                  fontWeight: "bold",
                }}
              >
                <span> &nbsp;</span>
              </h6>
              <div className="d-flex align-items-center ml-3">
                <h6 className="m-0" style={{ fontWeight: "bold" }}>
                  <span> &nbsp;</span>
                </h6>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const renderSubHeader = () => {
  return (
    <table
      style={{
        width: "100%",
        padding: "0.5rem 0",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          <td align="left" width="23%">
            <h6
              style={{
                margin: 0,
                fontWeight: "bold",
              }}
            >
              Patient Name
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "normal",
              }}
            >
              <span>&nbsp;</span>
            </h6>
          </td>
          <td align="left" width="15%">
            <h6
              style={{
                margin: 0,
                fontWeight: "bold",
              }}
            >
              Passport
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "normal",
              }}
            >
              <span>&nbsp;</span>
            </h6>
          </td>
          <td align="left" width="15%">
            <h6
              style={{
                margin: 0,
                fontWeight: "bold",
              }}
            >
              CNIC
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "normal",
              }}
            >
              <span>&nbsp;</span>
            </h6>
          </td>
          <td align="left" width="18%">
            <h6
              style={{
                margin: 0,
                fontWeight: "bold",
              }}
            >
              Panel
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "normal",
              }}
            >
              <span>&nbsp;</span>
            </h6>
          </td>
          <td align="left" width="28%">
            <h6
              style={{
                margin: 0,
                fontWeight: "normal",
              }}
            >
              Sample Time: <span>&nbsp;</span>
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "normal",
              }}
            >
              MR #: <span> &nbsp;</span>
            </h6>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const renderInvoiceHeader = () => (
  <>
    <h5
      style={{
        margin: 0,
        textAlign: "center",
      }}
    >
      Please do not lose this receipt. You'll need MR# to view reports online!
    </h5>
    <br />
    <h5
      style={{
        margin: 0,
        textAlign: "center",
      }}
    >
      INVOICE DETAILS
    </h5>
    <br />
  </>
)

const renderBillInformation = () => (
  <>
    <table
      style={{
        width: "100%",
        padding: "0.5rem 0",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          <td align="left">
            <h6
              style={{
                margin: 0,
              }}
            >
              Sr.
            </h6>
          </td>

          <td>
            <h6
              style={{
                margin: 0,
              }}
            >
              Items
            </h6>
          </td>
          <td colSpan="2">
            <h6
              style={{
                margin: 0,
              }}
            >
              Bill Amount (PKR)
            </h6>
          </td>
        </tr>
        <tr style={{ height: "150px" }}>
          <td align="left"></td>
          <td></td>
          <td colSpan="2"></td>
        </tr>
        <tr>
          <td style={{ borderRightColor: "transparent" }}></td>
          <td>
            <h6
              style={{
                margin: 0,
                textAlign: "right",
              }}
            >
              Net Bill Amount
            </h6>
          </td>
          <td colSpan="2">
            <p
              style={{
                margin: 0,
                color: "black",
                fontWeight: "400",
              }}
            >
              <span> &nbsp;</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </>
)

const renderPassengerInformation = () => (
  <>
    <br />
    <hr />
    <br />
    <h5
      style={{
        margin: 0,
        textAlign: "center",
      }}
    >
      COLLECTION POINT
    </h5>
    <br />
    <table
      style={{
        width: "100%",
        padding: "0.5rem 0",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          <td align="left">
            <h6
              style={{
                margin: 0,
              }}
            >
              Address
            </h6>
            <p
              style={{
                margin: 0,
                fontWeight: "400",
                color: "black",
              }}
            >
              <span> &nbsp;</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </>
)

const renderDisclaimer = () => (
  <>
    <hr />
    <ul>
      <li>
        {/* Disclaimer: Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Temporibus unde cum quo eaque necessitatibus quod voluptatum vero. Unde
          aspernatur commodi non quisquam earum atque cumque asperiores
          accusantium. Quas, est repellendus architecto labore maiores consequatur
          cumque aliquam nobis voluptatem minus consectetur eum necessitatibus. */}
      </li>
    </ul>
  </>
)

const InvoiceTemplate = () => {
  return (
    <div
      style={{
        maxWidth: "1000px",
        margin: "auto",
        backgroundColor: "white",
        minHeight: "100vh",
        paddingBottom: "2rem",
      }}
    >
      {renderHeader()}
      {renderPatientTypeInformation()}
      {renderSubHeader()}
      {renderInvoiceHeader()}
      {renderBillInformation()}
      {renderPassengerInformation()}
      {renderDisclaimer()}

      <button className="no-print" onClick={() => window.print()}>
        PRINT
      </button>
    </div>
  )
}

export default InvoiceTemplate
