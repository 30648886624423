// --- Recover soft deleted patient --- //
import React, { Fragment, useState, useEffect } from "react"
import { useDebounce } from "../hooks/UseDebounce"
import { useHistory } from "react-router-dom"
import { AllRoutesMap } from "../routes/RoutesConfig"
import { Alert, Spinner } from "reactstrap"
import Pagination from "react-js-pagination"
import { Checkbox } from "@chakra-ui/core"

import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"

import { getPatientsWithFilters } from "../services/api"
import { getPassport } from "../utils/common"

import DatePicker from "../components/common/DatePicker"
import PatientRecoverModal from "../components/modals/PatientRecoverModal"
import DeletePatientModal from "../components/modals/DeletePatientModal"
import MultiSelection from "../components/common/MultiSelection"
import FullScreenLoader from "../components/loaders/FullScreenLoader"
import { handleMultiCheckboxSelection } from "../utils/mutiple-checkbox-selection/common"
import dayjs from "dayjs"

const getConvertedDateWithStartOfDay = date => {
  if (date) {
    return dayjs(date).startOf("day").toISOString()
  }
  return null
}

const getConvertedDateWithEndOfDay = date => {
  if (date) {
    return dayjs(date).endOf("day").toISOString()
  }
  return null
}

const getFilterDateRange = dateRangeData => {
  let dateRange = {}
  const startDate = getConvertedDateWithStartOfDay(dateRangeData.startDate)
  const endDate = getConvertedDateWithEndOfDay(dateRangeData.endDate)
  if (startDate) {
    dateRange["startDate"] = startDate
  }
  if (endDate) {
    dateRange["endDate"] = endDate
  }
  return dateRange
}

const PatientRecovery = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [multiLoading, setMultiLoading] = useState(false)
  const [registrations, setRegistrations] = useState([])
  const [filter] = useState({})
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(false)
  const [reportIds, setReportIds] = useState([])
  const [isAllReportIdSelected, setIsAllReportIdSelected] = useState(false)
  const [openMultiSelectionToolbar, setOpenMultiSelectionToolbar] = useState(false)
  const [targetPage, setTargetPage] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  })
  //const [filters, setFilters] = useState({ ...getFilterDateRange(dateRange) })
  const [patientRecoverModal, setPatientRecoverModal] = useState({ isOpen: false, id: null, name: "" })
  const [deletePatientModal, setDeletePatientModal] = useState({ isOpen: false, id: null, name: "" })
  const debouncedValue = useDebounce(targetPage)
  const debouncedLimitRecordValue = useDebounce(limit)
  const count = Math.ceil(registrations.total / limit)

  useEffect(() => {
    initSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (debouncedValue && debouncedValue !== activePage && !loading) {
      goToPage(Number(debouncedValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  useEffect(() => {
    if (debouncedLimitRecordValue && !loading) {
      initSearch(0, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLimitRecordValue])

  const getRegistrationIds = registrations => {
    return registrations?.map(({ registrationId }) => ({ registrationId }))
  }

  const initSearch = async (
    updatedOffset = offset,
    pageNo = activePage,
    filters = { ...getFilterDateRange(dateRange) }
  ) => {
    if (updatedOffset >= 0) {
      try {
        setLoading(true)
        const res = await getPatientsWithFilters({
          limit,
          offset: updatedOffset,
          filters: filters || {},
          isRecover: true,
        })

        if (res?.data?.registrations) {
          setRegistrations(res?.data?.registrations)
          const reportIds = getRegistrationIds(res?.data?.registrations?.data)
          setReportIds(reportIds)
          setOffset(updatedOffset)
          setActivePage(pageNo)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const toggleDeletePatientModal = registration => {
    const data = {
      isOpen: !deletePatientModal.isOpen,
      id: registration?.registrationId || null,
      name: registration?.patient?.fullname || "",
    }
    setDeletePatientModal(data)
  }

  const togglePatientRecoverModal = registration => {
    const data = {
      isOpen: !patientRecoverModal.isOpen,
      id: registration?.registrationId || null,
      name: registration?.patient?.fullname || "",
    }
    setPatientRecoverModal(data)
  }

  const toggleMultiSelectionToolbar = () => {
    setOpenMultiSelectionToolbar(!openMultiSelectionToolbar)
  }

  const goToPage = pageNo => {
    const updatedOffset = (pageNo - 1) * limit
    const filters = {
      ...getFilterDateRange(dateRange),
      ...filter,
    }
    initSearch(updatedOffset, pageNo, filters)
    setTargetPage(pageNo)
    reset()
  }

  const onRecoverPatient = () => {
    if (registrations.data.length === 1 && registrations.total > 0) {
      goToPage(activePage - 1)
    } else {
      goToPage(activePage)
    }
  }

  const onClearDateRange = () => {
    const updatedDateRange = { startDate: null, endDate: null }
    setDateRange(updatedDateRange)
    const filters = {
      ...getFilterDateRange(updatedDateRange),
      ...filter,
    }
    initSearch(0, 1, filters)
  }

  const onChangeDateRange = (date, key) => {
    const updatedDateRange = { ...dateRange, [key]: date }
    setDateRange(updatedDateRange)
    const filters = {
      ...getFilterDateRange(updatedDateRange),
      ...filter,
    }
    //setFilters(filters)
    initSearch(0, 1, filters)
  }

  const handleJumpToPage = e => {
    const re = /^[0-9\b]+$/
    const page = e.target.value
    if (page === "" || (re.test(page) && Number(page) <= count)) {
      setTargetPage(page)
    }
  }

  // const onDeletePatient = () => {
  //   if (registrations.data.length === 1 && registrations.total > 0) {
  //     goToPage(activePage - 1)
  //   } else {
  //     goToPage(activePage)
  //   }
  // }

  const handleLimitRecords = e => {
    const re = /^[0-9\b]+$/

    const recordsLimit = Number(e.target.value)
    const total = registrations?.total

    if (recordsLimit === "" || (re.test(recordsLimit) && recordsLimit <= total)) {
      setLimit(recordsLimit)
    }
  }

  const handleOnChange = e => {
    const { name, checked } = e.target

    const { updatedReportIds, isAllChecked, openMultiSelectionToolbar } = handleMultiCheckboxSelection({
      name,
      checked,
      reportIds,
    })

    setReportIds(updatedReportIds)
    setIsAllReportIdSelected(isAllChecked)
    setOpenMultiSelectionToolbar(openMultiSelectionToolbar)
  }

  const reset = () => {
    const updatedReportIds = reportIds?.map(registration => ({ ...registration, isChecked: false }))
    setReportIds(updatedReportIds)
    setIsAllReportIdSelected(false)
  }

  const clearIcon = <i className="fas fa-times" />

  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay" style={{ backgroundColor: "#092031" }}></div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="text-white mb-0">
                    <i className="fas fa-window-restore mr-4"></i>Global Clinical Care Patient Recovery Portal
                  </h3>
                </div>
                <div className="col-md-4 text-right">
                  <button
                    className="btn btn-secondary"
                    title="Home"
                    onClick={() => {
                      history.push(AllRoutesMap.manage)
                    }}
                  >
                    <i className="fas fa-cogs mr-2"></i>
                    Manage
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="card p-4">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group mr-2">
                    <label className="text-bold">Start Date</label>
                    <div className="d-flex">
                      <div className="cms-input date-wrap w-100">
                        <DatePicker
                          placeholder="Select Date"
                          id="start-date-picker"
                          onChange={date => onChangeDateRange(date, "startDate")}
                          value={dateRange.startDate}
                          className="w-100"
                          clearIcon={clearIcon}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="text-bold">End Date</label>
                    <div className="d-flex">
                      <div className="cms-input date-wrap w-100">
                        <DatePicker
                          placeholder="Select Date"
                          id="end-date-picker"
                          onChange={date => onChangeDateRange(date, "endDate")}
                          value={dateRange.endDate}
                          className="w-100"
                          clearIcon={clearIcon}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <button onClick={onClearDateRange} className="btn btn-secondary btn-clear w-100">
                    Clear
                  </button>
                </div>

                <div className="form-group c-spinner text-center ml-2">{loading && <Spinner color="primary" />}</div>
              </div>
              {!loading && registrations?.data?.length === 0 && (
                <Alert color="warning" className="mb-0">
                  No results found.
                </Alert>
              )}

              {openMultiSelectionToolbar && (
                <MultiSelection
                  toggle={toggleMultiSelectionToolbar}
                  selectedReportIds={reportIds.filter(reportId => reportId?.isChecked === true)}
                  reset={reset}
                  isMultiLoading={multiLoading}
                  setIsMultiLoading={setMultiLoading}
                  updateData={() => initSearch(offset, activePage, { ...getFilterDateRange(dateRange) })}
                  isHardDelete={true}
                />
              )}

              {registrations?.data?.length > 0 && (
                <div className="row c-table">
                  <div className="col-12">
                    <div className="table-responsive table-hover">
                      <table className="table">
                        <thead className="table-header-bg">
                          <tr>
                            <th scope="col">
                              <label>
                                <Checkbox
                                  size="sm"
                                  colorScheme="gray"
                                  className="c-checkbox mt-2"
                                  name="selectAll"
                                  isChecked={isAllReportIdSelected}
                                  onChange={handleOnChange}
                                ></Checkbox>
                              </label>
                            </th>
                            <th scope="col">Sr#</th>
                            <th scope="col">T No.</th>
                            <th scope="col">Lab Id</th>
                            <th scope="col">Name</th>
                            <th scope="col">Passport#</th>
                            <th scope="col">CNIC</th>
                            <th scope="col">Sample Date/Time</th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {registrations.data.map((registration, idx) => (
                            <tr key={registration.registrationId}>
                              <td>
                                <label>
                                  <Checkbox
                                    size="sm"
                                    colorScheme="gray"
                                    className="c-checkbox mt-2"
                                    name={registration?.registrationId}
                                    isChecked={reportIds[idx]?.isChecked}
                                    key={`checkbox-${registration?.registrationId}-${idx}`}
                                    onChange={handleOnChange}
                                  ></Checkbox>
                                </label>
                              </td>
                              <td>{idx + 1 + offset}</td>
                              <td>{registration?.tNo}</td>
                              <td>{registration.labId}</td>
                              <td>{registration.patient?.fullname}</td>
                              <td>{getPassport(registration) || "-"}</td>
                              <td>{registration.patient?.cnic || "-"}</td>

                              <td>{dayjs(registration.createdAt).format("DD/MM/YYYY hh:mm A")}</td>
                              <td className={`text-center`}>
                                <button
                                  className="btn-action bg-danger border-0 mr-1"
                                  onClick={() => {
                                    toggleDeletePatientModal(registration)
                                  }}
                                  title="Delete Patient"
                                  disabled={openMultiSelectionToolbar}
                                >
                                  <i className="fas fa-trash" />
                                </button>
                                <button
                                  className="btn-action bg-success border-0 mr-1"
                                  onClick={() => {
                                    togglePatientRecoverModal(registration)
                                  }}
                                  title="Recover Patient"
                                  disabled={openMultiSelectionToolbar}
                                >
                                  <i className="fas fa-window-restore" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={limit}
                          totalItemsCount={registrations.total}
                          pageRangeDisplayed={5}
                          onChange={goToPage}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                      <div className="col-md-3 d-flex">
                        <span className="text-dark pt-2">Jump to Page</span>
                        <input
                          type="number"
                          disabled={loading}
                          value={targetPage}
                          className="jump-to-page ml-2"
                          placeholder="1"
                          onChange={handleJumpToPage}
                        />
                      </div>
                      <div className="col-md-3 d-flex">
                        <span className="text-dark pt-2">Limit records</span>
                        <input
                          type="number"
                          disabled={loading}
                          value={limit}
                          className="jump-to-page ml-2"
                          onChange={handleLimitRecords}
                        />
                      </div>
                      <div className="col-md-3 pt-2">
                        <span className="pull-right text-dark">
                          Showing:{" "}
                          {`${offset + 1} to ${Math.min(limit + offset, registrations.total)} of ${
                            registrations.total
                          } records`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {multiLoading && <FullScreenLoader />}
            </div>
          </div>

          {deletePatientModal.isOpen && (
            <DeletePatientModal
              openModal={deletePatientModal.isOpen}
              toggle={toggleDeletePatientModal}
              data={deletePatientModal}
              callback={() => initSearch(offset, activePage, { ...getFilterDateRange(dateRange) })}
              isHardDelete={true}
            />
          )}

          {patientRecoverModal.isOpen && (
            <PatientRecoverModal
              openModal={patientRecoverModal.isOpen}
              toggle={togglePatientRecoverModal}
              data={patientRecoverModal}
              callback={onRecoverPatient}
            />
          )}
        </section>
      </Main>
    </Fragment>
  )
}

export default PatientRecovery
