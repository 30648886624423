import * as yup from "yup"

import { useState, useEffect } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap"
import { CSVLink } from "react-csv"
import { Alert, useToast } from "@chakra-ui/core"

import RequiredFieldMark from "../common/RequiredFieldMark"
import DatePicker from "../common/DatePicker"
import { getCSVData } from "../../services/api"
import { getFormattedCSVData, covidTestCategories } from "../../utils/common"
import dayjs from "dayjs"
import Select from "react-select"

const customStyles = {
  control: provided => {
    const background = "#f1f5fe"
    const border = "1px solid #f1f5fe"
    return { ...provided, background, border }
  },
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "#07152a",
  }),
}

const schema = yup.object().shape({
  startDate: yup.date().required("Start date is required"),
  endDate: yup.date().required("End date is required"),
})

export default function ExportCSVDataModal(props) {
  const toast = useToast()
  const { openModal, toggle, isAdmin, isLab, client } = props
  const [isLoading, setIsLoading] = useState(false)

  const [csvData, setCSVData] = useState(null)
  const csvHeader = isAdmin
    ? [
        { label: "S#", key: "sNo" },
        { label: "T#", key: "tNo" },
        { label: "Mr#", key: "mrNo" },
        { label: "Lab#", key: "labNo" },
        { label: "Sample Booking Date/Time", key: "sampleTakenAt" },
        { label: "Result Date/Time", key: "reportVerifiedAt" },
        { label: "Patient Name", key: "patientName" },
        { label: "Patient Type (General, Embassy)", key: "registrationType" },
        { label: "Embassy", key: "embassy" },
        { label: "Phone#", key: "phone" },
        { label: "Passport#", key: "passport" },
        { label: "CNIC", key: "cnic" },
        { label: "Airline", key: "airline" },
        { label: "Flight#", key: "flightNo" },
        { label: "Booking Ref/PNR", key: "pnr" },
        { label: "Flight Date", key: "flightDate" },
        { label: "Departure", key: "departureAirport" },
        { label: "Destination", key: "destinationAirport" },
        { label: "PCR Test", key: "result" },
        { label: "IgM Value", key: "antibodyResultRange" },
        { label: "IgG Value", key: "iggResultRange" },
        { label: "Travel Charges", key: "travelCharges" },
        { label: "Net Bill", key: "netBill" },
      ]
    : isLab
    ? [
        { label: "S#", key: "sNo" },
        { label: "T#", key: "tNo" },
        { label: "Mr#", key: "mrNo" },
        { label: "Barcode#", key: "labNo" },
        { label: "Test Type", key: "testType" },
        { label: "Patient Name", key: "patientName" },
        { label: "Passport#", key: "passport" },
        { label: "CNIC", key: "cnic" },
        { label: "Phone#", key: "phone" },
        { label: "PCR Test", key: "result" },
        { label: "IgM Value", key: "antibodyResultRange" },
        { label: "Travel Charges", key: "travelCharges" },
        { label: "IgG Value", key: "iggResultRange" },
      ]
    : [
        { label: "S#", key: "sNo" },
        { label: "Mr#", key: "mrNo" },
        { label: "Patient Name", key: "patientName" },
        { label: "Passport#", key: "passport" },
        { label: "CNIC", key: "cnic" },
        { label: "Phone#", key: "phone" },
        { label: "PCR Test", key: "result" },
        { label: "IgM Value", key: "antibodyResultRange" },
        { label: "IgG Value", key: "iggResultRange" },
      ]

  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
    },
  })

  useEffect(() => {
    if (openModal) {
      setIsLoading(false)
    }
  }, [openModal])

  const onSubmit = async formData => {
    const { startDate, endDate, testType } = formData
    setIsLoading(true)
    setCSVData(null)
    const normalizedStartISODate = dayjs(new Date(startDate)).startOf("day").toISOString()
    const normalizedEndISODate = dayjs(new Date(endDate)).endOf("day").toISOString()
    try {
      const data = {
        startDate: normalizedStartISODate,
        endDate: normalizedEndISODate,
        testType: testType?.value,
        client,
      }
      const res = await getCSVData(data)
      const formattedData = getFormattedCSVData(res?.data)
      setCSVData(formattedData)

      toast({
        title: "Data generated",
        description: "Click download button to download CSV file",
        status: "success",
        duration: 5000,
      })
    } catch (error) {
      toast({
        title: "There was an error generating data.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: false,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true} size="md">
      <ModalHeader toggle={toggle}>Export Data</ModalHeader>
      <ModalBody>
        <form className="cms-contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6 pr-0">
              <div className="form-group">
                <label className="text-bold">
                  Start Date <RequiredFieldMark />
                </label>
                <div className="d-flex">
                  <div className="cms-input date-wrap w-100">
                    <Controller
                      control={control}
                      name="startDate"
                      render={({ onChange, value }) => (
                        <DatePicker
                          placeholder="Select Date"
                          id="start-date-picker"
                          onChange={onChange}
                          value={value}
                          className="w-100"
                        />
                      )}
                    />
                    <span className="text-danger">{errors.startDate?.message}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 pr-0">
              <div className="form-group">
                <label className="text-bold">
                  End Date <RequiredFieldMark />
                </label>
                <div className="d-flex">
                  <div className="cms-input date-wrap w-100">
                    <Controller
                      control={control}
                      name="endDate"
                      render={({ onChange, value }) => (
                        <DatePicker
                          placeholder="Select Date"
                          id="end-date-picker"
                          onChange={onChange}
                          value={value}
                          className="w-100"
                        />
                      )}
                    />
                    <span className="text-danger">{errors.endDate?.message}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="text-bold">Filter</label>
                <Controller
                  control={control}
                  name="testType"
                  render={({ onChange, value }) => (
                    <Select
                      label="Filter"
                      isLoading={isLoading}
                      isDisabled={isLoading}
                      classNamePrefix="select"
                      className="c-select"
                      onChange={onChange}
                      value={value}
                      styles={customStyles}
                      options={covidTestCategories}
                      closeMenuOnSelect={true}
                      isClearable
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button type="submit" className="btn btn-secondary w-100" disabled={isLoading}>
                Export
                {isLoading && <Spinner color="default" className="ml-2" size="sm" />}
              </button>

              <div className="mt-3">
                {csvData?.length === 0 && <Alert color="warning">No data found between these days.</Alert>}

                {csvData?.length > 0 && (
                  <CSVLink
                    filename={"patient-list.csv"}
                    style={{ whiteSpace: "nowrap" }}
                    className="btn btn-outline-secondary d-flex align-items-center justify-content-center"
                    data={csvData}
                    headers={csvHeader}
                  >
                    Download
                  </CSVLink>
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}
