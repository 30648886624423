import { PDFDocument, StandardFonts, rgb, grayscale } from "pdf-lib"
import { openPDFNewTab } from "../../utils/pdfs"
import { getCovidResult, getNumberOfPagesForPdfs } from "../../utils/common"
import dayjs from "dayjs"

export default async function createPDFSheet(result) {
  const { patientData, isDownload } = result

  const numberOfPages = getNumberOfPagesForPdfs(patientData)
  const pdfDoc = await PDFDocument.create()

  const pages = []

  for (let index = 1; index <= numberOfPages; index++) {
    pages.push(pdfDoc.addPage())
  }
  //const page = pdfDoc.addPage()

  //Embed Helvetica Font
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
  const courierFont = await pdfDoc.embedFont(StandardFonts.Courier)

  const config = {
    pages,
    helveticaFont,
    helveticaBoldFont,
    courierFont,
    pdfDoc,
  }

  await renderPageHeader(config)
  renderPatientInformationOnPdfPage(config, patientData)

  const pdfBytes = await pdfDoc.save()

  if (isDownload) {
    const sheetName = `patient-${patientData[0]?.patient.fullname}-medical-history-sheet.pdf`
    // Trigger the browser to download the PDF document
    window.download(pdfBytes, sheetName, "application/pdf")
  } else {
    openPDFNewTab(pdfBytes, true)
  }
}

const renderPageHeader = async config => {
  const { pages, helveticaBoldFont } = config
  let page = pages[0]

  const pngImageBytes = await fetch("assets/images/logo-dark.png").then(res => res.arrayBuffer())
  //Embed png image bytes and jpeg image bytes
  const pngImage = await config.pdfDoc.embedPng(pngImageBytes)
  // Get the width/height of the PNG image scaled down to 50% of its original size
  const pngDims = pngImage.scaleToFit(130, 130)

  //Global Clincal Care
  page.drawImage(pngImage, {
    x: 28,
    y: 725,
    width: pngDims.width,
    height: pngDims.height,
  })

  // Render Sheet Title
  page.drawText("PATIENT COVID-19 MEDICAL HISTORY", {
    x: 168,
    y: 775,
    size: 15,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })
}

const renderPatientInformationOnPdfPage = (config, patientData) => {
  //Rendering patient information
  renderPatientName(config, patientData)
  renderPatientInformationTableHeader(config)
  renderPatientInformationTableBody(config, patientData)
}

// Table creation utilities
const renderContainer = params => {
  const { page, x, y, width, height } = params

  page.drawRectangle({
    x: x,
    y: y,
    width: width,
    height: height,
    borderWidth: 1,
    borderColor: grayscale(0.5),
    opacity: 0.5,
    borderOpacity: 0.5,
  })
}
const renderColumnsForTableHeader = params => {
  const { page } = params

  //col#1
  page.drawLine({
    start: { x: 88, y: 610 },
    end: { x: 88, y: 642 },
    thickness: 1,
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })

  //col#2
  page.drawLine({
    start: { x: 218, y: 610 },
    end: { x: 218, y: 642 },
    thickness: 1,
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })

  //col#3
  page.drawLine({
    start: { x: 348, y: 610 },
    end: { x: 348, y: 642 },
    thickness: 1,
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })

  //col#4
  page.drawLine({
    start: { x: 458, y: 610 },
    end: { x: 458, y: 642 },
    thickness: 1,
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })
}
const renderColumnsForTableBody = params => {
  const { page, factor, yPosition } = params
  //let yPosition = 610
  const delta = 32

  /*factor - To distribute cols evenly on each row*/

  //col#1
  page.drawLine({
    start: { x: 88, y: yPosition - factor },
    end: { x: 88, y: yPosition + delta - factor },
    thickness: 1,
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })

  //col#2
  page.drawLine({
    start: { x: 218, y: yPosition - factor },
    end: { x: 218, y: yPosition + delta - factor },
    thickness: 1,
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })

  //col#3
  page.drawLine({
    start: { x: 348, y: yPosition - factor },
    end: { x: 348, y: yPosition + delta - factor },
    thickness: 1,
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })

  //col#4
  page.drawLine({
    start: { x: 458, y: yPosition - factor },
    end: { x: 458, y: yPosition + delta - factor },
    thickness: 1,
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })
}

const renderPatientName = (config, patientData) => {
  const { pages, helveticaBoldFont, helveticaFont } = config

  let page = pages[0]
  const patientName = patientData[0]?.patient?.fullname

  renderContainer({
    page: page,
    x: 28,
    y: 660,
    width: 535,
    height: 52,
  })

  // render fullname
  page.drawText("Patient Name", {
    x: 38,
    y: 698,
    size: 11,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  page.drawText(`${patientName}` || "-", {
    x: 38,
    y: 679,
    size: 14,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })
}

const renderPatientInformationTableHeader = config => {
  const { pages } = config

  let page = pages[0]

  //row - Container which containes header titles
  renderContainer({
    page: page,
    x: 28,
    y: 610,
    width: 535,
    height: 32,
  })
  //cols - Lines which divide the container
  renderColumnsForTableHeader({
    page: page,
  })

  //Rendering headers titles
  renderPatientInformationHeaderTitles(config)
}
const renderPatientInformationHeaderTitles = config => {
  const { pages, helveticaBoldFont } = config

  let page = pages[0]

  page.drawText("Sr.", {
    x: 38,
    y: 622,
    size: 13,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })

  page.drawText("Time", {
    x: 98,
    y: 622,
    size: 13,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })

  page.drawText("Covid Result", {
    x: 228,
    y: 622,
    size: 13,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })

  page.drawText("IgG", {
    x: 358,
    y: 622,
    size: 13,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })

  page.drawText("IgM", {
    x: 468,
    y: 622,
    size: 13,
    font: helveticaBoldFont,
    color: rgb(0, 0, 0),
  })
}

const renderPatientInformationTableBody = (config, patientData) => {
  const { pages, helveticaFont } = config
  let page = pages[0]

  //Rendering no. of rows
  //let no_of_rows = patientData.length

  let noOfRows = pages.length === 1 && patientData?.length <= 18 ? patientData?.length : 18

  /***
   * factor = height of the container
   * It is being used to generate multiple rows of same height
   * and to distribute columns-lines evenly on each row to create
   * table.
   ***/
  let factor = 32
  const xPosition = 28
  let yPosition = 610

  let pageCounter = 0
  let counter = 1
  if (pages.length > 1) {
    for (let i = 0; i < patientData.length; i++) {
      factor *= i + 1

      //Rendering no. of rows
      renderContainer({
        page: page,
        x: xPosition,
        y: yPosition - factor,
        width: 535,
        height: 32,
      })
      //Rendering no. of columns - Only Lines
      renderColumnsForTableBody({
        page: page,
        factor: factor,
        yPosition,
      })
      //Render patient's previous information
      renderPatientPreviousRecords({
        page: page,
        helveticaFont: helveticaFont,
        factor: factor,
        srNo: i + 1,
        yPosition: yPosition + 12,
        data: patientData[i],
      })

      //Move to next page
      if (counter % noOfRows === 0) {
        pageCounter += 1
        page = pages[pageCounter]
        yPosition += 770
        counter = 1
        noOfRows = 25
      }

      factor = 32
      counter++
    }
  } else {
    for (let i = 0; i < noOfRows; i++) {
      factor *= i + 1
      //Rendering no. of rows
      renderContainer({
        page: page,
        x: xPosition,
        y: yPosition - factor,
        width: 535,
        height: 32,
      })
      //Rendering no. of columns - Only Lines
      renderColumnsForTableBody({
        page: page,
        factor: factor,
        yPosition,
      })
      //Render patient's previous information
      renderPatientPreviousRecords({
        page: page,
        helveticaFont: helveticaFont,
        factor: factor,
        srNo: i + 1,
        yPosition: yPosition + 12,
        data: patientData[i],
      })

      factor = 32
    }
  }
}
const renderPatientPreviousRecords = params => {
  const { page, helveticaFont, factor, srNo, yPosition, data } = params

  //Rendering serial no#
  page.drawText(`${srNo}` || "-", {
    x: 40,
    y: yPosition - factor,
    size: 13,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  //Rendering time
  page.drawText(data?.sampleReceivedAt ? dayjs(data?.sampleReceivedAt).format("DD-MM-YYYY") : "-", {
    x: 98,
    y: yPosition - factor,
    size: 13,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  //Rendering Covid Test Result
  page.drawText(`${getCovidResult(data)}` || "-", {
    x: 228,
    y: yPosition - factor,
    size: 13,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  //Rendering IgG
  page.drawText(data?.iggResultRange || "-", {
    x: 358,
    y: yPosition - factor,
    size: 13,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  //Rendering IgM
  page.drawText(data?.antibodyResultRange || "-", {
    x: 468,
    y: yPosition - factor,
    size: 13,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })
}
