import React, {
  Fragment,
  useState,
  // useEffect
} from "react"
import { useForm } from "react-hook-form"
import { useToast } from "@chakra-ui/core"
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"

import { updateMultipleSampleDateAndTime } from "../../services/api"
import dayjs from "dayjs"

const getDefaultValues = registrations => {
  let dfVals = {}

  registrations?.forEach((registration, idx) => {
    const { sampleTakenAt, reportVerifiedAt, registrationId } = registration
    const sampleTakenDate = dayjs(sampleTakenAt).format("YYYY-MM-DD")
    const sampleTakenTime = dayjs(sampleTakenAt).format("HH:mm")
    const reportVerficationDate = dayjs(reportVerifiedAt).format("YYYY-MM-DD")
    const reportVerficationTime = dayjs(reportVerifiedAt).format("HH:mm")

    dfVals[`sampleTakenDate-${registrationId}-${idx}`] = sampleTakenDate
    dfVals[`sampleTakenTime-${registrationId}-${idx}`] = sampleTakenTime
    dfVals[`reportVerficationDate-${registrationId}-${idx}`] = reportVerficationDate
    dfVals[`reportVerficationTime-${registrationId}-${idx}`] = reportVerficationTime
  })

  return dfVals
}

const dataPerp = data => {
  let recs = {}
  const initialObj = {
    sampleTakenDate: "",
    sampleTakenTime: "",
    reportVerficationDate: "",
    reportVerficationTime: "",
  }

  // console.log("vals: ", vals)
  const vals = Object.values(data)
  // Get all the keys i.e regIds
  Object.keys(data).forEach((key, idx) => {
    const regId = key.split("-")[1]
    const prefix = key.split("-")[0]
    if (!(regId in recs)) {
      recs[regId] = {
        ...initialObj,
      }
    }
    recs[regId][prefix] = vals[idx]
  })

  return recs
}

export default function MultiEditSampleAndReportTimeModal(props) {
  const { openModal, toggle, registrations, updateData } = props
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    defaultValues: getDefaultValues(registrations),
  })

  const onSubmit = async formData => {
    try {
      setIsLoading(true)
      const data = dataPerp(formData)
      await updateMultipleSampleDateAndTime({ data })

      updateData()
      toggle()

      toast({
        title: "Sample date & time updated successfully",
        description: "Sample date & time updated successfully",
        status: "success",
        duration: 5000,
      })
    } catch (error) {
      setIsLoading(false)
      console.log(error)
      toast({
        title: "There was error while updating sample date & time",
        description: "There was error while updating sample date & time",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Fragment>
      <Modal isOpen={openModal} toggle={toggle} centered={true} size="xl">
        <ModalHeader toggle={toggle}>Edit Sample - Report Date & Time</ModalHeader>
        <form className="cms-contact-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <div className="container">
              <div className="row">
                <div className="col-12 mx-auto">
                  {registrations?.length <= 0 && isLoading && <Spinner color="primary" size={"md"} />}
                  {registrations?.length > 0 && (
                    <div className="row c-table">
                      <div className="col-12">
                        <div className="table-responsive table-border-top no-vertical-scroll">
                          <table className="table table-hover text-nowrap">
                            <thead className="table-header-bg">
                              <tr>
                                <th scope="col">Sr. No</th>

                                <th scope="col">MR #</th>
                                <th scope="col">Sample Date</th>
                                <th scope="col">Sample Time</th>
                                <th scope="col">Report Date</th>
                                <th scope="col">Report Time</th>
                              </tr>
                            </thead>

                            <tbody>
                              {registrations?.map((registration, idx) => (
                                <tr key={registration?.registrationId}>
                                  <td>{idx + 1}</td>
                                  {/* <td>{idx + 1 + offset}</td> */}

                                  <td>{registration.registrationId}</td>
                                  <td>
                                    <input
                                      type="date"
                                      name={`sampleTakenDate-${registration?.registrationId}-${idx}`}
                                      ref={register}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="time"
                                      name={`sampleTakenTime-${registration?.registrationId}-${idx}`}
                                      ref={register}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="date"
                                      name={`reportVerficationDate-${registration?.registrationId}-${idx}`}
                                      ref={register}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="time"
                                      name={`reportVerficationTime-${registration?.registrationId}-${idx}`}
                                      ref={register}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        {/* <div className="row mt-2">
                              <div className="col-md-3">
                                <Pagination
                                  activePage={activePage}
                                  itemsCountPerPage={limit}
                                  totalItemsCount={registrations.total}
                                  pageRangeDisplayed={5}
                                  onChange={goToPage}
                                  itemClass="page-item"
                                  linkClass="page-link"
                                />
                              </div>
                              <div className="col-md-3 d-flex">
                                <span className="text-dark pt-2">Jump to Page</span>
                                <input
                                  type="number"
                                  disabled={loading}
                                  className="jump-to-page ml-2"
                                  value={targetPage}
                                  placeholder="1"
                                  onChange={handleJumpToPage}
                                />
                              </div>
                              <div className="col-md-3 d-flex">
                                <span className="text-dark pt-2">Limit records</span>
                                <input
                                  type="number"
                                  disabled={loading}
                                  value={limit}
                                  className="jump-to-page ml-2"
                                  onChange={handleLimitRecords}
                                />
                              </div>
                              <div className="col-md-3 pt-2">
                                <span className="pull-right text-dark">
                                  Showing:{" "}
                                  {`${offset + 1} to ${Math.min(limit + offset, registrations.total)} of ${
                                    registrations.total
                                  } records`}
                                </span>
                              </div>
                            </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="justify-content-between">
              <div></div>
              <div>
                <button type="submit" className="btn btn-primary" name="submit">
                  <i className="fas fa-pencil mr-2"></i>
                  Update {isLoading && <Spinner color="default" className="ml-2" size="sm" />}
                </button>
              </div>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  )
}
