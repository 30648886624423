import React from "react"
import {
  renderHeader,
  renderSubHeader,
  renderPatientTypeInformation,
  renderPassengerInformation,
  renderAirlineInformation,
  renderTestInformation,
  renderDisclaimer,
} from "../components/report/view-report-layout"

const ReportTemplate = () => {
  return (
    <div
      style={{
        maxWidth: "1000px",
        margin: "auto",
        backgroundColor: "white",
        minHeight: "100vh",
        paddingBottom: "2rem",
      }}
    >
      {renderHeader()}
      {renderPatientTypeInformation()}
      {renderSubHeader()}
      {renderPassengerInformation()}
      {renderAirlineInformation()}
      {renderTestInformation()}
      {renderDisclaimer()}

      <button className="no-print" onClick={() => window.print()}>
        PRINT
      </button>
    </div>
  )
}

export default ReportTemplate
