import React from "react"

import "./Switch.scss"

function Switch(props) {
  const { name, onChange, checked } = props

  return (
    <label className="switch">
      <input type="checkbox" name={name} checked={!!checked} onChange={onChange} />
      <span className="slider"></span>
    </label>
  )
}

export default Switch
