import { getCurrentUser, loginUser, logoutUser } from "../../services/api"
import setAuthToken from "../../services/setAuthToken"
import { getRedirectRoute } from "../../routes/RoutesConfig"

export const login = async ({ state, actions }, { email, password, history }) => {
  try {
    state.auth.isLoading = true
    const res = await loginUser({ email, password })
    actions.auth.authenticate({ token: res.data.token })
    state.auth.user = res.data.user
    state.auth.permissions = res.data.user.permissions || []
    const { role } = res.data.user
    history.push(getRedirectRoute(role))
  } catch (err) {
    const error = err.response?.data?.errors?.message || "There was an error logging in."
    state.auth.error = { message: error }
  } finally {
    state.auth.isLoading = false
  }
}

export const authenticate = ({ state }, { token }) => {
  state.auth.token = token
  state.auth.isAuthenticated = true
  state.auth.error = null
  setAuthToken(token)
}

export const loadUser = async ({ state, actions }, { token }) => {
  try {
    state.auth.isLoading = true
    const res = await getCurrentUser()
    const { email, role } = res.data
    actions.auth.authenticate({ token })
    state.auth.user = { email, role }

    state.auth.permissions = res.data.permissions || []
  } catch (err) {
    const error = err.response?.data?.message || "Login expired, Please login again to continue"
    state.auth.error = { message: error }
  } finally {
    state.auth.isLoading = false
  }
}

export const logout = async ({ state }) => {
  await logoutUser()
  state.auth.token = null
  state.auth.isAuthenticated = false
  state.auth.isLoading = false
  state.auth.error = null
  state.auth.permissions = []
  setAuthToken(null)
}
