import dayjs from "dayjs"
import QRCode from "qrcode"
import { useEffect, useState } from "react"
import { getAirport } from "../../utils/airports"
import { useState as useOvermindState } from "../../overmind"
import { getAmplificationKit, getExtractionKit, getTestResultStatus } from "../../utils/common"
import { getAirline } from "../../utils/airlines"

const testResultStatus = (registration, resultKey = "result") => {
  const { status, color } = getTestResultStatus(registration, resultKey)
  if (status) {
    const rgbColor = `rgb(${color?.r},${color?.g},${color?.b})`
    return <span style={{ color: rgbColor, textTransform: "uppercase" }}>{status}</span>
  }
  return <span>&nbsp;</span>
}

export const renderHeader = (patient, registration) => {
  return (
    <table
      style={{
        width: "100%",
        margin: "auto",
        padding: "1rem 0",
        borderCollapse: "collapse",
        borderSpacing: "0",
        border: "0px solid",
      }}
    >
      <tbody>
        <tr>
          <td align="center" style={{ border: "none", paddingLeft: 0, paddingRight: 0 }}>
            <div className="d-flex align-items-center justify-content-between">
              <img
                src="assets/images/logo-dark.png"
                style={{
                  maxHeight: "120px",
                }}
                alt="Emirates Logo"
              />

              {!registration?.rapidPcrResult && (
                <div style={{ height: "5rem" }}>
                  <h4
                    style={{
                      width: "100%",
                      margin: "0rem auto",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    Patient Covid-19 Report
                  </h4>
                </div>
              )}
              <img
                src={patient?.profilePictureUrl ? patient.profilePictureUrl : "assets/images/blank.png"}
                style={{
                  maxHeight: "120px",
                }}
                alt="Blank Space For Profile DP"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export const renderSubHeader = (patient, registration, qrcode) => {
  return (
    <table
      style={{
        width: "100%",
        // marginTop: "-1.7rem", // comment this line after exporting PDF template
        padding: "0.5rem 0",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          <td align="left" width="35%">
            <h6
              style={{
                margin: 0,
                fontWeight: "bold",
              }}
            >
              Patient Name
            </h6>
            <h4 className="m-0 mb-4">{patient?.fullname ? <span> {patient.fullname}</span> : <span> &nbsp;</span>}</h4>
          </td>
          <td align="left" width="30%">
            <h6
              style={{
                margin: 0,
                fontWeight: "normal",
              }}
            >
              <b>Sample Time:</b>{" "}
              {registration?.sampleTakenAt ? (
                dayjs(registration.sampleTakenAt).format("DD/MM/YYYY hh:mm A")
              ) : (
                <span>&nbsp;</span>
              )}
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "normal",
              }}
            >
              <b>Report Time:</b>{" "}
              {registration?.reportVerifiedAt ? (
                dayjs(registration.reportVerifiedAt).format("DD/MM/YYYY hh:mm A")
              ) : (
                <span>&nbsp;</span>
              )}
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "normal",
              }}
            >
              <b>MR #:</b> {registration?.registrationId ? registration?.registrationId : <span>&nbsp;</span>}
            </h6>

            <h6
              style={{
                margin: 0,
                fontWeight: "normal",
              }}
            >
              <span>&nbsp;</span>
            </h6>
          </td>
          <td width="35%" style={{ position: "relative" }}>
            {(registration?.rapidPcrCharges || registration?.charges) && (
              <>
                <h5
                  style={{
                    margin: 0,
                    fontWeight: "bold",
                  }}
                >
                  COVID-19 Test Result
                </h5>
                <h4 className="m-0 mb-4">
                  {registration?.sampleTakenAt ? (
                    testResultStatus(registration, registration?.rapidPcrResult ? "rapidPcrResult" : "result")
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </h4>
              </>
            )}
            {qrcode && (
              <img
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  maxWidth: "6.75rem",
                }}
                src={qrcode}
                alt="Patient QRCode"
              />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export const renderPatientTypeInformation = () => {
  return (
    <table
      style={{
        width: "100%",
        padding: "0.5rem 0",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          {/* <td align="left" width="35%">
            <div className="d-flex align-items-center">
              <h6
                className="mb-0"
                style={{
                  fontWeight: "bold",
                }}
              >
                Type
              </h6>
              <div className="d-flex align-items-center ml-3">
                <img
                  src="assets/images/checkbox.png"
                  style={{
                    maxHeight: "20px",
                    marginRight: "0.5rem",
                  }}
                  alt="Checkbox"
                />
                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  Regular
                </h6>
              </div>
              <div className="d-flex align-items-center ml-5">
                <img
                  src="assets/images/checkbox.png"
                  style={{
                    maxHeight: "20px",
                    marginRight: "0.5rem",
                  }}
                  alt="Checkbox"
                />

                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  Passenger
                </h6>
              </div>
            </div>
          </td> */}
          <td align="left" width="100%">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center ml-3">
                <h6 className="m-0" style={{ fontWeight: "normal" }}>
                  <span> &nbsp;</span>
                </h6>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export const renderPassengerInformation = (patient, registration) => (
  <>
    <table
      style={{
        width: "100%",
        marginTop: "-1.7rem",
        padding: "0.5rem 0",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          <td align="left">
            <h6
              style={{
                margin: 0,
              }}
            >
              Passport
            </h6>
            <p
              style={{
                margin: 0,
                fontWeight: "400",
                color: "black",
              }}
            >
              {/* {registration?.registrationType === "general" && "NA"} */}
              {registration?.passport ? registration.passport : <span>&nbsp;</span>}
            </p>
          </td>
          <td>
            <h6
              style={{
                margin: 0,
              }}
            >
              CNIC No
            </h6>
            <p
              style={{
                margin: 0,
                fontWeight: "400",
                color: "black",
              }}
            >
              {patient?.cnic ? patient.cnic : <span>&nbsp;</span>}
            </p>
          </td>

          <td>
            <h6
              style={{
                margin: 0,
              }}
            >
              Cell No
            </h6>
            <p
              style={{
                margin: 0,
                color: "black",
                fontWeight: "400",
              }}
            >
              {patient?.phone ? patient.phone : <span>&nbsp;</span>}
            </p>
          </td>
          <td colSpan="2">
            <h6
              style={{
                margin: 0,
              }}
            >
              Email Address
            </h6>
            <p
              style={{
                margin: 0,
                color: "black",
                fontWeight: "400",
              }}
            >
              {patient?.email ? patient.email : <span>&nbsp;</span>}
            </p>
          </td>

          <td width="100px">
            <h6
              style={{
                margin: 0,
              }}
            >
              Gender
            </h6>
            <p
              style={{
                margin: 0,
                fontWeight: "400",
                textTransform: "capitalize",
                color: "black",
              }}
            >
              {patient?.gender ? patient.gender : <span>&nbsp;</span>}
            </p>
          </td>

          <td width="120px">
            <h6
              style={{
                margin: 0,
              }}
            >
              Age
            </h6>
            <p
              style={{
                margin: 0,
                fontWeight: "400",
                color: "black",
              }}
            >
              {patient?.dob ? `${dayjs().diff(patient.dob, "years", false)}` : <span>&nbsp;</span>}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </>
)

export const renderAirlineInformation = registration => (
  <>
    <table
      style={{
        width: "100%",
        marginTop: "-1.7rem",
        padding: "0.5rem 0",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          <td align="left" width="25%">
            <h6
              style={{
                margin: 0,
              }}
            >
              Airline
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "400",
                textTransform: "capitalize",
              }}
            >
              {registration?.airline ? getAirline(registration.airline) : <span>&nbsp;</span>}
            </h6>
          </td>
          <td width="25%">
            <h6
              style={{
                margin: 0,
              }}
            >
              Flight Time
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "400",
              }}
            >
              {registration?.flightDate ? (
                dayjs(`${registration.flightDate} ${registration.flightTime}`, "DD-MM-YYYY HH:mm").format(
                  "DD/MM/YYYY hh:mm A"
                )
              ) : (
                <span>&nbsp;</span>
              )}
            </h6>
          </td>

          <td width="25%">
            <h6
              style={{
                margin: 0,
              }}
            >
              Flight No.
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "400",
              }}
            >
              {registration?.flightNo ? registration.flightNo : <span>&nbsp;</span>}
            </h6>
          </td>
          <td width="25%">
            <h6
              style={{
                margin: 0,
              }}
            >
              Booking Ref/PNR
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "400",
              }}
            >
              {registration?.pnr ? registration.pnr : <span>&nbsp;</span>}
            </h6>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <h6
              style={{
                margin: 0,
              }}
            >
              Departure Airport
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "400",
              }}
            >
              {registration?.departureAirport ? registration.departureAirport : <span>&nbsp;</span>}
            </h6>
          </td>

          <td colSpan="2">
            <h6
              style={{
                margin: 0,
              }}
            >
              Arrival Airport
            </h6>
            <h6
              style={{
                margin: 0,
                fontWeight: "400",
              }}
            >
              {registration?.destinationAirport ? getAirport(registration.destinationAirport) : <span>&nbsp;</span>}
            </h6>
          </td>
        </tr>
      </tbody>
    </table>
  </>
)

export const renderTestInformation = (registration, role) => (
  <>
    {((!registration?.rapidPcrCharges && !registration?.rapidPcrResult) ||
      (!registration?.charges && !registration?.result) ||
      (!registration?.igmCharges && !registration?.antibodyResultRange) ||
      (!registration?.iggCharges && !registration?.iggResultRange)) && (
      <h5
        style={{
          margin: 0,
          marginTop: "-1.4rem",
          textAlign: "center",
        }}
      >
        COVID-19 (CoronaVirus) <br />
        REPORT DETAILS
      </h5>
    )}
    {!registration?.rapidPcrResult &&
      !registration?.result &&
      !registration?.antibodyResultRange &&
      !registration?.iggResultRange && (
        <table
          style={{
            width: "100%",
            padding: "0.5rem 0",
            borderCollapse: "collapse",
            marginBottom: "0px",
            marginTop: "5px",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                }}
              >
                <h6
                  style={{
                    margin: 0,
                  }}
                >
                  No results available
                </h6>
              </td>
            </tr>
          </tbody>
        </table>
      )}

    {registration?.rapidPcrCharges && registration?.rapidPcrResult && (
      <table
        style={{
          width: "100%",
          padding: "0.5rem 0",
          borderCollapse: "collapse",
          marginBottom: "10px",
          marginTop: "5px",
        }}
      >
        <tbody>
          <tr>
            <td align="left" width="33.33%">
              <h6
                style={{
                  margin: 0,
                }}
              >
                SPECIMEN
              </h6>
              <h6
                style={{
                  margin: 0,
                  fontWeight: "400",
                }}
              >
                {registration?.rapidPcrSpecimen || <span>&nbsp;</span>}
              </h6>
              <h6
                style={{
                  margin: 0,
                  fontWeight: "400",
                }}
              >
                <span>&nbsp;</span>
              </h6>
            </td>
            <td width="33.33%">
              <h6
                style={{
                  margin: 0,
                }}
              >
                TEST NAME
              </h6>
              <h6
                style={{
                  margin: 0,
                  fontWeight: "400",
                }}
              >
                {registration?.rapidPcrTest ? (
                  <>
                    <div>
                      <span>Covid-19 Rapid RNA PCR</span> <br />
                      <span>SARS-Co-V2 (nucleic acid)</span>
                    </div>
                  </>
                ) : (
                  <span>&nbsp;</span>
                )}
              </h6>
              <h6
                style={{
                  margin: 0,
                  fontWeight: "400",
                }}
              >
                <span>&nbsp;</span>
              </h6>
            </td>

            <td width="33.33%">
              <h6
                style={{
                  margin: 0,
                }}
              >
                TEST RESULT
              </h6>
              <h6
                style={{
                  margin: 0,
                  fontWeight: "400",
                }}
              >
                {registration?.rapidPcrResult ? "COVID-19 RNA" : <span>&nbsp;</span>}
              </h6>
              <h6
                style={{
                  margin: 0,
                  fontWeight: "400",
                }}
              >
                {registration?.sampleTakenAt ? testResultStatus(registration, "rapidPcrResult") : <span>&nbsp;</span>}
              </h6>
            </td>
          </tr>
        </tbody>
      </table>
    )}

    {registration?.charges && registration?.result && (
      <>
        <table
          style={{
            width: "100%",
            padding: "0.5rem 0",
            borderCollapse: "collapse",
            marginBottom: "0px",
            marginTop: "5px",
          }}
        >
          <tbody>
            <tr>
              <td align="left" width="33.33%">
                <h6
                  style={{
                    margin: 0,
                  }}
                >
                  SPECIMEN
                </h6>
                <h6
                  style={{
                    margin: 0,
                    fontWeight: "400",
                  }}
                >
                  {registration?.specimen ? "Nasopharyngeal Swab" : <span>&nbsp;</span>}
                </h6>
                <h6
                  style={{
                    margin: 0,
                    fontWeight: "400",
                  }}
                >
                  <span>&nbsp;</span>
                </h6>
              </td>
              <td width="33.33%">
                <h6
                  style={{
                    margin: 0,
                  }}
                >
                  TEST TYPE
                </h6>
                <h6
                  style={{
                    margin: 0,
                    fontWeight: "400",
                  }}
                >
                  {registration?.test ? "Real Time RT-PCR" : <span>&nbsp;</span>}
                </h6>
                <h6
                  style={{
                    margin: 0,
                    fontWeight: "400",
                  }}
                >
                  <span>&nbsp;</span>
                </h6>
              </td>

              <td width="33.33%">
                <div className="d-flex justify-content-between">
                  <div>
                    <h6
                      style={{
                        margin: 0,
                      }}
                    >
                      TEST RESULT
                    </h6>
                    <h6
                      style={{
                        margin: 0,
                        fontWeight: "400",
                      }}
                    >
                      {registration?.result ? "COVID-19 RNA" : <span>&nbsp;</span>}
                    </h6>
                    <h6
                      style={{
                        margin: 0,
                        fontWeight: "400",
                      }}
                    >
                      {registration?.sampleTakenAt ? testResultStatus(registration) : <span>&nbsp;</span>}
                    </h6>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    )}

    {registration?.igmCharges && registration?.antibodyResultRange && (
      <table
        style={{
          width: "100%",
          padding: "0.5rem 0",
          borderCollapse: "collapse",
          marginBottom: "0px",
        }}
      >
        <tbody>
          <tr>
            <td align="left" width="33.33%">
              <h6
                style={{
                  margin: 0,
                }}
              >
                SPECIMEN
              </h6>
              <h6
                style={{
                  margin: 0,
                  fontWeight: "400",
                }}
              >
                Blood
              </h6>
            </td>
            <td align="left" width="33.33%">
              <h6
                style={{
                  margin: 0,
                }}
              >
                TEST TYPE
              </h6>
              <h6
                style={{
                  margin: 0,
                  fontWeight: "400",
                }}
              >
                COVID-19 IgM Antibodies
              </h6>
            </td>

            <td width="33.33%">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h6
                    style={{
                      margin: 0,
                    }}
                  >
                    TEST RESULT
                  </h6>
                  <h6
                    style={{
                      margin: 0,
                      fontWeight: "400",
                    }}
                  >
                    {registration?.antibodyResultRange && role !== "chinese" ? (
                      registration.antibodyResultRange
                    ) : role === "chinese" ? (
                      <span>N/A</span>
                    ) : (
                      <span style={{ color: "white" }}>-</span>
                    )}
                  </h6>
                </div>
                {registration?.antibodyResultRange && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "105px",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                      }}
                    >
                      <span style={{ lineHeight: "1.4", justifySelf: "end", marginRight: "5px" }}> Negative: </span>
                      <span style={{ lineHeight: "1.4" }}> &#60;1.0</span>
                      <span style={{ lineHeight: "1.4", justifySelf: "end", marginRight: "5px" }}> Positive:</span>
                      <span style={{ lineHeight: "1.4" }}> &#62;1.0</span>
                    </div>
                  </div>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    )}

    {registration?.iggCharges && registration?.iggResultRange && (
      <table
        style={{
          width: "100%",
          padding: "0.5rem 0",
          borderCollapse: "collapse",
          marginBottom: "10px",
        }}
      >
        <tbody>
          <tr>
            <td align="left" width="33.33%">
              <h6
                style={{
                  margin: 0,
                }}
              >
                SPECIMEN
              </h6>
              <h6
                style={{
                  margin: 0,
                  fontWeight: "400",
                }}
              >
                Blood
              </h6>
            </td>
            <td align="left" width="33.33%">
              <h6
                style={{
                  margin: 0,
                }}
              >
                TEST TYPE
              </h6>
              <h6
                style={{
                  margin: 0,
                  fontWeight: "400",
                }}
              >
                COVID-19 IgG Antibodies
              </h6>
            </td>

            <td width="33.33%">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h6
                    style={{
                      margin: 0,
                    }}
                  >
                    TEST RESULT
                  </h6>
                  <h6
                    style={{
                      margin: 0,
                      fontWeight: "400",
                    }}
                  >
                    {registration?.iggResultRange && role !== "chinese" ? (
                      registration.iggResultRange
                    ) : role === "chinese" ? (
                      <span>N/A</span>
                    ) : (
                      <span style={{ color: "white" }}>-</span>
                    )}
                  </h6>
                </div>
                {registration?.iggResultRange && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "152px",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                      }}
                    >
                      <span style={{ lineHeight: "1.4", justifySelf: "end", marginRight: "5px" }}> &#60;1.4: </span>
                      <span style={{ lineHeight: "1.4" }}> Non Reactive</span>
                      <span style={{ lineHeight: "1.4", justifySelf: "end", marginRight: "5px" }}> =/&#62;1.4:</span>
                      <span style={{ lineHeight: "1.4" }}> Reactive</span>
                    </div>
                  </div>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    )}
  </>
)

export const renderDisclaimer = registration => (
  <>
    {registration?.rapidPcrCharges && (
      <>
        <b>
          <u>Rapid PCR Test:</u>
        </b>
        <ol style={{ marginLeft: "1rem", fontSize: "14px" }}>
          <li>
            The test has been performed by triple gene detection kit approved by USFDA and CE marked kits for novel
            corona virus.
          </li>
          <li>
            The negative result must be interpreted along with clinical observations, patient's history, and
            epidemiological information.
          </li>
          <li>
            A single gene might not exclude possibility of corona virus infection, repeat test might be required between
            24-48 hours if symptoms persist. The patient should consider himself/herself as suspected case for corona
            virus and should remain under self-quarantine and maintain social distancing.
          </li>
          <li>
            Passengers are responsible to fulfill destination-related documents including fitness certificates, letters,
            etc.
          </li>
          <li>
            A passenger tested positive after some time interval or landing in other country with a negative report by
            Global Clinical Care Diagnostic Center can be due to post sample exposure to covid-19 by not strictly
            following the sops for corona virus.
          </li>
        </ol>
      </>
    )}
    {registration?.charges && (
      <>
        <b>
          <u>PCR Test:</u>
        </b>
        <ol style={{ marginLeft: "1rem", fontSize: "14px", marginBottom: "15px" }}>
          <li>
            The test is performed by FDA approved and CE marked triple gene detection kits for novel coronavirus on
            nasopharyngeal/oropharyngeal swabs.
          </li>
          <li>
            The negative result must be interpreted along with clinical observations, patient's history, and
            epidemiological information. A single result might not exclude the possibility of coronavirus infection; a
            repeat test might be required between 24-48 hours if symptoms persist. The patient should consider
            himself/herself as a suspected case for coronavirus and should remain under self-quarantine and maintain
            social distancing.
          </li>
          <li>
            A positive result after some time interval/ landing in another country against a negative initial result by
            Global Clinical Care - Diagnostic Centre can be due to post sample exposure to Covid-19 and Global Clinical
            Care - Diagnostic Centre does not bear the responsibility for this.
          </li>
          <li>
            COVID -19 PCR Testing has globally accepted technology and technique related limitations of false negative
            and positive results. We try to keep that to the lowest permissible levels by using the best three targets
            FDA-approved &amp; CE marked kits. Moreover, the time of the sample taken and viral load are unavoidable
            variables.
          </li>
          <li>
            Passengers are responsible to fulfill destination-related documents including fitness certificates, letters,
            etc. Global Clinical Care - Diagnostic Center will not be responsible if passengers do not fulfill such
            protocol before traveling abroad.
          </li>
          <li>
            Instrument: {registration?.instrument || "-"}, Amplification Kit(s):{" "}
            {getAmplificationKit(registration?.amplificationKit) || "-"}, Extraction Kit(s):{" "}
            {getExtractionKit(registration?.extractionKit) || "-"}, Batch:{" "}
            {`${registration?.batchNo} (${registration?.batchDate})` || "-"}.
          </li>
          <li className="text-bold">
            {registration?.amplificationKit === "bioPerfectus"
              ? `
              ORF-1ab = ${registration?.orf1AbGene || "-"}, N-Gene = ${registration?.nGene || "-"}`
              : `RdRp-Gene = ${registration?.rdRpGene || "-"}, N-Gene = ${registration?.nGene || "-"}, E-Gene =
              ${registration?.eGene || "-"} `}
            .
          </li>
        </ol>
      </>
    )}

    {(registration?.igmCharges || registration?.iggCharges) && (
      <>
        <b>
          <u>Antibodies Test:</u>
        </b>

        <ol style={{ marginLeft: "1rem", fontSize: "14px" }}>
          <li>
            Antibody Tests are performed on state of art ARCHITECT i-1000 SR automated analyzer by Abbott Diagnostics
            U.S.A
          </li>
          <li>
            The results for IgM antibody needs to be interpreted in conjunction with the patient’s clinical evaluation
            and other diagnostic procedures.
          </li>
        </ol>
      </>
    )}

    <hr />
    <h6 className="text-center">Electronically Verified Report No Signature Required.</h6>
    <hr />
  </>
)

const getQRCodeBase64 = async data => {
  const { patient, registration } = data

  const mrNo = registration.registrationId
  const fullname = patient.fullname
  const createdAt = dayjs(registration.createdAt).format("DD/MM/YYYY hh:mm A")
  const passport = registration.passport || "-"
  const result = registration.result === "detected" ? "Detected" : "Not Detected"
  const base64URL = await QRCode.toDataURL(
    `MR #: ${mrNo}\nName: ${fullname}\nReg Date: ${createdAt}\nPassport #: ${passport}\nResult: ${result}`
  )

  return base64URL
}

const ViewReportLayout = props => {
  const { patient, ...registration } = props.data
  const { user } = useOvermindState().auth
  const [qrcodeBase64, setQrcodeBase64] = useState(null)

  useEffect(() => {
    const fetchQRCode = async () => {
      const qrcode = await getQRCodeBase64({ patient, registration })
      setQrcodeBase64(qrcode)
    }

    fetchQRCode()
  }, [patient, registration])

  const role = user.role
  return (
    <div
      style={{
        maxWidth: "1000px",
        margin: "auto",
        backgroundColor: "white",
        minHeight: "100vh",
        paddingBottom: "2rem",
      }}
    >
      {renderHeader(patient, registration)}
      {renderSubHeader(patient, registration, qrcodeBase64)}
      {renderPassengerInformation(patient, registration)}
      {registration?.registrationType === "passenger" && renderAirlineInformation(registration)}

      {renderTestInformation(registration, role)}
      {renderDisclaimer(registration)}
    </div>
  )
}

export default ViewReportLayout
