import { Fragment, useState, useEffect } from "react"
import { useState as useOvermindState } from "../../overmind"
import { useToast } from "@chakra-ui/core"
import dayjs from "dayjs"
import JSZip from "jszip"
import FileSaver from "file-saver"
import {
  sendMultipleReport,
  verifyMultipleReport,
  getRegistrationDetailsByRegistrationIds,
  multipleSoftDelete,
  multipleHardDelete,
  recoverMultiplePatient,
} from "../../services/api"
import createReportPDF from "../report/create-report"
import createInvoicePDF from "../invoice/create-invoice"
import ConfirmationModal from "../modals/ConfirmationModal"
import HardDeleteConfirmationModal from "../modals/HardDeleteConfirmationModal"
import MultiOptionalDownloadModal from "../modals/MultiOptionalDownloadModal"
import MultiEditSampleAndReportTimeModal from "../modals/MultiEditSampleAndReportTimeModal"
import { getSelectedRegistrationIds } from "../../utils/common"

const getCurrentRoute = () => {
  const pathName = window.location.pathname
  return pathName.substring(1)
}

export default function MultiSelection(props) {
  const {
    toggle,
    reset,
    selectedReportIds,
    isMultiLoading,
    setIsMultiLoading,
    updateData,
    isHardDelete = false,
  } = props
  const { user, permissions } = useOvermindState().auth
  const [registrations, setRegistrations] = useState([])
  const [openMultiOptionalDownloadModal, setOpenMultiOptionalDownloadModal] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [openHardDeleteConfirmationModal, setOpenHardDeleteConfirmationModal] = useState(false)
  const [currentRoute] = useState(getCurrentRoute())
  const [openMultiEditSampleAndReportTimeModal, setOpenMultiEditSampleAndReportTimeModal] = useState(false)
  const toast = useToast()

  const fetchRegistrations = async () => {
    try {
      const regIds = getSelectedRegistrationIds(selectedReportIds)
      const res = await getRegistrationDetailsByRegistrationIds(regIds)
      setRegistrations(res?.data?.registrations)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchRegistrations()

    // eslint-disable-next-line
  }, [selectedReportIds])

  const toggleConfirmationModal = () => setOpenConfirmationModal(!openConfirmationModal)
  const toggleHardDeleteConfirmationModal = () => setOpenHardDeleteConfirmationModal(!openHardDeleteConfirmationModal)
  const toggleMultiEditSampleAndReportTimeModal = () =>
    setOpenMultiEditSampleAndReportTimeModal(!openMultiEditSampleAndReportTimeModal)
  const toggleOpenMultiOptionalDownloadModal = () => setOpenMultiOptionalDownloadModal(!openMultiOptionalDownloadModal)

  //Handle Multiple Patient Records Recovery
  const multiPatientRecover = async () => {
    if (selectedReportIds?.length <= 0) {
      alert("Please select a patient!")
    } else {
      handleMultiPatientRecover()
    }
  }
  const handleMultiPatientRecover = async () => {
    try {
      setIsMultiLoading(true)

      const reportIds = getSelectedRegistrationIds(selectedReportIds)
      await recoverMultiplePatient(reportIds)

      updateData()

      reset()
      toggle()
      toast({
        title: "Report(s) deleted successfully!",
        description: "Selected report(s) have been deleted successfully.",
        status: "success",
        duration: 4000,
      })
    } catch (error) {
      console.log(error)
      toast({
        title: "There was an error while deleting report(s)",
        description: error.message,
        status: "error",
        duration: 4000,
      })
    } finally {
      setIsMultiLoading(false)
    }
  }

  //Handle Multiple Delete
  const multiDelete = async () => {
    if (selectedReportIds?.length <= 0) {
      alert("Please select a patient!")
    } else {
      handleMultiDelete()
    }
  }
  const handleMultiDelete = async () => {
    try {
      setIsMultiLoading(true)

      const reportIds = getSelectedRegistrationIds(selectedReportIds)
      if (isHardDelete) {
        await multipleHardDelete(reportIds)
      } else {
        await multipleSoftDelete(reportIds)
      }

      updateData()

      reset()
      toggle()
      toast({
        title: "Report(s) deleted successfully!",
        description: "Selected report(s) have been deleted successfully.",
        status: "success",
        duration: 4000,
      })
    } catch (error) {
      console.log(error)
      toast({
        title: "There was an error while deleting report(s)",
        description: error.message,
        status: "error",
        duration: 4000,
      })
    } finally {
      setIsMultiLoading(false)
    }
  }

  //Handle Multiple Send
  const multiReportSend = () => {
    if (selectedReportIds?.length <= 0) {
      alert("Please select a patient!")
    } else {
      handleMultiReportSend()
    }
  }
  const handleMultiReportSend = async () => {
    try {
      setIsMultiLoading(true)

      const reportIds = getSelectedRegistrationIds(selectedReportIds)
      await sendMultipleReport(reportIds)

      reset()

      toast({
        title: "Report(s) sent successfully!",
        description: "Selected report(s) have been sent successfully.",
        status: "success",
        duration: 4000,
      })
    } catch (error) {
      console.log(error)
      toast({
        title: "There was an error while sending report(s)",
        description: error.message,
        status: "error",
        duration: 4000,
      })
    } finally {
      setIsMultiLoading(false)
    }
  }

  //Handle Multiple Verification
  const multiReportVerify = () => {
    if (selectedReportIds?.length <= 0) {
      alert("Please select a patient!")
    } else {
      toggleConfirmationModal()
    }
  }
  const handleMultiReportVerify = async () => {
    try {
      setIsMultiLoading(true)
      const reportIds = getSelectedRegistrationIds(selectedReportIds)
      await verifyMultipleReport(reportIds)

      updateData()
      reset()

      toast({
        title: "Report(s) Verified!",
        description: "Report(s) have been verified succesfully.",
        status: "success",
        duration: 3000,
      })
    } catch (error) {
      console.log(error.message)
      toast({
        title: "There was an error while verifying report(s).",
        description: error.message,
        status: "error",
        duration: 3000,
      })
    } finally {
      setIsMultiLoading(false)
    }
  }

  //Handle Multiple Download
  const multiDownload = params => {
    if (selectedReportIds?.length <= 0) {
      alert("Please select a patient!")
    } else {
      handleMultiDownload(params)
    }
  }

  const handleMultiReportDownload = async params => {
    const { result, isZip } = params
    const zip = new JSZip()

    for (let index = 0; index < result.length; index++) {
      const { patient, ...registration } = result[index]

      if (isZip) {
        const pdfBytes = await createReportPDF({ patient, registration, isDownload: true, isZip: isZip })
        zip.file(`report-${patient?.fullname}-${registration?.registrationId}.pdf`, pdfBytes)
      } else {
        await createReportPDF({ patient, registration, isDownload: true, isZip: isZip })
      }
    }

    if (isZip) {
      zip.generateAsync({ type: "blob" }).then(content => {
        FileSaver.saveAs(content, `reports-${dayjs().format("DD-MM-YYYY_THH:mm_A")}.zip`)
      })
    }
  }

  const handleMultiInvoiceDownload = async params => {
    const { result, isZip } = params
    const zip = new JSZip()

    for (let index = 0; index < result.length; index++) {
      const { patient, ...registration } = result[index]

      if (isZip) {
        const pdfBytes = await createInvoicePDF({ patient, registration, isDownload: true, isZip: isZip })
        zip.file(`invoice-${patient?.fullname}-${registration?.registrationId}.pdf`, pdfBytes)
      } else {
        await createInvoicePDF({ patient, registration, isDownload: true, isZip: isZip })
      }
    }

    if (isZip) {
      zip.generateAsync({ type: "blob" }).then(content => {
        FileSaver.saveAs(content, `invoices-${dayjs().format("DD-MM-YYYY_THH:mm_A")}.zip`)
      })
    }
  }

  const handleMultiDownload = async ({ downloadOption, fileType }) => {
    try {
      setIsMultiLoading(true)

      const selectedRegistrationIds = getSelectedRegistrationIds(selectedReportIds)
      const res = await getRegistrationDetailsByRegistrationIds(selectedRegistrationIds)
      const result = res?.data?.registrations

      if (downloadOption === "report" && fileType === "zip") {
        handleMultiReportDownload({ result, isZip: true })
      } else if (downloadOption === "report" && fileType === "unzip") {
        handleMultiReportDownload({ result, isZip: false })
      } else if (downloadOption === "invoice" && fileType === "zip") {
        handleMultiInvoiceDownload({ result, isZip: true })
      } else {
        handleMultiInvoiceDownload({ result, isZip: false })
      }

      reset()
    } catch (error) {
      console.log(error.message)
      toast({
        title: "There was an error while downloading report(s).",
        description: error.message,
        status: "error",
        duration: 3000,
      })
    } finally {
      setIsMultiLoading(false)
    }
  }

  const handleOnClick = () => {
    reset()
    toggle()
  }

  const isVerifiable = permissions.includes("verifyResult")

  return (
    <Fragment>
      <div className="card mb-3">
        <div className="d-flex justify-content-between">
          <div className="d-flex px-2 py-2 horizontal-scroll scrollbar-none">
            {currentRoute !== "patient-recovery" && (
              <div className="ml-2">
                <button
                  className="btn-toolbar btn-primary"
                  title="Download Report(s)"
                  disabled={isMultiLoading}
                  onClick={toggleOpenMultiOptionalDownloadModal}
                >
                  <i className="fas fa-download"></i>
                </button>
              </div>
            )}

            {(user?.role === "admin" || isVerifiable) && currentRoute !== "patient-recovery" && (
              <>
                <div className="ml-2">
                  <button
                    className="btn-toolbar btn-dark"
                    title="Edit Sample & Report Date Time"
                    disabled={isMultiLoading}
                    onClick={toggleMultiEditSampleAndReportTimeModal}
                  >
                    <i className="fas fa-history"></i>
                  </button>
                </div>
                <div className="ml-2">
                  <button
                    className="btn-toolbar btn-success"
                    title="Verify Report(s)"
                    disabled={isMultiLoading}
                    onClick={multiReportVerify}
                  >
                    <i className="fas fa-check"></i>
                  </button>
                </div>
              </>
            )}
            {user.role === "admin" && currentRoute !== "patient-recovery" && (
              <div className="ml-2">
                <button
                  className="btn-toolbar btn-dark"
                  title="Send Report(s)"
                  disabled={isMultiLoading}
                  onClick={multiReportSend}
                >
                  <i className="fas fa-share"></i>
                </button>
              </div>
            )}

            {user.role === "admin" && (
              <div className="ml-2">
                <button
                  className="btn-toolbar btn-danger"
                  title="Delete Report(s)"
                  disabled={isMultiLoading}
                  onClick={() =>
                    currentRoute !== "patient-recovery" ? multiDelete() : toggleHardDeleteConfirmationModal()
                  }
                >
                  <i className="fas fa-trash"></i>
                </button>
              </div>
            )}

            {user.role === "admin" && currentRoute === "patient-recovery" && (
              <div className="ml-2">
                <button
                  className="btn-toolbar btn-success"
                  title="Recover Report(s)"
                  disabled={isMultiLoading}
                  onClick={multiPatientRecover}
                >
                  <i className="fas fa-window-restore"></i>
                </button>
              </div>
            )}
          </div>
          <div className="d-flex cursor-pointer align-items-center px-2" onClick={handleOnClick}>
            <i className="fas fa-times text-dark" />
          </div>
        </div>
      </div>

      {openMultiEditSampleAndReportTimeModal && (
        <MultiEditSampleAndReportTimeModal
          openModal={openMultiEditSampleAndReportTimeModal}
          toggle={toggleMultiEditSampleAndReportTimeModal}
          registrations={registrations}
          updateData={updateData}
        />
      )}

      {openMultiOptionalDownloadModal && (
        <MultiOptionalDownloadModal
          openModal={openMultiOptionalDownloadModal}
          toggle={toggleOpenMultiOptionalDownloadModal}
          multiDownload={multiDownload}
          toggleMultiSelectionToolbar={toggle}
        />
      )}

      {openConfirmationModal && (
        <ConfirmationModal
          openModal={openConfirmationModal}
          toggle={toggleConfirmationModal}
          callback={handleMultiReportVerify}
          headerText="Result Verify Confirmation"
          bodyText="Do you really want to verify these report(s)?"
        />
      )}

      {openHardDeleteConfirmationModal && (
        <HardDeleteConfirmationModal
          openModal={openHardDeleteConfirmationModal}
          toggle={toggleHardDeleteConfirmationModal}
          callback={multiDelete}
          headerText="Delete Confirmation"
          headerClass="bg-danger"
          headerTextClass="text-white"
          bodyText="This action is irreversible. Do you really want to permenantly delete these records?"
          okayBtnClass="btn-danger"
        />
      )}
    </Fragment>
  )
}
