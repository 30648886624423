import { useEffect } from "react"
import { Alert } from "reactstrap"
import dayjs from "dayjs"

export const renderSheetHeader = data => {
  const { patient, ...rest } = data
  data = rest
  return (
    <table
      style={{
        width: "100%",
        margin: "auto",
        padding: "1rem 0",
        borderCollapse: "collapse",
        borderSpacing: "0",
        border: "0px solid",
      }}
    >
      <tbody>
        <tr>
          <td align="center" style={{ border: "none", paddingLeft: 0, paddingRight: 0 }}>
            <div className="d-flex align-items-center justify-content-between">
              <img
                src="assets/images/logo-dark.png"
                style={{
                  maxHeight: "120px",
                }}
                alt="Emirates Logo"
              />

              {!data?.rapidPcrResult && (
                <div style={{ height: "5rem" }}>
                  <h4
                    style={{
                      width: "100%",
                      margin: "0rem auto",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    Patient Covid-19 Medical History
                  </h4>
                </div>
              )}

              <img
                src={patient?.profilePictureUrl ? patient.profilePictureUrl : "assets/images/blank.png"}
                style={{
                  maxHeight: "120px",
                }}
                alt="Blank Space For Profile DP"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export const renderPatientInformation = data => {
  return (
    <table
      style={{
        width: "100%",
        height: "fit-content",
        padding: "0.5rem 0",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          <td align="left" width="35%">
            <h6
              style={{
                margin: 0,
                fontWeight: "bold",
              }}
            >
              Patient Name
            </h6>
            <h4 className="m-0 mb-4">
              {data[0]?.patient?.fullname ? <span> {data[0]?.patient.fullname}</span> : <span> &nbsp;</span>}
            </h4>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export const renderTableHeader = () => {
  return (
    <thead className="table-header-bg">
      <tr>
        <th scope="col">S#</th>
        <th scope="col">Time</th>
        <th scope="col">Rapid PCR Result</th>
        <th scope="col">PCR Result</th>
        <th scope="col">IgG</th>
        <th scope="col">IgM</th>
      </tr>
    </thead>
  )
}
export const renderTableBody = data => {
  return (
    <tbody>
      {data.map((data, idx) => {
        return (
          <tr key={`Registration-${data?.registrationId}-${idx}`}>
            <td>{idx + 1}</td>
            <td>{data?.sampleReceivedAt ? dayjs(data?.sampleReceivedAt).format("DD-MM-YYYY") : "-"}</td>
            <td>
              {data?.rapidPcrResult === "not-detected" ? (
                <>
                  <strong className="text-success">NEGATIVE</strong>
                </>
              ) : !data?.rapidPcrResult ? (
                <>
                  <strong>-</strong>
                </>
              ) : (
                <>
                  <strong className="text-danger">POSITIVE</strong>
                </>
              )}
            </td>
            <td>
              {data?.result === "not-detected" ? (
                <>
                  <strong className="text-success">NEGATIVE</strong>
                </>
              ) : !data?.result ? (
                <>
                  <strong>-</strong>
                </>
              ) : (
                <>
                  <strong className="text-danger">POSITIVE</strong>
                </>
              )}
            </td>
            <td>{data?.iggResultRange || "-"}</td>
            <td>{data?.antibodyResultRange || "-"}</td>
          </tr>
        )
      })}
    </tbody>
  )
}
export const renderPatientTestRecords = data => {
  return (
    <table className="table table-hover text-nowrap">
      {renderTableHeader()}
      {renderTableBody(data)}
    </table>
  )
}

const ViewPatientHistorySheetLayout = props => {
  const { data } = props

  useEffect(() => {
    return null
  }, [data])

  return (
    <div
      style={{
        maxWidth: "1000px",
        margin: "auto",
        backgroundColor: "white",
        minHeight: "fit-content", //100vh
        paddingBottom: "2rem",
      }}
    >
      {renderSheetHeader(data)}
      {data.message === "identifier not found" ? (
        <Alert color="warning">No patient found corresponding to this search.</Alert>
      ) : (
        renderPatientInformation(data)
      )}
      {data.message !== "identifier not found" && renderPatientTestRecords(data)}
    </div>
  )
}

export default ViewPatientHistorySheetLayout
