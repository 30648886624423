import * as yup from "yup"
import PhoneInput from "react-phone-input-2"
import React, { Fragment, useEffect } from "react"
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { Spinner } from "reactstrap"

import { useToast } from "@chakra-ui/core"
import { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"

import * as apiURLs from "../services/apiURLs"
import api from "../services/api"
import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import Footer from "../components/layouts/footer/Footer"
import ImagePreview from "../components/common/ImagePreview"
import PhotoCaptureModal from "../components/modals/easy-registration/PhotoCaptureModal"
import ProfilePhotoCaptureModal from "../components/modals/easy-registration/ProfilePhotoCaptureModal"
import { AllRoutesMap } from "../routes/RoutesConfig"
import { embassies, getTNoPrefix, phoneNoCountries, registrationTypes } from "../utils/common"

const schema = yup.object().shape(
  {
    tNo: yup.string(),
    phone: yup.string(),
    passport: yup.string(),
    // phone: yup.string().when("passport", {
    //   is: passport => !passport || passport.length === 0,
    //   then: yup.string().required(""),
    //   otherwise: yup.string(),
    // }),
    // passport: yup.string().when("phone", {
    //   is: phone => !phone || phone.length === 0,
    //   then: yup.string().required("Passport is required field"),
    //   otherwise: yup.string(),
    // }),
    registrationType: yup.string().required(),
    testCenter: yup.string(),
    profilePictureUrl: yup.string(),
    registrationFormUrl: yup.string(),
    airlineTicketUrl: yup.string(),
    passportUrl: yup.string(),
    otherPhotoUrl: yup.string(),
  }
  // [["phone", "passport"]]
)

export default function EasyRegistration() {
  const toast = useToast()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const previousSelectedEmbassy = localStorage.getItem("embassy")

  const [openRegistrationFormPhotoModal, setOpenRegistrationFormPhotoModal] = useState(false)
  const [openProfilePhotoModal, setOpenProfilePhotoModal] = useState(false)
  const [openPassportPhotoModal, setOpenPassportPhotoModal] = useState(false)
  const [openAirlineTicketPhotoModal, setOpenAirlineTicketPhotoModal] = useState(false)
  const [openOtherPhotoModal, setOpenOtherPhotoModal] = useState(false)
  const [embassy, setEmbassy] = useState(previousSelectedEmbassy)
  const [tNoPrefix, setTNoPrefix] = useState("")
  const testCenter = "ISB02"

  const { register, handleSubmit, control, errors, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      registrationType: "passenger",
    },
  })

  const watcher = watch([
    "profilePictureUrl",
    "registrationFormUrl",
    "otherPhotoUrl",
    "airlineTicketUrl",
    "passportUrl",
  ])

  const profilePhoto = watcher.profilePictureUrl
  const registrationPhoto = watcher.registrationFormUrl
  const otherPhoto = watcher.otherPhotoUrl
  const airlineTicketPhoto = watcher.airlineTicketUrl
  const passportPhoto = watcher.passportUrl

  const toggleRegistrationFormPhotoModal = () => setOpenRegistrationFormPhotoModal(!openRegistrationFormPhotoModal)

  const toggleProfilePhotoModal = () => setOpenProfilePhotoModal(!openProfilePhotoModal)

  const togglePassportPhotoModal = () => setOpenPassportPhotoModal(!openPassportPhotoModal)

  const toggleAirlineTicketPhoModal = () => setOpenAirlineTicketPhotoModal(!openAirlineTicketPhotoModal)
  const toggleOtherPhotoModal = () => setOpenOtherPhotoModal(!openOtherPhotoModal)

  useEffect(() => {
    localStorage.setItem("embassy", embassy || "")
    setTNoPrefix(getTNoPrefix(embassy))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embassy])

  const onSubmit = async data => {
    try {
      setLoading(true)
      const res = await api.post(apiURLs.PATIENTS, {
        ...data,
        testCenter,
        embassy: embassy || undefined,
      })
      history.push(
        `${AllRoutesMap.registrationSuccess}?patientId=${res?.data?.patient.patientId}&registrationId=${res?.data?.registration.registrationId}&labId=${res?.data?.registration.labId}&easyRegister=true`
      )
      toast({
        title: "Registrated patient successfully.",
        status: "success",
        duration: 10000,
      })
    } catch (error) {
      toast({
        title: "There was an error while registering patient.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <h2 className="text-white mb-0">
                    <i className="fas fa-address-card mr-4"></i>
                    Registration Form
                  </h2>
                </div>
                <div className="col-12 col-lg-4 text-left text-lg-right ml-auto">
                  <Link to={AllRoutesMap.staff} className="btn btn-white mr-2">
                    <i className="fas fa-home space-right"></i>
                    Home
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5">
            <form className="cms-contact-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-8 mt-4">
                  <div className="row mb-4">
                    <div className="col-12">
                      <h5>Embassy</h5>
                    </div>
                    <div className="col-12">
                      <select
                        name="embassy"
                        value={embassy}
                        onChange={e => {
                          setEmbassy(e.target.value)
                        }}
                      >
                        <option value="" selected>
                          Select Embassy
                        </option>
                        {embassies.map(({ value, label }, idx) => (
                          <option key={`Embassy-${label}-${idx}`} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12">
                      <h5>Patient Type</h5>
                    </div>
                    <div className="col-12">
                      <select name="registrationType" ref={register}>
                        {registrationTypes.map(({ value, label }) => (
                          <option value={value} key={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12">
                      <h5>T Number</h5>
                    </div>
                    <div className="col-12">
                      {embassy ? (
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text input-prepend" id="basic-addon1">
                              {tNoPrefix}
                            </span>
                          </div>
                          <input
                            type="text"
                            placeholder="Enter T number"
                            name="tNo"
                            ref={register}
                            className="form-control c-input text-capitalize"
                            aria-describedby="basic-addon1"
                            style={{ paddingLeft: 3 }}
                          />
                        </div>
                      ) : (
                        <input
                          type="text"
                          placeholder="Enter T number"
                          name="tNo"
                          className="text-capitalize"
                          ref={register}
                        />
                      )}
                      <span className="text-danger">{errors.tNo?.message}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Passport No.</label>
                            <input type="text" placeholder="Your passport number" name="passport" ref={register} />
                            <span className="text-danger">{errors.passport?.message}</span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-bold">Cell No.</label>
                            <Controller
                              control={control}
                              name="phone"
                              render={({ onChange, value }) => (
                                <PhoneInput
                                  onlyCountries={phoneNoCountries}
                                  country={"pk"}
                                  onChange={onChange}
                                  value={value}
                                />
                              )}
                            />
                            <span className="text-danger">{errors.phone?.message}</span>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-12">
                          <h5>
                            <i className="fas fa-files-o mr-2"></i>
                            Photos
                          </h5>
                          <p>All photos are optional</p>

                          <div className="card p-4">
                            <ul className="requirements-list mb-0">
                              <li className="d-flex flex-column align-items-start border-bottom my-3">
                                <div className="d-flex mb-3">
                                  <i className="fas fa-paperclip mr-2"></i>
                                  <span className="list-text">Photo of Registration Form</span>
                                </div>

                                {registrationPhoto && <ImagePreview dataUri={registrationPhoto} isFullscreen={false} />}

                                <button
                                  type="button"
                                  className="btn btn-primary mt-2"
                                  onClick={() => setOpenRegistrationFormPhotoModal(true)}
                                >
                                  Take Photo
                                </button>

                                <Controller
                                  control={control}
                                  name="registrationFormUrl"
                                  render={({ onChange }) => (
                                    <PhotoCaptureModal
                                      openModal={openRegistrationFormPhotoModal}
                                      toggle={toggleRegistrationFormPhotoModal}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              </li>

                              <li className="d-flex flex-column align-items-start border-bottom my-3">
                                <div className="d-flex mb-3">
                                  <i className="fas fa-paperclip mr-2"></i>
                                  <span className="list-text">Photo of passport</span>
                                </div>

                                {passportPhoto && <ImagePreview dataUri={passportPhoto} isFullscreen={false} />}

                                <button
                                  type="button"
                                  className="btn btn-primary mt-2"
                                  onClick={() => setOpenPassportPhotoModal(true)}
                                >
                                  Take Photo
                                </button>

                                <Controller
                                  control={control}
                                  name="passportUrl"
                                  render={({ onChange }) => (
                                    <PhotoCaptureModal
                                      openModal={openPassportPhotoModal}
                                      toggle={togglePassportPhotoModal}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              </li>

                              <li className="d-flex flex-column align-items-start border-bottom my-3">
                                <div className="d-flex mb-3">
                                  <i className="fas fa-paperclip mr-2"></i>
                                  <span className="list-text">Photo of patient</span>
                                </div>

                                {profilePhoto && <ImagePreview dataUri={profilePhoto} isFullscreen={false} />}

                                <button
                                  type="button"
                                  className="btn btn-primary mt-2"
                                  onClick={() => setOpenProfilePhotoModal(true)}
                                >
                                  Take Photo
                                </button>

                                <Controller
                                  control={control}
                                  name="profilePictureUrl"
                                  render={({ onChange }) => (
                                    <ProfilePhotoCaptureModal
                                      openModal={openProfilePhotoModal}
                                      toggle={toggleProfilePhotoModal}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              </li>
                              <li className="d-flex flex-column align-items-start border-bottom my-3">
                                <div className="d-flex mb-3">
                                  <i className="fas fa-paperclip mr-2"></i>
                                  <span className="list-text">Photo of Airline Ticket</span>
                                </div>
                                {airlineTicketPhoto && (
                                  <ImagePreview dataUri={airlineTicketPhoto} isFullscreen={false} />
                                )}

                                <button
                                  type="button"
                                  className="btn btn-primary mt-2"
                                  onClick={() => setOpenAirlineTicketPhotoModal(true)}
                                >
                                  Take Photo
                                </button>

                                <Controller
                                  control={control}
                                  name="airlineTicketUrl"
                                  render={({ onChange }) => (
                                    <PhotoCaptureModal
                                      openModal={openAirlineTicketPhotoModal}
                                      toggle={toggleAirlineTicketPhoModal}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              </li>

                              <li className="d-flex flex-column align-items-start border-bottom my-3">
                                <div className="d-flex mb-3">
                                  <i className="fas fa-paperclip mr-2"></i>
                                  <span className="list-text">Photo of CNIC (For Pakistani Nationals)</span>
                                </div>

                                {otherPhoto && <ImagePreview dataUri={otherPhoto} isFullscreen={false} />}

                                <button
                                  type="button"
                                  className="btn btn-primary mt-2 "
                                  onClick={() => setOpenOtherPhotoModal(true)}
                                >
                                  Take Photo
                                </button>

                                <Controller
                                  control={control}
                                  name="otherPhotoUrl"
                                  render={({ onChange }) => (
                                    <PhotoCaptureModal
                                      openModal={openOtherPhotoModal}
                                      toggle={toggleOtherPhotoModal}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 pt-2">
                <div className="col-md-8 col-12">
                  <button
                    type="submit"
                    className="btn btn-secondary btn-fullwidth"
                    style={{ whiteSpace: "nowrap" }}
                    disabled={loading}
                  >
                    <i className="fas fa-arrow-circle-right mr-2"></i>
                    Submit Registration
                    {loading && <Spinner color="default" className="ml-2" size="sm" />}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </Main>
      <Footer />
    </Fragment>
  )
}
