import React from "react"
import dayjs from "dayjs"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"

import RequiredFieldMark from "../common/RequiredFieldMark"
import DatePicker from "../common/DatePicker"

const schema = yup.object().shape({
  labID: yup.string().required("Lab Serial is required"),
  registrationDate: yup.date().required("Sample received date is required"),
})

export default function LabManualModal(props) {
  const { openModal, toggle, onBarcodeScan, loadingDetails } = props

  const { register, handleSubmit, control, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      registrationDate: new Date(),
    },
  })

  const onSubmit = ({ labID, registrationDate }) => {
    const createdAt = dayjs(registrationDate).startOf("day")
    onBarcodeScan({ value: labID, createdAt })
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Receive Sample</ModalHeader>
      <ModalBody>
        <form className="cms-contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="text-bold">
                  Registration Date <RequiredFieldMark />
                </label>
                <div className="d-flex">
                  <div className="cms-input date-wrap w-100">
                    <Controller
                      control={control}
                      name="registrationDate"
                      render={({ onChange, value }) => (
                        <DatePicker
                          placeholder="Select Date"
                          id="date-picker-manual-modal"
                          onChange={onChange}
                          value={value}
                          maxDate={new Date()}
                          className="w-100"
                        />
                      )}
                    />
                    <span className="text-danger">{errors.registrationDate?.message}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="text-bold">
                  Lab Serial No <RequiredFieldMark />
                </label>
                <input type="text" placeholder="Enter Lab Serial No" name="labID" ref={register} />
                <span className="text-danger">{errors.labID?.message}</span>
              </div>
            </div>
          </div>

          <ModalFooter className="pb-0 px-0">
            <button type="submit" className="btn btn-secondary" disabled={loadingDetails}>
              Receive / Upload
              {loadingDetails && <Spinner color="default" className="ml-2" size="sm" />}
            </button>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  )
}
