import { useEffect, useState } from "react"

export const useDebounce = (value, delay) => {
  const [debounceValue, setDebounceValue] = useState(value)

  useEffect(() => {
    //Update debounced value after delay
    const handler = setTimeout(() => {
      setDebounceValue(value)
    }, delay || 500)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debounceValue
}
