import React, { Fragment } from "react"
import { Spinner } from "reactstrap"
export default function FullScreenLoader() {
  return (
    <Fragment>
      <div className="background-overlay-loader w-100 h-100" style={{ backgroundColor: "gray" }}></div>
      <div className="d-flex justify-content-center align-items-center w-100 h-100" style={{ position: "absolute" }}>
        <Spinner color="secondary" />
      </div>
    </Fragment>
  )
}
