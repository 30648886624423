export const roles = [
  {
    label: "Staff",
    value: "staff",
  },
  {
    label: "Lab",
    value: "lab",
  },
  {
    label: "Chinese",
    value: "chinese",
  },
  {
    label: "Gerry's",
    value: "turkish",
  },
  {
    label: "Emirates",
    value: "emirates",
  },
  {
    label: "PIA",
    value: "pia",
  },
  {
    label: "Serene Air",
    value: "sereneAir",
  },
  {
    label: "BGP",
    value: "bgp",
  },
  {
    label: "Sahiwal Coal Power Plant",
    value: "sahiwalCoalPower",
  },
  {
    label: "National Center For Physics (Pakistan)",
    value: "nationalCenterForPhysics",
  },
  {
    label: "Mega Pro Lynks Company",
    value: "megaProLynks",
  },
  {
    label: "Power China - FWO",
    value: "powerChinaFWO",
  },
  {
    label: "Hydrochina Inernational Engineering Co.,LTD",
    value: "hydroChinaInternationalEngineering",
  },
  {
    label: "GM & SONS",
    value: "gmAndSons",
  },
  {
    label: "China COV",
    value: "chinaCov",
  },
  {
    label: "Huawei",
    value: "huawei",
  },
  {
    label: "HSR",
    value: "hsr",
  },
  {
    label: "TGDC",
    value: "tgdc",
  },
  {
    label: "TGDC Section 1",
    value: "tgdcSec1",
  },
  {
    label: "TGDC Section 2",
    value: "tgdcSec2",
  },
  {
    label: "TGDC Section 3",
    value: "tgdcSec3",
  },
  {
    label: "TGDC Section 4",
    value: "tgdcSec4",
  },
  {
    label: "TGDC Section 5",
    value: "tgdcSec5",
  },
  {
    label: "Chashma Hydel Power Plant",
    value: "chashmaHydelPowerPlant",
  },
  {
    label: "CCDC",
    value: "ccdc",
  },
  {
    label: "Deli-JW Glassware Company",
    value: "deliJwGlasswareCompany",
  },
  {
    label: "CCDC Company (Adil Oil Field Doltala)",
    value: "ccdcAdilOilField",
  },
  {
    label: "MG Motors",
    value: "mgMotors",
  },
  {
    label: "CGGC Company",
    value: "cggc",
  },
  {
    label: "ZTE Company",
    value: "zte",
  },
  {
    label: "Airblue",
    value: "airBlue",
  },
  {
    label: "Etihad Airways",
    value: "etihadAirways",
  },
  {
    label: "Air Arabia",
    value: "airArabia",
  },
  {
    label: "Qatar Airways",
    value: "qatarAirways",
  },
  {
    label: "Saudi Airways",
    value: "saudiAirways",
  },
  {
    label: "Fly Dubai",
    value: "flyDubai",
  },
  {
    label: "Oman",
    value: "oman",
  },
  {
    label: "KPCL",
    value: "kpcl",
  },
  {
    label: "SMEC International Pty. Ltd",
    value: "smec",
  },
  {
    label: "Xinrong Construction Engineering (SMC-Private) Ltd",
    value: "smc",
  },
  {
    label: "Xinyuan International Engineering Company (Pvt) Ltd",
    value: "xinyuanInternationalEngineering",
  },
]

export const getRole = roleValue => {
  if (!roleValue) {
    return undefined
  }
  return roles.find(role => role.value === roleValue)?.label || roleValue
}
