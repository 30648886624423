import React from "react"
import SingleDatePicker from "react-date-picker"

export default function DatePicker({ onChange, value, clearIcon, ...otherProps }) {
  return (
    <SingleDatePicker
      onChange={onChange}
      value={value}
      clearIcon={clearIcon || null}
      calendarIcon={null}
      format={"d/M/y"}
      {...otherProps}
    />
  )
}
