import ReactBarcode from "react-barcode"
import React, { Fragment } from "react"
import { Alert } from "reactstrap"

import "./MRBarcode.css"
import { getFromQueryString } from "../utils/common"

export default function MRBarcode() {
  const labId = getFromQueryString("labId")
  const registrationId = getFromQueryString("registrationId")

  if (!labId || !registrationId) {
    return <Alert color="warning border-0 mb-0">Lab Serial or MR Number seems to be missing</Alert>
  }
  return (
    <Fragment>
      <div id="section-to-print">
        <div className="d-flex flex-column align-items-center">
          <ReactBarcode value={registrationId} displayValue={false} width={1.6} />
          <span className="text-dark">{labId}</span>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center mt-5 pt-5">
        <button className="no-print text-white" onClick={() => window.print()}>
          PRINT BARCODE
        </button>
      </div>
    </Fragment>
  )
}
