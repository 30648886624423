export const userPermissions = [
  { label: "Edit Registration", value: "editRegistration" },
  { label: "Edit Result", value: "editResult" },
  { label: "Upload Result", value: "uploadResult" },
  { label: "Verify Result", value: "verifyResult" },
  { label: "Bulk Invoicing", value: "bulkInvoicing" },
]

export const getSelectedPermissions = selectedPermissions => {
  return selectedPermissions.map(selectedPermission => selectedPermission.value)
}

export const getPermissions = permissions => {
  return userPermissions.filter(userPermission => permissions.includes(userPermission.value))
}
