import React from "react"
import { Modal, ModalFooter } from "reactstrap"
import { nanoid } from "nanoid"

import CameraPicture from "../../common/CameraPicture"

export default function ProfilePhotoCaptureModal(props) {
  const { openModal, toggle } = props
  const onChange = e => {
    toggle()
    props.onChange(e)
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true} style={{ padding: 0 }}>
      <div className="profile-camera">
        {openModal && (
          <CameraPicture
            onTakePhoto={onChange}
            pictureId={nanoid()}
            rearCamera={true}
            otherCameraProps={{
              idealResolution: { width: 600, height: 600 },
            }}
          />
        )}
      </div>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={toggle}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  )
}
