import React, { useState } from "react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"
import Select from "react-select"

import RequiredFieldMark from "../common/RequiredFieldMark"
import { useToast } from "@chakra-ui/core"
import { getRole } from "../../utils/roles"
import { userPermissions, getSelectedPermissions, getPermissions } from "../../utils/permissions"
import { updateUser } from "../../services/api"

const schema = yup.object().shape({
  password: yup.string(),
})

const customStyles = {
  control: provided => {
    const background = "#f1f5fe"
    const border = "1px solid #f1f5fe"
    return { ...provided, background, border }
  },
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "#07152a",
  }),
}

export default function ChangeUserModal(props) {
  const {
    openModal,
    toggle,
    data: { email, role, permissions },
    searchUsers,
  } = props

  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const { register, control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      permissions: getPermissions(permissions),
    },
    mode: "onChange",
  })

  const onSubmit = async data => {
    const apiData = {
      email,
      permissions: getSelectedPermissions(data.permissions),
      password: data.password,
    }
    try {
      setLoading(true)
      await updateUser(apiData)
      toggle()
      searchUsers()
      toast({
        title: "User updated successfully.",
        description: "User updated successfully.",
        status: "success",
        duration: 4000,
      })
    } catch (err) {
      const error = err.response?.data?.errors?.message || "There was an error while updating user."
      toast({
        title: "Error occurred while updating user.",
        description: error,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Update User</ModalHeader>
      <ModalBody>
        <form className="cms-contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="text-bold">Email/Username</label>
                <div>
                  <span className="text-dark">{email}</span>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="text-bold">
                  Role <RequiredFieldMark />
                </label>
                <div>
                  <span className="text-dark text-capitalize">{getRole(role)}</span>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-2">
              <div className="">
                <label className="text-bold">Permissions</label>
              </div>
              <div>
                <Controller
                  name="permissions"
                  control={control}
                  render={({ onChange, value }) => (
                    <Select
                      isMulti
                      value={value}
                      onChange={onChange}
                      classNamePrefix="select"
                      customStyles={customStyles}
                      options={userPermissions}
                      closeMenuOnSelect={false}
                      className="basic-multi-select"
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="text-bold">Password</label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  ref={register}
                  autoComplete="new-password"
                />
                <span className="text-danger">{errors.password?.message}</span>
              </div>
            </div>
          </div>

          <ModalFooter className="pb-0 px-0">
            <button type="submit" className="btn btn-secondary" disabled={loading}>
              Update
              {loading && <Spinner color="default" className="ml-2" size="sm" />}
            </button>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  )
}
