import QRCode from "qrcode"
import dayjs from "dayjs"
import { PDFDocument, StandardFonts, rgb } from "pdf-lib"

import {
  capitalize,
  getEmbassy,
  getReference,
  getAmplificationKit,
  getExtractionKit,
  getTestResultStatus,
  normalizeRGB,
  getIgmResultRange,
  getIggResultRange,
} from "../../utils/common"
import { openPDFNewTab, renderBarcode, renderProfileImage } from "../../utils/pdfs"
import { getAirport } from "../../utils/airports"
import { getAirline } from "../../utils/airlines"

const getTemplate = registration => {
  let templateFileURL = null

  if (registration?.charges && registration?.igmCharges && registration?.iggCharges) {
    if (registration?.registrationType === "passenger") {
      templateFileURL = `assets/pdf-templates/passenger/report-template.pdf`
    } else {
      templateFileURL = `assets/pdf-templates/general/report-template.pdf`
    }
  }
  let type = "all"

  if (registration?.charges && registration?.igmCharges && !registration?.iggCharges) {
    if (registration?.registrationType === "passenger") {
      templateFileURL = `assets/pdf-templates/passenger/report-template-pcr-and-igm-antibody.pdf`
    } else {
      templateFileURL = `assets/pdf-templates/general/report-template-pcr-and-igm-antibody.pdf`
    }
    type = "pcrAndIgm"
  } else if (registration?.charges && !registration?.igmCharges && registration?.iggCharges) {
    if (registration?.registrationType === "passenger") {
      templateFileURL = `assets/pdf-templates/passenger/report-template-pcr-and-igg-antibody.pdf`
    } else {
      templateFileURL = `assets/pdf-templates/general/report-template-pcr-and-igg-antibody.pdf`
    }
    type = "pcrAndIgg"
  } else if (registration?.rapidPcrCharges) {
    if (registration?.registrationType === "passenger") {
      templateFileURL = `assets/pdf-templates/passenger/report-template-rapid-pcr.pdf`
    } else {
      templateFileURL = `assets/pdf-templates/general/report-template-rapid-pcr.pdf`
    }
    type = "rapidPcr"
  } else if (registration?.charges && !registration?.igmCharges && !registration?.iggCharges) {
    if (registration?.registrationType === "passenger") {
      templateFileURL = `assets/pdf-templates/passenger/report-template-pcr.pdf`
    } else {
      templateFileURL = `assets/pdf-templates/general/report-template-pcr.pdf`
    }
    type = "pcr"
  } else if (!registration?.charges && registration?.igmCharges && registration?.iggCharges) {
    if (registration?.registrationType === "passenger") {
      templateFileURL = `assets/pdf-templates/passenger/report-template-antibody.pdf`
    } else {
      templateFileURL = `assets/pdf-templates/general/report-template-antibody.pdf`
    }
    type = "antibody"
  } else if (!registration?.charges && registration?.igmCharges && !registration?.iggCharges) {
    if (registration?.registrationType === "passenger") {
      templateFileURL = `assets/pdf-templates/passenger/report-template-igm-antibody.pdf`
    } else {
      templateFileURL = `assets/pdf-templates/general/report-template-igm-antibody.pdf`
    }
    type = "igm"
  } else if (!registration?.charges && !registration?.igmCharges && registration?.iggCharges) {
    if (registration?.registrationType === "passenger") {
      templateFileURL = `assets/pdf-templates/passenger/report-template-igg-antibody.pdf`
    } else {
      templateFileURL = `assets/pdf-templates/general/report-template-igg-antibody.pdf`
    }
    type = "igg"
  }

  return { url: templateFileURL, type }
}

export default async function createReportPDF(result) {
  const { registration, isDownload, isZip, role = null } = result

  // This should be a Uint8Array or ArrayBuffer
  // This data can be obtained in a number of different ways
  // If your running in a Node environment, you could use fs.readFile()
  // In the browser, you could make a fetch() call and use res.arrayBuffer()
  const { url, type } = getTemplate(registration)

  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes)

  // Embed the Helvetica font
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold) // for bold font
  const courierFont = await pdfDoc.embedFont(StandardFonts.Courier)

  // Get the first page of the document
  const pages = pdfDoc.getPages()
  const page = pages[0]

  const config = {
    page,
    helveticaFont,
    helveticaBoldFont,
    courierFont,
    pdfDoc,
    role,
  }

  await renderProfileImage(result, config)
  await renderEmbassyChecks(result, config)

  renderHeader(result, config)
  renderPassengerInformation(result, config)

  if (type === "all" || type === "pcrAndIgm" || type === "pcrAndIgg" || type === "pcr" || type === "rapidPcr") {
    renderReportDetails(result, config, registration.registrationType)
  }

  if (
    type === "all" ||
    type === "pcrAndIgm" ||
    type === "pcrAndIgg" ||
    type === "antibody" ||
    type === "igg" ||
    type === "igm"
  ) {
    renderAntibodyTestInformation(result, { ...config, type }, registration.registrationType)
  }

  if (registration.registrationType === "passenger") {
    renderAirlineInformation(result, config)
  }

  await renderQRCode(result, config)
  await renderBarcode(result, config, registration.registrationType)

  // Serialize the PDFDocument to bytes= (a Uint8Array)
  const pdfBytes = await pdfDoc.save()
  if (isDownload) {
    if (isZip) {
      return pdfBytes
    } else {
      const reportName = `report-${result.patient.fullname}-${result.registration.registrationId}.pdf`
      // Trigger the browser to download the PDF document
      window.download(pdfBytes, reportName, "application/pdf")
    }
  } else {
    openPDFNewTab(pdfBytes, false)
  }
}

export const getPDFBase64 = async result => {
  const { registration } = result

  // This should be a Uint8Array or ArrayBuffer
  // This data can be obtained in a number of different ways
  // If your running in a Node environment, you could use fs.readFile()
  // In the browser, you could make a fetch() call and use res.arrayBuffer()
  const url = `assets/pdf-templates/passenger/report-template.pdf`

  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes)

  // Embed the Helvetica font
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

  // Get the first page of the document
  const pages = pdfDoc.getPages()
  const page = pages[0]

  const config = {
    page,
    helveticaFont,
    pdfDoc,
  }

  await renderProfileImage(result, config)
  await renderEmbassyChecks(result, config)
  renderHeader(result, config)
  renderPassengerInformation(result, config)
  renderReportDetails(result, config)

  if (result.registration?.antibodyResult) {
    renderAntibodyTestInformation(result, config)
  }

  if (registration.registrationType === "passenger") {
    renderAirlineInformation(result, config)
  }

  await renderQRCode(result, config)
  await renderBarcode(result, config)

  // Serialize the PDFDocument to bytes= (a Uint8Array)
  const base64 = await pdfDoc.saveAsBase64()
  return base64
}

export const renderEmbassyChecks = async (data, config) => {
  const { page, helveticaBoldFont } = config
  const { registration } = data

  const yPosition = 728

  const embassyRefX = 40
  const embassyRefXConstant = registration?.embassy ? 45 : 22
  const embassyRefValueX = embassyRefX + embassyRefXConstant

  if (registration.registrationType === "passenger") {
    page.drawText(registration?.embassy ? "Embassy: " : "Ref: ", {
      x: embassyRefX,
      y: yPosition + 5,
      size: 10,
      font: helveticaBoldFont,
    })

    page.drawText(getEmbassy(registration.embassy) || getReference(registration?.reference) || "-", {
      x: embassyRefValueX,
      y: yPosition + 5,
      size: 10,
    })
  } else {
    page.drawText(registration?.embassy ? "Embassy: " : "Ref: ", {
      x: embassyRefX,
      y: yPosition + 5,
      size: 10,
      font: helveticaBoldFont,
    })

    page.drawText(getEmbassy(registration.embassy) || getReference(registration?.reference) || "-", {
      x: embassyRefValueX,
      y: yPosition + 5,
      size: 10,
    })
  }
}

const renderHeader = (data, config) => {
  const { page, helveticaFont, helveticaBoldFont } = config
  const { patient, registration } = data

  // Render Fullname
  page.drawText(patient?.fullname || "-", {
    x: 40,
    y: 687, //672
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  const sampleDateTime = dayjs(registration.sampleTakenAt).format("DD/MM/YYYY hh:mm A")

  // Render Sample Time
  page.drawText(sampleDateTime, {
    x: 281,
    y: 706, //691
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  const reportVerifiedDateTime = dayjs(registration.reportVerifiedAt).format("DD/MM/YYYY hh:mm A")

  // Render Report Time
  page.drawText(reportVerifiedDateTime, {
    x: 280,
    y: 695, //680
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render MR No.
  page.drawText(registration?.registrationId, {
    x: 251,
    y: 683, //668
    size: 8,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  if (registration?.charges || registration?.rapidPcrCharges) {
    const { status, color } = getTestResultStatus(
      registration,
      registration?.rapidPcrCharges ? "rapidPcrResult" : "result"
    )
    const { r, g, b } = normalizeRGB(color)

    // Render Status Text
    page.drawText("COVID-19 Test Result", {
      x: 382,
      y: 704, // 689
      size: 10,
      font: helveticaBoldFont,
      color: rgb(0.1, 0.1, 0.1),
    })

    // Render Status
    page.drawText(status || "", {
      x: 382,
      y: 679, //664
      size: 16,
      font: helveticaFont,
      color: rgb(r, g, b),
    })
  }
}

const renderQRCode = async (data, config) => {
  const { page, pdfDoc } = config
  const { patient, registration } = data

  const mrNo = registration.registrationId
  const fullname = patient.fullname
  const createdAt = dayjs(registration.createdAt).format("DD/MM/YYYY hh:mm A")
  const passport = registration.passport || "-"
  const result = registration?.result ? capitalize(registration.result) : "-"
  const base64URL = await QRCode.toDataURL(
    `MR #: ${mrNo}\nName: ${fullname}\nReg Date: ${createdAt}\nPassport #: ${passport}\nResult: ${result}`
  )

  const image = await pdfDoc.embedPng(base64URL)

  // Draw the JPG image in the center of the page
  page.drawImage(image, {
    x: 510,
    y: 665, //650
    width: 53,
    height: 53,
  })
}

const renderPassengerInformation = (data, config) => {
  const { page, helveticaFont } = config
  const { patient, registration } = data

  const yPosition = 630 //600

  // Render Passport
  page.drawText(registration?.passport || "-", {
    x: 40,
    y: yPosition,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render CNIC
  page.drawText(patient.cnic || "-", {
    x: 135,
    y: yPosition,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render Cell No
  page.drawText(patient.phone || "-", {
    x: 226,
    y: yPosition,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render Email
  page.drawText(patient.email || "-", {
    x: 313,
    y: yPosition,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render Gender
  page.drawText(capitalize(patient.gender) || "-", {
    x: 453,
    y: yPosition,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render DOB
  page.drawText(`${dayjs().diff(patient.dob, "years")}` || "-", {
    x: 507,
    y: yPosition,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })
}

const renderAirlineInformation = (data, config) => {
  const { page, helveticaFont } = config
  const { registration } = data

  const yPosition = 588 //544

  const airline = getAirline(registration?.airline)
  // Render Airline
  page.drawText(airline || "", {
    x: 40,
    y: yPosition,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  const flightDateAndTime = registration?.flightDate
    ? dayjs(`${registration.flightDate} ${registration.flightTime}`, "DD-MM-YYYY HH:mm").format("DD/MM/YYYY hh:mm A")
    : "-"

  // Render Flight Time
  page.drawText(flightDateAndTime, {
    x: 173,
    y: yPosition,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render Flight No.
  page.drawText(registration.flightNo || "-", {
    x: 305,
    y: yPosition,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render Ticket No.
  page.drawText(registration.pnr || "-", {
    x: 438,
    y: yPosition,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render Departure Airport
  page.drawText(registration.departureAirport || "-", {
    x: 40,
    y: yPosition - 35,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })

  // Render Destination Airport
  page.drawText(getAirport(registration.destinationAirport) || "-", {
    x: 305,
    y: yPosition - 35,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })
}

const renderReportDetails = (data, config, registrationType) => {
  const { page, helveticaFont, helveticaBoldFont } = config
  const { registration } = data
  const isRapidPcr = registration?.rapidPcrCharges
  const isPcr = registration?.charges
  const isAntibody = registration?.igmCharges && registration?.iggCharges
  const isIgm = registration?.igmCharges
  const isIgg = registration?.iggCharges
  const yPosition = isPcr || isRapidPcr ? 708 : 660 //708 : 660
  const yAdjust = isPcr && isAntibody ? 78 : isPcr && (isIgm || isIgg) ? 39 : 0 //78 : 36 : 0
  // const yAdjustForRapidPcr = isRapidPcr ? 68 : 0
  const specimen = isRapidPcr ? registration?.rapidPcrSpecimen : "Nasopharyngeal Swab"

  if (registrationType === "passenger") {
    // Render Specimen
    page.drawText(specimen || "-", {
      x: 40,
      y: yPosition - 230,
      size: 10,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    if (isRapidPcr) {
      // Render test
      page.drawText("Covid-19 Rapid RNA PCR", {
        x: 217,
        y: yPosition - 230,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      // Render test
      page.drawText("SARS-Co-V2 (nucleic acid)", {
        x: 217,
        y: yPosition - 243,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
    } else if (isPcr) {
      // Render test
      page.drawText("Real Time RT-PCR", {
        x: 217,
        y: yPosition - 232,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
    }

    // Render result
    page.drawText("COVID-19 RNA", {
      x: 394,
      y: yPosition - 230,
      size: 10,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    const { status, color } = getTestResultStatus(registration, isRapidPcr ? "rapidPcrResult" : "result")
    const { r, g, b } = normalizeRGB(color)

    page.drawText(status || "-", {
      x: 394,
      y: yPosition - 242,
      size: 10,
      font: helveticaFont,
      color: rgb(r, g, b),
    })

    if (isPcr) {
      // Render instrument, kits, target genes, Batch no and date
      page.drawText(
        `6- Instrument: ${registration?.instrument || "-"}, Amplification kit(s): ${
          getAmplificationKit(registration?.amplificationKit) || "-"
        }, Extraction kit(s): ${getExtractionKit(registration?.extractionKit) || "-"}, Batch: ${
          `${registration?.batchNo} (${registration?.batchDate})` || "-"
        }.`,
        {
          x: 42,
          y: yPosition - 408 - yAdjust,
          size: 7,
          font: helveticaFont,
          color: rgb(0.5, 0.6, 0.6),
        }
      )

      // Render gene values.
      const targetGenes = `${registration?.orf1AbGene ? `ORF-1ab=${registration?.orf1AbGene}` : ""}${" "}${
        registration?.rdRpGene ? `RdRp-Gene=${registration?.rdRpGene}` : ""
      }${" "}${registration?.nGene ? `N-Gene=${registration?.nGene}` : ""}${" "}${
        registration?.eGene ? `E-Gene=${registration?.eGene}` : ""
      }`

      const lineNo =
        isRapidPcr &&
        !registration?.orf1AbGene &&
        !registration?.rdRpGene &&
        !registration?.nGene &&
        !registration?.eGene
          ? ""
          : isRapidPcr &&
            (registration?.orf1AbGene || registration?.rdRpGene || registration?.nGene || registration?.eGene)
          ? "6-"
          : "7-"
      page.drawText(`${lineNo} ${targetGenes}`, {
        x: 42,
        y: yPosition - 420 - yAdjust, //408
        size: 7,
        font: helveticaBoldFont,
        color: rgb(0.5, 0.6, 0.6),
      })
    }
  } else {
    // Render Specimen
    page.drawText(specimen || "-", {
      x: 40,
      y: yPosition - 155,
      size: 10,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    if (isRapidPcr) {
      // Render test
      page.drawText("Covid-19 Rapid RNA PCR", {
        x: 217,
        y: yPosition - 155,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      // Render test
      page.drawText("SARS-Co-V2 (nucleic acid)", {
        x: 217,
        y: yPosition - 168,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
    }

    // Render result
    page.drawText("COVID-19 RNA", {
      x: 394,
      y: yPosition - 154, // 138
      size: 10,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })

    const { status, color } = getTestResultStatus(registration, isRapidPcr ? "rapidPcrResult" : "result")
    const { r, g, b } = normalizeRGB(color)

    page.drawText(status || "-", {
      x: 394,
      y: yPosition - 166, //150
      size: 10,
      font: helveticaFont,
      color: rgb(r, g, b),
    })

    if (isPcr) {
      // Render test
      page.drawText("Real Time RT-PCR", {
        x: 217,
        y: yPosition - 157,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      // Render instrument, kits and Batch no.
      page.drawText(
        `6- Instrument: ${registration?.instrument || "-"}, Amplification kit(s): ${
          getAmplificationKit(registration?.amplificationKit) || "-"
        }, Extraction kit(s): ${getExtractionKit(registration?.extractionKit) || "-"}, Batch: ${
          `${registration?.batchNo} (${registration?.batchDate})` || "-"
        }.`,
        {
          x: 42,
          y: yPosition - 335 - yAdjust, //320
          size: 7,
          font: helveticaFont,
          color: rgb(0.5, 0.6, 0.6),
        }
      )

      // Render gene values.
      const targetGenes = `${registration?.orf1AbGene ? `ORF-1ab=${registration?.orf1AbGene}` : ""}${" "}${
        registration?.rdRpGene ? `RdRp-Gene=${registration?.rdRpGene}` : ""
      }${" "}${registration?.nGene ? `N-Gene=${registration?.nGene}` : ""}${" "}${
        registration?.eGene ? `E-Gene=${registration?.eGene}` : ""
      }`

      const lineNo =
        isRapidPcr &&
        !registration?.orf1AbGene &&
        !registration?.rdRpGene &&
        !registration?.nGene &&
        !registration?.eGene
          ? ""
          : isRapidPcr &&
            (registration?.orf1AbGene || registration?.rdRpGene || registration?.nGene || registration?.eGene)
          ? "6-"
          : "7-"
      page.drawText(`${lineNo} ${targetGenes}`, {
        x: 42,
        y: yPosition - 347 - yAdjust, //335
        size: 7,
        font: helveticaBoldFont,
        color: rgb(0.5, 0.6, 0.6),
      })
    }
  }
}

const renderAntibodyTestInformation = (data, config, registrationType) => {
  const { page, helveticaFont, courierFont, type, role } = config
  const { registration } = data
  const yPosition =
    type === "all" ||
    type === "pcrAndIgm" ||
    type === "pcrAndIgg" ||
    type === "anitbody" ||
    type === "igg" ||
    type === "igm"
      ? 665
      : 615

  if (registrationType === "passenger") {
    // For antibody and pcr only
    if (type === "all") {
      // Render Antibody Test Range
      page.drawText(getIgmResultRange(registration, role).status || "N/A", {
        x: 396,
        y: yPosition - 235, //185
        size: 10,
        font: helveticaFont,
        color: getIgmResultRange(registration, role).color,
      })

      page.drawText("Negative: <1.0", {
        x: 450,
        y: yPosition - 235, //185
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("Positive: >1.0", {
        x: 450,
        y: yPosition - 219, //169
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      // Render Igg Test Range
      page.drawText(getIggResultRange(registration, role).status || "N/A", {
        x: 396,
        y: yPosition - 270, //219
        size: 10,
        font: helveticaFont,
        color: getIggResultRange(registration, role).color,
      })

      page.drawText("<1.4: Non Reactive", {
        x: 450,
        y: yPosition - 270, //219
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("=/>1.4: Reactive", {
        x: 450,
        y: yPosition - 255, //204
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })
    }

    // For pcr and igm only
    if (type === "pcrAndIgm") {
      // Render Antibody Test Range
      page.drawText(getIgmResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 235, //185
        size: 10,
        font: helveticaFont,
        color: getIgmResultRange(registration).color,
      })

      page.drawText("Negative: <1.0", {
        x: 450,
        y: yPosition - 235, //185
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("Positive: >1.0", {
        x: 450,
        y: yPosition - 219, //169
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })
    }

    // For pcr and igg only
    if (type === "pcrAndIgg") {
      // Render Igg Test Range
      page.drawText(getIggResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 235, //185
        size: 10,
        font: helveticaFont,
        color: getIggResultRange(registration).color,
      })

      page.drawText("<1.4: Non Reactive", {
        x: 450,
        y: yPosition - 235, //185
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("=/>1.4: Reactive", {
        x: 450,
        y: yPosition - 219, //169
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })
    }

    // For antibody only
    if (type === "antibody") {
      // Render Antibody Test Range
      page.drawText(getIgmResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 135, //185
        size: 10,
        font: helveticaFont,
        color: getIgmResultRange(registration).color,
      })

      page.drawText("Negative: <1.0", {
        x: 450,
        y: yPosition - 135, //185
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("Positive: >1.0", {
        x: 450,
        y: yPosition - 119, //169
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      // Render Igg Test Range
      page.drawText(getIggResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 169, //219
        size: 10,
        font: helveticaFont,
        color: getIggResultRange(registration).color,
      })

      page.drawText("<1.4: Non Reactive", {
        x: 450,
        y: yPosition - 168, //218
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("=/>1.4: Reactive", {
        x: 450,
        y: yPosition - 154, //204
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })
    }

    // For igm only
    if (type === "igm") {
      // Render Antibody Test Range
      page.drawText(getIgmResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 185,
        size: 10,
        font: helveticaFont,
        color: getIgmResultRange(registration).color,
      })

      page.drawText("Negative: <1.0", {
        x: 450,
        y: yPosition - 185,
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("Positive: >1.0", {
        x: 450,
        y: yPosition - 169,
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })
    }

    // For igg only
    if (type === "igg") {
      page.drawText(getIggResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 185,
        size: 10,
        font: helveticaFont,
        color: getIggResultRange(registration).color,
      })

      page.drawText("<1.4: Non Reactive", {
        x: 450,
        y: yPosition - 184,
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("=/>1.4: Reactive", {
        x: 450,
        y: yPosition - 169,
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })
    }
  } else {
    // For all
    if (type === "all") {
      // Render Antibody Test Range
      page.drawText(getIgmResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 163, //185
        size: 10,
        font: helveticaFont,
        color: getIgmResultRange(registration).color,
      })

      page.drawText("Negative: <1.0", {
        x: 450,
        y: yPosition - 163, //185
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("Positive: >1.0", {
        x: 450,
        y: yPosition - 146, //169
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      // Render Igg Test Range
      page.drawText(getIggResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 197, //219
        size: 10,
        font: helveticaFont,
        color: getIggResultRange(registration).color,
      })

      page.drawText("<1.4: Non Reactive", {
        x: 450,
        y: yPosition - 197, //218
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("=/>1.4: Reactive", {
        x: 450,
        y: yPosition - 182, //204
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })
    }

    // For pcr and igm only
    if (type === "pcrAndIgm") {
      // Render Antibody Test Range
      page.drawText(getIgmResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 162, //235
        size: 10,
        font: helveticaFont,
        color: getIgmResultRange(registration).color,
      })

      page.drawText("Negative: <1.0", {
        x: 450,
        y: yPosition - 162, //235
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("Positive: >1.0", {
        x: 450,
        y: yPosition - 146, //219
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })
    }

    // For pcr and igg only
    if (type === "pcrAndIgg") {
      // Render Igg Test Range
      page.drawText(getIggResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 162, //235
        size: 10,
        font: helveticaFont,
        color: getIggResultRange(registration).color,
      })

      page.drawText("<1.4: Non Reactive", {
        x: 450,
        y: yPosition - 162, //235
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("=/>1.4: Reactive", {
        x: 450,
        y: yPosition - 146, //219
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })
    }

    // For antibody only
    if (type === "antibody") {
      // Render Antibody Test Range
      page.drawText(getIgmResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 62, //185
        size: 10,
        font: helveticaFont,
        color: getIgmResultRange(registration).color,
      })

      page.drawText("Negative: <1.0", {
        x: 450,
        y: yPosition - 62, //185
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("Positive: >1.0", {
        x: 450,
        y: yPosition - 46, //169
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      // Render Igg Test Range
      page.drawText(getIggResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 97, //219
        size: 10,
        font: helveticaFont,
        color: getIggResultRange(registration).color,
      })

      page.drawText("<1.4: Non Reactive", {
        x: 450,
        y: yPosition - 97, //218
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("=/>1.4: Reactive", {
        x: 450,
        y: yPosition - 82, //204
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })
    }

    if (type === "igm") {
      // Render Antibody Test Range
      page.drawText(getIgmResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 112, //97
        size: 10,
        font: helveticaFont,
        color: getIgmResultRange(registration).color,
      })

      page.drawText("Negative: <1.0", {
        x: 450,
        y: yPosition - 112, //97
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("Positive: >1.0", {
        x: 450,
        y: yPosition - 96, //81
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })
    }

    if (type === "igg") {
      page.drawText(getIggResultRange(registration).status || "N/A", {
        x: 396,
        y: yPosition - 112, //97
        size: 10,
        font: helveticaFont,
        color: getIggResultRange(registration).color,
      })

      page.drawText("<1.4: Non Reactive", {
        x: 450,
        y: yPosition - 112, //97
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })

      page.drawText("=/>1.4: Reactive", {
        x: 450,
        y: yPosition - 96, //81
        size: 10,
        font: courierFont,
        color: rgb(0, 0, 0),
      })
    }
  }
}
