import React, { useEffect } from "react"

import * as yup from "yup"
import { Fragment } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"

import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import Footer from "../components/layouts/footer/Footer"
import RequiredFieldMark from "../components/common/RequiredFieldMark"
import { useState as useOvermindState, useOvermind } from "../overmind"
import { useHistory } from "react-router-dom"
import { useToast } from "@chakra-ui/core"

const schema = yup.object().shape({
  email: yup.string().required("Email is a required field."),
  password: yup.string().required("Password is a required field."),
})

export default function Login() {
  const history = useHistory()
  const { actions } = useOvermind()
  const error = useOvermindState().auth.error
  const toast = useToast()

  useEffect(() => {
    if (error) {
      toast({
        title: "Error Occurred",
        description: error?.message || "Unexpected error occurred while signing in.",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async ({ email, password }) => {
    actions.auth.login({ email, password, history })
  }

  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="text-white mb-0">
                    <i className="fas fa-user-circle mr-4"></i> Login Portal
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5">
            <div className="row">
              <div className="col-12 col-lg-8">
                <h4>Login</h4>
                <p className="lead">Please enter your login credentials below to access staff portal.</p>

                <form className="cms-contact-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-bold">
                          Email <RequiredFieldMark />
                        </label>

                        <input type="text" placeholder="Enter your email" name="email" ref={register} />
                        <span className="text-danger">{errors.email?.message}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Password <RequiredFieldMark />
                        </label>
                        <input type="password" placeholder="Enter your password" name="password" ref={register} />
                        <span className="text-danger">{errors.password?.message}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                      <button type="submit" className="btn btn-secondary">
                        <i className="fas fa-arrow-circle-right space-right"></i>
                        Login To Portal
                      </button>
                    </div>
                  </div>

                  {/* To push footer a bit low */}
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </form>
              </div>
            </div>
          </div>
        </section>
      </Main>
      <Footer />
    </Fragment>
  )
}
