import React, { useState, useEffect } from "react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useToast } from "@chakra-ui/core"
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"
import { verifyReport } from "../../services/api"
import { amplificationKits, extractionKits } from "../../utils/common"

const schema = yup.object().shape({
  rapidPcrResult: yup.string(),
  rapidPcrSpecimen: yup.string(),
  result: yup.string(),
  antibodyResult: yup.string(),
  antibodyResultRange: yup.string(),
  iggResult: yup.string(),
  iggResultRange: yup.string(),
})

export default function ReportVerificationModal(props) {
  const { openModal, toggle, registration, updateUser } = props
  const [loading, setLoading] = useState(false)
  const [isChangedResult, setIsChangedResult] = React.useState(false)
  const rapidPcrResult = registration?.rapidPcrResult?.trim() || ""
  const rapidPcrSpecimen = registration?.rapidPcrSpecimen?.trim() || ""
  const result = registration?.result?.trim() || ""
  const antibodyResult = registration?.antibodyResult?.trim() || ""
  const antibodyResultRange = registration?.antibodyResultRange?.trim()
  const iggResult = registration?.iggResult?.trim() || ""
  const iggResultRange = registration?.iggResultRange?.trim()
  const [amplificationKit, setAmplificationKit] = useState(registration?.amplificationKit || "")
  //const amplificationKit = registration?.amplificationKit || ""
  const extractionKit = registration?.extractionKit || ""
  const [targetGenes, setTargetGenes] = useState(registration?.targetGenes || [""])
  const orf1AbGene = registration?.orf1AbGene || ""
  const rdRpGene = registration?.rdRpGene || ""
  const nGene = registration?.nGene || ""
  const eGene = registration?.eGene || ""
  const batchNo = registration?.batchNo || ""
  const batchDate = registration?.batchDate || ""
  const instrument = registration?.instrument || ""
  const toast = useToast()

  const { register, handleSubmit, errors, reset, watch } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      rapidPcrResult,
      rapidPcrSpecimen,
      result,
      antibodyResult,
      antibodyResultRange,
      iggResult,
      iggResultRange,
      extractionKit,
      targetGenes,
      orf1AbGene: orf1AbGene || "",
      rdRpGene: rdRpGene || "",
      eGene: eGene || "",
      nGene: nGene || "",
      batchNo,
      batchDate,
      instrument,
    },
  })

  const watcher = watch([
    "rapidPcrResult",
    "rapidPcrSpecimen",
    "result",
    "antibodyResult",
    "antibodyResultRange",
    "iggResult",
    "iggResultRange",
    "extractionKit",
    "orf1AbGene",
    "rdRpGene",
    "eGene",
    "nGene",
    "batchNo",
    "batchDate",
    "instrument",
  ])

  useEffect(() => {
    if (openModal) {
      setLoading(false)
      setIsChangedResult(false)
      resetModalState()
      reset({
        rapidPcrResult,
        rapidPcrSpecimen,
        result,
        antibodyResult,
        antibodyResultRange,
        iggResult,
        iggResultRange,
        extractionKit,
        targetGenes,
        orf1AbGene,
        rdRpGene,
        eGene,
        nGene,
        batchNo,
        batchDate,
        instrument,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  const resetModalState = () => {
    setAmplificationKit(registration?.amplificationKit)
  }

  useEffect(() => {
    if (
      watcher.rapidPcrResult !== rapidPcrResult ||
      watcher.rapidPcrSpecimen !== rapidPcrSpecimen ||
      watcher.result !== result ||
      watcher.antibodyResult !== antibodyResult ||
      watcher.antibodyResultRange !== antibodyResultRange ||
      watcher.iggResult !== iggResult ||
      watcher.iggResultRange !== iggResultRange ||
      watcher.extractionKit !== extractionKit ||
      watcher.orf1AbGene !== orf1AbGene ||
      watcher.rdRpGene !== rdRpGene ||
      watcher.nGene !== nGene ||
      watcher.eGene !== eGene ||
      watcher.batchNo !== batchNo ||
      watcher.batchDate !== batchDate ||
      watcher.instrument !== instrument
    ) {
      setIsChangedResult(true)
    } else {
      setIsChangedResult(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watcher.rapidPcrResult,
    watcher.rapidPcrSpecimen,
    watcher.result,
    watcher.antibodyResult,
    watcher.antibodyResultRange,
    watcher.iggResult,
    watcher.iggResultRange,
    watcher.extractionKit,
    watcher.orf1AbGene,
    watcher.rdRpGene,
    watcher.nGene,
    watcher.eGene,
    watcher.batchNo,
    watcher.batchDate,
    watcher.instrument,
  ])

  const onSubmit = async formData => {
    try {
      setLoading(true)
      let data = {
        rapidPcrResult: formData.rapidPcrResult || undefined,
        rapidPcrSpecimen: formData.rapidPcrSpecimen || undefined,
        result: formData.result || undefined,
        antibodyResult: formData.antibodyResult || undefined,
        antibodyResultRange: formData.antibodyResultRange?.trim() || undefined,
        iggResult: formData.iggResult || undefined,
        iggResultRange: formData.iggResultRange?.trim() || undefined,
        amplificationKit: amplificationKit || undefined,
        extractionKit: formData?.extractionKit || undefined,
        targetGenes: targetGenes || undefined,
        orf1AbGene: formData?.orf1AbGene || undefined,
        rdRpGene: formData?.rdRpGene || undefined,
        nGene: formData?.nGene || undefined,
        eGene: formData?.eGene || undefined,
        batchNo: formData?.batchNo || undefined,
        batchDate: formData?.batchDate || undefined,
        instrument: formData?.instrument || undefined,
      }
      const res = await verifyReport(registration?.registrationId, data)
      const updatedRegistration = res.data

      updateUser(updatedRegistration)
      toggle()

      toast({
        title: isChangedResult ? "Report Changed" : "Report Verified",
        description: "Request executed succesfully.",
        status: "success",
        duration: 3000,
      })
    } catch (error) {
      toast({
        title: "There was an error uploading report.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true} size="xl">
      <ModalHeader toggle={toggle}>{isChangedResult ? "Change Report" : "Verify Report"}</ModalHeader>
      <ModalBody>
        <form className="cms-contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12">
              <div className="form-group mb-0">
                <label className="text-bold">MR #: </label>
                <span className="text-dark font-15">{" " + registration?.registrationId}</span>
              </div>
              {registration?.patient?.fullname && (
                <div className="form-group">
                  <label className="text-bold">Patient Name: </label>
                  <span className="text-dark font-15">{" " + registration.patient.fullname}</span>
                </div>
              )}

              <div className="d-flex flex-column mb-3 mt-2">
                {registration?.rapidPcrCharges && (
                  <>
                    <div className="h-card mb-3">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Test</label>
                            <div>
                              <span className="text-dark font-15">Rapid PCR</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Result</label>
                            <select name="rapidPcrSpecimen" ref={register}>
                              <option value="" selected>
                                Select Option
                              </option>
                              <option value="Nasopharyngeal Swab">Nasopharyngeal Swab</option>
                              <option value="Oropharyngeal Swab">Oropharyngeal Swab</option>
                            </select>
                            <span className="text-danger">{errors.rapidPcrSpecimen?.message}</span>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Result</label>
                            <select name="rapidPcrResult" ref={register}>
                              <option value="" selected>
                                Select Option
                              </option>
                              <option value="detected">Covid 19 RNA Detected</option>
                              <option value="not-detected">Covid 19 RNA Not Detected</option>
                            </select>
                            <span className="text-danger">{errors.rapidPcrResult?.message}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {registration?.charges && (
                  <>
                    <div className="h-card mb-3">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Test</label>
                            <div>
                              <span className="text-dark font-15">Real Time RT-PCR</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Specimen</label>
                            <div>
                              <span className="text-dark font-15">Nasopharyngeal Swab</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Result</label>
                            <select name="result" ref={register}>
                              <option value="">Select Option</option>
                              <option value="detected">Covid 19 RNA Detected</option>
                              <option value="not-detected">Covid 19 RNA Not Detected</option>
                            </select>
                            <span className="text-danger">{errors.result?.message}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="h-card mb-3">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>Kit Category</label>
                            <div>
                              <span className="text-dark font-15">Amplification Kit</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>Testing Kit</label>
                            <select
                              name="amplificationKit"
                              value={amplificationKit}
                              // ref={register}
                              onChange={e => {
                                setAmplificationKit(e.target.value)
                                if (e.target.value === "seegene") {
                                  setTargetGenes(["RdRP-Gene", "N-Gene", "E-Gene"])
                                } else if (e.target.value === "bioPerfectus") {
                                  setTargetGenes(["ORF-1ab", "N-Gene"])
                                } else {
                                  setTargetGenes([""])
                                }
                              }}
                            >
                              <option value="">Select Option</option>
                              {amplificationKits.map((kit, idx) => {
                                return (
                                  <option value={kit.value} key={`Amplification-Kit-${kit.label}-${idx}`}>
                                    {kit.label}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>

                        {amplificationKit === "seegene" ? (
                          <>
                            <div className="col-lg-2">
                              <div className="form-group">
                                <label>RdRP-Gene</label>
                                <input type="text" ref={register} name="rdRpGene" placeholder="Enter value here" />
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <div className="form-group">
                                <label>N-Gene</label>
                                <input type="text" ref={register} name="nGene" placeholder="Enter value here" />
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <div className="form-group">
                                <label>E-Gene</label>
                                <input type="text" ref={register} name="eGene" placeholder="Enter value here" />
                              </div>
                            </div>
                          </>
                        ) : amplificationKit === "daAnGene" ? (
                          <>
                            <div className="col-lg-2">
                              <div className="form-group">
                                <label>ORF-1ab</label>
                                <input type="text" ref={register} name="orf1AbGene" placeholder="Enter value here" />
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <div className="form-group">
                                <label>N-Gene</label>
                                <input type="text" ref={register} name="nGene" placeholder="Enter value here" />
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <div className="form-group">
                                <label>E-Gene</label>
                                <input type="text" ref={register} name="eGene" placeholder="Enter value here" />
                              </div>
                            </div>
                          </>
                        ) : amplificationKit === "bioPerfectus" ? (
                          <>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>ORF-1ab</label>
                                <input type="text" ref={register} name="orf1AbGene" placeholder="Enter value here" />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>N-Gene</label>
                                <input type="text" ref={register} name="nGene" placeholder="Enter value here" />
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>

                      <div className="row">
                        <div className="col-lg-3">
                          <div className="form-group">
                            <span className="text-dark font-15">Extraction Kit</span>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <select name="extractionKit" ref={register}>
                              <option value="">Select Option</option>
                              {extractionKits.map((kit, idx) => {
                                return (
                                  <option value={kit.value} key={`Extraction-Kit-${kit.label}-${idx}`}>
                                    {kit.label}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group"></div>
                        </div>
                      </div>
                    </div>

                    <div className="h-card mb-3">
                      <div className="row">
                        <div className="col-lg-2">
                          <div className="form-group">
                            <label>Batch Number</label>
                            <input type="text" name="batchNo" ref={register} placeholder="Batch Number" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Batch Date</label>
                            <input type="text" name="batchDate" ref={register} placeholder="Batch Date" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Instrument</label>
                            <select name="instrument" ref={register}>
                              <option value="">Select Option</option>
                              <option value="CFX96 Touch Real-Time PCR Detection System - Bio-Rad, USA">
                                CFX96 Touch Real-Time PCR Detection System - Bio-Rad, USA
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {registration?.igmCharges && (
                  <>
                    <div className="h-card mb-3">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Test</label>
                            <div>
                              <span className="text-dark font-15">COVID-19 IgM Antibodies</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Result</label>
                            <select name="antibodyResult" ref={register}>
                              <option value="">Select</option>
                              <option value="positive">Positive</option>
                              <option value="negative">Negative</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Range</label>
                            <input name="antibodyResultRange" ref={register} type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {registration?.iggCharges && (
                  <>
                    <div className="h-card mb-3">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Test</label>
                            <div>
                              <span className="text-dark font-15">COVID-19 IgG Antibodies</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Result</label>
                            <select name="iggResult" ref={register}>
                              <option value="">Select</option>
                              <option value="positive">Positive</option>
                              <option value="negative">Negative</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Range</label>
                            <input name="iggResultRange" ref={register} type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <ModalFooter className="pb-0 px-0">
            <button type="submit" className="btn btn-secondary" disabled={loading}>
              <i className="fas fa-arrow-circle-right space-right"></i>
              {isChangedResult ? "Change Report" : "Verify Report"}
              {loading && <Spinner color="default" className="ml-2" size="sm" />}
            </button>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  )
}
