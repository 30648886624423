const dev = {
  API_URL: "http://localhost:5000/api",
}
/*
 ACTUAL SERVER
 API_URL: "https://gcc-core.herokuapp.com/api",
 APP RUNNING BACKUP HEROKU SERVER (MINE)
 API_URL: "https://gcc-core-bk.herokuapp.com/api",
*/
const prod = {
  API_URL: "https://gcc-core-backup.herokuapp.com/api",
}

const config = process.env.NODE_ENV === "production" ? prod : dev

export default config
