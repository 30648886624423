import React from "react"
import { Link } from "react-router-dom"
import { AllRoutesMap } from "../../../routes/RoutesConfig"
import { landingPageAirlines } from "../../../utils/airlines"

export default function Hero() {
  return (
    <section className="hero-wrapper container-fluid">
      <div className="row">
        <div className="col-lg-6 col-sm-12 content-left" />
        <div className="col-lg-6 col-sm-12 content-right d-flex flex-column justify-content-center align-items-center text-white">
          <div className="d-flex flex-column">
            <h1 className="page-title text-center">COVID-19 TESTING</h1>
            <p className="text-light text-center">(Approved by NIH, National Institute of Health)</p>
            <div className="divider"></div>
            <div className="d-flex flex-lg-row flex-md-column justify-content-center mt-3 mb-4">
              <Link to={AllRoutesMap.bookingInformation} className="btn btn-white-secondary mb-lg-0 mb-md-4 mr-3">
                <i className="fa-phone fa-rotate-90 fas space-right"></i>
                Book an appointment
              </Link>
              <Link to={AllRoutesMap.patient} className="btn btn-default btn-teal-hover mr-3">
                <i className="fas fa-arrow-circle-right space-right"></i>
                Check Online Reports
              </Link>
            </div>
            <h6 className="text-white text-center mt-4">Now open for general purpose COVID-19 testing!</h6>
            <p className="text-light text-center mt-4">Approved for the following Airlines</p>
            <div className="d-flex flex-wrap justify-content-center">
              {landingPageAirlines.map(({ src, label, value }) => (
                <div className="airline-container" key={value}>
                  <img className="rounded-circle img-airline" alt={label} src={src} title={label} />
                  <p className="caption-airline text-light text-center small">{label}</p>
                </div>
              ))}
            </div>
            <div className="corona-virus-bg">
              <img src="/assets/images/virus.png" alt="Corona Virus" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
