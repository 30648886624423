import React, { useState } from "react"
import { Spinner } from "reactstrap"
import PhoneInput from "react-phone-input-2"
import { phoneNoCountries } from "../../utils/common"

const radios = [
  {
    value: "passport",
    label: "Passport",
  },
  {
    value: "cnic",
    label: "CNIC",
  },
  {
    value: "phone",
    label: "Contact#",
  },
  {
    value: "fullname",
    label: "Name",
  },
  {
    value: "flightNo",
    label: "Flight#",
  },
]

export default function PatientsFiltersBar(props) {
  const { loading, onFilterApply } = props
  const [query, setQuery] = useState("")
  const [filter, setFilter] = useState("passport")

  const onKeyUp = event => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  const handleSearch = () => {
    const updatedQuery = query && query.trim()
    onFilterApply(filter, updatedQuery)
  }

  const clearSearch = (newFilter = "passport") => {
    setFilter(newFilter)
    setQuery("")
    const updatedQuery = ""
    onFilterApply(filter, updatedQuery)
  }

  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <div className="col-12">
          <h4>COVID-19 Reports</h4>
          <p className="lead mb-2">
            <b>Search by:</b>
          </p>

          <form className="cms-contact-form" onSubmit={e => e.preventDefault()}>
            <div className="row">
              <div className="col-12 mb-2">
                <div className="d-flex">
                  {radios.map(radio => {
                    return (
                      <div key={radio.value}>
                        <input
                          type="radio"
                          value={radio.value}
                          checked={radio.value === filter}
                          onChange={e => {
                            clearSearch(e.target.value)
                          }}
                        />
                        <span className="ml-1 mr-4 text-dark">{radio.label}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-7 mb-2">
                {filter === "phone" ? (
                  <PhoneInput
                    onlyCountries={phoneNoCountries}
                    country={"pk"}
                    value={query}
                    onChange={e => setQuery(e)}
                    onKeyDown={onKeyUp}
                    placeholder="92 123-4567890"
                  />
                ) : (
                  <input
                    placeholder="Search reports..."
                    type="text"
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    onKeyUp={onKeyUp}
                  />
                )}
              </div>
              <div className="col-12 col-md-3 mb-2">
                <button
                  type="button"
                  className="btn btn-primary h-100 w-100"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={handleSearch}
                >
                  <i className="fas fa-search mr-2"></i>
                  Search
                  {loading && <Spinner color="default" className="ml-2" size="sm" />}
                </button>
              </div>
              <div className="col-12 col-md-2 mb-2">
                <button
                  type="button"
                  className="btn btn-secondary h-100 w-100"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={() => {
                    clearSearch()
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
