const airports = [
  {
    value: "Dubai",
    label: "Dubai Airport",
  },
  {
    value: "London Heathrow",
    label: "London Heathrow Airport",
  },
  {
    value: "Wuhan",
    label: "Wuhan Tianhe International Airpot",
  },
  {
    value: "Istanbul Airport",
    label: "Istanbul Airport",
  },
  {
    value: "Beijing",
    label: "Beijing Airport",
  },
  {
    value: "Islamabad",
    label: "Islamabad Airport",
  },
  {
    value: "Abu Dhabi International Airport",
    label: "Abu Dhabi International Airport",
  },
  {
    value: "Amsterdam",
    label: "Amsterdam Airport",
  },
  {
    value: "Kabul",
    label: "Kabul Airport",
  },
  {
    value: "Xianyang",
    label: "Xianyang International Airport",
  },
  {
    value: "Cape Town",
    label: "Cape Town Airport",
  },
]

export const getAirport = airportValue => {
  if (!airportValue) {
    return undefined
  }
  return airports.find(airport => airport.value === airportValue)?.label || airportValue
}

export const newAirports = [
  {
    value: "Abidjan",
    label: "Abidjan Airport",
  },

  {
    value: "Abuja",
    label: "Abuja Airport",
  },
  {
    value: "Accra",
    label: "Accra Airport",
  },
  {
    value: "Adana Sakirpasa Airpot",
    label: "Adana Sakirpasa Airpot",
  },
  {
    value: "Addis Ababa",
    label: "Addis Ababa Airport",
  },
  {
    value: "Adelaide",
    label: "Adelaide Airport",
  },
  {
    value: "Adiyaman Airpot",
    label: "Adiyaman Airpot",
  },
  {
    value: "Agri Airpot",
    label: "Agri Airpot",
  },
  {
    value: "AhmedAbad",
    label: "AhmedAbad Airport",
  },
  {
    value: "Al Najaf International Airport",
    label: "Al Najaf International Airport",
  },
  {
    value: "Algiers",
    label: "Algiers Airport",
  },
  {
    value: "Allama Iqbal International Airport",
    label: "Allama Iqbal International Airport",
  },
  {
    value: "Amasya Merzifon Airpot",
    label: "Amasya Merzifon Airpot",
  },
  {
    value: "Amman",
    label: "Amman Airport",
  },

  {
    value: "Ankara Esenboga Airpot",
    label: "Ankara Esenboga Airpot",
  },
  {
    value: "Antalya Airpot",
    label: "Antalya Airpot",
  },
  {
    value: "Ar Rayyan",
    label: "Ar Rayyan",
  },
  {
    value: "Athens",
    label: "Athens Airport",
  },
  {
    value: "Auckland",
    label: "Auckland Airport",
  },
  {
    value: "Bacha Khan International Airport",
    label: "Bacha Khan International Airport",
  },
  {
    value: "Baghdad",
    label: "Baghdad Airport",
  },
  {
    value: "Bahawalpur Airport",
    label: "Bahawalpur Airport",
  },
  {
    value: "Bahrain",
    label: "Bahrain Airport",
  },
  {
    value: "Balikesir Airpot",
    label: "Balikesir Airpot",
  },
  {
    value: "Balikesir Koca Seyit Airpot",
    label: "Balikesir Koca Seyit Airpot",
  },
  {
    value: "Bangalore",
    label: "Bangalore Airport",
  },
  {
    value: "Bangkok",
    label: "Bangkok Airport",
  },
  {
    value: "Barcelona Airport",
    label: "Barcelona Airport",
  },
  {
    value: "Barcelona",
    label: "Barcelona Airport",
  },
  {
    value: "Basra",
    label: "Basra Airport",
  },
  {
    value: "Batman Airpot",
    label: "Batman Airpot",
  },

  {
    value: "Beirut",
    label: "Beirut Airport",
  },
  {
    value: "Birmingham Airport",
    label: "Birmingham Airport",
  },
  {
    value: "Birmingham",
    label: "Birmingham Airport",
  },
  {
    value: "Boston",
    label: "Boston Airport",
  },
  {
    value: "Brisbane",
    label: "Brisbane Airport",
  },
  {
    value: "Brussels",
    label: "Brussels Airport",
  },
  {
    value: "Buenos Aires",
    label: "Buenos Aires Airport",
  },
  {
    value: "Cairo",
    label: "Cairo Airport",
  },
  {
    value: "Canakkale Airpot",
    label: "Canakkale Airpot",
  },

  {
    value: "Capital International Airport",
    label: "Capital International Airport",
  },
  {
    value: "Casablanca",
    label: "Casablanca Airport",
  },
  {
    value: "Cebu",
    label: "Cebu Airport",
  },
  {
    value: "Cengiz Topel Naval Air Station",
    label: "Cengiz Topel Naval Air Station",
  },
  {
    value: "Chennai",
    label: "Chennai Airport",
  },
  {
    value: "Chhatrapati Shivaji International Airport",
    label: "Chhatrapati Shivaji International Airport",
  },
  {
    value: "Chicago",
    label: "Chicago Airport",
  },
  {
    value: "Chitral Airport",
    label: "Chitral Airport",
  },
  {
    value: "Christchurch",
    label: "Christchurch Airport",
  },
  {
    value: "Clark",
    label: "Clark Airport",
  },
  {
    value: "Colombo",
    label: "Colombo Airport",
  },
  {
    value: "Conakry",
    label: "Conakry Airport",
  },
  {
    value: "Copenhagen Airport",
    label: "Copenhagen Airport",
  },
  {
    value: "Dadu Airport",
    label: "Dadu Airport",
  },
  {
    value: "Dakar Blaise Diagne",
    label: "Dakar Blaise Diagne Airport",
  },
  {
    value: "Dalaman Airpot",
    label: "Dalaman Airpot",
  },
  {
    value: "Dalbandin Airport",
    label: "Dalbandin Airport",
  },
  {
    value: "Dallas",
    label: "Dallas Airport",
  },
  {
    value: "Dammam",
    label: "Dammam Airport",
  },
  {
    value: "Dar Es Salaam",
    label: "Dar Es Salaam Airport",
  },
  {
    value: "Delhi",
    label: "Delhi Airport",
  },
  {
    value: "Denizli Cardak Airpot",
    label: "Denizli Cardak Airpot",
  },
  {
    value: "Denpasar",
    label: "Denpasar Airport",
  },
  {
    value: "Dera Ghazi Khan International Airport",
    label: "Dera Ghazi Khan International Airport",
  },
  {
    value: "Dhaka",
    label: "Dhaka Airport",
  },
  {
    value: "Diyarbakir Airpot",
    label: "Diyarbakir Airpot",
  },
  {
    value: "Doha International Airport",
    label: "Doha International Airport",
  },
  {
    value: "Dubai International Airport",
    label: "Dubai International Airport",
  },
  {
    value: "Dublin",
    label: "Dublin Airport",
  },
  {
    value: "Durban",
    label: "Durban Airport",
  },
  {
    value: "Düsseldorf",
    label: "Düsseldorf Airport",
  },
  {
    value: "Edinburgh",
    label: "Edinburgh Airport",
  },
  {
    value: "Elazi  Airpot",
    label: "Elazi  Airpot",
  },
  {
    value: "Elazig Airpot",
    label: "Elazig Airpot",
  },
  {
    value: "Entebbe",
    label: "Entebbe Airport",
  },
  {
    value: "Erkilet International Airpot",
    label: "Erkilet International Airpot",
  },
  {
    value: "Erzincan Airpot",
    label: "Erzincan Airpot",
  },
  {
    value: "Erzurum Airpot",
    label: "Erzurum Airpot",
  },
  {
    value: "Faisalabad International Airport",
    label: "Faisalabad International Airport",
  },
  {
    value: "Frankfurt",
    label: "Frankfurt Airport",
  },
  {
    value: "Fujairah International Airport",
    label: "Fujairah International Airport",
  },
  {
    value: "Gazipasa Airpot",
    label: "Gazipasa Airpot",
  },
  {
    value: "Geneva",
    label: "Geneva Airport",
  },
  {
    value: "Gilgit Airport",
    label: "Gilgit Airport",
  },
  {
    value: "Glasgow",
    label: "Glasgow Airport",
  },
  {
    value: "Guangzhou",
    label: "Guangzhou Airport",
  },
  {
    value: "Gwadar International Airport",
    label: "Gwadar International Airport",
  },
  {
    value: "Hamad International Airport",
    label: "Hamad International Airport",
  },
  {
    value: "Hamad International Airport",
    label: "Hamad International Airport",
  },
  {
    value: "Hamburg",
    label: "Hamburg Airport",
  },
  {
    value: "Hamid Karzai International Airport",
    label: "Hamid Karzai International Airport",
  },
  {
    value: "Hanoi",
    label: "Hanoi Airport",
  },
  {
    value: "Harare",
    label: "Harare Airport",
  },
  {
    value: "Hasan Polatkan Airpot",
    label: "Hasan Polatkan Airpot",
  },
  {
    value: "Hatay Airpot",
    label: "Hatay Airpot",
  },
  {
    value: "Ho Chi Minh City",
    label: "Ho Chi Minh City Airport",
  },
  {
    value: "Houston",
    label: "Houston Airport",
  },
  {
    value: "Hyderabad",
    label: "Hyderabad Airport",
  },
  {
    value: "Indira Gandhi International Airport",
    label: "Indira Gandhi International Airport",
  },

  {
    value: "Islamabad International Airport",
    label: "Islamabad International Airport",
  },
  {
    value: "Isparta Suleyman Airpot",
    label: "Isparta Suleyman Airpot",
  },

  {
    value: "Istanbul Ataturk Airport",
    label: "Istanbul Ataturk Airport",
  },
  {
    value: "Izmir Adnan Menderes Airpot",
    label: "Izmir Adnan Menderes Airpot",
  },
  {
    value: "Jakarta",
    label: "Jakarta Airport",
  },
  {
    value: "Jeddah",
    label: "Jeddah Airport",
  },
  {
    value: "Jinnah International Airport",
    label: "Jinnah International Airport",
  },
  {
    value: "Johannesburg",
    label: "Johannesburg Airport",
  },
  {
    value: "Kabul - Khwaja Rawash Airport",
    label: "Kabul - Khwaja Rawash Airport",
  },

  {
    value: "Kadanwari Airfield",
    label: "Kadanwari Airfield",
  },
  {
    value: "Kahramanmaras Airpot",
    label: "Kahramanmaras Airpot",
  },
  {
    value: "Karachi",
    label: "Karachi Airport",
  },
  {
    value: "Kars Airpot",
    label: "Kars Airpot",
  },
  {
    value: "Kastamonu Airpot",
    label: "Kastamonu Airpot",
  },
  {
    value: "Khartoum",
    label: "Khartoum Airport",
  },
  {
    value: "Khwaja Rawash Airport",
    label: "Khwaja Rawash Airport",
  },
  {
    value: "King Abdul Aziz International Airport",
    label: "King Abdul Aziz International Airport",
  },
  {
    value: "King Fahad International Airport",
    label: "King Fahad International Airport",
  },
  {
    value: "King Khalid International Airport",
    label: "King Khalid International Airport",
  },
  {
    value: "Kochi",
    label: "Kochi Airport",
  },
  {
    value: "Kolkatta",
    label: "Kolkatta Airport",
  },
  {
    value: "Konya Airpot",
    label: "Konya Airpot",
  },
  {
    value: "Kuala Lumpur",
    label: "Kuala Lumpur Airport",
  },
  {
    value: "Kuala Lumpur International Airport",
    label: "Kuala Lumpur International Airport",
  },
  {
    value: "Kuwait",
    label: "Kuwait Airport",
  },
  {
    value: "Lagos",
    label: "Lagos Airport",
  },
  {
    value: "Lahore",
    label: "Lahore Airport",
  },
  {
    value: "Larnaca",
    label: "Larnaca Airport",
  },
  {
    value: "Lauderdale",
    label: "Lauderdale Airport",
  },
  {
    value: "Lisbon",
    label: "Lisbon Airport",
  },
  {
    value: "London Gatwick",
    label: "London Gatwick Airport",
  },
  {
    value: "London Heathrow International Airport",
    label: "London Heathrow International Airport",
  },
  {
    value: "London Stansted",
    label: "London Stansted Airport",
  },
  {
    value: "Los Angeles",
    label: "Los Angeles Airport",
  },
  {
    value: "Luanda",
    label: "Luanda Airport",
  },
  {
    value: "Lusaka",
    label: "Lusaka Airport",
  },
  {
    value: "Lyon",
    label: "Lyon Airport",
  },
  {
    value: "Madina",
    label: "Madina Airport",
  },
  {
    value: "Madrid",
    label: "Madrid Airport",
  },
  {
    value: "Malta",
    label: "Malta Airport",
  },
  {
    value: "Malé",
    label: "Malé Airport",
  },
  {
    value: "Manchester Airport",
    label: "Manchester Airport",
  },
  {
    value: "Manchester",
    label: "Manchester Airport",
  },
  {
    value: "Manila",
    label: "Manila Airport",
  },
  {
    value: "Mardin Airpot",
    label: "Mardin Airpot",
  },
  {
    value: "Mauritius",
    label: "Mauritius Airport",
  },
  {
    value: "Melbourne",
    label: "Melbourne Airport",
  },
  {
    value: "Mexico City",
    label: "Mexico City Airport",
  },
  {
    value: "Milan",
    label: "Milan Airport",
  },
  {
    value: "Milan Malpensa Airport",
    label: "Milan Malpensa Airport",
  },
  {
    value: "Milas-Bodrum Airpot",
    label: "Milas-Bodrum Airpot",
  },
  {
    value: "Moenjodaro Airport",
    label: "Moenjodaro Airport",
  },
  {
    value: "Moscow",
    label: "Moscow Airport",
  },
  {
    value: "Multan International Airport",
    label: "Multan International Airport",
  },
  {
    value: "Mumbai",
    label: "Mumbai Airport",
  },
  {
    value: "Munich",
    label: "Munich Airport",
  },
  {
    value: "Mus Airpot",
    label: "Mus Airpot",
  },
  {
    value: "Muscat",
    label: "Muscat Airport",
  },
  {
    value: "Muscat International Airport",
    label: "Muscat International Airport",
  },
  {
    value: "Nairobi",
    label: "Nairobi Airport",
  },
  {
    value: "Narita International Airport",
    label: "Narita International Airport",
  },
  {
    value: "New York JFK",
    label: "New York JFK Airport",
  },
  {
    value: "Newark",
    label: "Newark Airport",
  },
  {
    value: "Newcastle",
    label: "Newcastle Airport",
  },
  {
    value: "Nice",
    label: "Nice Airport",
  },
  {
    value: "Oguzeli Airpot",
    label: "Oguzeli Airpot",
  },
  {
    value: "Ordu Airpot",
    label: "Ordu Airpot",
  },
  {
    value: "Orlando",
    label: "Orlando Airport",
  },
  {
    value: "Osaka",
    label: "Osaka Airport",
  },
  {
    value: "Oslo",
    label: "Oslo Airport",
  },
  {
    value: "Oslo Gardermoen Airport",
    label: "Oslo Gardermoen Airport",
  },
  {
    value: "Panjgur Airport",
    label: "Panjgur Airport",
  },
  {
    value: "Paris",
    label: "Paris Airport",
  },
  {
    value: "Paris-Charles de Gaulle International Airport",
    label: "Paris-Charles de Gaulle International Airport",
  },
  {
    value: "Pearson International Airport",
    label: "Pearson International Airport",
  },
  {
    value: "Perth",
    label: "Perth Airport",
  },
  {
    value: "Peshawar",
    label: "Peshawar Airport",
  },
  {
    value: "Phnom Penh",
    label: "Phnom Penh Airport",
  },
  {
    value: "Phuket",
    label: "Phuket Airport",
  },
  {
    value: "Porto",
    label: "Porto Airport",
  },
  {
    value: "Prague",
    label: "Prague Airport",
  },
  {
    value: "Prince Mohammad Bin Abdulaziz Airport",
    label: "Prince Mohammad Bin Abdulaziz Airport",
  },
  {
    value: "Quetta International Airport",
    label: "Quetta International Airport",
  },
  {
    value: "Ras Al Khaimah International Airport",
    label: "Ras Al Khaimah International Airport",
  },
  {
    value: "Rio de Janeiro",
    label: "Rio de Janeiro Airport",
  },
  {
    value: "Riyadh",
    label: "Riyadh Airport",
  },
  {
    value: "Rome",
    label: "Rome Airport",
  },
  {
    value: "Sabiha Gokcen Airport",
    label: "Sabiha Gokcen Airport",
  },
  {
    value: "Samsun-Carsamba Airpot",
    label: "Samsun-Carsamba Airpot",
  },
  {
    value: "San Francisco",
    label: "San Francisco Airport",
  },
  {
    value: "Sanliurfa GAP Airpot",
    label: "Sanliurfa GAP Airpot",
  },
  {
    value: "Santiago De Chile",
    label: "Santiago De Chile Airport",
  },
  {
    value: "Sawan Airfield",
    label: "Sawan Airfield",
  },
  {
    value: "Seattle",
    label: "Seattle Airport",
  },
  {
    value: "Seoul",
    label: "Seoul Airport",
  },
  {
    value: "Seychelles",
    label: "Seychelles Airport",
  },
  {
    value: "Shahjalal International Airport",
    label: "Shahjalal International Airport",
  },
  {
    value: "Shaikh Zayed International Airport",
    label: "Shaikh Zayed International Airport",
  },
  {
    value: "Shangai",
    label: "Shangai Airport",
  },
  {
    value: "Sharjah International Airport",
    label: "Sharjah International Airport",
  },
  {
    value: "Sharjah International Airport",
    label: "Sharjah International Airport",
  },
  {
    value: "Sialkot",
    label: "Sialkot Airport",
  },
  {
    value: "Sialkot International Airport",
    label: "Sialkot International Airport",
  },
  {
    value: "Siirt Airpot",
    label: "Siirt Airpot",
  },
  {
    value: "Singapore",
    label: "Singapore Airport",
  },
  {
    value: "Sinop Airpot",
    label: "Sinop Airpot",
  },
  {
    value: "Sirnak Airpot",
    label: "Sirnak Airpot",
  },
  {
    value: "Sivas Airpot",
    label: "Sivas Airpot",
  },
  {
    value: "Skardu Airport",
    label: "Skardu Airport",
  },
  {
    value: "St. Petersburg",
    label: "St. Petersburg Airport",
  },
  {
    value: "Stockholm",
    label: "Stockholm Airport",
  },
  {
    value: "Sukkur Airport",
    label: "Sukkur Airport",
  },
  {
    value: "Suvarnabhumi Airport",
    label: "Suvarnabhumi Airport",
  },
  {
    value: "Sydney",
    label: "Sydney Airport",
  },
  {
    value: "São Paulo",
    label: "São Paulo Airport",
  },
  {
    value: "Taipei",
    label: "Taipei Airport",
  },
  {
    value: "Tehran",
    label: "Tehran Airport",
  },
  {
    value: "Tekirdag Corlu Airpot",
    label: "Tekirdag Corlu Airpot",
  },
  {
    value: "Thirubanathapuram",
    label: "Thirubanathapuram Airport",
  },
  {
    value: "Tokat Airpot",
    label: "Tokat Airpot",
  },
  {
    value: "Tokyo Haneda",
    label: "Tokyo Haneda Airport",
  },
  {
    value: "Tokyo Narita",
    label: "Tokyo Narita Airport",
  },
  {
    value: "Toronto",
    label: "Toronto Airport",
  },
  {
    value: "Trabzon Airpot",
    label: "Trabzon Airpot",
  },
  {
    value: "Tunis",
    label: "Tunis Airport",
  },
  {
    value: "Turbat International Airport",
    label: "Turbat International Airport",
  },
  {
    value: "Usak Airpot",
    label: "Usak Airpot",
  },
  {
    value: "Van Ferit Melen Airpot",
    label: "Van Ferit Melen Airpot",
  },
  {
    value: "Venice",
    label: "Venice Airport",
  },
  {
    value: "Vienna",
    label: "Vienna Airport",
  },
  {
    value: "Warsaw",
    label: "Warsaw Airport",
  },
  {
    value: "Washington",
    label: "Washington Airport",
  },

  {
    value: "Yangon",
    label: "Yangon Airport",
  },
  {
    value: "Yenisehir Airpot",
    label: "Yenisehir Airpot",
  },
  {
    value: "Yunseli Airpot",
    label: "Yunseli Airpot",
  },
  {
    value: "Zafer Airpot",
    label: "Zafer Airpot",
  },
  {
    value: "Zagreb",
    label: "Zagreb Airport",
  },
  {
    value: "Zonguldak Airpot",
    label: "Zonguldak Airpot",
  },
  {
    value: "Zürich",
    label: "Zürich Airport",
  },
]
