import React, { Fragment } from "react"

import Header from "../components/layouts/home/Header"
import Hero from "../components/layouts/home/Hero"
import Main from "../components/layouts/home/Main"
import Footer from "../components/layouts/footer/Footer"

export default function Home() {
  return (
    <Fragment>
      <Header showDownloadForm={true} />
      <Main>
        <Hero />
      </Main>
      <Footer />
    </Fragment>
  )
}
