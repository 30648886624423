import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useToast } from "@chakra-ui/core"
import FileSaver from "file-saver"
import Papa from "papaparse"
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"

import { uploadMultipeResultsViaCSV } from "../../../services/api"
import { getSpecimenType } from "../../../utils/common"
import Badge from "../../common/Badge"

import "./MultiResultUploadViaCSVModal.scss"
import dayjs from "dayjs"

export default function MultipleResultUploadModal(props) {
  const { openModal, toggle, updateData } = props
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [csvData, setCSVData] = useState([])
  const [isCSVImported, setIsCSVImported] = useState(false)
  const [isHeadersValidated, setIsHeadersValidated] = useState(false)
  const [isCSVDataValidated, setIsCSVDataValidated] = useState(false)
  const [defectiveHeaders, setDefectiveHeaders] = useState([])
  const [defectiveRows, setDefectiveRows] = useState([])

  const { handleSubmit, register } = useForm({
    mode: "onSubmit",
    defaultValues: {
      uploadCSV: null,
    },
  })

  let csvResultHeaders = [
    "registrationId",
    "labId",
    "testType",
    "specimen",
    "rapidPcrResult",
    "result",
    "antibodyResult",
    "antibodyResultRange",
    "iggResult",
    "iggResultRange",
    "extractionKit",
    "amplificationKit",
    "orf1AbGene",
    "rdRpGene",
    "nGene",
    "eGene",
    "batchNo",
    "batchDate",
    "instrument",
  ]

  const onSubmit = async () => {
    const params = { data: { resultData: csvData } }
    try {
      setIsLoading(true)
      await uploadMultipeResultsViaCSV(params)
      toggle()
      updateData()
      toast({
        title: "Result uploading process initiated successfully",
        description: "Result uploading process initiated successfully",
        status: "success",
        duration: 4000,
      })
    } catch (error) {
      toast({
        title: "Error while uploading result",
        description: error?.response?.data?.errors?.message || error?.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true} size="lg">
      <ModalHeader toggle={toggle}>Upload Multiple Results - Import CSV</ModalHeader>
      <form className="cms-contact-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <div className="container">
            <div className="cms-contact-form">
              <div className="row">
                <div className="col-lg-6">
                  <button
                    type="button"
                    className="btn btn-outline w-100"
                    onClick={() => {
                      const url =
                        "https://firebasestorage.googleapis.com/v0/b/gcc-diagnostic.appspot.com/o/map-guides%2Fresult_map_guide.xlsx?alt=media&token=ce01647d-c4ca-4bb7-806c-030314e1a93f"
                      FileSaver.saveAs(url)
                    }}
                  >
                    Download Guide
                  </button>
                </div>
                <div className="col-lg-6">
                  <button
                    type="button"
                    className="btn btn-outline w-100"
                    onClick={() => {
                      const url =
                        "https://firebasestorage.googleapis.com/v0/b/gcc-diagnostic.appspot.com/o/csv-samples%2Fsample_sheet_results.csv?alt=media&token=ac86aa31-c5d7-4236-9002-2ac207011bb8"
                      FileSaver.saveAs(url)
                    }}
                  >
                    Download Sample
                  </button>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 card p-3">
                  <h6>Choose File</h6>
                  <div>
                    <input
                      type="file"
                      name="uploadCSV"
                      ref={register}
                      onChange={e => {
                        const [file] = e.target.files
                        if (file) {
                          setIsCSVImported(true)

                          // Validate CSV
                          Papa.parse(file, {
                            complete: function (results) {
                              results?.data?.pop() // Removing last empty element

                              const headers = results?.data.shift()
                              const tempCSVData = results?.data

                              let tempDefectiveHeader = []

                              // Validate headers
                              headers.forEach((header, idx) => {
                                const isPresent = csvResultHeaders.includes(header)
                                if (!isPresent) tempDefectiveHeader.push({ header, colNo: idx + 1 })
                              })

                              const isCSVHeadersValidated = tempDefectiveHeader?.length === 0 ? true : false
                              if (isCSVHeadersValidated) setIsHeadersValidated(true)
                              setDefectiveHeaders(tempDefectiveHeader)

                              /*
                                index - value
                                0-registrationId i.e MR#
                                1-labId
                                2-testType
                                3-specimen
                                4-rapidPcrResult
                                5-result
                                6-antibodyResult
                                7-antibodyResultRange
                                8-iggResult
                                9-iggResultRange
                                10-extractionKit
                                11-amplificationKit
                                12-orf1AbGene
                                13-rdRpGene
                                14-nGene
                                15-eGene
                                16-batchNo
                                17-batchDate
                                18-instrument
                              */

                              if (isCSVHeadersValidated) {
                                let tempDefectiveRow = []
                                let csvJsonData = []

                                tempCSVData?.forEach(data => {
                                  const registrationId = data[0]
                                  const labId = data[1]
                                  const testType = data[2]
                                  const specimen = data[3]
                                  const rapidPcrResult = data[4]
                                  const result = data[5]
                                  const antibodyResult = data[6]
                                  const antibodyResultRange = data[7]
                                  const iggResult = data[8]
                                  const iggResultRange = data[9]
                                  const extractionKit = data[10]
                                  const amplificationKit = data[11]
                                  const orf1AbGene = data[12]
                                  const rdRpGene = data[13]
                                  const nGene = data[14]
                                  const eGene = data[15]
                                  const batchNo = data[16]
                                  const batchDate = data[17]
                                  const instrument = data[18]

                                  csvJsonData.push({
                                    registrationId,
                                    labId,
                                    testType,
                                    specimen,
                                    rapidPcrResult,
                                    result,
                                    antibodyResult,
                                    antibodyResultRange,
                                    iggResult,
                                    iggResultRange,
                                    extractionKit,
                                    amplificationKit,
                                    orf1AbGene,
                                    rdRpGene,
                                    nGene,
                                    eGene,
                                    batchNo,
                                    batchDate,
                                    instrument,
                                  })
                                })

                                const lineNo = 2

                                csvJsonData?.forEach((data, idx) => {
                                  // MR #
                                  if (data?.registrationId === "") {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "MR# should not be empty",
                                    })
                                  } else if (data?.registrationId?.length < 6) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid MR#",
                                    })
                                  }

                                  // labId
                                  if (data?.labId === "") {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "labId should not be empty",
                                    })
                                  }

                                  // testType
                                  if (data?.specimen === "" && data?.testType === "rapidPcr") {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "specimen should not be empty",
                                    })
                                  } else if (
                                    getSpecimenType(data?.specimen) === undefined &&
                                    data?.testType === "rapidPcr"
                                  ) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid specimen",
                                    })
                                  }

                                  // specimen
                                  if (
                                    data?.rapidPcrResult !== "" &&
                                    data?.rapidPcrResult !== "detected" &&
                                    data?.rapidPcrResult !== "not-detected"
                                  ) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid rapidPcrResult",
                                    })
                                  }

                                  // rapidPcrResult
                                  if (
                                    data?.result !== "" &&
                                    data?.result !== "detected" &&
                                    data?.result !== "not-detected"
                                  ) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid result",
                                    })
                                  }

                                  // result
                                  if (
                                    data?.antibodyResult !== "" &&
                                    data?.antibodyResult !== "positive" &&
                                    data?.antibodyResult !== "negative"
                                  ) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid antibodyResult",
                                    })
                                  }

                                  // antibodyResult
                                  const decimelPattern = new RegExp(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/)
                                  if (
                                    data?.antibodyResultRange !== "" &&
                                    !decimelPattern.test(data?.antibodyResultRange)
                                  ) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid antibodyResultRange",
                                    })
                                  }

                                  // antibodyResultRange
                                  if (
                                    data?.iggResult !== "" &&
                                    data?.iggResult !== "positive" &&
                                    data?.iggResult !== "negative"
                                  ) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid iggResult",
                                    })
                                  }

                                  // iggResult
                                  if (data?.iggResultRange !== "" && !decimelPattern.test(data?.iggResultRange)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid iggResultRange",
                                    })
                                  }

                                  // extractionKit
                                  if (
                                    data?.extractionKit !== "" &&
                                    data?.extractionKit !== "maccura" &&
                                    data?.extractionKit !== "zybio"
                                  ) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid extractionKit",
                                    })
                                  }

                                  // amplificationKit
                                  if (
                                    data?.amplificationKit !== "" &&
                                    data?.amplificationKit !== "seegene" &&
                                    data?.amplificationKit !== "bioPerfectus" &&
                                    data?.amplificationKit !== "daAnGene"
                                  ) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid amplificationKit",
                                    })
                                  }

                                  // orf1AbGene
                                  if (data?.orf1AbGene !== "" && !decimelPattern.test(data?.orf1AbGene)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid orf1AbGene",
                                    })
                                  }

                                  // rdRpGene
                                  if (data?.rdRpGene !== "" && !decimelPattern.test(data?.rdRpGene)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid rdRpGene",
                                    })
                                  }

                                  // nGene
                                  if (data?.nGene !== "" && !decimelPattern.test(data?.nGene)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid nGene",
                                    })
                                  }

                                  // eGene
                                  if (data?.eGene !== "" && !decimelPattern.test(data?.eGene)) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid eGene",
                                    })
                                  }

                                  // batchNo
                                  // No check for now

                                  // batchDate
                                  if (
                                    data?.batchDate !== "" &&
                                    data?.batchDate !== `${dayjs(data?.batchDate).format("YYYY-MM-DD")}`
                                  ) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "batchDate should not be in format (YYYY-MM-DD) i.e 2022-12-31",
                                    })
                                  }

                                  // instrument
                                  if (
                                    data?.instrument !== "" &&
                                    data?.instrument !== "CFX96 Touch Real-Time PCR Detection System - Bio-Rad, USA"
                                  ) {
                                    tempDefectiveRow.push({
                                      lineNo: lineNo + idx,
                                      error: "Invalid instrument",
                                    })
                                  }
                                })

                                const isCSVRowsValidated = tempDefectiveRow?.length === 0 ? true : false

                                if (isCSVRowsValidated) {
                                  setIsCSVDataValidated(isCSVRowsValidated)
                                  setCSVData(csvJsonData)
                                }

                                setDefectiveRows(tempDefectiveRow)
                              }
                            },
                          })
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3">
                  {isCSVImported && <Badge type={isHeadersValidated ? "success" : "danger"} label={"CSV Headers"} />}
                </div>
                <div className="col-3">
                  {isCSVImported && <Badge type={isCSVDataValidated ? "success" : "danger"} label={"CSV Data"} />}
                </div>
              </div>
              {defectiveHeaders?.length > 0 && (
                <>
                  <span className="text-danger font-xsm text-bold mt-2">Error</span>

                  {defectiveHeaders?.map((data, idx) => {
                    return (
                      <div
                        className="w-100 mt-1"
                        style={{ backgroundColor: "#feeceb", borderRadius: "4px" }}
                        key={`error-${idx}`}
                      >
                        <span className="font-xsm text-danger ml-1" key={`defective-headers-${idx}`}>
                          col#{data?.colNo} - title: {data?.header}{" "}
                        </span>
                      </div>
                    )
                  })}
                </>
              )}

              {defectiveRows?.length > 0 && (
                <>
                  <span className="text-danger font-xsm text-bold mt-2">Error</span>

                  {defectiveRows?.map((data, idx) => {
                    return (
                      <div
                        className="w-100 mt-1"
                        style={{ backgroundColor: "#feeceb", borderRadius: "4px" }}
                        key={`error-${idx}`}
                      >
                        <span className="font-xsm text-danger ml-1" key={`defective-rows-${idx}`}>
                          line#{data?.lineNo} - {data?.error}{" "}
                        </span>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="submit"
            className="btn btn-secondary col-lg-2"
            disabled={isLoading || !isHeadersValidated || !isCSVDataValidated}
          >
            Upload
            {isLoading && <Spinner color="default" className="ml-2" size="sm" />}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
