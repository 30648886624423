import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { useState, useEffect } from "react"
import { useState as useOvermindState } from "../../overmind"

export default function HardDeleteConfirmationModal(props) {
  const {
    openModal,
    toggle,
    callback,
    callbackData = null,
    headerText = "",
    bodyText = "",
    headerClass = "",
    headerTextClass = "",
    okayBtnClass = "btn-primary",
  } = props

  const { user } = useOvermindState().auth
  const [value, setValue] = useState("")
  const [canDelete, setCanDelete] = useState(true)
  const confirmationText = user?.email

  useEffect(() => {
    if (value === confirmationText) {
      setCanDelete(false)
    } else {
      setCanDelete(true)
    }

    // eslint-disable-next-line
  }, [value])

  const onSubmit = () => {
    toggle()
    if (callback) {
      callback(callbackData)
    }
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true} backdrop="static">
      <ModalHeader className={headerClass}>
        <div>
          <h5 className={headerTextClass}>{headerText}</h5>
        </div>
      </ModalHeader>
      <ModalBody>
        <p className="text-dark mb-2" dangerouslySetInnerHTML={{ __html: bodyText }} />
        <p className="text-dark mb-2">
          Type <strong>{confirmationText}</strong> below
        </p>
        <input
          style={{
            height: "40px",
            fontSize: "16px",
            background: "rgba(220, 53, 69, 0.1)",
          }}
          className="delete-confirmation mb-2"
          type="text"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <ModalFooter className="pb-0 px-0">
          <button onClick={toggle} className="btn btn-secondary">
            Cancel
          </button>
          <button onClick={onSubmit} className={`btn ${okayBtnClass}`} disabled={canDelete}>
            Yes
          </button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}
