import React, { Fragment, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useToast } from "@chakra-ui/core"

import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import Footer from "../components/layouts/footer/Footer"
import Switch from "../components/common/Switch"

import { embassies, embassyList, references, referencesList } from "../utils/common"
import { useState as useOvermindState } from "../overmind"
import { AllRoutesMap } from "../routes/RoutesConfig"
import { getRestrictedSearchDetails, restrictSearch } from "../services/api"

function ManageRestrictions() {
  const { user } = useOvermindState().auth
  const history = useHistory()
  const toast = useToast()

  const [embassyData, setEmbassyData] = useState([])
  const [referenceData, setReferenceData] = useState([])

  // List varibales

  useEffect(() => {
    initSearch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initSearch = async () => {
    try {
      const res = await getRestrictedSearchDetails()
      const embassyResult = res?.data?.restriction[0]?.embassy
      const referenceResult = res?.data?.restriction[0]?.reference

      const embassyRawdata = getUpdatedData({ data: embassyResult, type: "embassy" })
      const referenceRawdata = getUpdatedData({ data: referenceResult, type: "reference" })

      setEmbassyData(embassyRawdata)
      setReferenceData(referenceRawdata)
    } catch (error) {
      console.log(error)
      toast({
        title: "There was an error while getting information",
        description: "There was an error while getting information",
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const getUpdatedData = params => {
    const { data, type } = params

    if (type === "embassy") {
      const updatedData = embassyList?.map(embassy =>
        data?.includes(embassy) ? { embassy, isChecked: true } : { embassy }
      )
      return updatedData
    }

    if (type === "reference") {
      const updatedData = referencesList?.map(reference =>
        data?.includes(reference) ? { reference, isChecked: true } : { reference }
      )
      return updatedData
    }
  }

  const onChange = async e => {
    const { name, checked } = e.target
    let data = {}

    //--- For Embassy ---//
    if (embassyList.includes(name)) {
      const updatedEmbassyData = embassyData?.map(data =>
        name === data?.embassy ? { embassy: data?.embassy, isChecked: checked } : { ...data }
      )

      data = { embassy: name, isSearchRestricted: checked }
      setEmbassyData(updatedEmbassyData)
    }

    //--- For Reference ---//
    if (referencesList.includes(name)) {
      const updatedReferenceData = referenceData?.map(data =>
        name === data?.reference ? { reference: data?.reference, isChecked: checked } : { ...data }
      )

      data = { reference: name, isSearchRestricted: checked }
      setReferenceData(updatedReferenceData)
    }

    restrictSearch(data)
  }

  const isAdmin = user?.role === "admin" ? true : false
  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay" style={{ backgroundColor: "#092031" }}></div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="text-white mb-0">
                    <i className="fas fa-cogs mr-4"></i>Global Clinical Care Restrictions Portal
                  </h3>
                </div>
                <div className="col-md-4 text-right">
                  <button
                    className="btn btn-secondary"
                    title={isAdmin ? "Manage" : "Home"}
                    onClick={() => {
                      const route = isAdmin ? AllRoutesMap.manage : AllRoutesMap.landing
                      history.push(route)
                    }}
                  >
                    <i className={`fas fa-${isAdmin ? "cogs" : "home"} mr-2`}></i>
                    {isAdmin ? "Manage" : "Home"}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid cms-contact-form mt-5">
            <div className="card p-3">
              <div className="row mb-2">
                <div className="col-12">
                  <h4>Embassy Restrictions</h4>
                </div>
              </div>
              {embassies?.map(({ label, value: name }, idx) => {
                return (
                  <div key={`Restriction-${label}-${idx}`} className="row hover-highlight mb-2 mx-1">
                    <div className="col-md-7 mt-2">
                      <label>{label} Embassy</label>
                    </div>
                    <div className="col-md-5 mt-2">
                      <Switch
                        name={name}
                        value={embassyData[idx]?.embassy}
                        onChange={onChange}
                        checked={embassyData[idx]?.isChecked}
                      />
                    </div>
                  </div>
                )
              })}

              <div className="row mt-4">
                <div className="col-12">
                  <h4>Company Restrictions</h4>
                </div>
              </div>

              {references?.map(({ label, value: name }, idx) => {
                return (
                  <div key={`Restriction-${label}-${idx}`} className="row hover-highlight mb-2 mx-1">
                    <div className="col-md-7 mt-2">
                      <label>{label}</label>
                    </div>
                    <div className="col-md-5 mt-2">
                      <Switch
                        name={name}
                        value={referenceData[idx]?.embassy}
                        onChange={onChange}
                        checked={referenceData[idx]?.isChecked}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Main>
      <Footer />
    </Fragment>
  )
}

export default ManageRestrictions
