import { Spinner } from "reactstrap"
import React, { useState } from "react"
import { nanoid } from "nanoid"
import cn from "classnames"
import { storage } from "../../config/firebaseClient"

export default function FileUploader(props) {
  const [uploading, setUploading] = useState(false)

  const handleChange = e => {
    const [file] = e.target.files
    if (!file) {
      return
    }
    const blob = new Blob([file], { type: file.type })
    const extension = file.name.split(".").pop()
    const randomId = nanoid()

    const uploadTask = storage.ref(`/attachments/${randomId}.${extension}`).put(blob)

    setUploading(true)
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      snapShot => {
        //takes a snap shot of the process as it is happening
        console.info(snapShot)
      },
      err => {
        //catches the errors
        console.log(err)
        setUploading(false)
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref("attachments")
          .child(`${randomId}.${extension}`)
          .getDownloadURL()
          .then(fireBaseUrl => {
            props.onChange(fireBaseUrl)
            setUploading(false)
          })
          .catch(error => {
            setUploading(false)
          })
      }
    )
  }

  return (
    <span className={cn("d-flex", props.className)}>
      {uploading && <Spinner size="sm" className="mr-2" />}
      <input type="file" accept={props.accept} onChange={handleChange} />
    </span>
  )
}
