import React, { Fragment, useEffect, useState } from "react"
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap"
import { useToast } from "@chakra-ui/core"
import { getRegistrationDetailsByPassportAndCnic } from "../../services/api"
import ViewPatientHistorySheetLayout from "../report/view-patient-history-sheet-layout"
import createPDFSheet from "../report/create-patient-history-sheet"

export default function ViewPatientHistoryModal(props) {
  const { openModal, toggle, passport, cnic } = props
  const [patientData, setPatientData] = useState([])
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    if (openModal) {
      fetchPatientDetailsByIdentifier()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  const fetchPatientDetailsByIdentifier = async () => {
    try {
      setLoading(true)
      const data = { passport, cnic }
      const res = await getRegistrationDetailsByPassportAndCnic(data)
      setPatientData(res?.data)
    } catch (error) {
      toast({
        title: "There was an error generating records.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Modal isOpen={openModal} toggle={toggle} centered={true} size="xl">
        <ModalHeader toggle={toggle}>
          <div className="d-flex">
            <button
              className="btn btn-secondary ml-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                createPDFSheet({ patientData, isDownload: true })
              }}
            >
              <i className="fas fa-download space-right"></i>
              Download
            </button>

            <button
              className="btn btn-secondary ml-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                createPDFSheet({ patientData, isDownload: false })
              }}
            >
              <i className="fas fa-print space-right"></i>
              Print
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          {loading && (
            <div className="container py-5">
              <div className="row">
                <div className="col-12 text-center">
                  <Spinner color="primary" />
                </div>
              </div>
            </div>
          )}
          {!loading && patientData && <ViewPatientHistorySheetLayout data={patientData} />}
        </ModalBody>
      </Modal>
    </Fragment>
  )
}
