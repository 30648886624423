import React, { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import { Alert, Spinner, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown, Button } from "reactstrap"
import { useToast } from "@chakra-ui/core"

import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import Footer from "../components/layouts/footer/Footer"
import createRegistrationPDF from "../components/pdf-reports/create-registration"
import createInvoicePDF from "../components/invoice/create-invoice"
import createGeneralConsentPDF from "../components/consent/create-general-consent"
import createPassengerConsentPDF from "../components/consent/create-passenger-consent"
import createAllFormsGeneralPDF from "../components/combined-forms/create-combined-forms-general"
import createAllFormsPassengerPDF from "../components/combined-forms/create-combined-forms-passenger"
import { getFromQueryString } from "../utils/common"
import { getRegistrationDetails } from "../services/api"
import { AllRoutesMap } from "../routes/RoutesConfig"

export default function RegistrationSuccess() {
  const labId = getFromQueryString("labId")
  const registrationId = getFromQueryString("registrationId")
  const patientId = getFromQueryString("patientId")
  const easyRegister = getFromQueryString("easyRegister")

  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [printDropdownOpen, setPrintDropdownOpen] = useState(false)

  if (!labId || !registrationId || !patientId) {
    return <Alert color="warning border-0 mb-0">Lab ID, Lab Serial or MR Number seems to be missing</Alert>
  }

  const togglePrintDropdown = () => setPrintDropdownOpen(prevState => !prevState)

  const handlePrintConsent = async () => {
    try {
      setLoading(true)
      const res = await getRegistrationDetails(registrationId)
      const data = res.data

      if (data) {
        const { patient, ...rest } = data
        const registration = rest
        await downloadConsentForm({ patient, registration })
      }
    } catch (error) {
      toast({
        title: "There was an error generating consent form.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const downloadConsentForm = async ({ patient, registration }) => {
    try {
      if (registration.registrationType === "general") {
        await createGeneralConsentPDF({ patient, registration })
      } else if (registration.registrationType === "passenger") {
        await createPassengerConsentPDF({ patient, registration })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handlePrintAllForms = async () => {
    try {
      setLoading(true)
      const res = await getRegistrationDetails(registrationId)
      const data = res.data

      if (data) {
        const { patient, ...rest } = data
        const registration = rest
        await createAllFormsPDF({ patient, registration })
      }
    } catch (error) {
      toast({
        title: "There was an error generating all forms.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const createAllFormsPDF = async ({ patient, registration }) => {
    try {
      if (registration.registrationType === "general") {
        await createAllFormsGeneralPDF({ patient, registration })
      } else if (registration.registrationType === "passenger") {
        await createAllFormsPassengerPDF({ patient, registration })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handlePrintRegistration = async () => {
    try {
      setLoading(true)
      const res = await getRegistrationDetails(registrationId)
      const data = res.data

      if (data) {
        const { patient, ...rest } = data
        const registration = rest
        await createRegistrationPDF({ patient, registration })
      }
    } catch (error) {
      toast({
        title: "There was an error generating registration form.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handlePrintInvoice = async () => {
    try {
      setLoading(true)
      const res = await getRegistrationDetails(registrationId)
      const data = res.data

      if (data) {
        const { patient, ...rest } = data
        const registration = rest
        await createInvoicePDF({ patient, registration })
      }
    } catch (error) {
      toast({
        title: "There was an error generating invoice form.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <h2 className="text-white mb-0">
                    <i className="fas fa-address-card mr-4"></i>
                    Passenger Registration Success
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5">
            <div className="row">
              <div className="col-12" style={{ minHeight: "calc(100vh - 410px)" }}>
                <div className="row">
                  <div className="col-12">
                    <Alert
                      color="success"
                      style={{
                        width: "100%",
                      }}
                      isOpen={true}
                    >
                      <i className="fa fa-check-circle text-success mr-2" />
                      Registration was successful.
                    </Alert>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-3 mb-2 d-flex">
                    {!easyRegister ? (
                      <>
                        <Link to={AllRoutesMap.staff} className="btn btn-primary">
                          <i className="fas fa-home space-right"></i>
                          Home
                        </Link>
                        {/* Adding register button here */}

                        <Link to={AllRoutesMap.registration} className="btn btn-primary ml-2">
                          <i className="fas fa-plus mr-2"></i>
                          Register
                        </Link>
                      </>
                    ) : (
                      <Link to={AllRoutesMap.easyRegistration} className="btn btn-primary">
                        <i className="fas fa-home space-right"></i>
                        Back
                      </Link>
                    )}
                  </div>
                  <div className="col-sm-9 mb-2">
                    <div className="row">
                      <div className="col-12">
                        {!easyRegister && (
                          <div className="d-flex align-items-center justify-content-end">
                            <Link
                              to={`${AllRoutesMap.barcode}?labId=${labId}&registrationId=${registrationId}`}
                              className="btn btn-secondary mr-2"
                              target="_blank"
                            >
                              Print MR#
                            </Link>
                            <ButtonDropdown isOpen={printDropdownOpen} toggle={togglePrintDropdown}>
                              <Button id="caret" color="primary" onClick={() => handlePrintAllForms()}>
                                Print All Forms
                                {loading && <Spinner color="default" className="ml-2" size="sm" />}
                              </Button>
                              <DropdownToggle split color="primary" className="ml-0" />
                              <DropdownMenu right>
                                <DropdownItem onClick={() => handlePrintConsent()}>Download Consent Form</DropdownItem>
                                <DropdownItem onClick={() => handlePrintRegistration()}>
                                  Download Registration Form
                                </DropdownItem>
                                <DropdownItem onClick={() => handlePrintInvoice()}>Download Invoice</DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Main>
      <Footer />
    </Fragment>
  )
}
