import { createOvermind } from "overmind"
import { namespaced } from "overmind/config"
import { createHook, createStateHook, createActionsHook } from "overmind-react"

import * as auth from "./auth"

export const config = namespaced({
  auth,
})

export const overmind = createOvermind(config, { devtools: "localhost:3031" })
export const useOvermind = createHook()

export const useState = createStateHook()
export const useActions = createActionsHook()
