import jsbarcode from "jsbarcode"
import { rgb, grayscale } from "pdf-lib"

export const openPDFNewTab = (data, isNewTab = true) => {
  //for chrome of ios
  if (navigator.userAgent.match("CriOS")) {
    const fileData = [data]
    const reader = new FileReader()
    const blob = new Blob(fileData, { type: "application/pdf" })
    reader.onload = function () {
      window.location.href = reader.result
    }
    reader.readAsDataURL(blob)
  }
  // for IE and edge browser
  else if (window.navigator.msSaveOrOpenBlob) {
    const fileData = [data]
    const blobObject = new Blob(fileData)
    window.navigator.msSaveBlob(blobObject)
  }
  //for all other browser
  else {
    const fileData = [data]
    const blob = new Blob(fileData, { type: "application/pdf" })
    const url = window.URL.createObjectURL(blob)
    setTimeout(function () {
      window.open(url, isNewTab ? "_blank" : "_self")
    }, 100)
  }
}

export const renderHeader = title => {
  return (
    <table
      style={{
        width: "100%",
        margin: "auto",
        padding: "1rem 0",
        borderCollapse: "collapse",
        borderSpacing: "0",
        border: "0px solid",
      }}
    >
      <tbody>
        <tr>
          <td align="center" style={{ border: "none", paddingLeft: 0, paddingRight: 0 }}>
            <div className="d-flex align-items-center justify-content-between">
              <img
                src="assets/images/logo-dark.png"
                style={{
                  maxHeight: "120px",
                }}
                alt="Emirates Logo"
              />

              <div style={{ height: "5rem" }}>
                <h4
                  style={{
                    width: "100%",
                    margin: "0rem auto",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {title}
                </h4>
              </div>
              <img
                src="assets/images/blank.png"
                style={{
                  maxHeight: "120px",
                }}
                alt="Blank Space For Profile DP"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export const renderBarcode = async (result, config, registrationType) => {
  const canvas = document.createElement("canvas")
  jsbarcode(canvas, result.registration?.registrationId, {
    width: 1,
    height: 50,
    displayValue: false,
    margin: 0,
  })
  const base64URL = await config.pdfDoc.embedPng(canvas.toDataURL("image/png"))
  config.page.drawText(result.registration?.labId || "--------", {
    x: 280,
    y: 68,
    size: 10,
    font: config.helveticaFont,
    color: rgb(0, 0, 0),
  })
  config.page.drawImage(base64URL, {
    x: 253,
    y: 10,
  })
}

export const renderProfileImage = async (result, config) => {
  // Fetch Profile image
  const pictureUrl = result.patient?.profilePictureUrl

  if (pictureUrl) {
    const imageBytes = await fetch(pictureUrl).then(res => res.arrayBuffer())
    // Embed the JPG image bytes and PNG image bytes
    let image
    if (pictureUrl.indexOf("png") > -1) {
      image = await config.pdfDoc.embedPng(imageBytes)
    } else {
      image = await config.pdfDoc.embedJpg(imageBytes)
    }
    // Get the width/height of the PNG image scaled down to 50% of its original size
    const dimensions = image.scaleToFit(80, 80)

    config.page.drawImage(image, {
      x: 484,
      y: 751,
      width: dimensions.width,
      height: dimensions.height,
    })
  }
}

export const getNumberOfPagesForPdfs = data => {
  const rowLimitPerPage = 25
  const coverPage = 1

  const numberOfPages = data.length === 0 ? 2 : Math.ceil(data.length / rowLimitPerPage) * 2

  return numberOfPages + coverPage
}

export const createTable = (rows, cols, isHeader, activePage, config, yPosition = 553) => {
  const { pdfDoc } = config
  let page = pdfDoc.getPage(activePage)
  const next = isHeader ? 0 : 24
  yPosition -= next
  //let jumpToNextPage = rows <= 18 && activePage === 0 ? 21 : rows > 18 && activePage === 0 ? 21 : 0
  //let jumpToNextPage = 0

  //Render rows - Container spanning the width of the page
  for (let row = 1; row <= rows; row++) {
    //if (row % jumpToNextPage === 0) {
    if (yPosition < 97) {
      activePage += 1
      page = pdfDoc.getPage(activePage)
      yPosition = 720
      rows -= row - 1
      row = 1
      //jumpToNextPage = 27
    }

    // if (yPosition < 97) {
    //   activePage++
    //   page = pdfDoc.getPage(activePage)
    //   yPosition = 720
    // }

    //Print rows&cols on page1 and accountant signature on page2
    renderContainer({
      page: page,
      x: 37,
      y: yPosition,
      width: 530,
      height: 24,
    })
    if (row <= rows - 2 || isHeader) {
      //Render cols - Vertical lines which divide the row container, thus making a CELL
      renderColumns({
        page: page,
        cols,
        yPosition,
      })
    }
    yPosition -= next
  }
}

//Table Creation Utilities
export const renderContainer = params => {
  const { page, x, y, width, height } = params
  page.drawRectangle({
    x: x,
    y: y,
    width: width,
    height: height,
    borderWidth: 1,
    borderColor: grayscale(0.5),
    opacity: 0.5,
    borderOpacity: 0.9,
  })
}
export const renderColumns = params => {
  const { page, cols } = params
  let { yPosition } = params

  const paddingLeft = 37
  const colSpacing = Math.ceil(530 / cols) //76
  let xConstant = Math.ceil(530 / cols)

  for (let col = 1; col < cols; col++) {
    renderColumnLines({
      page: page,
      yPosition: yPosition,
      xPosition: xConstant + paddingLeft,
    })
    xConstant += colSpacing
  }
}
export const renderColumnLines = params => {
  const { page, yPosition, xPosition } = params
  const lineSpacing = 24
  page.drawLine({
    start: { x: xPosition, y: yPosition },
    end: { x: xPosition, y: yPosition + lineSpacing },
    thickness: 1,
    borderColor: grayscale(0.5),
    color: rgb(0.5, 0.5, 0.5),
    opacity: 0.5,
  })
}

export const NumInWords = number => {
  const first = [
    "",
    "One ",
    "Two ",
    "Three ",
    "Four ",
    "Five ",
    "Six ",
    "Seven ",
    "Eight ",
    "Nine ",
    "Ten ",
    "Eleven ",
    "Twelve ",
    "Thirteen ",
    "Fourteen ",
    "Fifteen ",
    "Sixteen ",
    "Seventeen ",
    "Eighteen ",
    "Nineteen ",
  ]
  const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"]
  const mad = ["", "Thousand", "Million", "Billion", "Trillion"]
  let word = ""

  for (let i = 0; i < mad.length; i++) {
    let tempNumber = number % (100 * Math.pow(1000, i))
    if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
      if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
        word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + " " + word
      } else {
        word =
          tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
          "-" +
          first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
          mad[i] +
          " " +
          word
      }
    }

    tempNumber = number % Math.pow(1000, i + 1)
    if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
      word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + "Hunderd " + word
  }
  return word
}
