import React, { Fragment } from "react"

import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import Footer from "../components/layouts/footer/Footer"
import PatientSearchView from "../components/common/PatientSearchView"

const Patient = () => {
  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="text-white mb-0">
                    <i className="fas fa-user-circle mr-4"></i>
                    Global Clinical Care Patient
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <PatientSearchView />
          {/* To push footer a bit low */}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </section>
      </Main>
      <Footer />
    </Fragment>
  )
}

export default Patient
