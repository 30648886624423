import React from "react"

import * as yup from "yup"
import { Fragment } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { Spinner } from "reactstrap"

import Header from "../components/layouts/home/Header"
import Main from "../components/layouts/home/Main"
import Footer from "../components/layouts/footer/Footer"
import RequiredFieldMark from "../components/common/RequiredFieldMark"
import { useOvermind } from "../overmind"
import { updateUser } from "../services/api"
import { useToast } from "@chakra-ui/core"

const schema = yup.object().shape({
  email: yup.string().required("Email is a required field."),
  password: yup.string().required(),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
})

export default function ResetPassword() {
  const overmind = useOvermind()
  const toast = useToast()

  const [loading, setLoading] = React.useState(false)

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async ({ email, password }) => {
    setLoading(true)
    try {
      await updateUser({ email, password })
      overmind.actions.auth.logout()

      toast({
        title: "Password changed successfully",
        description: "Please login again with your new password.",
        status: "success",
        duration: 10000,
        isClosable: false,
      })
    } catch (error) {
      toast({
        title: "Password change error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: false,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Header />
      <Main>
        <section className="airline pb-5">
          <div className="airline-header" style={{ position: "relative" }}>
            <div className="background-overlay" style={{ backgroundColor: "#d61c21" }} />
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <h2 className="text-white mb-0">
                    <i className="fas fa-user-circle mr-4"></i>
                    Reset Password
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5">
            <div className="row">
              <div className="col-12 col-lg-8">
                <h4>Password Information</h4>
                <p className="lead">Please enter your new password to reset.</p>
                <form className="cms-contact-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-bold">
                          Email <RequiredFieldMark />
                        </label>

                        <input type="email" placeholder="Enter your email" name="email" ref={register} />
                        <span className="text-danger">{errors.email?.message}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Password <RequiredFieldMark />
                        </label>
                        <input type="password" placeholder="Enter your password" name="password" ref={register} />
                        <span className="text-danger">{errors.password?.message}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Confirm Password <RequiredFieldMark />
                        </label>
                        <input type="password" placeholder="Confirm Password" name="confirmPassword" ref={register} />
                        <span className="text-danger">{errors.confirmPassword?.message}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-6">
                      <button type="submit" className="btn btn-secondary" disabled={loading}>
                        <i className="fas fa-arrow-circle-right space-right"></i>
                        Confirm
                        {loading && <Spinner color="default" className="ml-2" size="sm" />}
                      </button>
                    </div>
                  </div>

                  {/* To push footer a bit low */}
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </form>{" "}
              </div>
            </div>
          </div>
        </section>
      </Main>
      <Footer />
    </Fragment>
  )
}
