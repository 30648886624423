import React, { useState } from "react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"

import RequiredFieldMark from "../common/RequiredFieldMark"
import { useToast } from "@chakra-ui/core"
import { addAirport } from "../../services/api"

const schema = yup.object().shape({
  name: yup.string().required("Airport name is required"),
})

export default function AddAirportModal(props) {
  const { openModal, toggle, updateAirport } = props
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
    mode: "onChange",
  })

  const onSubmit = async data => {
    const apiData = {
      label: data.name,
      value: data.name,
    }
    try {
      setLoading(true)

      const res = await addAirport(apiData)
      toggle()
      updateAirport(res?.data?.airport)
      toast({
        title: "Added airport successfully.",
        description: "Added airport successfully.",
        status: "success",
        duration: 4000,
      })
    } catch (err) {
      const error = err.response?.data?.errors?.message || "There was an error while adding airport."
      toast({
        title: "Error occurred while adding airport.",
        description: error,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Add Airport</ModalHeader>
      <ModalBody>
        <form className="cms-contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="text-bold">
                  Name <RequiredFieldMark />
                </label>
                <input type="text" placeholder="Enter Airport Name" name="name" ref={register} autoComplete="off" />
                <span className="text-danger">{errors.name?.message}</span>
              </div>
            </div>
          </div>

          <ModalFooter className="pb-0 px-0">
            <button type="submit" className="btn btn-secondary" disabled={loading}>
              Add Airport
              {loading && <Spinner color="default" className="ml-2" size="sm" />}
            </button>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  )
}
