import { Spinner, useToast } from "@chakra-ui/core"
import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { recoverPatient } from "../../services/api"

export default function PatientRecoverModal(props) {
  const {
    openModal,
    toggle,
    callback,
    data: { id, name },
  } = props
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const closeModal = () => {
    toggle()
    if (callback) {
      callback()
    }
  }

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      await recoverPatient(id)
      closeModal()
      toast({
        title: "Patient recovered successfully.",
        description: "Patient recovered successfully.",
        status: "success",
        duration: 4000,
      })
    } catch (err) {
      const error = err.response?.data?.errors?.message || "There was an error while recovering patient."
      toast({
        title: "Error occurred while recovering patient.",
        description: error,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} className="bg-success">
        <div>
          <h5 className="text-white">Patient Recovery Confirmation</h5>
        </div>
      </ModalHeader>
      <ModalBody>
        <p className="text-dark">
          Do you really want to recover <b>{name}</b>?
        </p>
        <ModalFooter className="pb-0 px-0">
          <button onClick={toggle} className="btn btn-secondary">
            Cancel
          </button>
          <button onClick={onSubmit} className="btn btn-success">
            Yes
            {isLoading && <Spinner color="default" className="ml-2" size="sm" />}
          </button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}
