import { Spinner, useToast } from "@chakra-ui/core"
import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { softDeletePatient, deletePatient } from "../../services/api"

export default function DeletePatientModal(props) {
  const {
    openModal,
    toggle,
    callback,
    data: { id, name },
    isHardDelete = false,
  } = props
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const closeModal = () => {
    toggle()
    if (callback) {
      callback()
    }
  }

  const onSubmit = async () => {
    try {
      setIsLoading(true)

      if (isHardDelete) {
        await deletePatient(id)
      } else {
        await softDeletePatient(id)
      }

      closeModal()
      toast({
        title: "Patient deleted successfully.",
        description: "Patient deleted successfully.",
        status: "success",
        duration: 4000,
      })
    } catch (err) {
      const error = err.response?.data?.errors?.message || "There was an error while deleting patient."
      toast({
        title: "Error occurred while deleting patient.",
        description: error,
        status: "error",
        duration: 4000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={openModal} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} className="bg-danger">
        <div>
          <h5 className="text-white">Delete Patient Confirmation</h5>
        </div>
      </ModalHeader>
      <ModalBody>
        <p className="text-dark">
          Do you really want to delete <b>{name}</b> {isHardDelete && <b>permenantly</b>}?
        </p>
        <ModalFooter className="pb-0 px-0">
          <button onClick={toggle} className="btn btn-secondary">
            Cancel
          </button>
          <button onClick={onSubmit} className="btn btn-danger">
            Yes
            {isLoading && <Spinner color="default" className="ml-2" size="sm" />}
          </button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}
